import { Column, Row } from "components/reusable/GenericStyledComponents";
import StyledText from "components/reusable/StyledText";
import React, { useState } from "react";
import styled from "styled-components";
import { Pylon, Pylons, MATURITY } from "types";
import { precision } from "utils/numbers";
import { InputNumber } from "antd";
import { CaretUpOutlined } from "@ant-design/icons";

type Props = {
  pylons: Pylons;
  setPylons: (val: Pylons) => void;
  sticky_model: number;
};

const PylonsComponent: React.FC<Props> = ({
  pylons,
  setPylons,
  sticky_model,
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <Column style={{ gap: "2rem", width: "100%", alignItems: "center" }}>
        <Line />
        <ListTitle
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <h1>Pylons</h1>
          <div>
            <CaretUpOutlined
              style={{
                transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                transition: "all ease 300ms",
              }}
            />
          </div>
          <StyledText fontSize={16} fontWeight={16}>
            {Object.keys(pylons).length} items
          </StyledText>
        </ListTitle>
        {expanded && (
          <div>
            {Object.keys(pylons).map((p1, idx1) => {
              return (
                <Column key={p1} style={{ margin: ".5rem" }}>
                  <div
                    style={{
                      display: "inline-grid",
                      gridAutoFlow: "column",
                      gridAutoColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
                    }}
                  >
                    <StyledText fontSize={20} fontWeight={20}>
                      {sticky_model
                        ? MATURITY[p1 as keyof typeof MATURITY]
                        : `T+${(idx1 + 1) * 3}M`}
                    </StyledText>
                    {React.Children.toArray(
                      Object.keys(pylons[p1]).map((p2, idx2) => {
                        return (
                          <InputNumber
                            type="number"
                            // step={step}
                            // formatter={(value) => {
                            //   console.log(value, `${precision((value || 0) * 100, 2)}%`);
                            //   return `${precision((value || 0) * 100, 2)}%`
                            // }
                            // }
                            // parser={(value) => {
                            //   console.log(value);
                            //   return parseFloat(value!.replace('%', ''))/100;
                            // }
                            // }
                            value={parseFloat(
                              (pylons[p1][p2] * 100).toFixed(2)
                            )}
                            onChange={(value) => {
                              setPylons({
                                ...pylons,
                                [p1]: {
                                  ...pylons[p1],
                                  [p2]: ((value as number) || 0) / 100,
                                },
                              });
                            }}
                          />
                        );
                      })
                    )}
                  </div>
                </Column>
              );
            })}
          </div>
        )}
      </Column>
    </>
  );
};

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #2d363b;
  margin-left: 14px;
  margin-right: 14px;
`;

const ListTitle = styled(Row)`
  z-index: 100;
  &:hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
  }
  position: sticky;
  top: 0;
`;

export default PylonsComponent;
