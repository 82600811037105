import { put, takeEvery } from "redux-saga/effects";
import { socketSendMessage } from "../socket/actions";
import { PayloadAction } from "@reduxjs/toolkit";

function* changeSettingsSaga({
  payload: { param_id, value },
}: PayloadAction<{ param_id: string; value: string }>) {
  const requestData = {
    headers: { command: "params.cmd.param.update" },
    param_id,
    value,
  };

  yield put(socketSendMessage(JSON.stringify(requestData)));
}

export function* watchSettingsAsync() {
  yield takeEvery("settings/updateSettings", changeSettingsSaga);
}
