import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import StyledText from "./StyledText";

const LoadingSpinner = ({ message }: { message?: string }) => {
  return (
    <div
      style={{
        display: "flex",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "50%",
      }}
    >
      <FontAwesomeIcon icon={faSpinner} spin />
      <StyledText
        fontSize={24}
        fontWeight={900}
        style={{ textAlign: "center" }}
      >
        {message ?? "Loading"}
      </StyledText>
    </div>
  );
};

export default LoadingSpinner;
