import React, { useEffect, useState } from "react";
import { Column, Row } from "components/reusable/GenericStyledComponents";
import {
  DeltaHedge,
  MarketImpactPayload,
  MATURITY,
  Maturity,
  Tenor,
  VolImpact,
} from "types";
import { Button, Form, InputNumber, Slider, Table, Tabs, Tag } from "antd";
import { timeUnixToDate, timeUnixToDatePretty } from "utils/time";
import { convertDelta } from "utils/delta";
import { useAppDispatch } from "store/store";
import {
  directionConfidenceSpotRefreshed,
  getDirectionConfidenceSpot,
  getDirectionConfidenceVol,
} from "store/events/actions";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import StyledText from "components/reusable/StyledText";
import { UndoOutlined } from "@ant-design/icons";

type Props = {
  marketImpact: MarketImpactPayload;
  setMarketImpact: React.Dispatch<React.SetStateAction<MarketImpactPayload>>;
  marketShift: MarketImpactPayload;
  setMarketShift: React.Dispatch<React.SetStateAction<MarketImpactPayload>>;
  sticky_model: number;
};

function reacursiveSet(obj: any, val: any) {
  if (typeof obj === "object") {
    // iterating over the object using for..in
    for (const keys in obj) {
      // checking if the current value is an object itself
      if (typeof obj[keys] === "object") {
        // if so then again calling the same function
        reacursiveSet(obj[keys], val);
      } else {
        // else getting the value and replacing single { with {{ and so on
        const keyValue = val;
        obj[keys] = keyValue;
      }
    }
  }
  return obj;
}

const MarketImpactSliders: React.FC<Props> = ({
  marketImpact,
  setMarketImpact,
  marketShift,
  setMarketShift,
  sticky_model,
}) => {
  const dispatch = useAppDispatch();
  const { room_id } = useSelector((state: RootState) => state.room);
  const swifts = useSelector((state: RootState) => state.swifts);
  const swift_id = Object.keys(swifts)[0];

  useEffect(() => {
    dispatch(getDirectionConfidenceSpot({ swift_id, room_id }));
    dispatch(getDirectionConfidenceVol({ swift_id, room_id }));
    setMarketShift({
      spot: 0,
      vol: reacursiveSet(
        JSON.parse(JSON.stringify({ ...marketImpact.vol })),
        0
      ),
    });
  }, [marketImpact]);

  return (
    <Column>
      <div>
        <Button
          onClick={() => {
            dispatch(getDirectionConfidenceSpot({ swift_id, room_id }));
            dispatch(getDirectionConfidenceVol({ swift_id, room_id }));
          }}
        >
          <UndoOutlined />
        </Button>
      </div>
      <Column>
        <StyledText fontSize={24} fontWeight={500}>
          Spot:
        </StyledText>
        <Row style={{ width: "100%" }}>
          <Column style={{ width: "100%" }}>
            {/* <Slider
              min={-10}
              max={10}
              step={0.1}
              onChange={(e) => {
                setMarketShift({ ...marketShift, spot: e });
              }}
              value={marketShift.spot}
            /> */}
          </Column>
          <Column>
            <InputNumber
              min={-100}
              max={100}
              style={{ margin: "0 16px" }}
              value={marketShift.spot}
              onChange={(e) => {
                setMarketShift({ ...marketShift, spot: e || 0 });
              }}
            />
          </Column>
          <Row style={{ gap: ".5rem" }}>
            {/* <StyledText fontSize={20} fontWeight={300}>
              Value after update:
            </StyledText> */}
            <StyledText fontSize={20} fontWeight={300}>
              {(marketImpact.spot + marketShift.spot).toFixed(2)}
            </StyledText>
          </Row>
        </Row>
      </Column>
      <Column>
        {React.Children.toArray(
          Object.entries(marketShift.vol).map(([delta_strike, val]) => {
            return (
              <Column
                style={{
                  width: "100%",
                  border: "1px solid black",
                  margin: ".5rem 0",
                  padding: ".5rem .5rem",
                  borderRadius: ".5rem",
                }}
              >
                <StyledText
                  fontSize={24}
                  fontWeight={500}
                  style={{ marginRight: ".75rem" }}
                >
                  {sticky_model ? convertDelta[delta_strike] : delta_strike}
                </StyledText>
                <Row>
                  {React.Children.toArray(
                    Object.entries(val).map(([tenor, v]) => {
                      return (
                        <Row style={{ width: "100%" }}>
                          <StyledText fontSize={22} fontWeight={400}>
                            {sticky_model
                              ? MATURITY[tenor as keyof typeof MATURITY]
                              : timeUnixToDatePretty(parseFloat(tenor))}
                            :
                          </StyledText>
                          <Row style={{ width: "100%" }}>
                            <Column>
                              <div>
                                <Column style={{ width: "100%" }}>
                                  {/* <Slider
                                    min={-10}
                                    max={10}
                                    step={0.1}
                                    onChange={(e) => {
                                      setMarketShift({
                                        ...marketShift,
                                        vol: {
                                          ...marketShift.vol,
                                          [Number(tenor)]: {
                                            ...marketShift.vol[Number(tenor)],
                                            [Number(delta_strike)]: e,
                                          },
                                        },
                                      });
                                    }}
                                    value={val}
                                  /> */}
                                </Column>
                                <Column>
                                  <InputNumber
                                    min={-100}
                                    max={100}
                                    style={{ margin: "0 16px" }}
                                    value={v}
                                    onChange={(e) => {
                                      setMarketShift({
                                        ...marketShift,
                                        vol: {
                                          ...marketShift.vol,
                                          [Number(delta_strike)]: {
                                            ...marketShift.vol[
                                              Number(delta_strike)
                                            ],
                                            [Number(tenor)]: e || 0,
                                          },
                                        },
                                      });
                                    }}
                                  />
                                </Column>
                              </div>
                              <Column>
                                <Row style={{ gap: ".5rem" }}>
                                  {/* <StyledText fontSize={20} fontWeight={300}>
                                    Value after update:
                                  </StyledText> */}
                                  <StyledText fontSize={20} fontWeight={300}>
                                    {(
                                      marketImpact.vol[
                                        parseFloat(delta_strike)
                                      ][parseFloat(tenor)] +
                                      marketShift.vol[parseFloat(delta_strike)][
                                        parseFloat(tenor)
                                      ]
                                    ).toFixed(2)}
                                  </StyledText>
                                </Row>
                              </Column>
                            </Column>
                          </Row>
                        </Row>
                      );
                    })
                  )}
                </Row>
              </Column>
            );
          })
        )}
      </Column>
    </Column>
  );
};
interface DeltaStrikeTabsProps {
  marketImpact: MarketImpactPayload;
  // setMarketImpact: React.Dispatch<React.SetStateAction<MarketImpactPayload>>;
  form: any;
  sticky_model: number;
}

const DeltaStrikeTabs: React.FC<DeltaStrikeTabsProps> = ({
  marketImpact,
  form,
  sticky_model,
}) => {
  const dispatch = useAppDispatch();
  const { room_id } = useSelector((state: RootState) => state.room);
  const swifts = useSelector((state: RootState) => state.swifts);
  const swift_id = Object.keys(swifts)[0];

  useEffect(() => {
    dispatch(getDirectionConfidenceSpot({ swift_id, room_id }));
    dispatch(getDirectionConfidenceVol({ swift_id, room_id }));

    form.setFieldsValue(marketImpact);
  }, []);

  const columns = [
    {
      title: "Tenor",
      dataIndex: "tenor",
      key: "tenor",
      render: (text: number) => {
        return sticky_model ? MATURITY[text] : timeUnixToDatePretty(text);
      },
    },
    {
      title: "Current Value",
      dataIndex: "value",
      key: "value",
    },
    {
      title: "Change",
      key: "change",
      render: (
        _: any,
        record: { tenor: string; value: number; deltaStrike: string }
      ) => (
        <Form.Item
          name={[record.deltaStrike, record.tenor]}
          style={{ margin: 0 }}
        >
          <InputNumber
            min={-100}
            max={100}
            // defaultValue={record.value}
            onChange={(value) => {
              // Ensure the whole path to the value is updated correctly
              form.setFieldsValue({
                vol: {
                  ...form.getFieldValue("vol"),
                  [record.deltaStrike]: {
                    ...form.getFieldValue(["vol", record.deltaStrike]),
                    [record.tenor]: value,
                  },
                },
              });
            }}
          />
        </Form.Item>
      ),
    },
    {
      title: "Value after update",
      key: "value_after_update",
      render: (
        _: any,
        record: { tenor: string; value: number; deltaStrike: string }
      ) => (
        <Tag color="primary">
          {(
            (form.getFieldValue([record.deltaStrike, record.tenor]) ??
              record.value) + record.value
          ).toFixed(2)}
        </Tag>
      ),
    },
  ];  

  return (
    <Tabs centered type="card">
      {Object.entries(marketImpact.vol).map(([deltaStrike, tenors]) => (
        <Tabs.TabPane
          tab={`${
            sticky_model ? convertDelta[parseFloat(deltaStrike)] : parseFloat(deltaStrike)
          }`}
          key={deltaStrike}
        >
          <Table
            columns={columns}
            dataSource={Object.entries(tenors).map(([tenor, value]) => ({
              key: `${deltaStrike}-${tenor}`,
              tenor,
              value,
              deltaStrike,
            }))}
            pagination={false}
          />
        </Tabs.TabPane>
      ))}
    </Tabs>
  );
};

export default DeltaStrikeTabs;
