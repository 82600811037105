import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  HistoryOutlined,
  LeftOutlined,
  PieChartOutlined,
  PlayCircleOutlined,
  TeamOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Typography } from "antd";

import { getFromLS, setToLS } from "utils";
import CBNavItem from "./CBNavItem";
import SmallLogo from "../../assets/small_logo.svg";
import { RootState } from "store/rootReducer";
import { switchCBNavHidden } from "store/centralbank/reducers";
import { useAppDispatch } from "store/store";
import { useSelector } from "react-redux";

const { Text } = Typography;

interface ITrainerNavProps {
  activeNav: number;
  changeNav: (idx: number) => void;
}

const initNav = () => {
  const nav = getFromLS("cbnavhidden");
  return nav !== null ? nav : false;
};

const CentralBankNav: React.FC<ITrainerNavProps> = ({
  changeNav,
  activeNav,
}) => {
  const dispatch = useAppDispatch();
  const { cb_nav_hidden: isHidden } = useSelector(
    (state: RootState) => state.centralbank
  );
  const [navWidth, setNavWidth] = useState("226px");

  useEffect(() => {
    if (!isHidden) {
      setNavWidth("226px");
    } else {
      setNavWidth("50px");
    }
  }, [isHidden]);

  return (
    <CBNav style={{ width: navWidth }}>
      <Link to="/trainer">
        <Logo
          className={isHidden ? "hide" : ""}
          src="/logo2.png"
          alt="Traderion"
        />
        <LogoSM
          className={isHidden ? "" : "hide"}
          src={SmallLogo}
          alt="Traderion"
        />
      </Link>

      <NavTitle isHidden={isHidden}>
        {!isHidden && "Trainer Dashboard"}
      </NavTitle>

      <CBNavItem
        isHidden={isHidden}
        icon={<TeamOutlined />}
        title={isHidden ? "" : "Traders"}
        active={activeNav === 0}
        changeNav={() => changeNav(0)}
      />
      <CBNavItem
        isHidden={isHidden}
        icon={<TeamOutlined />}
        title={isHidden ? "" : "Leaderboard"}
        active={activeNav === 1}
        changeNav={() => changeNav(1)}
      />
      <CBNavItem
        isHidden={isHidden}
        icon={<PieChartOutlined />}
        title={isHidden ? "" : "Parameters"}
        active={activeNav === 2}
        changeNav={() => changeNav(2)}
      />
      <CBNavItem
        isHidden={isHidden}
        icon={<HistoryOutlined />}
        title={isHidden ? "" : "Macro"}
        active={activeNav === 3}
        changeNav={() => changeNav(3)}
      />
      <CBNavItem
        isHidden={isHidden}
        icon={<PlayCircleOutlined />}
        title={isHidden ? "" : "Game"}
        active={activeNav === 4}
        changeNav={() => changeNav(4)}
      />
      <CBNavItem
        isHidden={isHidden}
        icon={<SearchOutlined />}
        title={isHidden ? "" : "Game controls"}
        active={activeNav === 5}
        changeNav={() => changeNav(5)}
      />

      <HideButton
        onClick={() => {
          setToLS("cbnavhidden", !isHidden);
          dispatch(switchCBNavHidden({}));
        }}
      >
        {isHidden ? "SHOW" : "HIDE"}
        <div>
          <LeftOutlined
            style={{
              transform: isHidden ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform ease 300ms",
            }}
          />
        </div>
      </HideButton>
    </CBNav>
  );
};

const Logo = styled.img`
  width: 100%;
  height: auto;
  padding: 0 15px;
  margin: 10px 0;

  &.hide {
    width: 0;
    height: 0;
    padding: 0;
  }
`;

const LogoSM = styled.img`
  width: 100%;
  height: auto;
  padding: 0 15px;
  margin: 10px 0;
  max-width: 50px;

  &.hide {
    width: 0;
    height: 0;
    padding: 0;
  }
`;

const NavTitle = styled(Text)<{ isHidden: boolean }>`
  display: block;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 10px;
  margin-bottom: 20px;

  white-space: nowrap;
  animation: ${(p) => (p.isHidden ? "none" : "showText 500ms forwards")};

  @keyframes showText {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const HideButton = styled.button`
  margin-top: auto;
  position: relative;
  border: none;
  border-radius: unset;
  padding: 0.5rem;
  background: #141414;
  font-size: 12px;
  font-weight: 200;

  transition: none;

  color: #bfbfbf;
  &:hover {
    color: ${(p) => p.theme.primary};
  }
`;

const CBNav = styled.div`
  z-index: 10000 !important;
  height: 100vh;
  transition: all ease-in-out 300ms;
  background-color: ${(props) => props.theme.color0};
  box-shadow: 5px 0 20px 5px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
`;

export default CentralBankNav;
