import { END, eventChannel } from "redux-saga";

export function createWebSocketConnection(
  socketUrl: string
): Promise<WebSocket> {
  return new Promise((resolve, reject) => {
    const socket = new WebSocket(socketUrl);

    socket.onopen = () => {
      resolve(socket);
    };

    socket.onerror = (error) => {
      reject(error);
    };
  });
}

export function createSocketChannel(socket: WebSocket) {
  return eventChannel((emit) => {
    socket.onclose = () => {
      emit(JSON.stringify({ type: "SOCKET_CLOSED" }));
      emit(END);
    };

    socket.onmessage = (event) => {
      emit(event.data);
    };

    return () => {
      socket.onmessage = () => null;
    };
  });
}
