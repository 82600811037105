import React, { useState } from "react";
import GenericModal from "../../../Modal/GenericModal";
import styled from "styled-components";
import {
  Column,
  Row,
  FloatInput,
} from "components/reusable/GenericStyledComponents";
import StyledText from "../../../reusable/StyledText";
import CloseButton from "assets/NavIcons/clear-button.svg";
import { TrainerRegisterPayload } from "types";
import FloatLabel from "components/reusable/FloatLabel";
import { Button, message } from "antd";
import { adminService } from "services";

type Props = {
  isShown: boolean;
  closeModal: () => void;
};

const CreateTrainerModal: React.FC<Props> = ({ isShown, closeModal }) => {
  const windowUrl = window.location.pathname.split("/");
  const customer_id = parseInt(windowUrl[windowUrl.length - 1]);
  const [newTrainer, setNewTrainer] = useState<TrainerRegisterPayload>({
    first_name: "",
    last_name: "",
    username: "",
    password: "",
    customer_id,
  });
  return (
    <GenericModal
      isShown={isShown}
      onRequestClose={closeModal}
      customClassName="GameRulesModal__Content"
      customOverlayClassName="MiddleModal__Overlay"
      overlayBackground="#00000060"
    >
      <Container style={{ overflowY: "auto" }}>
        <Row className="justify-content-between align-items-center">
          <StyledText
            fontSize={14}
            fontWeight={500}
            fontFamily="IBMPlexSans"
            color="#FFFFFF50"
          >
            Create a new trainer
          </StyledText>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
          <img
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
            role="button"
            src={CloseButton}
            alt="delete"
            onClick={() => {
              closeModal();
            }}
          />
        </Row>
        <Column
          style={{
            marginTop: "1rem",
            marginLeft: ".5rem",
            gap: "1rem",
          }}
        >
          <FloatLabel label="First name" value={newTrainer.first_name}>
            <FloatInput
              value={newTrainer.first_name}
              onChange={(e) => {
                setNewTrainer({ ...newTrainer, first_name: e.target.value });
              }}
            />
          </FloatLabel>
          <FloatLabel label="Last name" value={newTrainer.last_name}>
            <FloatInput
              value={newTrainer.last_name}
              onChange={(e) => {
                setNewTrainer({ ...newTrainer, last_name: e.target.value });
              }}
            />
          </FloatLabel>
          <FloatLabel label="Username" value={newTrainer.username}>
            <FloatInput
              value={newTrainer.username}
              onChange={(e) => {
                setNewTrainer({ ...newTrainer, username: e.target.value });
              }}
            />
          </FloatLabel>
          <FloatLabel label="Password" value={newTrainer.password}>
            <FloatInput
              value={newTrainer.password}
              onChange={(e) => {
                setNewTrainer({ ...newTrainer, password: e.target.value });
              }}
            />
          </FloatLabel>
          <Button
            onClick={() => {
              adminService
                .registerTrainer(customer_id, newTrainer)
                .then((res) => {
                  closeModal();
                  message.success("Trainer added, please refresh the page!");
                });
            }}
          >
            Save
          </Button>
        </Column>
      </Container>
    </GenericModal>
  );
};
const Container = styled(Column)`
  width: 100%;
`;
export default CreateTrainerModal;
