import styled from "styled-components";

interface IStyledTextProps {
  fontSize: number;
  fontWeight?: number;
  color?: string;
  fontFamily?: string;
  center?: boolean;
}

const StyledText = styled.p<IStyledTextProps>`
  margin: 0;
  font-size: ${({ fontSize }) => `${fontSize}px`};
  font-weight: ${({ fontWeight }) => fontWeight || 400};
  color: ${({ color, theme }) => color || theme.colors.primaryText};
  font-family: ${({ fontFamily }) => fontFamily || "Roboto"};
`;

export default StyledText;
