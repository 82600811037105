import React from "react";
import { Line } from "react-chartjs-2";
import { Chart as Chrt, registerables, ScatterDataPoint } from "chart.js";
import StyledText from "./StyledText";
import { Column } from "./GenericStyledComponents";

Chrt.defaults.scale.grid.display = false;
Chrt.register(...registerables);

interface IChartProps {
  title: string;
  graphColor: string;
  chartValues: ScatterDataPoint[];
}

const Chart: React.FC<IChartProps> = ({ title, graphColor, chartValues }) => (
  <Column style={{ position: "relative" }}>
    <StyledText
      fontSize={13}
      fontWeight={500}
      style={{
        paddingTop: 8,
        paddingLeft: 20,
        paddingBottom: 5,
        position: "absolute",
        left: 10,
      }}
    >
      {title}
    </StyledText>
    <Line
      data={{
        labels: new Array(chartValues.length).fill(""),
        datasets: [
          {
            yAxisID: "A",
            tension: 0.4,
            showLine: true,
            borderWidth: 1,
            pointBorderWidth: 0,
            pointBackgroundColor: graphColor,
            data: chartValues,
            fill: true,
            backgroundColor: (context: any) => {
              const { ctx } = context.chart;
              const gradient = ctx.createLinearGradient(0, 0, 0, 200);
              gradient.addColorStop(0, `${graphColor}50`);
              gradient.addColorStop(1, `${graphColor}10`);
              return gradient;
            },
            borderColor: graphColor,
          },
        ],
      }}
      height={50}
      width={317}
      options={{
        animation: { duration: 0 },
        plugins: { legend: { display: false } },
        scales: {
          A: {
            type: "linear",
            position: "right",
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },
      }}
      style={{ marginTop: 10 }}
    />
  </Column>
);

export default Chart;
