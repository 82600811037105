import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { STRADDLE, OPTION } from "stateConstants";
// import { strategyChanged } from 'store/room/actions';
import { updateSettings } from "store/settings/actions";
import { ParameterTypes } from "types";
import { Switch } from "antd";
import { useAppDispatch } from "store/store";

const StrategyToggle: React.FC = () => {
  const dispatch = useAppDispatch();
  const { permission } = useSelector((state: RootState) => state.user);
  const { TRADABLE_VOLS_PUT_CALL_OR_STRADDLE: strategy } = useSelector(
    (state: RootState) => state.settings.parameters
  );
  const {
    settings: { parameters },
  } = useSelector((state: RootState) => state.centralbank);
  const strategy_param = parameters.find(
    (p) =>
      p.code ===
      ("TRADABLE_VOLS_PUT_CALL_OR_STRADDLE" as unknown as ParameterTypes)
  );

  return (
    <>
      {/* eslint-disable-next-line */}
      {/* <label style={{ alignItems: "center", gap: "1rem" }}> */}
      {
        // (selectedSellTenor || selectedBuyTenor) &&
        (permission === "Admin" || permission === "Trainer") && (
          <Switch
            style={{
              width: "11ch",
              marginRight: ".75rem",
            }}
            checkedChildren="STRADDLE"
            unCheckedChildren="CALL/PUT"
            checked={!!strategy}
            onChange={() => {
              if (strategy_param)
                dispatch(
                  updateSettings({
                    param_id: strategy_param.id,
                    value: strategy === STRADDLE ? OPTION : STRADDLE,
                  })
                );
            }}
          />
        )
      }
      {/* <StyledText fontSize={8} fontWeight={100}>Straddle/Option</StyledText> */}
      {/* </label> */}
    </>
  );
};

export default StrategyToggle;
