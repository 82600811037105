import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { Button, Tag, Rate, Typography, message, Tooltip } from "antd";
import { HistoryOutlined, SendOutlined } from "@ant-design/icons";

import { RootState } from "store/rootReducer";
import { MacroEvent } from "types";
import NewsHistoryModal from "../Modal/NewsHistoryModal";
import useModal from "../../hooks/useModal";
import CustomRating from "./CustomRating";
import { Row } from "components/reusable/GenericStyledComponents";
import { useAppDispatch } from "store/store";
import { voteNews } from "store/events/actions";
import { useRoomTimeContext } from "contexts/RoomTimeProvider";

interface Props {
  width?: number;
}

const NewsTicker: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { news, latestNews } = useSelector((state: RootState) => state.events);
  const { IMPACT_VOTE_SECONDS } = useSelector(
    (state: RootState) => state.settings.parameters
  );
  const { gameTime } = useRoomTimeContext();
  const swifts = useSelector((state: RootState) => state.spot.swifts);
  const [flash, setFlash] = useState(false);

  const [Modal, showModal] = useModal(NewsHistoryModal);
  const [spotRating, setSpotRating] = useState<number | undefined>(undefined);
  const [volRating, setVolRating] = useState<number | undefined>(undefined);

  const animationDuration = 500;
  const animationCount = 2;

  useEffect(() => {
    setFlash(true);
    setTimeout(() => {
      setFlash(false);
    }, animationDuration * animationCount);
  }, [news]);

  const parseHeadline = (text: string) => {
    if (!text) {
      return "";
    }

    if (text.length <= 150) {
      return text;
    }

    return text.substring(0, 150).trim() + "...";
  };

  const handleSpotChange = (value: number) => {
    setSpotRating(value);
  };

  const handleVolChange = (value: number) => {
    setVolRating(value);
  };

  if (!latestNews) return null;

  return (
    <NewsContainer>
      <LatestNews
        animationDuration={animationDuration}
        animationCount={animationCount}
        active={flash && !!news.length}
      >
        Latest news: {parseHeadline(latestNews?.headline)}
      </LatestNews>

      {!latestNews.vote_expired && (
        <>
          <RatingContainer>
            <Row style={{ gap: ".25rem" }}>
              <Typography.Text style={{ fontSize: 11 }}>
                {swifts[0] ? swifts[0].code : ""} Spot Impact:
              </Typography.Text>
              <CustomRating onChange={handleSpotChange} value={spotRating} />
            </Row>
            <Row style={{ gap: ".25rem" }}>
              <Typography.Text style={{ fontSize: 11 }}>
                {swifts[0] ? swifts[0].code : ""} Vol Impact:
              </Typography.Text>
              <CustomRating onChange={handleVolChange} value={volRating} />
            </Row>
          </RatingContainer>

          <Tooltip title={latestNews.votes ? "You already voted!" : ""}>
            <VoteButton
              size="small"
              disabled={
                latestNews.vote_expired ||
                !!latestNews.votes ||
                spotRating === undefined ||
                volRating === undefined
              }
              onClick={() => {
                if (spotRating !== undefined && volRating !== undefined) {
                  // message.info(
                  //   `[DEBUG] voting spot: ${spotRating}, vol: ${volRating}`
                  // );

                  dispatch(
                    voteNews({
                      event_id: latestNews.id,
                      swift_id: swifts[0] ? swifts[0].swift_id : 0,
                      vote: spotRating,
                      spot_or_vol: "spot",
                    })
                  );
                  dispatch(
                    voteNews({
                      event_id: latestNews.id,
                      swift_id: swifts[0] ? swifts[0].swift_id : 0,
                      vote: volRating,
                      spot_or_vol: "vol",
                    })
                  );
                } else {
                  message.info(`[DEBUG] You did on spot or vol`);
                }
              }}
            >
              Vote
              {/* <SendOutlined style={{ margin: 0, padding: 0 }} /> */}
            </VoteButton>
          </Tooltip>
        </>
      )}

      <HistoryButton type="link" onClick={showModal}>
        <HistoryOutlined />
        History
      </HistoryButton>

      {Modal}
    </NewsContainer>
  );
};

const NewsContainer = styled(Tag)`
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 5px 0;
`;

const LatestNews = styled.div<{
  active: boolean;
  animationCount: number;
  animationDuration: number;
}>`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  color: ${(p) => p.theme.primary};
  font-size: 13px;

  ${(props) =>
    props.active &&
    css`
      animation-name: newsFlash;
      animation-iteration-count: ${props.animationCount};
      animation-timing-function: linear;
      animation-duration: ${props.animationDuration / 1000}s;
    `}
`;

const RatingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;

const HistoryButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 0;

  line-height: 1 !important;
  height: auto;
`;

const VoteButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: gold;

  & active {
    color: gray;
  }
`;

export default NewsTicker;
