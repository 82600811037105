import { PayloadAction } from "@reduxjs/toolkit";
import { put, takeEvery } from "redux-saga/effects";
import { socketSendMessage } from "store/socket/actions";
import {
  visibleDeltaStrikeRestConfirmation,
  visibleDeltaStrikeVolEbConfirmation,
} from "./actions";

function* updateDeltaStrikeRestSaga({
  payload,
}: PayloadAction<{ pattern: number[] }>) {
  const request = {
    headers: {
      command: "delta_strike.cmd.visible.update",
    },
    ...payload,
  };
  yield put(socketSendMessage(JSON.stringify(request)));
}
function* updateDeltaStrikeVolEbSaga({
  payload,
}: PayloadAction<{ pattern: number[] }>) {
  const request = {
    headers: {
      command: "delta_strike.cmd.vol_eb_visible.update",
    },
    ...payload,
  };
  yield put(socketSendMessage(JSON.stringify(request)));
}

function* visibleRestConfirmationSaga({
  payload,
}: PayloadAction<{ pattern: number[]; swift_id: number }>) {
  yield put(visibleDeltaStrikeRestConfirmation(payload));
}

function* visibleVolEbConfirmationSaga({
  payload,
}: PayloadAction<{ pattern: number[]; swift_id: number }>) {
  yield put(visibleDeltaStrikeVolEbConfirmation(payload));
}

export function* watchActiveDeltaStrikeAsync() {
  yield takeEvery(
    "delta_strike_values/updateDeltaStrikeRest",
    updateDeltaStrikeRestSaga
  );
  yield takeEvery(
    "delta_strike_values/updateDeltaStrikeVolEb",
    updateDeltaStrikeVolEbSaga
  );
  yield takeEvery(
    "delta_strike_values/VisibleUpdated",
    visibleRestConfirmationSaga
  );
  yield takeEvery(
    "delta_strike_values/VisibleVolEbUpdated",
    visibleVolEbConfirmationSaga
  );
}
