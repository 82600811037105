import React, { ChangeEvent, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { authenticate } from "store/auth/actions";
import { useAppDispatch } from "store/store";
import { RootState } from "store/rootReducer";
import { Input, Button, Typography } from "antd";

const { Paragraph } = Typography;

const LoginForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const error = useSelector((state: RootState) => state.auth.error);

  const [pin, setPin] = useState("");
  const [name, setName] = useState("");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
        alignSelf: "center",
        width: 400,
      }}
    >
      <LoginInput
        type="text"
        placeholder="Username"
        value={name}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setName(event.target.value);
        }}
        onKeyPress={(event)=>{
          if (event.key === 'Enter') {
            dispatch(authenticate({ username: name, password: pin }));
          }
        }}
      />

      <LoginInput
        type="password"
        placeholder="Password"
        value={pin}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setPin(event.target.value);
        }}
        onKeyPress={(event)=>{
          if (event.key === 'Enter') {
            dispatch(authenticate({ username: name, password: pin }));
          }
        }}
      />
      <Button
        typeof="primary"
        onClick={() => {
          dispatch(authenticate({ username: name, password: pin }));
        }}
      >
        Login
      </Button>

      {error && <Paragraph>{error}</Paragraph>}
    </div>
  );
};

const LoginInput = styled(Input)`
  color: ${({ theme }) => theme.color3};
  text-align: center;
  font-size: 18px;
  background-color: #000;
`;

export default LoginForm;
