import React from "react";

import styled from "styled-components";

import { BUY, CC_WAITING, SELL } from "stateConstants";
import { useAppDispatch } from "store/store";
import { clientCallAccept, clientCallDecline } from "store/clientCalls/actions";
import { ClientCall } from "types";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { linkCallToPricer, setPricer } from "store/newpricer/actions";
import ToPricerButton from "components/reusable/ToPricerButton";

interface Props {
  call: ClientCall;
}

const QuotedActions: React.FC<Props> = ({ call }) => {
  const dispatch = useAppDispatch();

  const sticky_model = useSelector(
    (state: RootState) => state.spot.sticky_model,
    shallowEqual
  );

  const isWaiting = call.status === CC_WAITING;

  return (
    <Container>
      <Column>
        <ToPricerButton
          call={call}
          callId={`${call.id}_cc`}
          isWaiting={isWaiting}
          sticky_model={sticky_model}
        />
      </Column>

      <Column>
        <AcceptButton
          disabled={!isWaiting}
          onClick={() => {
            dispatch(clientCallAccept({ callId: call.id }));
          }}
        >
          Accept
        </AcceptButton>
      </Column>

      <Column>
        <DeclineButton
          disabled={!isWaiting}
          onClick={() => {
            dispatch(clientCallDecline({ callId: call.id }));
          }}
        >
          Decline
        </DeclineButton>
      </Column>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 15px;
  width: 405px;
  padding: 10px;
  // background: #272e33; // layout
`;

const Column = styled.div`
  flex: 1;
  display: flex;
`;

const ActionButton = styled.button`
  width: 100%;
  margin: 0;
  padding: 4px;
  border-radius: initial;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  background: rgba(0, 0, 0, 0.2);

  &:disabled {
    color: #ebebeb80;
    border-color: rgba(25, 32, 37, 0.8);
  }
`;

const DeclineButton = styled(ActionButton)`
  border-color: rgba(248, 73, 96, 0.6);
  color: rgba(248, 73, 96);
`;

const AcceptButton = styled(ActionButton)`
  border-color: rgba(2, 192, 118, 0.6);
  color: rgba(2, 192, 118);
`;

export default QuotedActions;
