const settings = {
  REACT_APP_API_URL: "",
  REACT_APP_WS_URL: "",
};

switch (process.env.REACT_APP_GOAL) {
  case "production": {
    settings.REACT_APP_API_URL = `https://backend-prod.options.traderion.com`;
    settings.REACT_APP_WS_URL = `wss://backend-proxy-prod.options.traderion.com`;
    break;
  }
  case "stage": {
    settings.REACT_APP_API_URL = `https://backend-stg.options.traderion.com`;
    settings.REACT_APP_WS_URL = `wss://backend-proxy-stg.options.traderion.com`;
    break;
  }
  default: {
    settings.REACT_APP_API_URL = `http://127.0.0.1:8000`;
    settings.REACT_APP_WS_URL = `ws://127.0.0.1:8888`;
  }
}

export default settings;
