import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import StickyDeltaBroker from "./StickyDelta/StickyDeltaBroker";
import StickyStrikeBroker from "./StickyStrike/StickyStrikeBroker";

const BrokerArea: React.FC = () => {
  const sticky_model = useSelector(
    (state: RootState) => state.spot.sticky_model
  );

  if (sticky_model) return <StickyDeltaBroker />;
  return <StickyStrikeBroker />;
};

export default BrokerArea;
