import React, { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Column, Row } from "../reusable/GenericStyledComponents";
import StyledText from "../reusable/StyledText";
import { RootState } from "store/rootReducer";
import { formatTime } from "utils/time";
import ClassView from "components/Trainer/ClassView";
import { Space, Table, Button, Typography } from "antd";

const TrainerClasses: React.FC = () => {
  const [viewClass, setViewClass] = useState(false);
  const [selectedClass, setSelectedClass] = useState({ id: 0 });
  const { username } = useSelector((state: RootState) => state.user);
  const { classes } = useSelector((state: RootState) => state.trainer);

  return (
    <>
      {viewClass && selectedClass ? (
        <Container>
          <ClassView id={selectedClass.id} setViewClasses={setViewClass} />
        </Container>
      ) : (
        <Container>
          <Typography.Title level={3}>Active Classes</Typography.Title>

          <DataContainer>
            <Table
              loading={!classes}
              rowKey="id"
              style={{ width: "100%" }}
              columns={[
                {
                  dataIndex: "name",
                  title: "Class name",
                },
                {
                  dataIndex: "name",
                  title: "Trainer",
                  render: () => <Space size="middle">{username}</Space>,
                },
                {
                  dataIndex: "assigned_students",
                  title: "Trainees",
                  render: (_, record) => (
                    <Space size="middle">
                      {record.assigned_students.length} trainees
                    </Space>
                  ),
                },
                {
                  dataIndex: "created",
                  title: "Created",
                  render: (_, record) => (
                    <Space size="middle">
                      {formatTime(new Date(record.created))}
                    </Space>
                  ),
                  defaultSortOrder: "descend",
                  sorter: (a, b) =>
                    new Date(a.created).getTime() -
                    new Date(b.created).getTime(),
                },
                {
                  title: "Actions",
                  render: (_, record) => (
                    <Space size="middle">
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          setViewClass(true);
                          setSelectedClass(record);
                        }}
                      >
                        <StyledText
                          fontSize={14}
                          fontWeight={400}
                          color="#FFFFFF"
                          fontFamily="IBMPlexSans"
                        >
                          View Class
                        </StyledText>
                      </Button>
                    </Space>
                  ),
                },
              ]}
              dataSource={classes}
              pagination={false}
            />
          </DataContainer>
        </Container>
      )}
    </>
  );
};

const Container = styled.div`
  width: 100%;
`;

const DataContainer = styled.div`
  margin-top: 1rem;
  max-height: 85vh;
  overflow-y: auto;
`;

export default TrainerClasses;
