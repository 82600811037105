import FloatLabel from "components/reusable/FloatLabel";
import {
  Column,
  Row,
  FloatInput,
} from "components/reusable/GenericStyledComponents";
import React, { useState } from "react";
import styled from "styled-components";
import { TrainerEditPayload } from "types";
import { Input } from "antd";

const createEmptyUser = (customer_id: number) => {
  return {
    id: -1,
    first_name: "",
    last_name: "",
    username: "",
    password: "",
    customer_id,
  };
};

const EditClassList: React.FC<{
  users: TrainerEditPayload[];
  setUsers: React.Dispatch<React.SetStateAction<TrainerEditPayload[]>>;
  customer_id: number;
}> = ({ users, setUsers, customer_id }) => {
  return (
    <Column style={{ gap: ".5rem" }}>
      {React.Children.toArray(
        users.map((user, idx) => {
          return (
            <Row>
              <div>[{idx}]:</div>
              <User key={user.id} users={users} setUsers={setUsers} idx={idx} />
            </Row>
          );
        })
      )}
      <ColumnButton
        onClick={() => {
          setUsers([...users, createEmptyUser(customer_id)]);
        }}
      >
        +
      </ColumnButton>
    </Column>
  );
};

const User: React.FC<{
  users: TrainerEditPayload[];
  setUsers: React.Dispatch<React.SetStateAction<TrainerEditPayload[]>>;
  idx: number;
}> = ({ users, setUsers, idx }) => {
  return (
    <Row>
      <FloatLabel label="First name" value={users[idx].first_name}>
        <FloatInput
          value={users[idx].first_name}
          onChange={(e) => {
            const newUsers = [...users];
            newUsers[idx] = { ...users[idx], first_name: e.target.value };
            setUsers(newUsers);
          }}
        />
      </FloatLabel>
      <FloatLabel label="Last name" value={users[idx].last_name}>
        <FloatInput
          value={users[idx].last_name}
          onChange={(e) => {
            const newUsers = [...users];
            newUsers[idx] = { ...users[idx], last_name: e.target.value };
            setUsers(newUsers);
          }}
        />
      </FloatLabel>
      <FloatLabel label="Email" value={users[idx].email}>
        <FloatInput
          value={users[idx].email}
          onChange={(e) => {
            const newUsers = [...users];
            newUsers[idx] = { ...users[idx], email: e.target.value };
            setUsers(newUsers);
          }}
        />
      </FloatLabel>
      <FloatLabel label="Username" value={users[idx].username}>
        <FloatInput
          value={users[idx].username}
          onChange={(e) => {
            const newUsers = [...users];
            newUsers[idx] = { ...users[idx], username: e.target.value };
            setUsers(newUsers);
          }}
        />
      </FloatLabel>
      <FloatLabel label="Password" value={users[idx].password}>
        <FloatInput
          value={users[idx].password}
          onChange={(e) => {
            const newUsers = [...users];
            newUsers[idx] = { ...users[idx], password: e.target.value };
            setUsers(newUsers);
          }}
        />
      </FloatLabel>
    </Row>
  );
};

const ColumnButton = styled(Column)`
  &:hover {
    background: #ffffff20;
  }
  border-radius: 0.5rem;
  padding: 0;
  margin: 0;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 0.25rem;
`;
export default EditClassList;
