import { CreateRoomPlayer, CreateRoomTrainer } from "types";

export const getRoomData = (
  classId: number,
  players: CreateRoomPlayer[],
  trainers: CreateRoomTrainer[],
  ownerId: number,
  scenario: any
) => ({
  scenario,
  room_type: 1,
  players,
  trainers,
  traders: [],
  owner_id: ownerId,
  training_class_id: classId,
});
