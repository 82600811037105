import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ASK, BID } from "stateConstants";
import {
  clearRoom,
  retrieveInitialData,
  strategyChanged,
} from "../room/actions";
import type {
  InitialDataPayload,
  PriceCurveDTO,
  SpotChartPoint,
  SpotChartPriceUpdatedPayload,
  VolEbOrder,
  VolEbOrderPayload,
  VolEbPylon,
} from "types";
import {
  SportEbOrderAddedPayload,
  SportEbOrderCanceledPayload,
  SpotEBUpdatedPayload,
  SpotOrderExercisedPayload,
  Tenor,
} from "types";
import { initialState } from "./initial_state";
import {
  removeTenor,
  VolEbDepthChanged,
  createTenor,
  strikesUpdated,
  volEbOrderCanceled,
  volEbOrderAdded,
  volEbOrderExecuted,
  VolEbDepthsChanged,
  setSpotChartValues,
} from "./actions";
import _ from "lodash";
import {
  visibleRestConfirmation,
  visibleVolEbConfirmation,
} from "store/tenors/actions";
import {
  visibleDeltaStrikeRestConfirmation,
  visibleDeltaStrikeVolEbConfirmation,
} from "store/delta_strike/actions";

const parseVolEbOrder: (order: VolEbOrderPayload) => VolEbOrder = ({
  delta_strike,
  tenor,
  volatility,
  ...order
}) => {
  return {
    ...order,
    delta_strike: Number(delta_strike),
    tenor: Number(tenor),
    volatility: Number(volatility),
  };
};

const getEmptyVolEbPylon = () => {
  return {
    buyVolume: 0,
    buy: 0,
    sell: 0,
    sellVolume: 0,
    buyTraders: [],
    sellTraders: [],
  };
};

const spotSlice = createSlice({
  initialState,
  name: "spot",
  reducers: {
    setSelectedSellTenor: (
      state,
      action: PayloadAction<number | undefined>
    ) => {
      state.selectedSellTenor = action.payload;
    },
    setSelectedBuyTenor: (state, action: PayloadAction<number | undefined>) => {
      state.selectedBuyTenor = action.payload;
    },
    setDeltaHedge: (state, action: PayloadAction<any>) => {
      const { selectedDelta, TRADABLE_VOLS_PUT_CALL_OR_STRADDLE } =
        action.payload;
      state.selectedDelta = selectedDelta;
      // give flexibility in the future
      // if (state.sticky_model && TRADABLE_VOLS_PUT_CALL_OR_STRADDLE === 0) {
      //   state.selectedDeltaKey = selectedDelta;
      //   if (selectedDelta !== 0.4999 && selectedDelta !== 0.5001) {
      //     state.selectedDelta = selectedDelta;
      //   } else {
      //     state.selectedDelta = 0.5;
      //   }
      // } else {
      //   state.selectedDelta = selectedDelta;
      // }
    },
    setSpotQuantity: (state, action: PayloadAction<number>) => {
      state.spotQuantity = action.payload;
    },
    setValidSpotQuantity: (state, action: PayloadAction<boolean>) => {
      state.validSpotQuantity = action.payload;
    },
    setVolQuantity: (state, action: PayloadAction<number>) => {
      state.volQuantity = action.payload;
    },
    setValidVolQuantity: (state, action: PayloadAction<boolean>) => {
      state.validVolQuantity = action.payload;
    },
    ChartPriceUpdated: (
      state,
      action: PayloadAction<SpotChartPriceUpdatedPayload>
    ) => {
      const { point, ohlc_data } = action.payload;
      const { spotChartValues } = state;
      const chartPoint = {
        ...point,
        price: parseFloat(point.price),
        room_date: point.room_date * 1000,
      };
      state.spotChartValues = [...spotChartValues, chartPoint].sort(
        (a, b) => a.room_time - b.room_time
      );
      // Object.keys(ohlc_data).forEach((k) => {
      //   const candleData = ohlc_data[k].data.map((ohlc) => {
      //     return {
      //       o: Number(ohlc.o),
      //       h: Number(ohlc.h),
      //       l: Number(ohlc.l),
      //       c: Number(ohlc.c),
      //       x: Number(ohlc.x),
      //     };
      //   });

      //   if (ohlc_data[k].index !== null) {
      //     // Update existing candle
      //     // eslint-disable-next-line prefer-destructuring
      //     state.spotChartOHLC[k][ohlc_data[k].index!] = candleData[0];
      //   } else {
      //     // Add new candle
      //     state.spotChartOHLC[k] = [...state.spotChartOHLC[k], ...candleData];
      //   }
      // });
    },

    SpotElectronicBrokerUpdated: (
      state,
      action: PayloadAction<SpotEBUpdatedPayload>
    ) => {
      const { order, direction } = action.payload;

      const [_order] = order;
      const type: keyof typeof state =
        direction === ASK ? "bestAsk" : "bestBid";
      state[type] = { ..._order, price: parseFloat(_order.price) };
    },

    OrderAdded: (state, action: PayloadAction<SportEbOrderAddedPayload>) => {
      const { order } = action.payload;
      const { ebOrders } = state;
      state.ebOrders = [
        ...ebOrders,
        { ...order, price: parseFloat(order.price) },
      ].sort((a, b) => b.room_time - a.room_time);
    },

    OrderCanceled: (
      state,
      action: PayloadAction<SportEbOrderCanceledPayload>
    ) => {
      const { order } = action.payload;
      const { ebOrders } = state;

      const index = ebOrders.findIndex((o) => o.id === order.id);
      if (index === -1) return;

      const newOrders = [...ebOrders];
      newOrders.splice(index, 1);
      state.ebOrders = newOrders;
    },
    OrderExercised: (
      state,
      action: PayloadAction<SpotOrderExercisedPayload>
    ) => {
      const { order } = action.payload;
      const { ebOrders } = state;

      const index = ebOrders.findIndex((o) => o.id === order.id);
      if (index === -1) return;
      state.ebOrders[index] = { ...order, price: parseFloat(order.price) };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(
        retrieveInitialData,
        (state, action: PayloadAction<InitialDataPayload>) => {
          const {
            swifts,
            eb_depth,
            price_curves,
            // candles,
            eb_orders,
            vol_eb_depth,
            vol_eb_orders,
            tenors,
            visible_tenors,
            visible_vol_eb_tenors,
            vol_eb_strikes,
            visible_delta_strike_values,
            visible_vol_eb_delta_strike_values,
            settings: { general, asset_settings: assets },
          } = action.payload;
          state.initial_state_loaded = false;
          console.log("Initial data");

          state.swiftId = swifts[0].swift_id;
          state.swifts = swifts;

          state.spotQuantity = assets[0].parameters.PLAYERS_SPOT_MAX_TICKET;
          state.volQuantity = assets[0].parameters.PLAYERS_VOL_MAX_TICKET;
          state.sticky_model = swifts[0].sticky_model;

          const bbid = eb_depth[0].orders[BID];
          const bask = eb_depth[0].orders[ASK];

          state.bestBid = { ...bbid, price: parseFloat(bbid.price) };
          state.bestAsk = { ...bask, price: parseFloat(bask.price) };

          state.ebOrders = [
            ...eb_orders[0].orders[BID],
            ...eb_orders[0].orders[ASK],
          ].map((order) => {
            return { ...order, price: parseFloat(order.price) };
          });

          state.volEbOrders = vol_eb_orders[0].orders.map(parseVolEbOrder);

          // state.spotChartValues = price_curves[0].points.map(
          //   (point): SpotChartPoint => ({
          //     price: parseFloat(point.price),
          //     room_time: point.room_time,
          //     room_date: point.room_date * 1000,
          //   })
          // );

          // Object.keys(candles).forEach((k) => {
          //   state.spotChartOHLC[k] = candles[k].map((ohlc) => {
          //     return {
          //       o: Number(ohlc.o),
          //       h: Number(ohlc.h),
          //       l: Number(ohlc.l),
          //       c: Number(ohlc.c),
          //       x: Number(ohlc.x),
          //     };
          //   });
          // });

          state.tenors = tenors;
          state.visible_vol_eb_tenors = visible_vol_eb_tenors;
          state.visible_tenors = visible_tenors;
          state.visible_vol_eb_delta_strike_values =
            visible_vol_eb_delta_strike_values;
          state.visible_delta_strike_values = visible_delta_strike_values;

          Object.entries(vol_eb_depth[0].orders).forEach((entry) => {
            const [_tenor, delta_strike] = entry;
            const tenor = parseFloat(_tenor);
            // sticky delta
            if (state.sticky_model) {
              state.delta_strike_values = [0.1, 0.25, 0.5, 0.75, 0.9];
              if (tenor)
                // TODO: redundant
                Object.entries(delta_strike).forEach((d) => {
                  const delta_strike_key = parseFloat(d[0]);
                  const order = d[1];
                  if (!state.tradableVols[tenor])
                    state.tradableVols[tenor] = {};
                  if (!state.tradableVols[tenor][delta_strike_key])
                    state.tradableVols[tenor][delta_strike_key] =
                      getEmptyVolEbPylon();
                  state.tradableVols[tenor][delta_strike_key].sellVolume =
                    order["0"].amount;
                  state.tradableVols[tenor][delta_strike_key].buyVolume =
                    order["1"].amount;
                  state.tradableVols[tenor][delta_strike_key].sell = parseFloat(
                    order["0"].volatility
                  );
                  state.tradableVols[tenor][delta_strike_key].buy = parseFloat(
                    order["1"].volatility
                  );
                  state.tradableVols[tenor][delta_strike_key].sellTraders =
                    order["0"].traders;
                  state.tradableVols[tenor][delta_strike_key].buyTraders =
                    order["1"].traders;
                });
              state.selectedDelta = Number(
                Object.keys(
                  state.tradableVols[
                    tenors[state.swiftId][
                      visible_vol_eb_tenors[state.swiftId][0]
                    ]
                  ]
                )[
                  Math.floor(
                    visible_vol_eb_delta_strike_values[state.swiftId].length / 2
                  )
                ]
              );
              if (state.selectedDelta === 0.5) {
                state.selectedDeltaKey = 0.4999;
              } else {
                state.selectedDeltaKey = state.selectedDelta;
              }
            } else {
              state.delta_strike_values = vol_eb_strikes[state.swiftId].map(
                (x) => Number(x)
              );
              state.selectedDelta =
                state.delta_strike_values[
                  Math.floor(state.delta_strike_values.length / 2)
                ];

              Object.entries(delta_strike).forEach((d) => {
                const delta_strike_key = parseFloat(d[0]);
                const order = d[1];

                if (!state.tradableVols[tenor]) state.tradableVols[tenor] = {};
                if (!state.tradableVols[tenor][delta_strike_key])
                  state.tradableVols[tenor][delta_strike_key] =
                    getEmptyVolEbPylon();
                state.tradableVols[tenor][delta_strike_key].buyTraders =
                  order["1"].traders;
                state.tradableVols[tenor][delta_strike_key].sellTraders =
                  order["0"].traders;
                state.tradableVols[tenor][delta_strike_key].sellVolume =
                  order["0"].amount;
                state.tradableVols[tenor][delta_strike_key].buyVolume =
                  order["1"].amount;
                state.tradableVols[tenor][delta_strike_key].sell = parseFloat(
                  order["0"].volatility
                );
                state.tradableVols[tenor][delta_strike_key].buy = parseFloat(
                  order["1"].volatility
                );
              });
            }
          });
          state.initial_state_loaded = true;
          // const haveSameKeys = (a: any, b: any) => JSON.stringify(Object.keys(a).sort()) === JSON.stringify(Object.keys(b).sort());
          // const diffKeys = (obj1: any, obj2: any) => {
          //   const keysObj1 = Object.keys(obj1);
          //   const keysObj2 = Object.keys(obj2);

          //   const missingInObj1 = keysObj2.filter(key => !keysObj1.includes(key));
          //   const missingInObj2 = keysObj1.filter(key => !keysObj2.includes(key));

          //   return {
          //     missingInObj1,
          //     missingInObj2
          //   };
          // };
          // console.log(Object.keys(initialState()));
          // console.log(Object.keys(state));
          // console.log("State", haveSameKeys(state, initialState()));
          // const differences = diffKeys(initialState(), state);
          // console.log('Keys missing in initialState:', differences.missingInObj1);
          // console.log('Keys missing in state:', differences.missingInObj2);
          // console.log(current(state));
        }
      )
      .addCase(strikesUpdated, (state, action) => {
        const { strikes } = action.payload;
        const oldStrikes = state.delta_strike_values.slice();
        const parsedStrikes = strikes.map((s) => Number(s));
        // new one is the biggest
        if (!parsedStrikes.includes(oldStrikes[0])) {
          const strike_to_be_removed = oldStrikes[0];
          const new_strike = parsedStrikes[parsedStrikes.length - 1];
          // delete the smallest old strike from all tenors
          Object.keys(state.tradableVols).forEach((_tenor) => {
            const tenor = parseFloat(_tenor);
            delete state.tradableVols[tenor][strike_to_be_removed];
            state.tradableVols[tenor][new_strike] = getEmptyVolEbPylon();
          });
          // prevent selected delta going out of table
          if (state.selectedDelta === strike_to_be_removed)
            // eslint-disable-next-line
            state.selectedDelta = parsedStrikes[0];
          state.delta_strike_values = parsedStrikes;
        } else if (
          !parsedStrikes.includes(oldStrikes[oldStrikes.length - 1]) // new strike is the biggest
        ) {
          const strike_to_be_removed = oldStrikes[oldStrikes.length - 1];
          const new_strike = parsedStrikes[0];
          Object.keys(state.tradableVols).forEach((_tenor) => {
            const tenor = parseFloat(_tenor);
            delete state.tradableVols[tenor][strike_to_be_removed];
            state.tradableVols[tenor][new_strike] = {
              buyVolume: 0,
              buy: 0,
              sell: 0,
              sellVolume: 0,
              buyTraders: [],
              sellTraders: [],
            };
          });
          // prevent selected delta going out of table
          if (state.selectedDelta === strike_to_be_removed)
            state.selectedDelta = parsedStrikes[strikes.length - 1];
          state.delta_strike_values = parsedStrikes;
        }
      })
      .addCase(removeTenor, (state, action) => {
        const { tenor } = action.payload;
        // state.tradableVols = { [tenor]: _, ...state.tradableVols };
        if (state.selectedBuyTenor === tenor) {
          if (state.sticky_model) {
            state.selectedBuyTenor =
              Tenor[
                Object.keys(
                  state.tradableVols
                )[1] as unknown as keyof typeof Tenor
              ];
          } else {
            [, state.selectedBuyTenor] = state.tenors[state.swiftId];
          }
        }
        if (state.selectedSellTenor === tenor) {
          if (state.sticky_model) {
            state.selectedSellTenor =
              Tenor[
                Object.keys(
                  state.tradableVols
                )[1] as unknown as keyof typeof Tenor
              ];
          } else {
            [, state.selectedSellTenor] = state.tenors[state.swiftId];
          }
        }
        delete state.tradableVols[tenor];
        state.tenors[state.swiftId] = state.tenors[state.swiftId].splice(1);
      })
      .addCase(createTenor, (state, action) => {
        const { tenor } = action.payload;
        state.tenors[state.swiftId].push(tenor);
        state.visible_vol_eb_tenors[state.swiftId].forEach((t) => {
          const newVisibleTenor = state.tenors[state.swiftId][t + 1];
          if (
            !Object.keys(state.tradableVols).includes(
              newVisibleTenor.toString()
            )
          ) {
            state.tradableVols[newVisibleTenor] = {};
            state.delta_strike_values.forEach((d) => {
              // TODO: look only in visible_tv_dltsk
              state.tradableVols[newVisibleTenor][d] = getEmptyVolEbPylon();
            });
          }
        });
      })
      .addCase(VolEbDepthsChanged, (state, action) => {
        const { swift_id, vol_eb_depths } = action.payload;
        const depths = vol_eb_depths[swift_id];

        depths.forEach(({ tenor, delta_strike, depth, direction }) => {
          // const _delta_strike = parseFloat(delta_strike.toString());
          try {
            const { amount, volatility, traders } = depth;
            if (state.sticky_model === 1) {
              if (
                state.tradableVols[tenor] &&
                state.tradableVols[tenor][delta_strike]
              ) {
                if (direction === 1) {
                  state.tradableVols[tenor][delta_strike].buyTraders = traders;
                  state.tradableVols[tenor][delta_strike].buyVolume = amount;
                  state.tradableVols[tenor][delta_strike].buy =
                    parseFloat(volatility);
                } else {
                  state.tradableVols[tenor][delta_strike].sellTraders = traders;
                  state.tradableVols[tenor][delta_strike].sellVolume = amount;
                  state.tradableVols[tenor][delta_strike].sell =
                    parseFloat(volatility);
                }
              }
            } else {
              // eslint-disable-next-line no-lonely-if
              if (
                state.tradableVols[tenor] &&
                state.tradableVols[tenor][delta_strike]
              ) {
                // eslint-disable-next-line no-lonely-if
                if (direction === 1) {
                  state.tradableVols[tenor][delta_strike].buyTraders = traders;
                  state.tradableVols[tenor][delta_strike].buyVolume = amount;
                  state.tradableVols[tenor][delta_strike].buy =
                    parseFloat(volatility);
                } else {
                  state.tradableVols[tenor][delta_strike].sellTraders = traders;
                  state.tradableVols[tenor][delta_strike].sellVolume = amount;
                  state.tradableVols[tenor][delta_strike].sell =
                    parseFloat(volatility);
                }
              }
            }
          } catch {
            console.log(
              `[VolEbDepthChanged] tenor ${tenor} delta_strike ${delta_strike} which is not in the system`
            );
          }
        });
      })
      // .addCase(VolEbDepthChanged, (state, action) => {
      //   const { tenor, vol_eb_depth, direction } = action.payload;
      //   const { amount, volatility, traders } = vol_eb_depth[0];
      //   let { delta_strike } = action.payload;

      //   if (state.tradableVols) {
      //     // sticky delta
      //     if (state.sticky_model === 1) {
      //       if (defaultTenors.includes(tenor)) {
      //         if (delta_strike.length === 4) delta_strike += '00';
      //         if (state.tradableVols[tenor] && state.tradableVols[tenor][delta_strike])
      //           state.tradableVols[tenor][delta_strike].traders = traders;
      //         if (direction === 1) {
      //           state.tradableVols[tenor][delta_strike].buyVolume = amount;
      //           state.tradableVols[tenor][delta_strike].buy = parseFloat(volatility);
      //         } else {
      //           state.tradableVols[tenor][delta_strike].sellVolume = amount;
      //           state.tradableVols[tenor][delta_strike].sell = parseFloat(volatility);
      //         }
      //       }
      //     }
      //     // sticky strike
      //     else {
      //       if (state.tradableVols[tenor] && state.tradableVols[tenor][delta_strike])
      //         state.tradableVols[tenor][delta_strike].traders = traders;
      //       // eslint-disable-next-line no-lonely-if
      //       if (direction === 1) {
      //         state.tradableVols[tenor][delta_strike].buyVolume = amount;
      //         state.tradableVols[tenor][delta_strike].buy = parseFloat(volatility);
      //       } else {
      //         state.tradableVols[tenor][delta_strike].sellVolume = amount;
      //         state.tradableVols[tenor][delta_strike].sell = parseFloat(volatility);
      //       }
      //     }
      //   }
      // })
      .addCase(volEbOrderCanceled, (state, action) => {
        const { order } = action.payload;
        const { volEbOrders } = state;

        const index = volEbOrders.findIndex((o) => o.id === order.id);
        if (index === -1) return;

        const newOrders = [...volEbOrders];
        newOrders.splice(index, 1);
        state.volEbOrders = newOrders;
      })
      .addCase(volEbOrderAdded, (state, action) => {
        const { order } = action.payload;

        const index = state.volEbOrders.findIndex((o) => o.id === order.id);
        const volEb = parseVolEbOrder(order);
        if (index === -1) {
          state.volEbOrders.push(volEb);
        } else {
          state.volEbOrders[index] = volEb;
        }
      })
      .addCase(volEbOrderExecuted, (state, action) => {
        const { order } = action.payload;
        const index = state.volEbOrders.findIndex((o) => o.id === order.id);

        if (index === -1) return;
        state.volEbOrders[index] = { ...order, amount: order.amount };
      })
      .addCase(visibleDeltaStrikeVolEbConfirmation, (state, action) => {
        const { pattern, swift_id } = action.payload;
        const toDelete = state.visible_vol_eb_delta_strike_values[swift_id]
          .filter((x) => !pattern.includes(x))
          .map((t) => state.delta_strike_values[t]);
        const toAdd = pattern
          .filter(
            (x) =>
              !state.visible_vol_eb_delta_strike_values[swift_id].includes(x)
          )
          .map((t) => state.delta_strike_values[t]);
        toDelete.forEach((d) => {
          Object.keys(state.tradableVols).forEach((tenor) => {
            delete state.tradableVols[parseFloat(tenor)][d];
          });
        });
        toAdd.forEach((d) => {
          Object.keys(state.tradableVols).forEach((tenor) => {
            state.tradableVols[parseFloat(tenor)][d] = getEmptyVolEbPylon();
          });
        });
        if (toDelete.includes(state.selectedDelta)) {
          state.selectedDelta = Number(
            Object.keys(
              state.tradableVols[
                state.tenors[state.swiftId][
                  state.visible_vol_eb_tenors[state.swiftId][0]
                ]
              ]
            )[
              Math.floor(
                state.visible_vol_eb_delta_strike_values[state.swiftId].length /
                  2
              )
            ]
          );
          if (state.selectedDelta === 0.5) {
            state.selectedDeltaKey = 0.4999;
          } else {
            state.selectedDeltaKey = state.selectedDelta;
          }
        }
        state.visible_vol_eb_delta_strike_values[swift_id] = pattern;
      })
      .addCase(visibleDeltaStrikeRestConfirmation, (state, action) => {
        const { pattern, swift_id } = action.payload;
        state.visible_delta_strike_values[swift_id] = pattern;
        state.visible_delta_strike_values = {
          ...state.visible_delta_strike_values,
        };
      })
      .addCase(visibleRestConfirmation, (state, action) => {
        const { pattern, swift_id } = action.payload;
        state.visible_tenors[swift_id] = pattern;
      })
      .addCase(visibleVolEbConfirmation, (state, action) => {
        const { pattern, swift_id } = action.payload;
        const toDelete = state.visible_vol_eb_tenors[swift_id]
          .filter((x) => !pattern.includes(x))
          .map((t) => state.tenors[swift_id][t]);
        const toAdd = pattern
          .filter((x) => !state.visible_vol_eb_tenors[swift_id].includes(x))
          .map((t) => state.tenors[swift_id][t]);
        toDelete.forEach((t) => {
          if ((state.selectedBuyTenor as unknown as number) === t) {
            if (state.sticky_model) {
              state.selectedBuyTenor =
                Tenor[
                  state.tenors[
                    pattern.indexOf(1)
                  ] as unknown as keyof typeof Tenor
                ];
            } else {
              state.selectedBuyTenor = state.tenors[
                pattern.indexOf(1)
              ] as unknown as Tenor;
            }
          }
          if ((state.selectedSellTenor as unknown as number) === t) {
            if (state.sticky_model) {
              state.selectedSellTenor =
                Tenor[
                  state.tenors[
                    pattern.indexOf(1)
                  ] as unknown as keyof typeof Tenor
                ];
            } else {
              state.selectedSellTenor = state.tenors[
                pattern.indexOf(1)
              ] as unknown as Tenor;
            }
          }
          delete state.tradableVols[t];
        });
        toAdd.forEach((t) => {
          state.tradableVols[t] = {};
          state.delta_strike_values.forEach((d) => {
            state.tradableVols[t][d] = getEmptyVolEbPylon();
          });
        });
        state.visible_vol_eb_tenors[swift_id] = pattern;
      })
      .addCase(
        setSpotChartValues,
        (state, action: PayloadAction<PriceCurveDTO[]>) => {
          const price_curves = action.payload;
          state.spotChartValues = price_curves[0].points.map(
            (point): SpotChartPoint => ({
              price: parseFloat(point.price),
              room_time: point.room_time,
              room_date: point.room_date * 1000,
            })
          );
        }
      )
      .addCase(clearRoom, initialState);
  },
});

export default spotSlice.reducer;
export const {
  setSelectedSellTenor,
  setSelectedBuyTenor,
  setDeltaHedge,
  setSpotQuantity,
  setVolQuantity,
  setValidSpotQuantity,
  setValidVolQuantity,
} = spotSlice.actions;
