import React, { ChangeEvent, useState, useEffect } from "react";

import styled from "styled-components";

import { CC_WAITING, VOLATILITY_QUOTE } from "stateConstants";
import { clientCallAnswer, clientCallCancel } from "store/clientCalls/actions";
import { useAppDispatch } from "store/store";
import { percent2Digits, precision } from "utils/numbers";
import { ClientCall } from "types";
import NumberInput from "../../reusable/NumberInput";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import ToPricerButton from "components/reusable/ToPricerButton";
import {
  GreenButton,
  RedButton,
} from "components/reusable/GenericStyledComponents";

interface Props {
  call: ClientCall;
}

const UnquotedActions: React.FC<Props> = ({ call }) => {
  const dispatch = useAppDispatch();
  const sticky_model = useSelector(
    (state: RootState) => state.spot.sticky_model,
    shallowEqual
  );
  const { SPOT_DECIMAL_PLACES } = useSelector(
    (state: RootState) => state.settings.parameters
  );
  const [bid, setBid] = useState(
    call.quote_type === VOLATILITY_QUOTE
      ? call.trader_vol_bid_quote * 100
      : call.trader_premium_bid_quote
  );
  const [ask, setAsk] = useState(
    call.quote_type === VOLATILITY_QUOTE
      ? call.trader_vol_ask_quote * 100
      : call.trader_premium_ask_quote
  );

  useEffect(() => {
    // console.log({ divYield }, { interestRates }, call);
  }, []);

  // useEffect(() => {
  //   if (call.quote_type === VOLATILITY_QUOTE) {
  //     setBid(call.trader_vol_bid_quote * 100);
  //     setAsk(call.trader_vol_ask_quote * 100);
  //   } else {
  //     setBid(call.trader_premium_bid_quote);
  //     setAsk(call.trader_premium_ask_quote);
  //   }
  // }, [call.volatility, call.trader_premium_ask_quote, call.client_vol_ask]);

  const [callSent, setCallSent] = useState(false);
  const inputsAreInvalid = !bid || !ask;
  const isWaiting = call.status === CC_WAITING;

  const handleSendResponse = () => {
    dispatch(
      clientCallAnswer({
        callId: call.id,
        traderBidQuote: call.quote_type === VOLATILITY_QUOTE ? bid / 100 : bid,
        traderAskQuote: call.quote_type === VOLATILITY_QUOTE ? ask / 100 : ask,
      })
    );
    setCallSent(true);
  };

  const handleKeyPress = (
    key: string,
    setter: any,
    value: any,
    enterFn?: any
  ) => {
    let power = 0;
    if (call.quote_type === VOLATILITY_QUOTE) power = 2;
    else power = sticky_model ? SPOT_DECIMAL_PLACES : 2;
    switch (key) {
      case "ArrowUp":
        setter(precision(parseFloat(value) + 5 / 10 ** power, power));
        break;
      case "ArrowDown":
        setter(precision(parseFloat(value) - 5 / 10 ** power, power));
        break;
      case "ArrowLeft":
        setter(precision(parseFloat(value) - 1 / 10 ** power, power));
        break;
      case "ArrowRight":
        setter(precision(parseFloat(value) + 1 / 10 ** power, power));
        break;
      case "Enter":
        if (enterFn) enterFn();
        break;
    }
  };

  return (
    <Container>
      {!callSent && call.status === CC_WAITING && (
        <InputContainer>
          <Group>
            <Label>Bid</Label>
            <BidInput
              autoFocus
              fontSize={14}
              fontWeight={500}
              color="#F4F4F4"
              value={
                call.quote_type === VOLATILITY_QUOTE ? precision(bid, 2) : bid
              }
              suffix={call.quote_type === VOLATILITY_QUOTE ? " %" : " $"}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const value = e.target.value.replaceAll(/[ %]/g, "");
                if (value === "") return;
                setBid(parseFloat(value));
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
                handleKeyPress(e.key, setBid, bid);
              }}
            />
          </Group>

          <Group>
            <Label>Ask</Label>
            <AskInput
              fontSize={14}
              fontWeight={500}
              color="#F4F4F4"
              value={
                call.quote_type === VOLATILITY_QUOTE ? precision(ask, 2) : ask
              }
              suffix={call.quote_type === VOLATILITY_QUOTE ? " %" : " $"}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const value = e.target.value.replaceAll(/[ %]/g, "");
                if (value === "") return;
                setAsk(parseFloat(value));
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
                handleKeyPress(e.key, setAsk, ask, handleSendResponse);
              }}
            />
          </Group>
        </InputContainer>
      )}

      {callSent && (
        <Info>
          <Group>
            <Label>Bid</Label>
            {call.quote_type === VOLATILITY_QUOTE
              ? percent2Digits(bid / 100)
              : `${bid}$`}
          </Group>
          <Group>
            <Label>Ask</Label>
            {call.quote_type === VOLATILITY_QUOTE
              ? percent2Digits(ask / 100)
              : `${ask}$`}
          </Group>
        </Info>
      )}

      <ActionsContainer>
        <Column>
          <ToPricerButton
            call={call}
            callId={`${call.id}_cc`}
            isWaiting={isWaiting}
            sticky_model={sticky_model}
          />
        </Column>

        <Column>
          <GreenButton
            disabled={!isWaiting || callSent || inputsAreInvalid}
            onClick={handleSendResponse}
          >
            Send
          </GreenButton>
        </Column>

        <Column>
          <RedButton
            disabled={!isWaiting}
            onClick={() => {
              dispatch(clientCallCancel({ callId: call.id }));
            }}
          >
            Cancel
          </RedButton>
        </Column>
      </ActionsContainer>
    </Container>
  );
};

const Container = styled.div`
  // background: #272e33; // layout
  padding: 10px;

  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  gap: 15px;
`;

const Column = styled.div`
  flex: 1;
  display: flex;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const Group = styled.div`
  display: flex;
  gap: 5px;
`;

const Label = styled.span`
  color: #ebebeb80;
  align-self: center;
`;

const Input = styled(NumberInput)`
  background-color: #000;
  padding-right: 5px;
  position: relative;
`;

const BidInput = styled(Input)`
  color: #ff7875aa;
  border: 1px solid #ff7875aa;

  transition: all 0.2s;

  &:hover,
  &:active,
  &:focus {
    color: #ff7875 !important;
    border: 1px solid #ff7875 !important;
  }
`;

const AskInput = styled(Input)`
  color: #b7eb8f95;
  border: 1px solid #b7eb8f95;

  transition: all 0.2s;

  &:hover,
  &:active,
  &:focus {
    color: #b7eb8f !important;
    border: 1px solid #b7eb8f !important;
  }
`;

const Info = styled.div`
  display: flex;
  gap: 10px;
`;

export default UnquotedActions;
