import { Button } from "antd";
import StyledText from "components/reusable/StyledText";
import React, { useState } from "react";
import CloseButton from "assets/NavIcons/clear-button.svg";

import { Column, Row } from "../reusable/GenericStyledComponents";
import GenericModal from "./GenericModal";
import ModalCloseButton from "./ModalCloseButton";

type Props = {
  onClose: () => void;
  action: () => void;
  isShown: boolean;
  prompt: string;
};

const ConfirmationPromptModal: React.FC<Props> = ({
  onClose,
  action,
  isShown,
  prompt,
}) => {
  return (
    <GenericModal
      isShown={isShown}
      customClassName="ConfirmModal__Content"
      customOverlayClassName="MiddleModal__Overlay"
      overlayBackground="#00000060"
    >
      <img
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
        role="button"
        src={CloseButton}
        alt="delete"
        onClick={onClose}
        style={{ float: "right" }}
      />
      <Column style={{ alignItems: "center", marginTop: "1rem" }}>
        <StyledText fontWeight={400} fontSize={26}>
          {" "}
          {prompt}{" "}
        </StyledText>
        <Row style={{ marginTop: "1rem", alignItems: "center", gap: "2rem" }}>
          <Button
            onClick={() => {
              action();
              onClose();
            }}
          >
            Yes
          </Button>
          <Button onClick={onClose}>No</Button>
        </Row>
      </Column>
    </GenericModal>
  );
};

export default ConfirmationPromptModal;
