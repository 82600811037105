import { InfoCircleOutlined } from "@ant-design/icons";
import { Switch, Typography, Tooltip } from "antd";
import { Column, Row } from "components/reusable/GenericStyledComponents";
import StyledText from "components/reusable/StyledText";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import {
  updateDeltaStrikeRest,
  updateDeltaStrikeVolEb,
} from "store/delta_strike/actions";
import { RootState } from "store/rootReducer";
import { useAppDispatch } from "store/store";
import { convertDelta } from "utils/delta";
import { withStickyModel } from "utils/withStickyModel";
import styled from "styled-components";

type ActiveStrikesGameControlsStickyDeltaProps =
  | {
      // Define props for ActiveStrikesGameControlsStickyDelta
    }
  | unknown;

type ActiveStrikesGameControlsStickyStrikeProps =
  | {
      // Define props for ActiveStrikesGameControlsStickyStrike
    }
  | unknown;

const ActiveStrikesGameControlsStickyDelta: React.FC<
  ActiveStrikesGameControlsStickyDeltaProps
> = (props) => {
  const dispatch = useAppDispatch();
  const {
    visible_delta_strike_values,
    visible_vol_eb_delta_strike_values,
    delta_strike_values,
    swiftId,
    initial_state_loaded,
  } = useSelector(
    (state: RootState) => ({
      visible_delta_strike_values: state.spot.visible_delta_strike_values,
      visible_vol_eb_delta_strike_values:
        state.spot.visible_vol_eb_delta_strike_values,
      delta_strike_values: state.spot.delta_strike_values,
      swiftId: state.spot.swiftId,
      initial_state_loaded: state.spot.initial_state_loaded,
    }),
    shallowEqual
  );

  const [patternVolEb, setPatternVolEb] = useState(
    (delta_strike_values || []).map((_, t) =>
      (visible_vol_eb_delta_strike_values[swiftId] || []).includes(t) ? 1 : 0
    )
  );
  const [patternRest, setPatternRest] = useState(
    (delta_strike_values || []).map((_, t) =>
      (visible_delta_strike_values[swiftId] || []).includes(t) ? 1 : 0
    )
  );
  useEffect(() => {
    setPatternRest(
      (delta_strike_values || []).map((_, t) =>
        (visible_delta_strike_values[swiftId] || []).includes(t) ? 1 : 0
      )
    );
  }, [visible_delta_strike_values, delta_strike_values]);

  useEffect(() => {
    setPatternVolEb(
      (delta_strike_values || []).map((_, t) =>
        (visible_vol_eb_delta_strike_values[swiftId] || []).includes(t) ? 1 : 0
      )
    );
  }, [visible_vol_eb_delta_strike_values, delta_strike_values]);
  const middleDeltaStrike = Math.floor(delta_strike_values.length / 2);

  if (!initial_state_loaded) return null;

  return (
    <Column
      style={{ width: "100%", justifyContent: "center", alignItems: "center" }}
    >
      <Typography.Title level={5} style={{ fontWeight: "bold" }}>
        Delta values
      </Typography.Title>
      <Row
        style={{
          padding: "1rem 15%",
          width: "100%",
          justifyContent: "space-around",
          alignItems: "space-around",
        }}
      >
        <Column style={{ justifyContent: "center", alignItems: "center" }}>
          <Tooltip title="Choose what delta values are available for trading in the electronic brokers.">
            Tradable Vols <InfoCircleOutlined />
          </Tooltip>
          {React.Children.toArray(
            patternVolEb.map((mat, idx) => {
              return (
                <Row style={{ gap: "1rem" }}>
                  {/* eslint-disable-next-line */}
                  <label>
                    <Switch
                      checked={!!patternVolEb[idx]}
                      checkedChildren="ACTIVE"
                      unCheckedChildren="DISABLED"
                      onChange={(checked) => {
                        const newPattern = [...patternVolEb];
                        newPattern[idx] = checked ? 1 : 0;
                        setPatternVolEb(newPattern);
                        dispatch(
                          updateDeltaStrikeVolEb({
                            pattern: newPattern,
                            swift_id: swiftId,
                          })
                        );
                      }}
                    />
                    <span>{convertDelta[delta_strike_values[idx]]}</span>
                  </label>
                </Row>
              );
            })
          )}
        </Column>
        <Column style={{ justifyContent: "center", alignItems: "center" }}>
          <Tooltip title="Choose what delta values are available for trading when banks or clients request for quotes. To increase difficulty, you can choose more tenors for all calls vs. tradable vols tenors..">
            <Typography.Title level={5}>
              All calls <InfoCircleOutlined />
            </Typography.Title>
          </Tooltip>
          {React.Children.toArray(
            patternRest.map((mat, idx) => {
              return (
                <Row style={{ gap: "1rem" }}>
                  {/* eslint-disable-next-line */}
                  <label>
                    <Switch
                      checked={!!patternRest[idx]}
                      checkedChildren="ACTIVE"
                      unCheckedChildren="DISABLED"
                      onChange={(checked) => {
                        const newPattern = [...patternRest];
                        newPattern[idx] = checked ? 1 : 0;
                        setPatternRest(newPattern);
                        dispatch(
                          updateDeltaStrikeRest({
                            pattern: newPattern,
                            swift_id: swiftId,
                          })
                        );
                      }}
                    />
                    <span>{convertDelta[delta_strike_values[idx]]}</span>
                    {/* {
                      idx - delta_strike_values.length === 0 ? "ATM" :
                        `ATM${idx - delta_strike_values.length < 0 ? "" : "+"}${idx - delta_strike_values.length}
                            `} */}
                  </label>
                </Row>
              );
            })
          )}
        </Column>
      </Row>
    </Column>
  );
};

const ActiveStrikesGameControlsStickyStrike: React.FC<
  ActiveStrikesGameControlsStickyStrikeProps
> = (props) => {
  const dispatch = useAppDispatch();
  const {
    visible_delta_strike_values,
    visible_vol_eb_delta_strike_values,
    delta_strike_values,
    swiftId,
    initial_state_loaded,
  } = useSelector(
    (state: RootState) => ({
      visible_delta_strike_values: state.spot.visible_delta_strike_values,
      visible_vol_eb_delta_strike_values:
        state.spot.visible_vol_eb_delta_strike_values,
      delta_strike_values: state.spot.delta_strike_values,
      swiftId: state.spot.swiftId,
      initial_state_loaded: state.spot.initial_state_loaded,
    }),
    shallowEqual
  );
  const swift_ = useSelector((state: RootState) => state.swifts.selectedSwift);
  const [patternVolEb, setPatternVolEb] = useState(
    (delta_strike_values || []).map((_, t) =>
      (visible_vol_eb_delta_strike_values[swiftId] || []).includes(t) ? 1 : 0
    )
  );
  const [patternRest, setPatternRest] = useState(
    (delta_strike_values || []).map((_, t) =>
      (visible_delta_strike_values[swiftId] || []).includes(t) ? 1 : 0
    )
  );
  useEffect(() => {
    setPatternRest(
      (delta_strike_values || []).map((_, t) =>
        (visible_delta_strike_values[swiftId] || []).includes(t) ? 1 : 0
      )
    );
  }, [visible_delta_strike_values, delta_strike_values]);

  useEffect(() => {
    setPatternVolEb(
      (delta_strike_values || []).map((_, t) =>
        (visible_vol_eb_delta_strike_values[swiftId] || []).includes(t) ? 1 : 0
      )
    );
  }, [visible_vol_eb_delta_strike_values, delta_strike_values]);
  const middleDeltaStrike = Math.floor(delta_strike_values.length / 2);

  if (!initial_state_loaded) return null;

  return (
    <Column
      style={{ width: "100%", justifyContent: "center", alignItems: "center" }}
    >
      <Typography.Title level={5} style={{ fontWeight: "bold" }}>
        Strikes
      </Typography.Title>

      <Row
        style={{
          padding: "1rem 15%",
          width: "100%",
          justifyContent: "space-around",
          alignItems: "space-around",
        }}
      >
        <Column style={{ justifyContent: "center", alignItems: "center" }}>
          <Tooltip title="Choose what strikes are available for trading in the electronic brokers.">
            <Typography.Title level={5} style={{ fontWeight: "bold" }}>
              Tradable vols <InfoCircleOutlined />
            </Typography.Title>
          </Tooltip>

          {React.Children.toArray(
            patternVolEb.map((mat, idx) => {
              return (
                <Row style={{ gap: "1rem" }}>
                  {/* eslint-disable-next-line */}
                  <label>
                    <Switch
                      checked={!!patternVolEb[idx]}
                      checkedChildren="ACTIVE"
                      unCheckedChildren="DISABLED"
                      onChange={(checked) => {
                        const newPattern = [...patternVolEb];
                        newPattern[idx] = checked ? 1 : 0;
                        setPatternVolEb(newPattern);
                        dispatch(
                          updateDeltaStrikeVolEb({
                            pattern: newPattern,
                            swift_id: swiftId,
                          })
                        );
                      }}
                    />
                    {/* <span>{delta_strike_values[idx]}</span> */}
                    {idx - middleDeltaStrike === 0
                      ? "ATM"
                      : `ATM${idx - middleDeltaStrike < 0 ? "" : "+"}${
                          swift_.strike_tick * (idx - middleDeltaStrike)
                        }
                            `}
                  </label>
                </Row>
              );
            })
          )}
        </Column>
        <Column style={{ justifyContent: "center", alignItems: "center" }}>
          <Tooltip title="Choose what strikes are available for trading when banks or clients request for quotes. To increase difficulty, you can choose more tenors for all calls vs. tradable vols tenors..">
            <Typography.Title level={5}>
              All calls <InfoCircleOutlined />
            </Typography.Title>
          </Tooltip>
          {React.Children.toArray(
            patternRest.map((mat, idx) => {
              return (
                <Row style={{ gap: "1rem" }}>
                  {/* eslint-disable-next-line */}
                  <label>
                    <Switch
                      checked={!!patternRest[idx]}
                      checkedChildren="ACTIVE"
                      unCheckedChildren="DISABLED"
                      onChange={(checked) => {
                        const newPattern = [...patternRest];
                        newPattern[idx] = checked ? 1 : 0;
                        setPatternRest(newPattern);
                        dispatch(
                          updateDeltaStrikeRest({
                            pattern: newPattern,
                            swift_id: swiftId,
                          })
                        );
                      }}
                    />
                    {/* <span>{delta_strike_values[idx]}</span> */}
                    {idx - middleDeltaStrike === 0
                      ? "ATM"
                      : `ATM${idx - middleDeltaStrike < 0 ? "" : "+"}${
                          swift_.strike_tick * (idx - middleDeltaStrike)
                        }
                            `}
                  </label>
                </Row>
              );
            })
          )}
        </Column>
      </Row>
    </Column>
  );
};

const Container = styled.div`
  margin-top: 1rem;
`;

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
`;

const Group = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 0.5rem;
`;

const ActiveStrikesGameControls = withStickyModel<
  ActiveStrikesGameControlsStickyDeltaProps,
  ActiveStrikesGameControlsStickyStrikeProps
>(ActiveStrikesGameControlsStickyDelta, ActiveStrikesGameControlsStickyStrike);

export default ActiveStrikesGameControls;
export {
  ActiveStrikesGameControlsStickyDelta,
  ActiveStrikesGameControlsStickyStrike,
};
export type {
  ActiveStrikesGameControlsStickyDeltaProps,
  ActiveStrikesGameControlsStickyStrikeProps,
};
