import React, { useState } from "react";
import {
  BaseGameContainer,
  Row,
  Column,
  Line,
} from "components/reusable/GenericStyledComponents";
import StyledText from "components/reusable/StyledText";
import styled from "styled-components";
import StrategyToggle from "./QuickActions/StrategyToggle";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { Permission } from "types";
import { Button, Input, Space } from "antd";
import MarketImpactSlidersModal from "./QuickActions/MarketImpactSlidersModal";
import PnlDivider from "./QuickActions/PnlDivider";

const TrainerQuickActions: React.FC = () => {
  const { permission } = useSelector((state: RootState) => state.user);
  const [showSliders, setShowSliders] = useState<boolean>(false);

  return (
    <Container>
      <MarketImpactSlidersModal
        showSliders={showSliders}
        setShowSliders={setShowSliders}
      />
      <StyledText fontSize={15} fontWeight={500}>
        Trainer quick actions
      </StyledText>
      <Line />
      <Column style={{ marginTop: ".5rem", gap: ".5rem", width: "50%" }}>
        <Row style={{ justifyContent: "space-between" }}>
          <StyledText fontSize={14} fontWeight={400}>
            Strategy:
          </StyledText>
          <StrategyToggle />
        </Row>
        <PnlDivider />
        {permission === Permission.Admin && (
          <Row
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <StyledText fontSize={14} fontWeight={400}>
              Market impact:
            </StyledText>
            <Button onClick={() => setShowSliders(true)}>Show sliders</Button>
          </Row>
        )}
      </Column>
    </Container>
  );
};

const Container = styled(Column)`
  justify-content: center;
  align-items: center;
`;

export default TrainerQuickActions;
