import { Skeleton } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import { DataRow, TableData } from "types";
import { Column, Row } from "./GenericStyledComponents";
import StyledText from "./StyledText";

interface ICustomTableProps {
  header: string[];
  data: TableData;
}

const Container = styled(Column)`
  margin: 1rem 2rem;
`;

const HeaderRow = styled(Row)`
  background: transparent;

  padding: 10px 0;

  gap: 10px;

  & p:first-child {
    margin-left: 11px;
  }

  & p:last-child {
    margin-right: 11px;
  }

  & > * {
    flex: 1;
  }
`;

const TableDataCol = styled(Column)`
  overflow-y: auto;
  overflow-x: hidden;
  height: max-content;
`;

const TableRow = styled(Row)<{ expanded: boolean }>`
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  padding: 18px 11px;
  z-index: 2;

  align-items: center;
  transition: 0.25s ease-in-out;

  gap: 10px;

  & > * {
    flex: 1;
  }
  ${({ expanded }) =>
    !expanded
      ? `
      &: hover {
        background: var(--row-hover-color);
        box-shadow: 0 6px 6px rgba(0, 0, 0, 0.4);
    }
    `
      : "&:hover {}"}
`;

const ExpandedDiv = styled.div`
  transition: height 0.3s, opacity 0.3s;
  margin-bottom: 4px;
  padding-right: 5px;
`;

const RowContainer: React.FC<{ row: DataRow }> = ({ row }) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <>
      <TableRow
        expanded={expanded}
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        {row.data.map((RenderComponent) => (
          <Column key={Math.random()} style={{ flex: 1 }}>
            <RenderComponent />
          </Column>
        ))}
      </TableRow>
      {row.ExpandedComponent ? (
        <ExpandedDiv
          style={{
            height: expanded ? "fit-content" : 0,
            opacity: expanded ? 1 : 0,
            zIndex: expanded ? 1 : -1,
            position: "relative",
          }}
        >
          <row.ExpandedComponent />
        </ExpandedDiv>
      ) : null}
    </>
  );
};

const CustomTable: React.FC<ICustomTableProps> = ({ header, data }) => (
  <Container>
    <HeaderRow>
      {header.map((text) => (
        <StyledText
          fontSize={14}
          fontWeight={400}
          fontFamily="IBMPlexSans"
          color="#FFFFFF50"
          key={text}
        >
          {text}
        </StyledText>
      ))}
    </HeaderRow>
    {/* TODO: fk react and find a key */}
    {/* React defaults to array index */}
    <TableDataCol>
      {data.map((row, idx) =>
        // eslint-disable-next-line react/no-array-index-key
        row ? <RowContainer row={row} key={idx} /> : <Skeleton active />
      )}
    </TableDataCol>
  </Container>
);

export default CustomTable;
