import { createSlice } from "@reduxjs/toolkit";

import { Swift, SwiftsState } from "types";
import { clearRoom, retrieveInitialData } from "../room/actions";

const dummySwift: Swift = {
  id: 0,
  asset_class: "",
  code: "",
  name: "",
  reporting_currency: "",
  tenor_type: 0,
  spot_decimal_places: 0,
  swift_id: 0,
  tick: 0,
  vol_ticket_unit: 0,
  spot_ticket_unit: 0,
  extra: {},
  sticky_model: 0,
  volatility_decimal_places: 0,
  volatility_tick: 0,
  strike_tick: 0,
  spot_lot_size: 0,
  vol_lot_size: 0,
};

const initialState: () => SwiftsState = () => ({
  selectedSwift: dummySwift,
});

const swiftsSlice = createSlice({
  initialState,
  name: "swifts",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveInitialData, (state, action) => {
        const { swifts } = action.payload;
        if (swifts.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          state.selectedSwift = swifts[0];
        }
        swifts.forEach((swift) => {
          state[swift.swift_id] = {
            ...swift,
          };
        });
      })
      .addCase(clearRoom, initialState);
  },
});

export default swiftsSlice.reducer;
