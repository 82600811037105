import React, { useEffect } from "react";

import styled from "styled-components";
import { listClientCalls } from "store/clientCalls/selectors";
import { BaseGameContainer, Row } from "../../reusable/GenericStyledComponents";
import { useSelector } from "react-redux";
import QuotedCC from "./QuotedCC";
import UnquotedCC from "./UnquotedCC";
import { useAppDispatch } from "store/store";
import { VOLATILITY_QUOTE, SELL, BUY, SHORT } from "stateConstants";
import { sendVolRequest, pricerSendData } from "store/newpricer/actions";
import { timeAbsoluteTenorToUnix, timeDateToUnixYMD } from "utils/time";
import { RootState } from "store/rootReducer";
import { Typography } from "antd";

const ClientCalls = () => {
  const ccList = useSelector(listClientCalls);
  const dispatch = useAppDispatch();
  const { yield_curves } = useSelector((state: RootState) => state.room);
  const { swift_id } = useSelector(
    (state: RootState) => state.swifts.selectedSwift
  );

  useEffect(() => {
    ccList.forEach((call) => {
      // this sets trader_vol_{bid/ask}_quote
      if (call.volatility === 0) {
        dispatch(
          sendVolRequest({
            strike: call.strike,
            tenor: call.tenor,
            routing_key: "cc",
            correlation_id: `${call.id}`,
          })
        );
      }
      if (
        call.quote_type !== VOLATILITY_QUOTE &&
        call.volatility !== 0 &&
        call.trader_premium_ask_quote === 0 &&
        call.trader_premium_bid_quote === 0
      ) {
        const divYield = yield_curves[swift_id].variable_yc[call.tenor];
        const interestRates = yield_curves[swift_id].base_yc[call.tenor];
        const payload = {
          direction: call.short_or_long === SHORT ? SELL : BUY,
          type: call.put_or_call,
          notional: 1,
          strike: call.strike,
          divYield: Number(divYield),
          interestRates: Number(interestRates),
          maturity: call.tenor,
          routing_key: "cc",
        };
        dispatch(
          pricerSendData({
            ...payload,
            correlationId: `${call.id} BID`,
            volatility: call.trader_vol_bid_quote * 100,
          })
        );
        dispatch(
          pricerSendData({
            ...payload,
            correlationId: `${call.id} ASK`,
            volatility: call.trader_vol_ask_quote * 100,
          })
        );
      }
    });
  }, [ccList]);

  return (
    <CallsList>
      {ccList.map((call) => {
        if (call.quote_type === VOLATILITY_QUOTE) {
          if (call.client_vol_ask === 0) {
            return <UnquotedCC key={call.id} call={call} />;
          }
          return <QuotedCC key={call.id} call={call} />;
        }
        if (call.client_premium_ask === 0) {
          return <UnquotedCC key={call.id} call={call} />;
        }
        return <QuotedCC key={call.id} call={call} />;
      })}
    </CallsList>
  );
};

const Container = styled(BaseGameContainer)`
  flex: 1;
  overflow-y: auto;
`;

const CallsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 20px;
`;

export default ClientCalls;
