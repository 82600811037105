import { InfoCircleOutlined } from "@ant-design/icons";
import { Switch, Tooltip, Typography } from "antd";
import { Column, Row } from "components/reusable/GenericStyledComponents";
import StyledText from "components/reusable/StyledText";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { useAppDispatch } from "store/store";
import { updateTenorsRest, updateTenorsVolEb } from "store/tenors/actions";
import { MATURITY } from "types";
import { timeUnixToDatePretty } from "utils/time";
import { withStickyModel } from "utils/withStickyModel";

type ActiveTenorsGameControlsStickyDeltaProps =
  | {
      // Define props for ActiveTenorsGameControlsStickyDelta
    }
  | unknown;

type ActiveTenorsGameControlsStickyStrikeProps =
  | {
      // Define props for ActiveTenorsGameControlsStickyStrike
    }
  | unknown;

const ActiveTenorsGameControlsStickyDelta: React.FC<
  ActiveTenorsGameControlsStickyDeltaProps
> = (props) => {
  const dispatch = useAppDispatch();
  const {
    visible_vol_eb_tenors,
    visible_tenors,
    tenors,
    swiftId,
    initial_state_loaded,
  } = useSelector(
    (state: RootState) => ({
      visible_vol_eb_tenors: state.spot.visible_vol_eb_tenors,
      visible_tenors: state.spot.visible_tenors,
      tenors: state.spot.tenors,
      swiftId: state.spot.swiftId,
      initial_state_loaded: state.spot.initial_state_loaded,
    }),
    shallowEqual
  );
  const [patternVolEb, setPatternVolEb] = useState(
    (tenors[swiftId] || []).map((_, t) =>
      visible_vol_eb_tenors[swiftId].includes(t) ? 1 : 0
    )
  );
  const [patternRest, setPatternRest] = useState(
    (tenors[swiftId] || []).map((_, t) =>
      visible_tenors[swiftId].includes(t) ? 1 : 0
    )
  );

  useEffect(() => {
    setPatternRest(
      (tenors[swiftId] || []).map((_, t) =>
        visible_tenors[swiftId].includes(t) ? 1 : 0
      )
    );
  }, [visible_tenors, tenors]);

  useEffect(() => {
    setPatternVolEb(
      (tenors[swiftId] || []).map((_, t) =>
        visible_vol_eb_tenors[swiftId].includes(t) ? 1 : 0
      )
    );
  }, [visible_vol_eb_tenors, tenors]);

  if (!initial_state_loaded) return null;
  return (
    <Column
      style={{ width: "100%", justifyContent: "center", alignItems: "center" }}
    >
      <Typography.Title level={5} style={{ fontWeight: "bold" }}>
        Tenors
      </Typography.Title>
      <Row
        style={{
          padding: "1rem 15%",
          width: "100%",
          justifyContent: "space-around",
          alignItems: "space-around",
        }}
      >
        <Column style={{ justifyContent: "center", alignItems: "center" }}>
          <Tooltip title="Choose what tenors are available for trading in the electronic brokers.">
            Tradable vols <InfoCircleOutlined />
          </Tooltip>
          {React.Children.toArray(
            patternVolEb.map((mat, idx) => {
              return (
                <Row style={{ gap: "1rem" }}>
                  {/* eslint-disable-next-line */}
                  <label>
                    <Switch
                      checked={!!patternVolEb[idx]}
                      checkedChildren="ACTIVE"
                      unCheckedChildren="DISABLED"
                      onChange={(checked) => {
                        const newPattern = [...patternVolEb];
                        newPattern[idx] = checked ? 1 : 0;
                        setPatternVolEb(newPattern);
                        dispatch(
                          updateTenorsVolEb({
                            pattern: newPattern,
                            swift_id: swiftId,
                          })
                        );
                      }}
                    />
                    <span>{MATURITY[tenors[swiftId][idx]]}</span>
                  </label>
                </Row>
              );
            })
          )}
        </Column>
        <Column style={{ justifyContent: "center", alignItems: "center" }}>
          <Tooltip title="Choose what tenors are available for trading when banks or clients request for quotes. To increase difficulty, you can choose more tenors for all calls vs. tradable vols tenors..">
            <Typography.Title level={5}>
              All calls <InfoCircleOutlined />
            </Typography.Title>
          </Tooltip>
          {React.Children.toArray(
            patternRest.map((mat, idx) => {
              return (
                <Row style={{ gap: "1rem" }}>
                  {/* eslint-disable-next-line */}
                  <label>
                    <Switch
                      checked={!!patternRest[idx]}
                      checkedChildren="ACTIVE"
                      unCheckedChildren="DISABLED"
                      onChange={(checked) => {
                        const newPattern = [...patternRest];
                        newPattern[idx] = checked ? 1 : 0;
                        setPatternRest(newPattern);
                        dispatch(
                          updateTenorsRest({
                            pattern: newPattern,
                            swift_id: swiftId,
                          })
                        );
                      }}
                    />
                    <span>{MATURITY[tenors[swiftId][idx]]}</span>
                  </label>
                </Row>
              );
            })
          )}
        </Column>
      </Row>
    </Column>
  );
};

const ActiveTenorsGameControlsStickyStrike: React.FC<
  ActiveTenorsGameControlsStickyStrikeProps
> = (props) => {
  const dispatch = useAppDispatch();

  const {
    visible_vol_eb_tenors,
    visible_tenors,
    tenors,
    swiftId,
    initial_state_loaded,
  } = useSelector(
    (state: RootState) => ({
      visible_vol_eb_tenors: state.spot.visible_vol_eb_tenors,
      visible_tenors: state.spot.visible_tenors,
      tenors: state.spot.tenors,
      swiftId: state.spot.swiftId,
      initial_state_loaded: state.spot.initial_state_loaded,
    }),
    shallowEqual
  );
  const [patternVolEb, setPatternVolEb] = useState(
    (tenors[swiftId] || []).map((_, t) =>
      visible_vol_eb_tenors[swiftId].includes(t) ? 1 : 0
    )
  );
  const [patternRest, setPatternRest] = useState(
    (tenors[swiftId] || []).map((_, t) =>
      visible_tenors[swiftId].includes(t) ? 1 : 0
    )
  );
  // console.log("Strike");
  // console.log({ visible_vol_eb_tenors });
  // console.log({ visible_tenors });
  // console.log({ tenors });
  // console.log({ patternVolEb });
  // console.log({ patternRest });

  useEffect(() => {
    if (visible_tenors)
      setPatternRest(
        (tenors[swiftId] || []).map((_, t) =>
          visible_tenors[swiftId].includes(t) ? 1 : 0
        )
      );
  }, [visible_tenors, tenors]);

  useEffect(() => {
    if (visible_vol_eb_tenors)
      setPatternVolEb(
        (tenors[swiftId] || []).map((_, t) =>
          visible_vol_eb_tenors[swiftId].includes(t) ? 1 : 0
        )
      );
  }, [visible_vol_eb_tenors, tenors]);
  if (!initial_state_loaded) return null;
  return (
    <Column
      style={{ width: "100%", justifyContent: "center", alignItems: "center" }}
    >
      <Typography.Title level={5} style={{ fontWeight: "bold" }}>
        Active TradableVols tenors
      </Typography.Title>
      <Row
        style={{
          padding: "1rem 15%",
          width: "100%",
          justifyContent: "space-around",
          alignItems: "space-around",
        }}
      >
        <Column style={{ justifyContent: "center", alignItems: "center" }}>
          <Tooltip title="Choose what tenors are available for trading in the electronic brokers.">
            <Typography.Title level={5}>
              Tradable vols <InfoCircleOutlined />
            </Typography.Title>
          </Tooltip>
          {React.Children.toArray(
            patternVolEb.map((mat, idx) => {
              return (
                <Row style={{ gap: "1rem" }}>
                  {/* eslint-disable-next-line */}
                  <label>
                    <Switch
                      checked={!!patternVolEb[idx]}
                      checkedChildren="ACTIVE"
                      unCheckedChildren="DISABLED"
                      onChange={(checked) => {
                        const newPattern = [...patternVolEb];
                        newPattern[idx] = checked ? 1 : 0;
                        setPatternVolEb(newPattern);
                        dispatch(
                          updateTenorsVolEb({
                            pattern: newPattern,
                            swift_id: swiftId,
                          })
                        );
                      }}
                    />
                    <span>{timeUnixToDatePretty(tenors[swiftId][idx])}</span>
                  </label>
                </Row>
              );
            })
          )}
        </Column>
        <Column style={{ justifyContent: "center", alignItems: "center" }}>
          <Tooltip title="Choose what strikes are available for trading when banks or clients request for quotes. To increase difficulty, you can choose more tenors for all calls vs. tradable vols tenors..">
            <Typography.Title level={5}>
              All calls <InfoCircleOutlined />
            </Typography.Title>
          </Tooltip>
          {React.Children.toArray(
            patternRest.map((mat, idx) => {
              return (
                <Row style={{ gap: "1rem" }}>
                  {/* eslint-disable-next-line */}
                  <label>
                    <Switch
                      checked={!!patternRest[idx]}
                      checkedChildren="ACTIVE"
                      unCheckedChildren="DISABLED"
                      onChange={(checked) => {
                        const newPattern = [...patternRest];
                        newPattern[idx] = checked ? 1 : 0;
                        setPatternRest(newPattern);
                        dispatch(
                          updateTenorsRest({
                            pattern: newPattern,
                            swift_id: swiftId,
                          })
                        );
                      }}
                    />
                    <span>{timeUnixToDatePretty(tenors[swiftId][idx])}</span>
                  </label>
                </Row>
              );
            })
          )}
        </Column>
      </Row>
    </Column>
  );
};

const ActiveTenorsGameControls = withStickyModel<
  ActiveTenorsGameControlsStickyDeltaProps,
  ActiveTenorsGameControlsStickyStrikeProps
>(ActiveTenorsGameControlsStickyDelta, ActiveTenorsGameControlsStickyStrike);

export default ActiveTenorsGameControls;
export {
  ActiveTenorsGameControlsStickyDelta,
  ActiveTenorsGameControlsStickyStrike,
};
export type {
  ActiveTenorsGameControlsStickyDeltaProps,
  ActiveTenorsGameControlsStickyStrikeProps,
};
