import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
// import { useAppDispatch } from 'store/store';
// import { deleteScenario } from 'store/trainer/reducers';
import TrainerCreateSessionCard from "../Trainer/TrainerCreateSessionCard";
import { Modal } from "antd";
// import { hideScenarioModal } from "store/trainer/reducers";
// import { useAppDispatch } from "store/store";

const ScenarioModal: React.FC<{ showModal: boolean; onClose: () => void }> = ({
  showModal,
  onClose,
}) => {
  const { selectedScenario } = useSelector((state: RootState) => state.trainer);
  // const dispatch = useAppDispatch();

  return (
    <Modal
      open={showModal}
      title={selectedScenario.name}
      onCancel={onClose}
      footer={null}
      width="30rem"
    >
      <TrainerCreateSessionCard
        scenario={selectedScenario}
        // deleteScenario={dispatch(deleteScenario(selectedScenario.id))}
      />
    </Modal>
  );
};

export default ScenarioModal;
