import React from "react";

import styled from "styled-components";
import NewCandlestickSpotChart from "./NewCandlestickSpotChart";
import ApexVolchart from "./ApexVolchart";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";

const ChartsSection: React.FC = () => {
  const editing = useSelector((state: RootState) => state.layout.editing);

  return (
    <Container
      onClick={(e) => {
        console.log("Click");

        if (editing) {
          console.log("Click while editing");

          e.stopPropagation();
          e.preventDefault();
        }
      }}
    >
      <Col>
        {/* <Chart
        title="Spot Chart"
        graphColor="#22B793"
        chartValues={_.takeRight(
          [
            ...spotChartValues
              .map(
                (point): ScatterDataPoint => ({
                  x: point.room_time,
                  y: point.price,
                })
              )
              .sort((a, b) => a.x - b.x),
          ],
          30
        )}
      /> */}
        {/* <CandlestickSpotChart /> */}
        <NewCandlestickSpotChart />
      </Col>

      <Col>
        <ApexVolchart />
        {/* // <NewVolChart
          //   delta_strike_values={delta_strike_values}
          //   tenorChoices={tenorChoices}
          //   decimals={swift.volatility_decimal_places}
          // />
          // <StackedVolChart/> */}
      </Col>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
`;

const Col = styled.div`
  width: 50%;
  position: relative;
`;
export default ChartsSection;
