import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row } from "../components/reusable/GenericStyledComponents";
import TrainerClasses from "../components/Trainer/TrainerClasses";
import TrainerNav from "../components/Trainer/TrainerNav";
import TrainerScenarios from "../components/Trainer/TrainerScenarios";
import TrainerLiveSessions from "../components/Trainer/TrainerLiveSessions";
import TrainerHistory from "../components/Trainer/TrainerHistory";
import {
  getTrainerClasses,
  getTrainerScenarios,
  getTrainerLiveRooms,
  getTrainerHistoryRooms,
} from "../store/trainer/reducers";
import { useAppDispatch } from "store/store";
import { trainerService } from "services";
import { getFromLS, setToLS } from "utils";

const initNav = () => {
  const nav = getFromLS("trainernav");
  return nav !== null ? nav : 0;
};

const Trainer = () => {
  const dispatch = useAppDispatch();

  const fetchRooms = async () => {
    const liveRooms = await trainerService.getTrainerLiveRooms();
    const historyRooms = await trainerService.getTrainerHistoryRooms();
    dispatch(getTrainerLiveRooms(liveRooms));
    dispatch(getTrainerHistoryRooms(historyRooms));
  };

  const fetchClasses = async () => {
    const classes = await trainerService.getTrainerClasses();
    dispatch(getTrainerClasses(classes));
  };
  const fetchScenarios = async () => {
    const sc = await trainerService.getTrainerScenarios();
    dispatch(getTrainerScenarios(sc));
  };
  useEffect(() => {
    fetchScenarios();
    fetchClasses();
    fetchRooms();
  }, []);
  useEffect(() => {
    fetchRooms();
    fetchClasses();
  }, []);

  const [selectedNav, setSelectedNav] = useState(initNav());
  const changeNav = (idx: number) => {
    setToLS("trainernav", idx);
    setSelectedNav(idx);
  };

  return (
    <Container>
      <TrainerNav activeNav={selectedNav} changeNav={changeNav} />
      <Content>
        {selectedNav === 0 && <TrainerClasses />}
        {selectedNav === 1 && <TrainerScenarios />}
        {selectedNav === 2 && <TrainerLiveSessions />}
        {selectedNav === 3 && <TrainerHistory />}
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
`;

const Content = styled.div`
  padding: 20px 30px;
  flex: 1;
`;

export default Trainer;
