import React from "react";

import styled from "styled-components";
import NumberFormat, { NumberFormatProps } from "react-number-format";

import { Column, Row } from "./GenericStyledComponents";
import ArrowUp from "assets/input-arrow-up.svg";
import ArrowDown from "assets/input-arrow-down.svg";

interface INumberInputProps {
  fontSize: number;
  fontWeight: number;
  color?: string;
  fontFamily?: string;
}

// @ts-ignore
// @ts-ignore
export const NumberInputWithArrows: React.FC<
  NumberFormatProps & {
    fontSize: number;
    fontWeight: number;
    incrementValue?: number;
    value: string;
    setValue?: (val: string) => void;
  }
> = ({ incrementValue, setValue, fontWeight, fontSize, ...props }) => (
  <Row style={{ position: "relative", width: "100%" }}>
    {/* @ts-ignore */}
    <ArrowsNumberInput
      fontWeight={fontWeight}
      fontSize={fontSize}
      style={{ paddingRight: 22, marginRight: incrementValue ? 0 : 8 }}
      onKeyDown={(event) => {
        if (incrementValue && setValue) {
          if (event.key === "ArrowUp") {
            setValue((parseFloat(props.value) + incrementValue).toString());
          } else if (event.key === "ArrowDown") {
            setValue((parseFloat(props.value) - incrementValue).toString());
          }
        }
      }}
      {...props}
    />
    {incrementValue && setValue && (
      <ArrowsContainer className="justify-content-center" style={{ gap: 6 }}>
        <img
          style={{ width: 8, height: 8 }}
          src={ArrowUp}
          alt="arrow up"
          role="button"
          onClick={() => {
            setValue((parseFloat(props.value) + incrementValue).toString());
          }}
        />
        <img
          style={{ width: 8, height: 8 }}
          src={ArrowDown}
          alt="arrow down"
          role="button"
          onClick={() => {
            setValue((parseFloat(props.value) - incrementValue).toString());
          }}
        />
      </ArrowsContainer>
    )}
  </Row>
);

const NumberInput = styled(NumberFormat)<INumberInputProps>`
  margin: 0;
  padding: 0;
  width: auto;
  border: none;
  background: #232c3200;
  text-align: right;
  font-size: ${({ fontSize }) => `${fontSize}px`};
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ color, theme }) => color || theme.colors.primaryText};
  font-family: ${({ fontFamily }) => fontFamily || "Roboto"};

  &:focus-visible {
    outline: none;
  }
`;

NumberInput.defaultProps = {
  size: 8,
};

const ArrowsNumberInput = styled(NumberInput)`
  position: relative;
`;

const ArrowsContainer = styled(Column)`
  position: relative;
  right: 14px;
`;

export default NumberInput;
