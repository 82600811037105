import React from "react";
import { Switch, Typography, Tooltip } from "antd";

import { TrainerScenarioBE } from "types";
import { convertDelta } from "utils/delta";
import { withStickyModel } from "utils/withStickyModel";
import { InfoCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";

type ComponentProps = {
  modifiedScenario: TrainerScenarioBE;
  setModifiedScenario: React.Dispatch<React.SetStateAction<TrainerScenarioBE>>;
};

const ActiveDeltaStrikeStickyDelta: React.FC<ComponentProps> = ({
  modifiedScenario,
  setModifiedScenario,
}) => {
  const possibleDeltas = [0.1, 0.25, 0.5, 0.75, 0.9];

  return (
    <Container>
      <Typography.Title level={5} style={{ fontWeight: "bold" }}>
        Deltas
      </Typography.Title>

      <Wrapper>
        <div>
          <Tooltip title="Choose what tenors are available for trading in the electronic brokers.">
            <Typography.Title level={5}>
              Tradable vols <InfoCircleOutlined />
            </Typography.Title>
          </Tooltip>

          <div>
            {React.Children.toArray(
              modifiedScenario.visible_vol_eb_delta_strike_values.map(
                (dk, idx) => {
                  return (
                    <Group>
                      <Switch
                        checked={
                          modifiedScenario.visible_vol_eb_delta_strike_values[
                            idx
                          ]
                        }
                        checkedChildren="ACTIVE"
                        unCheckedChildren="DISABLED"
                        onChange={(checked) => {
                          const checkedCount =
                            modifiedScenario.visible_vol_eb_delta_strike_values.filter(
                              (t) => !!t
                            ).length;

                          // If trying to uncheck the last item, prevent it.
                          if (!checked && checkedCount === 1) {
                            return;
                          }
                          const newDeltaStrike =
                            modifiedScenario.visible_vol_eb_delta_strike_values.map(
                              (value, i) => (i === idx ? checked : value)
                            );
                          setModifiedScenario({
                            ...modifiedScenario,
                            visible_vol_eb_delta_strike_values: newDeltaStrike,
                          });
                        }}
                      />

                      <span>{convertDelta[possibleDeltas[idx]]}</span>
                    </Group>
                  );
                }
              )
            )}
          </div>
        </div>

        <div>
          <Tooltip title="Choose what tenors are available for trading in the electronic brokers.">
            <Typography.Title level={5}>
              All calls <InfoCircleOutlined />
            </Typography.Title>
          </Tooltip>

          <div>
            {React.Children.toArray(
              modifiedScenario.visible_delta_strike_values.map((dk, idx) => {
                return (
                  <Group>
                    <Switch
                      checked={
                        modifiedScenario.visible_delta_strike_values[idx]
                      }
                      checkedChildren="ACTIVE"
                      unCheckedChildren="DISABLED"
                      onChange={(checked) => {
                        const checkedCount =
                          modifiedScenario.visible_delta_strike_values.filter(
                            (t) => !!t
                          ).length;

                        // If trying to uncheck the last item, prevent it.
                        if (!checked && checkedCount === 1) {
                          return;
                        }
                        const newDeltaStrike =
                          modifiedScenario.visible_delta_strike_values.map(
                            (value, i) => (i === idx ? checked : value)
                          );
                        setModifiedScenario({
                          ...modifiedScenario,
                          visible_delta_strike_values: newDeltaStrike,
                        });
                      }}
                    />
                    <span>{convertDelta[possibleDeltas[idx]]}</span>
                  </Group>
                );
              })
            )}
          </div>
        </div>
      </Wrapper>
    </Container>
  );
};

const ActiveStrikesStickyStrike: React.FC<ComponentProps> = ({
  modifiedScenario,
  setModifiedScenario,
}) => {
  const middleDeltaStrike = Math.floor(
    modifiedScenario.visible_vol_eb_delta_strike_values.length / 2
  );

  return (
    <Container>
      <Typography.Title level={5} style={{ fontWeight: "bold" }}>
        Strikes
      </Typography.Title>

      <Wrapper>
        <div>
          <Tooltip title="Choose what strikes are available for trading in the electronic brokers.">
            <Typography.Title level={5}>
              Tradable vols <InfoCircleOutlined />
            </Typography.Title>
          </Tooltip>
          {React.Children.toArray(
            modifiedScenario.visible_vol_eb_delta_strike_values.map(
              (dltsk, idx) => {
                return (
                  <Group>
                    <Switch
                      checked={
                        modifiedScenario.visible_vol_eb_delta_strike_values[idx]
                      }
                      checkedChildren="ACTIVE"
                      unCheckedChildren="DISABLED"
                      onChange={(checked) => {
                        const checkedCount =
                          modifiedScenario.visible_vol_eb_delta_strike_values.filter(
                            (t) => !!t
                          ).length;

                        // If trying to uncheck the last item, prevent it.
                        if (!checked && checkedCount === 1) {
                          return;
                        }
                        const newDltsk =
                          modifiedScenario.visible_vol_eb_delta_strike_values.map(
                            (value, i) => (i === idx ? checked : value)
                          );
                        setModifiedScenario({
                          ...modifiedScenario,
                          visible_vol_eb_delta_strike_values: newDltsk,
                        });
                      }}
                    />
                    <span>
                      {idx - middleDeltaStrike === 0
                        ? "ATM"
                        : `ATM${idx - middleDeltaStrike < 0 ? "" : "+"}${
                            Number(modifiedScenario.swift[0].strike_tick) *
                            (idx - middleDeltaStrike)
                          }
                            `}
                    </span>
                  </Group>
                );
              }
            )
          )}
        </div>

        <div>
          <Tooltip title="Choose what strikes are available for trading when banks or clients request for quotes. To increase difficulty, you can choose more tenors for all calls vs. tradable vols tenors..">
            <Typography.Title level={5}>
              All calls <InfoCircleOutlined />
            </Typography.Title>
          </Tooltip>

          <div>
            {React.Children.toArray(
              modifiedScenario.visible_delta_strike_values.map(
                (delta_strike, idx) => {
                  return (
                    <Group>
                      <Switch
                        checked={
                          modifiedScenario.visible_delta_strike_values[idx]
                        }
                        checkedChildren="ACTIVE"
                        unCheckedChildren="DISABLED"
                        onChange={(checked) => {
                          const checkedCount =
                            modifiedScenario.visible_delta_strike_values.filter(
                              (t) => !!t
                            ).length;

                          // If trying to uncheck the last item, prevent it.
                          if (!checked && checkedCount === 1) {
                            return;
                          }
                          const newDeltaStrike =
                            modifiedScenario.visible_delta_strike_values.map(
                              (value, i) => (i === idx ? checked : value)
                            );
                          setModifiedScenario({
                            ...modifiedScenario,
                            visible_delta_strike_values: newDeltaStrike,
                          });
                        }}
                      />

                      <span>
                        {idx - middleDeltaStrike === 0
                          ? "ATM"
                          : `ATM${idx - middleDeltaStrike < 0 ? "" : "+"}${
                              modifiedScenario.swift[0].strike_tick *
                              (idx - middleDeltaStrike)
                            }
                            `}
                      </span>
                    </Group>
                  );
                }
              )
            )}
          </div>
        </div>
      </Wrapper>
    </Container>
  );
};

const ActiveStrikes = withStickyModel<ComponentProps, ComponentProps>(
  ActiveDeltaStrikeStickyDelta,
  ActiveStrikesStickyStrike
);

const Container = styled.div`
  margin-top: 1rem;
`;

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
`;

const Group = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 0.5rem;
`;

export default ActiveStrikes;
