import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Button, Table, Space, Typography } from "antd";

import StyledText from "../reusable/StyledText";
import { Permission, RoomStatus } from "types";
import Finish from "assets/NavIcons/finish.svg";
import Running from "assets/NavIcons/play.svg";
import Paused from "assets/NavIcons/pause.svg";
import { RootState } from "store/rootReducer";
import { formatTime } from "utils/time";
import { clearRoom } from "store/room/actions";
import { useAppDispatch } from "store/store";
import { trainerService } from "services";

const TrainerLiveSessions: React.FC = () => {
  const dispatch = useAppDispatch();
  const { liveSessions, scenarios, instruments } = useSelector(
    (state: RootState) => state.trainer
  );
  const { permission } = useSelector((state: RootState) => state.user);
  const history = useHistory();

  const joinScenario = (scenarioId: number) => {
    const scenario = scenarios.find((s) => scenarioId === s.id);
    if (scenario !== undefined) {
      return scenario;
    }
    return { name: "", swift: [{ code: "" }] };
  };
  const [displayedLiveSessions, setDisplayedLiveSessions] = useState(
    liveSessions.filter(
      (sessionData) => sessionData.status !== RoomStatus.FINISHED
    )
  );

  useEffect(() => {
    setDisplayedLiveSessions(
      liveSessions.filter(
        (sessionData) => sessionData.status !== RoomStatus.FINISHED
      )
    );
  }, [liveSessions]);

  const renderStatusIcon = (status: RoomStatus) => {
    switch (status) {
      case RoomStatus.FINISHED:
        return <img src={Finish} alt="finish" />;
      case RoomStatus.PAUSED:
        return <img src={Running} alt="play" />;
      case RoomStatus.PENDING:
        return <img src={Running} alt="play" />;
      case RoomStatus.PLAYING:
        return <img src={Paused} alt="pause" />;
      default:
        return <img src={Paused} alt="pause" />;
    }
  };

  return (
    <Container>
      <Typography.Title level={3}>Live Sessions</Typography.Title>

      <DataContainer>
        <Table
          sticky
          loading={!displayedLiveSessions.length}
          rowKey="created"
          style={{ width: "100%", height: "90%" }}
          columns={[
            {
              dataIndex: "name",
              title: "Scenario name",
              render: (_, record) => (
                <Space size="middle">
                  {joinScenario(parseInt(record.scenario_id)).name}
                </Space>
              ),
            },
            {
              dataIndex: "training_class_n",
              title: "Class",
              render: (_, record) => (
                <Space size="middle">{record.training_class.name}</Space>
              ),
            },
            {
              dataIndex: "training_class_fn",
              title: "Trainer",
              render: (_, record) => (
                <Space size="middle">
                  {record.training_class.owner.first_name}
                </Space>
              ),
            },
            {
              filters: instruments.map((ac) => {
                return { text: ac, value: ac };
              }),
              onFilter: (value: string | number | boolean, record) => {
                return (
                  joinScenario(parseInt(record.scenario_id)).swift[0].code ===
                  value
                );
              },
              dataIndex: "scenario_id",
              title: "Instrument",
              render: (_, record) => (
                <Space size="middle">
                  {joinScenario(parseInt(record.scenario_id)).swift[0].code}
                </Space>
              ),
            },
            {
              dataIndex: "created",
              title: "Created",
              defaultSortOrder: "ascend",
              sorter: (a, b) => {
                const dateA = new Date(a.created);
                const dateB = new Date(b.created);
                // eslint-disable-next-line no-nested-ternary
                return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
              },
              render: (_, record) => (
                <Space size="middle">
                  {formatTime(new Date(record.created))}
                </Space>
              ),
            },
            {
              dataIndex: "status",
              title: "Status",
              render: (_, record) => (
                <Space size="middle">
                  {renderStatusIcon(record.status)}
                  {RoomStatus[record.status]}
                </Space>
              ),
            },
            {
              dataIndex: "duration",
              title: "Duration",
              render: (_, record) => {
                const hours = Math.floor(record.duration / 3600);
                const minutes =
                  Math.floor(record.duration / 60) -
                  Math.floor(record.duration / 3600) * 60;
                return (
                  <Space size="middle">
                    {`${hours !== 0 ? hours.toString() + "h" : ""} ${
                      minutes !== 0 ? minutes.toString() + "min" : ""
                    }`}
                  </Space>
                );
              },
            },
            {
              title: "Actions",
              render: (_, record) => (
                <div>
                  <StyledText
                    fontSize={14}
                    fontWeight={400}
                    color="#FFFFFF"
                    fontFamily="IBMPlexSans"
                  >
                    <Button
                      onClick={() => {
                        dispatch(clearRoom());
                        history.push("/centralbank/" + record.uuid);
                      }}
                    >
                      Enter session
                    </Button>
                  </StyledText>
                  {permission === Permission.Admin && (
                    <Button
                      onClick={async () => {
                        const response = await trainerService.historical_vols(
                          record.uuid
                        );

                        const volBlob = new Blob([response.volatility], {
                          type: "text/csv",
                        });
                        const spotBlob = new Blob([response.spot], {
                          type: "text/csv",
                        });

                        const volUrl = window.URL.createObjectURL(volBlob);
                        const volLink = document.createElement("a");
                        volLink.style.display = "none";
                        volLink.href = volUrl;
                        volLink.download = `historical_volatilities_${record.uuid}.csv`;
                        document.body.appendChild(volLink);
                        volLink.click();
                        window.URL.revokeObjectURL(volUrl);

                        const spotUrl = window.URL.createObjectURL(spotBlob);
                        const spotLink = document.createElement("a");
                        spotLink.style.display = "none";
                        spotLink.href = spotUrl;
                        spotLink.download = `historical_spot_price_${record.uuid}.csv`;
                        document.body.appendChild(spotLink);
                        spotLink.click();
                        window.URL.revokeObjectURL(spotUrl);
                      }}
                    >
                      Spot/Vol data
                    </Button>
                  )}
                </div>
              ),
            },
          ]}
          dataSource={displayedLiveSessions}
          pagination={false}
        />
      </DataContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const DataContainer = styled.div`
  margin-top: 1rem;
  max-height: 70vh;
  overflow-y: auto;
`;

export default TrainerLiveSessions;
