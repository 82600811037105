import { TrainerScenarioBE } from "types";

export const empty_scenario_defaults: TrainerScenarioBE = {
  id: -1,
  visible: true,
  created: "",
  duration: 0,
  forecasts: [],
  morning_brief: "",
  name: "New scenario",
  description: "Scenario description: will appear in the trainer dashboard",
  news: [],
  relative_tenors: [],
  yield_curves: { base_yc: [], variable_yc: [] },
  visible_delta_strike_values: [],
  visible_tenors: [],
  visible_vol_eb_delta_strike_values: [],
  visible_vol_eb_tenors: [],
  parameters: {
    parameters: [
      {
        code: "CLIENT_CALL_LIFETIME_SECONDS",
        value: 0,
        min_value: 0,
        max_value: 0,
      },
      {
        code: "RFQ_USER_DECIDES_SECONDS",
        value: 0,
        min_value: 0,
        max_value: 0,
      },
      { code: "CLIENT_CALLS_ENABLED", value: 0, min_value: 0, max_value: 0 },
      { code: "DARKPOOL_ENABLED", value: 0, min_value: 0, max_value: 0 },
      { code: "RFQ_ENABLED", value: 0, min_value: 0, max_value: 0 },
      { code: "SPOT_DECIMAL_PLACES", value: 2, min_value: 0, max_value: 0 },
      { code: "IMPACT_VOTE_SECONDS", value: 0, min_value: 0, max_value: 0 },
      {
        code: "PLAYERS_RFQ_PLAYERS_QUOTE_MAX_SPREAD",
        value: 0,
        min_value: 0,
        max_value: 0,
      },
      { code: "PLAYERS_SPOT_MAX_TICKET", value: 0, min_value: 0, max_value: 0 },
      { code: "PLAYERS_SPOT_MIN_TICKET", value: 0, min_value: 0, max_value: 0 },
      { code: "PLAYERS_VOL_MAX_TICKET", value: 0, min_value: 0, max_value: 0 },
      { code: "PLAYERS_VOL_MIN_TICKET", value: 0, min_value: 0, max_value: 0 },
      {
        code: "PLAYERS_TOTALORDERS_LIMIT",
        value: 0,
        min_value: 0,
        max_value: 0,
      },
      { code: "TICK", value: 0, min_value: 0, max_value: 0 },
      { code: "TICKET_UNIT", value: 0, min_value: 0, max_value: 0 },
      { code: "PLAYERS_DELTA_LIMIT", value: 0, min_value: 0, max_value: 0 },
      { code: "PLAYERS_GAMMA_LIMIT", value: 0, min_value: 0, max_value: 0 },
      { code: "PLAYERS_VEGA_LIMIT", value: 0, min_value: 0, max_value: 0 },
      { code: "PLAYERS_THETA_LIMIT", value: 0, min_value: 0, max_value: 0 },
      {
        code: "CLIENT_CALL_LARGE_AMOUNT_PROB",
        value: 0,
        min_value: 0,
        max_value: 0,
      },
      {
        code: "CLIENT_CALL_WITH_QUOTE_PROB",
        value: 0,
        min_value: 0,
        max_value: 0,
      },
      {
        code: "CLIENT_CALL_BINDING_SPREAD_PROB",
        value: 0,
        min_value: 0,
        max_value: 0,
      },
      { code: "RFQ_BOTS_CALL_FREQUENCY", value: 0, min_value: 0, max_value: 0 },
      { code: "EB_BOTS_HIT_FREQUENCY", value: 0, min_value: 0, max_value: 0 },
      {
        code: "BOTS_SPOT_MAX_TICKET_MULTIPLIER",
        value: 0,
        min_value: 0,
        max_value: 0,
      },
      { code: "RFQ_BOT_QUOTES_SECONDS", value: 0, min_value: 0, max_value: 0 },
      {
        code: "RFQ_PLAYERS_QUOTE_MAX_POINTS_OFF_MARKET",
        value: 0,
        min_value: 0,
        max_value: 0,
      },
      {
        code: "EB_PLAYERS_QUOTE_MAX_POINTS_OFF_MARKET",
        value: 0,
        min_value: 0,
        max_value: 0,
      },
      {
        code: "CLIENT_CALL_PLAYERS_QUOTE_MAX_POINTS_OFF_MARKET",
        value: 0,
        min_value: 0,
        max_value: 0,
      },
      {
        code: "TRADABLE_VOLS_BOTS_HIT_FREQUENCY",
        value: 0,
        min_value: 0,
        max_value: 0,
      },
      {
        code: "REAL_SECONDS_TO_GAME_DAYS_RATIO",
        value: 10,
        min_value: 0,
        max_value: 0,
      },
      { code: "AUTO_EXERCISE", value: 0, min_value: 0, max_value: 0 },
    ],
  },
  price_curves: { "": [] },
  swift: [],
  pylons: {},
};
