import { createAction } from "@reduxjs/toolkit";
import { UpdateSettingsPayload, ParamUpdatedPayload } from "types";

export const updateSettings = createAction<UpdateSettingsPayload>(
  "settings/updateSettings"
);
export const alterSettings = createAction("settings/alterSettings");
export const paramUpdated = createAction<ParamUpdatedPayload>(
  "parameters/paramUpdated"
);
