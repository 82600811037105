/* eslint-disable no-nested-ternary */
import React from "react";

import styled, { css } from "styled-components";

import { BUY, RFQ_ANSWERED, SELL, VOLATILITY_QUOTE } from "stateConstants";
import { useAppDispatch } from "store/store";
import { rfqHitCall, rfqNtgCall } from "store/rfq/actions";
import { numberWithCommas, percent2Digits, precision } from "utils/numbers";
import { RFQCall, StrategyType } from "types";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { setPricer } from "store/newpricer/actions";
import ToPricerButton from "components/reusable/ToPricerButton";

interface Props {
  rfq: RFQCall;
}

const OutgoingActions: React.FC<Props> = ({ rfq }) => {
  const dispatch = useAppDispatch();
  const sticky_model = useSelector(
    (state: RootState) => state.spot.sticky_model,
    shallowEqual
  );
  console.log({rfq});
  
  const disabled = rfq.status !== RFQ_ANSWERED;
  return (
    <>
      {/* <Column>
        <ToPricerButton
          call={rfq}
          callId={`${rfq.id}_rfq`}
          isWaiting={!disabled}
          sticky_model={sticky_model}
        />
      </Column> */}

      <RedButton
        disabled={disabled}
        onClick={() => {
          dispatch(rfqHitCall({ callId: rfq.id, direction: SELL }));
        }}
      >
        {/* <span>Sell</span>{" "} */}
        {rfq.quote_type === VOLATILITY_QUOTE
          ? rfq.strategy.strategy_name === StrategyType.INDIVIDUAL_OPTION
            ? percent2Digits(rfq.strategy_volatility_bid)
            : `${numberWithCommas(precision(rfq.strategy_price_bid, 2))}$`
          : `${numberWithCommas(precision(rfq.strategy_price_bid, 2))}$`}
      </RedButton>

      <GreenButton
        disabled={disabled}
        onClick={() => {
          dispatch(rfqHitCall({ callId: rfq.id, direction: BUY }));
        }}
      >
        {/* <span>Buy</span>{" "} */}
        {rfq.quote_type === VOLATILITY_QUOTE
          ? rfq.strategy.strategy_name === StrategyType.INDIVIDUAL_OPTION
            ? percent2Digits(rfq.strategy_volatility_ask)
            : `${numberWithCommas(precision(rfq.strategy_price_ask, 2))}$`
          : `${numberWithCommas(precision(rfq.strategy_price_ask, 2))}$`}
      </GreenButton>

      <BlackButton
        disabled={disabled}
        onClick={() => {
          dispatch(rfqNtgCall({ callId: rfq.id }));
        }}
      >
        NTG
      </BlackButton>
    </>
  );
};

const Container = styled.div`
  display: flex;
  // background: #272e33; // layout
`;

const Column = styled.div`
  flex: 1;
  display: flex;
`;

const ActionButton = styled.button`
  flex: 1;
  margin: 0;
  padding: 4px;
  border-radius: initial;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  background: rgba(0, 0, 0, 0.2);

  &:disabled {
    color: #ebebeb80;
    border-color: rgba(25, 32, 37, 0.8);
  }
`;

const BlackButton = styled(ActionButton)`
  border-color: #8c8c8c;
  color: #8c8c8c;
`;

const GreenButton = styled(ActionButton)`
  color: #b7eb8f95;
  border: 1px solid #b7eb8f95;

  transition: all 0.2s;
  ${(p) => {
    if (!p.disabled) {
      return css`
        &:hover {
          color: #b7eb8f !important;
          border: 1px solid #b7eb8f !important;
        }
      `;
    }
  }}
`;

const RedButton = styled(ActionButton)`
  color: #ff7875aa;
  border: 1px solid #ff7875aa;

  transition: all 0.2s;

  ${(p) => {
    if (!p.disabled) {
      return css`
        &:hover {
          color: #ff7875 !important;
          border: 1px solid #ff7875 !important;
        }
      `;
    }
  }}
`;
export default OutgoingActions;
