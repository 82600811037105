import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { roomsService } from "services/rooms.service";
import { Room, RoomStatus } from "types";
import SmallLogo from "assets/small_logo.svg";
import Session from "./Session";

const SessionsList = () => {
  const [rooms, setRooms] = useState<Room[]>([]);
  // const [error, setError] = useState<string | undefined>(undefined);
  // const [classes, setClasses] = useState<TrainerClassesRoom[]>([]);
  // const [scenarios, setScenarios] = useState<TrainerScenarioBE[]>([]);

  // const getTrainerClasses = async () => {
  // const classesResponse = await trainerService.getTrainerClasses();
  // const scenarios_ = await trainerService.getTrainerScenarios();
  // setScenarios(scenarios_);
  // setClasses(classesResponse);
  // };

  const getAvailableRooms = async () => {
    const availableRooms = await roomsService.getRooms();
    setRooms(availableRooms);
  };

  useEffect(() => {
    // getTrainerClasses();
    getAvailableRooms();

    const roomsInterval = setInterval(getAvailableRooms, 5 * 1000);

    return () => {
      clearInterval(roomsInterval);
    };
  }, []);

  return (
    <Container>
      {/* <Error>{error}</Error> */}

      <Header>
        <Group>Your sessions</Group>
      </Header>

      <Sessions>
        {rooms
          .filter((room) => room.status !== RoomStatus.FINISHED)
          .sort((a, b) => {
            const dateA = new Date(a.created);
            const dateB = new Date(b.created);
            // eslint-disable-next-line no-nested-ternary
            return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
          })
          .map((room) => {
            return (
              <Session key={room.uuid} room={room} teacherPhoto={SmallLogo} />
            );
          })}
      </Sessions>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 45rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 3rem 0 rgba(0, 0, 0, 0.7);
  padding: 1rem;
  background: ${(props) => props.theme.color0};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 20px;
  color: #cecece;
`;

const Group = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const Sessions = styled.div`
  height: 70vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
`;

export default SessionsList;
