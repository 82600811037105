import ReportsHeader from "components/Reports/ReportsHeader";
import React from "react";

import Card from "antd/es/card/Card";
import { SwiftReport, TraderReportsType } from "types/reports";

type CardProps = {
  title: string;
  loading: boolean;
  content: Partial<SwiftReport> | undefined;
  extra?: React.FC;
};

const ReportCard: React.FC<CardProps> = ({
  title,
  loading,
  content,
  extra,
}) => {
  return (
    <div
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        minHeight: "20%",
        maxHeight: "100%",
      }}
    >
      <Card title={title} loading={loading}>
        {content &&
          Object.entries(content).map(([key, val]) => {
            if (
              !key.includes("evolution") &&
              key !== "underlying_asset" &&
              key !== "asset_class"
            )
              return (
                <p key={key}>
                  {key}: {val}
                </p>
              );
            return null;
          })}
      </Card>
    </div>
  );
};

export default ReportCard;
