import React, { useState } from "react";
import styled, { keyframes } from "styled-components";

import { RFQ_STATUS_STR, RFQ_ANSWERED, RFQ_WAITING } from "stateConstants";
import { useAppDispatch } from "store/store";
import { rfqNtgCall } from "store/rfq/actions";
import { RFQCall } from "types";
import IncomingActions from "./IncomingActions";
import {
  generateClientCallHeader,
  generateRfqHeader,
} from "../ClientCalls/utils";
import { addCallQuote, selectCallQuote } from "store/callQuote/reducers";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import ToPricerButton from "components/reusable/ToPricerButton";

interface Props {
  rfq: RFQCall;
}

const getStatus = (call: RFQCall) => {
  if (call.status === RFQ_ANSWERED && call.short_or_long !== undefined) {
    return call.short_or_long ? "Sold to client" : "Bought from client";
  }
  return RFQ_STATUS_STR[call.status];
};

const IncomingRFQ: React.FC<Props> = ({ rfq }) => {
  const dispatch = useAppDispatch();
  const sticky_model = useSelector(
    (state: RootState) => state.spot.sticky_model,
    shallowEqual
  );
  const { selectedCalls } = useSelector((state: RootState) => state.callQuotes);
  
  // const [expanded, setExpanded] = useState(false);
  return (
    <Container>
      <Header>
        <GreySpan>{rfq.market_taker_name}</GreySpan>
        {rfq.errorMessage && <ErrorSpan>{rfq.errorMessage}</ErrorSpan>}

        <Details>
          <YellowSpan>{generateRfqHeader(rfq, sticky_model)}</YellowSpan>
          <GreySpan>{getStatus(rfq)}</GreySpan>
          {rfq.status === RFQ_WAITING && (
            <Group>
              <GreenButton
                disabled={selectedCalls.includes(rfq)}
                onClick={() => {
                  // setExpanded(true);
                  dispatch(addCallQuote({ call: rfq }));
                  dispatch(selectCallQuote({ call: rfq }));
                }}
              >
                Select
              </GreenButton>

              <ToPricerButton
                call={rfq}
                callId={`${rfq.id}_rfq`}
                isWaiting={rfq.status === RFQ_WAITING}
                sticky_model={sticky_model}
              />

              <RedButton
                onClick={() => {
                  dispatch(rfqNtgCall({ callId: rfq.id }));
                }}
              >
                NTG
              </RedButton>
            </Group>
          )}
        </Details>
      </Header>

      {/* {expanded && <IncomingActions rfq={rfq} />} */}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  flex: 1;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 5px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 13px;
`;

const Details = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const YellowSpan = styled.span`
  color: ${(p) => p.theme.primaryDark1};
`;

const GreySpan = styled.span`
  color: #8c8c8c;
`;

const popup = keyframes`
  from {
    transform: scale(105%);
  }

  to {
    transform: scale(100%);
  }
`;
const ErrorSpan = styled.span`
  color: #a32a2a;
  animation: ${popup} 0.5s ease-in-out;
`;

const ActionButton = styled.button`
  border: none;
  padding: 2px 8px;
  margin: 0;
  border-radius: initial;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #000;
`;

const GreenButton = styled(ActionButton)`
  color: #b7eb8f95;
  border: 1px solid #b7eb8f95;

  transition: all 0.2s;

  &:hover {
    color: #b7eb8f !important;
    border: 1px solid #b7eb8f !important;
  }
  &:disabled {
    color: gray !important;
    border: 1px solid gray !important;
  }
`;

const RedButton = styled(ActionButton)`
  color: #ff7875aa;
  border: 1px solid #ff7875aa;

  transition: all 0.2s;

  &:hover {
    color: #ff7875 !important;
    border: 1px solid #ff7875 !important;
  }
`;

const Group = styled.div`
  display: flex;
  gap: 5px;
`;

export default IncomingRFQ;
