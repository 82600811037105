export const SELL = 0;
export const BUY = 1;

export const BID = 0;
export const ASK = 1;

export const OPTION = 0;
export const STRADDLE = 1;

export const DIRECTION_STR = {
  [SELL]: "Buy",
  [BUY]: "Sell",
};

export const SHORT = 0;
export const LONG = 1;

export const SHORT_LONG_STR = {
  [SHORT]: "Short",
  [LONG]: "Long",
};

export const PUT = 0;
export const CALL = 1;

export const PUT_CALL_STR = {
  [PUT]: "PUT",
  [CALL]: "CALL",
};

// Room status
export const ROOM_PENDING = 0;
export const ROOM_PLAYING = 1;
export const ROOM_PAUSED = 2;
export const ROOM_FINISHED = 3;

export const ROOM_STATUS_STR = {
  [ROOM_PENDING]: "Pending",
  [ROOM_PLAYING]: "Playing",
  [ROOM_PAUSED]: "Paused",
  [ROOM_FINISHED]: "Finished",
} as const;

// Pricer
export const LEFT = 0;
export const RIGHT = 1;

// RFQ
export const RFQ_WAITING = 0;
export const RFQ_ANSWERED = 1;
export const RFQ_NTG = 2;
export const RFQ_HIT_BID = 3;
export const RFQ_HIT_ASK = 4;
export const RFQ_EXPIRED = 5;

export const RFQ_STATUS_STR = {
  [RFQ_WAITING]: "Waiting",
  [RFQ_ANSWERED]: "Answered",
  [RFQ_NTG]: "Nothing There",
  [RFQ_HIT_BID]: "You bought",
  [RFQ_HIT_ASK]: "You sold",
  [RFQ_EXPIRED]: "Expired",
};

// Client ClientCalls
export const CC_WAITING = 0;
export const CC_ANSWERED = 1;
export const CC_ACCEPTED = 2;
export const CC_DECLINED = 3;
export const CC_CANCELLED = 4;
export const CC_EXPIRED = 5;

export const CC_STATUS_ARRAY = [
  CC_WAITING,
  CC_ANSWERED,
  CC_ACCEPTED,
  CC_DECLINED,
  CC_CANCELLED,
  CC_EXPIRED,
] as const;

export const CC_STATUS_STR = {
  [CC_WAITING]: "Waiting",
  [CC_ANSWERED]: "Sent",
  [CC_ACCEPTED]: "Accepted",
  [CC_DECLINED]: "Declined",
  [CC_CANCELLED]: "Canceled",
  [CC_EXPIRED]: "Expired",
};

// Options
export const OPT_PENDING = 0;
export const OPT_EXERCISED = 1;
export const OPT_EXPIRED = 2;

export const TOGL_IGNORE = 0;
export const TOGL_EXECUTE = 1;
export const TOGL_AUTO = 2;
export const VOLATILITY_QUOTE = 0;
export const PREMIUM_QUOTE = 1;

// Orders
export const ORD_PENDING = 0;
export const ORD_COMPLETED = 1;
export const ORD_CANCELED = 2;

export enum VOL_EB_ORDER_STATUS {
  PENDING,
  COMPLETED,
  CANCELED,
}
