import { Input, Button } from "antd";
import FloatLabel from "components/reusable/FloatLabel";
import {
  Row,
  Column,
  FloatInputNumber,
} from "components/reusable/GenericStyledComponents";
import React, { useState } from "react";

const { TextArea } = Input;

type AddNewsProps = {
  dataList: any[];
  setDataList: (val: any) => void;
  props?: any;
};

const AddNews: React.FC<AddNewsProps> = ({
  dataList: allNews,
  setDataList,
  props,
}) => {
  const { swift } = props;
  const [expanded, setExpanded] = useState(false);
  const [news, setNews] = useState({
    body: "",
    headline: "",
    delta_seconds: 0,
    market_impact: {
      [swift]: {
        spot: 0,
        st_steep: 0,
        lt_steep: 0,
        vol_parallel: 0,
        call_skew: 0,
        put_skew: 0,
      },
    },
  });

  return (
    <Row style={{ marginBottom: ".5rem", alignItems: "center", width: "100%" }}>
      {!expanded && (
        <Button
          onClick={() => {
            setExpanded(true);
          }}
        >
          Add new
        </Button>
      )}
      {expanded && (
        <Column
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            width: "100%",
          }}
        >
          <Button
            onClick={() => {
              if (setExpanded) setExpanded(false);
            }}
          >
            Minimize
          </Button>
          <FloatLabel label="Headline" value={news.headline}>
            <TextArea
              autoSize
              value={news.headline}
              onChange={(e) => {
                setNews({ ...news, headline: e.target.value });
              }}
            />
          </FloatLabel>
          <FloatLabel label="Body" value={news.body}>
            <TextArea
              autoSize
              value={news.body}
              onChange={(e) => {
                setNews({ ...news, body: e.target.value });
              }}
            />
          </FloatLabel>
          <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
            <FloatLabel label="Time(s)" value={news.delta_seconds}>
              <FloatInputNumber
                min={0}
                type="number"
                onChange={(value) => {
                  setNews({
                    ...news,
                    delta_seconds: (value as number) || 0,
                  });
                }}
                value={news.delta_seconds}
              />
            </FloatLabel>
            <FloatLabel label="Time(m)" value={news.delta_seconds / 60}>
              <FloatInputNumber
                type="number"
                min={0}
                onChange={(value: any) => {
                  setNews({
                    ...news,
                    delta_seconds: ((value * 60) as number) || 0,
                  });
                }}
                value={news.delta_seconds / 60}
              />
            </FloatLabel>
            <FloatLabel label="Time(h)" value={news.delta_seconds / 3600}>
              <FloatInputNumber
                type="number"
                min={0}
                onChange={(value: any) => {
                  setNews({
                    ...news,
                    delta_seconds: ((value * 3600) as number) || 0,
                  });
                }}
                value={news.delta_seconds / 3600}
              />
            </FloatLabel>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
            <FloatLabel label="Spot" value={news.market_impact[swift].spot}>
              <FloatInputNumber
                type="number"
                onChange={(value) => {
                  setNews({
                    ...news,
                    market_impact: {
                      [swift]: {
                        ...news.market_impact[swift],
                        spot: (value as number) || 0,
                      },
                    },
                  });
                }}
                value={news.market_impact[swift].spot}
              />
            </FloatLabel>
            <FloatLabel
              label="Lt. steepness"
              value={news.market_impact[swift].lt_steep}
            >
              <FloatInputNumber
                type="number"
                onChange={(value) => {
                  setNews({
                    ...news,
                    market_impact: {
                      [swift]: {
                        ...news.market_impact[swift],
                        lt_steep: (value as number) || 0,
                      },
                    },
                  });
                }}
                value={news.market_impact[swift].lt_steep}
              />
            </FloatLabel>
            <FloatLabel
              label="Put skew"
              value={news.market_impact[swift].put_skew}
            >
              <FloatInputNumber
                type="number"
                onChange={(value) => {
                  setNews({
                    ...news,
                    market_impact: {
                      [swift]: {
                        ...news.market_impact[swift],
                        put_skew: (value as number) || 0,
                      },
                    },
                  });
                }}
                value={news.market_impact[swift].put_skew}
              />
            </FloatLabel>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
            <FloatLabel
              label="St. steepness"
              value={news.market_impact[swift].st_steep}
            >
              <FloatInputNumber
                type="number"
                onChange={(value) => {
                  setNews({
                    ...news,
                    market_impact: {
                      [swift]: {
                        ...news.market_impact[swift],
                        st_steep: (value as number) || 0,
                      },
                    },
                  });
                }}
                value={news.market_impact[swift].st_steep}
              />
            </FloatLabel>
            <FloatLabel
              label="Call skew"
              value={news.market_impact[swift].call_skew}
            >
              <FloatInputNumber
                type="number"
                onChange={(value) => {
                  setNews({
                    ...news,
                    market_impact: {
                      [swift]: {
                        ...news.market_impact[swift],
                        call_skew: (value as number) || 0,
                      },
                    },
                  });
                }}
                value={news.market_impact[swift].call_skew}
              />
            </FloatLabel>
            <FloatLabel
              label="Vol parallel"
              value={news.market_impact[swift].vol_parallel}
            >
              <FloatInputNumber
                type="number"
                onChange={(value) => {
                  setNews({
                    ...news,
                    market_impact: {
                      [swift]: {
                        ...news.market_impact[swift],
                        vol_parallel: (value as number) || 0,
                      },
                    },
                  });
                }}
                value={news.market_impact[swift].vol_parallel}
              />
            </FloatLabel>
          </div>
          <Button
            onClick={() => {
              setDataList([...allNews, news]);
              if (setExpanded) setExpanded(false);
            }}
          >
            Add new
          </Button>
        </Column>
      )}
    </Row>
  );
};

export default AddNews;
