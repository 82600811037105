import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import {
  Row,
  GenericSellBuyButton,
} from "components/reusable/GenericStyledComponents";
import { CALL, PUT, STRADDLE } from "stateConstants";
import { RootState } from "store/rootReducer";
import { timeUnixToDatePretty } from "utils/time";
import { numberWithCommas, percent2Digits } from "utils/numbers";
import { SimpleEbOrder, Tenor } from "types";
import useSellBuyButtons from "./useSellBuyButtons";

type Props = {
  order: SimpleEbOrder | null;
  type: "Sell" | "Buy";
};

const StickyDeltaSellBuy: React.FC<Props> = ({ type, order }) => {
  const isSell = type === "Sell";

  const { settingsLoaded } = useSelector((state: RootState) => state.settings);
  const { TRADABLE_VOLS_PUT_CALL_OR_STRADDLE: strategy } = useSelector(
    (state: RootState) => state.settings.parameters,
    shallowEqual
  );

  const {
    selectedSellTenor,
    selectedBuyTenor,
    tradableVols,
    selectedDelta,
    sticky_model,
    volQuantity: quantity,
    validVolQuantity,
  } = useSelector(
    (state: RootState) => ({
      selectedSellTenor: state.spot.selectedSellTenor,
      selectedBuyTenor: state.spot.selectedBuyTenor,
      tradableVols: state.spot.tradableVols,
      selectedDelta: state.spot.selectedDelta,
      sticky_model: state.spot.sticky_model,
      volQuantity: state.spot.volQuantity,
      validVolQuantity: state.spot.validVolQuantity,
    }),
    shallowEqual
  );

  const selectedTenor = isSell ? selectedSellTenor : selectedBuyTenor;

  const { handleButtonPress, straddleButtonMessage } = useSellBuyButtons(type);

  const optionButtonMessage = (put_or_call: string, isATM: boolean) => {
    const volEb = tradableVols[selectedTenor!][selectedDelta];
    const price = isSell ? volEb.sell : volEb.buy;

    return `${type} ${numberWithCommas(quantity)} X ${
      sticky_model
        ? Tenor[selectedTenor!]
        : timeUnixToDatePretty(parseInt(selectedTenor as any))
    } ${isATM ? "STRADDLE" : put_or_call} ${percent2Digits(price)}`;
  };

  if (settingsLoaded && strategy !== null) {
    if (strategy === STRADDLE) {
      return (
        <Row style={{ flex: "1", gap: "5px" }}>
          <GenericSellBuyButton
            style={{ width: "100%" }}
            disabled={!validVolQuantity}
            onClick={() => handleButtonPress(CALL)}
          >
            {straddleButtonMessage()}
          </GenericSellBuyButton>
        </Row>
      );
    }

    return (
      <Row style={{ flex: "1", gap: "5px" }}>
        <GenericSellBuyButton
          style={{ flex: "1" }}
          disabled={!validVolQuantity}
          onClick={() => {
            handleButtonPress(selectedDelta > 0.5 ? CALL : PUT);
          }}
        >
          {optionButtonMessage(
            selectedDelta > 0.5 ? "CALL" : "PUT",
            selectedDelta === 0.5
          )}
        </GenericSellBuyButton>
      </Row>
    );
  }

  return <></>;
};

export default StickyDeltaSellBuy;
