import { createAction } from "@reduxjs/toolkit";
import { PRICER_SEND_DATA, PRICER_UPDATE_DATA } from "./constants";
import {
  NewPricerColumn,
  NewPricerSendPayload,
  PricerUpdateDataPayload,
  SetPricerPayload,
  RequestVolatilityPayload,
  GetVolatilityPayload,
} from "types";

export const setPricer = createAction<SetPricerPayload>("pricer/setPricer");
export const updatePricerField = createAction<{
  key: keyof Omit<NewPricerColumn, "linked_to">;
  value: number;
  correlation_id: number;
}>("pricer/updateField");
export const pricerSendData =
  createAction<NewPricerSendPayload>("pricer/sendData");
export const pricerUpdateData = createAction<PricerUpdateDataPayload>(
  "pricer/getPricerData"
);
export const removePricer = createAction<{ col: string }>(
  "pricer/removePricer"
);
export const sendVolRequest = createAction<RequestVolatilityPayload>(
  "pricer/sendVolRequest"
);
export const pricerGetVolatility = createAction<GetVolatilityPayload>(
  "pricer/GetVolatility"
);
export const linkCallToPricer = createAction<{
  pricerId: string;
  callId: string;
}>("pricer/linkCallToPricer");
export const unlinkPricerCall = createAction<{ callId: string }>(
  "pricer/unlinkPricerCall"
);
