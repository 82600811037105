import { all, call } from "redux-saga/effects";
import { watchSocketAsync } from "./socket/sagas";
import { watchAuthAsync } from "./auth/sagas";
import { watchSpotAsync } from "./spot/sagas";
import { watchRoomAsync } from "./room/sagas";
import { watchRFQAsync } from "./rfq/sagas";
import { watchClientCallsAsync } from "./clientCalls/sagas";
import { watchBlotterAsync } from "./blotter/sagas";
import { watchSettingsAsync } from "./settings/sagas";
import { watchEventsAsync } from "./events/sagas";
import { watchCentralBankAsync } from "./centralbank/sagas";
import { watchNewPricerAsync } from "./newpricer/sagas";
import { watchActiveTenorsAsync } from "./tenors/sagas";
import { watchActiveDeltaStrikeAsync } from "./delta_strike/sagas";
import { watchShowNotification } from "./notification/sagas";

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    call(watchSocketAsync),
    call(watchAuthAsync),
    call(watchSpotAsync),
    call(watchRoomAsync),
    call(watchRFQAsync),
    call(watchClientCallsAsync),
    call(watchBlotterAsync),
    call(watchSettingsAsync),
    call(watchEventsAsync),
    call(watchCentralBankAsync),
    call(watchNewPricerAsync),
    call(watchActiveTenorsAsync),
    call(watchActiveDeltaStrikeAsync),
    call(watchShowNotification),
  ]);
}
