import React from "react";
import { useSelector } from "react-redux";

import styled from "styled-components";

import { BUY, SELL } from "stateConstants";
import { RootState } from "store/rootReducer";
import { numberWithCommas } from "utils/numbers";
import { SpotDeal } from "types";
import StyledText from "../../../reusable/StyledText";

const Text: React.FC = ({ children }) => (
  <TableData>
    <StyledText fontSize={10} fontWeight={600} color="#F4F4F460">
      {children}
    </StyledText>
  </TableData>
);

type Props = {
  deal: SpotDeal;
};

const SpotRow: React.FC<Props> = ({ deal }) => {
  const { SPOT_DECIMAL_PLACES } = useSelector(
    (state: RootState) => state.settings.parameters
  );
  const { traderId } = useSelector((state: RootState) => state.user);

  const { market_maker_id, direction } = deal;

  // A deal's direction is based on the market maker
  const isBought = (traderId === market_maker_id ? BUY : SELL) === direction;

  return (
    <TableRow>
      <TableData>
        <StyledText
          fontSize={10}
          fontWeight={600}
          fontFamily="Roboto"
          color={isBought ? "#00B287" : "#F84960"}
        >
          {isBought ? "Bought" : "Sold"}
        </StyledText>
      </TableData>
      <Text>{numberWithCommas(deal.amount, SPOT_DECIMAL_PLACES)}</Text>
      <Text>{numberWithCommas(deal.price, SPOT_DECIMAL_PLACES)}</Text>
    </TableRow>
  );
};

const TableRow = styled.tr`
  padding: 10px;
`;

const TableData = styled.td`
  padding: 2px 0;
`;

export default SpotRow;
