import React, { ReactNode } from "react";
import styled from "styled-components";
import { Typography } from "antd";

interface ITrainerNavItemProps {
  icon?: ReactNode;
  title: string;
  active: boolean;
  isHidden?: boolean;
  changeNav: () => void;
}

const TrainerNavItem: React.FC<ITrainerNavItemProps> = ({
  isHidden,
  icon,
  title,
  active,
  changeNav,
}) => {
  return (
    <Container onClick={changeNav} active={active}>
      {icon || <div />}
      <ItemTitle>{title}</ItemTitle>
    </Container>
  );
};

const Container = styled.div<{ active: boolean }>`
  display: grid;
  grid-template-columns: 20px 1fr;
  align-items: center;
  padding: 12px 30px;
  gap: 10px;

  color: ${(p) => (p.active ? p.theme.color3 : p.theme.gray6)};

  &:hover {
    cursor: pointer;

    svg {
      color: ${(p) => p.theme.color3};
    }
  }
`;

const ItemTitle = styled(Typography.Text)`
  font-size: 15px;
  font-weight: 500;
  font-family: IBMPlexSans, sans-serif;
  color: inherit;
`;

export default TrainerNavItem;
