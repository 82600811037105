import React, { useState } from "react";
import { Column, Row } from "components/reusable/GenericStyledComponents";
import styled from "styled-components";
import StyledText from "components/reusable/StyledText";
import { ScatterDataPoint } from "chart.js";
import Chart from "components/reusable/Chart";
import { InputNumber } from "antd";
import { CaretUpOutlined } from "@ant-design/icons";

const PriceCurves: React.FC<{
  price_curves: number[];
  setPriceCurves: (val: number[]) => void;
}> = ({ price_curves, setPriceCurves }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <Column style={{ gap: "2rem", width: "100%", alignItems: "center" }}>
        <Line />
        <ListTitle
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <h1>Spot Curves</h1>
          <div>
            <CaretUpOutlined
              style={{
                transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                transition: "all ease 300ms",
              }}
            />
          </div>
          <StyledText fontSize={16} fontWeight={16}>
            {price_curves.length} items
          </StyledText>
        </ListTitle>
        {expanded && (
          <Column>
            <Chart
              title=""
              graphColor="#22B793"
              chartValues={[
                ...price_curves
                  .map(
                    (point, idx): ScatterDataPoint => ({
                      x: idx,
                      y: point,
                    })
                  )
                  .sort((a, b) => a.x - b.x),
              ]}
            />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
                alignItems: "center",
              }}
            >
              {React.Children.toArray(
                price_curves.map((p, idx) => {
                  return (
                    <InputNumber
                      style={{ margin: ".125rem" }}
                      value={p}
                      onChange={(value) => {
                        const newPriceCurves = [...price_curves];
                        newPriceCurves[idx] = (value as number) || 0;
                        setPriceCurves(newPriceCurves);
                      }}
                    />
                  );
                })
              )}
            </div>
          </Column>
        )}
      </Column>
    </>
  );
};

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #2d363b;
  margin-left: 14px;
  margin-right: 14px;
`;

const ListTitle = styled(Row)`
  z-index: 100;
  &:hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
  }
  position: sticky;
  top: 0;
  align-items: center;
`;

export default PriceCurves;
