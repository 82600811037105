import React, { useState } from "react";
import { Column, Row } from "../reusable/GenericStyledComponents";
import { RootState } from "store/rootReducer";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { MATURITY, PositionGridCell, PositionGridMatrix } from "types";
import { timeUnixToDatePretty } from "utils/time";
import { numberWithCommas } from "utils/numbers";
import { Modal, Tabs, Typography } from "antd";
import LoadingSpinner from "components/reusable/LoadingSpinner";

const { TabPane } = Tabs;

type Props = {
  setTitle: React.Dispatch<React.SetStateAction<string>>;
};
const convertDelta: any = {
  0: "0-10 dP",
  1: "10-25 dP",
  2: "25-50 dP",
  3: "25-50 dC",
  4: "10-25 dC",
  5: "0-10 dC",
};

const tabsNames = {
  amount: "Amount",
  delta: "Delta",
  gamma: "Gamma",
  vega: "Vega",
  theta: "Theta",
};

const PositionGrid: React.FC = () => {
  const position_grid = useSelector(
    (state: RootState) => state.trader.position_grid
  );
  const sticky_model = useSelector(
    (state: RootState) => state.spot.sticky_model
  );
  const initial_state_loaded = useSelector(
    (state: RootState) => state.spot.initial_state_loaded
  );
  const [activeTabKey, setActiveTabKey] =
    useState<keyof PositionGridCell>("amount");

  const [showPositionGridModal, setShowPositionGridModal] =
    useState<boolean>(false);

  const { SPOT_DECIMAL_PLACES } = useSelector(
    (state: RootState) => state.settings.parameters
  );

  const onTabChange = (key: string) => {
    setActiveTabKey(key as keyof PositionGridCell);
  };

  if (!initial_state_loaded) return <LoadingSpinner />;

  return (
    <Column style={{ gap: ".5rem", height: "100%" }}>
      <Modal
        open={showPositionGridModal}
        title="Position grid"
        onCancel={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setShowPositionGridModal(false);
        }}
        footer={null}
        bodyStyle={{ padding: "2rem 1rem", width: "auto" }}
        width="60vw"
        destroyOnClose
      >
        <Tabs
          defaultActiveKey="amount"
          size="small"
          centered
          onChange={onTabChange}
          activeKey={activeTabKey}
        >
          {Object.entries(tabsNames).map(([key, value]) => (
            <TabPane tab={value} key={key}>
              <Column style={{ gap: ".5rem" }}>
                <PositionGridTable
                  title="PUT"
                  strikes={[...position_grid.delta_strikes]}
                  tenors={[...position_grid.tenors]}
                  parsedOptions={position_grid.PUT}
                  sticky_model={sticky_model}
                  SPOT_DECIMAL_PLACES={SPOT_DECIMAL_PLACES}
                  cellKey={activeTabKey}
                />
                <PositionGridTable
                  title="CALL"
                  strikes={[...position_grid.delta_strikes]}
                  tenors={[...position_grid.tenors]}
                  parsedOptions={position_grid.CALL}
                  sticky_model={sticky_model}
                  SPOT_DECIMAL_PLACES={SPOT_DECIMAL_PLACES}
                  cellKey={activeTabKey}
                />
              </Column>
            </TabPane>
          ))}
        </Tabs>
      </Modal>
      <StyledTabs
        style={{ height: "100%" }}
        defaultActiveKey="amount"
        size="small"
        centered
        onChange={onTabChange}
        activeKey={activeTabKey}
      >
        {Object.entries(tabsNames).map(([key, value]) => (
          <TabPane style={{ height: "100%" }} tab={value} key={key}>
            <Column
              style={{
                gap: ".5rem",
                justifyContent: "space-around",
                height: "100%",
              }}
              onClick={() => {
                setShowPositionGridModal(true);
              }}
            >
              <PositionGridTable
                title="PUT"
                strikes={[...position_grid.delta_strikes]}
                tenors={[...position_grid.tenors]}
                parsedOptions={position_grid.PUT}
                sticky_model={sticky_model}
                SPOT_DECIMAL_PLACES={SPOT_DECIMAL_PLACES}
                cellKey={activeTabKey}
              />
              <PositionGridTable
                title="CALL"
                strikes={[...position_grid.delta_strikes]}
                tenors={[...position_grid.tenors]}
                parsedOptions={position_grid.CALL}
                sticky_model={sticky_model}
                SPOT_DECIMAL_PLACES={SPOT_DECIMAL_PLACES}
                cellKey={activeTabKey}
              />
            </Column>
          </TabPane>
        ))}
      </StyledTabs>
    </Column>
  );
};

const PositionGridTable = ({
  strikes,
  tenors,
  parsedOptions,
  sticky_model,
  SPOT_DECIMAL_PLACES,
  title,
  bins,
  cellKey,
}: {
  strikes: number[];
  tenors: (string | number)[];
  parsedOptions: PositionGridMatrix;
  sticky_model: any;
  SPOT_DECIMAL_PLACES: number;
  title: string;
  cellKey: keyof PositionGridCell;
  bins?: number;
}) => {
  return (
    <Row style={{ justifyContent: "center", alignItems: "center" }}>
      <Typography.Text
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          writingMode: "vertical-lr",
          transform: "rotate(180deg)",
          fontSize: 18,
          fontWeight: 400,
        }}
      >
        {title}
      </Typography.Text>

      <Table>
        <thead>
          <tr>
            <TableHeader>
              <Typography.Text style={{ fontSize: 12, fontWeight: 1000 }}>
                Maturity/Strike {!strikes.length ? "(no options)" : ""}
              </Typography.Text>
            </TableHeader>
            {React.Children.toArray(
              sticky_model
                ? strikes.map((d) => {
                    return (
                      <TableHeader>
                        <Typography.Text
                          style={{ fontSize: 13, fontWeight: 900 }}
                        >
                          {convertDelta[d]}
                        </Typography.Text>{" "}
                      </TableHeader>
                    );
                  })
                : strikes
                    // .filter(d => dltskColumnNotEmpty(parsedOptions, d))
                    .map((d) => {
                      return (
                        <TableHeader>
                          <Typography.Text
                            style={{ fontSize: 13, fontWeight: 900 }}
                          >
                            {d.toFixed(SPOT_DECIMAL_PLACES)}
                          </Typography.Text>
                        </TableHeader>
                      );
                    })
            )}
          </tr>
        </thead>
        <tbody>
          {sticky_model
            ? React.Children.toArray(
                tenors
                  // .filter((t) => tenorRowNotEmpty(parsedOptions, t as string))
                  .map((tenor) => {
                    return (
                      <TableRow>
                        <TableData style={{ background: "#00000030" }}>
                          <Typography.Text
                            style={{ fontSize: 12, fontWeight: 600 }}
                          >
                            {MATURITY[tenor as number]}
                          </Typography.Text>
                        </TableData>
                        {React.Children.toArray(
                          strikes.map((delta_strike) => {
                            return (
                              <TableData>
                                <Typography.Text
                                  style={{ fontSize: 12, fontWeight: 600 }}
                                >
                                  {(parsedOptions[tenor]?.[delta_strike]?.[
                                    cellKey
                                  ] ?? 0) === 0
                                    ? "-"
                                    : `${numberWithCommas(
                                        parsedOptions[tenor][delta_strike][
                                          cellKey
                                        ]
                                      )}`}
                                </Typography.Text>
                              </TableData>
                            );
                          })
                        )}
                      </TableRow>
                    );
                  })
              )
            : React.Children.toArray(
                tenors
                  // .filter((t) => tenorRowNotEmpty(parsedOptions, t as string))
                  .map((tenor) => {
                    return (
                      <TableRow>
                        <TableData style={{ background: "#00000030" }}>
                          <Typography.Text
                            style={{ fontSize: 12, fontWeight: 600 }}
                          >
                            {timeUnixToDatePretty(tenor as number)}
                          </Typography.Text>
                        </TableData>
                        {React.Children.toArray(
                          strikes.map((delta_strike) => {
                            return (
                              <TableData key={`${tenor}${delta_strike}`}>
                                <Typography.Text
                                  style={{ fontSize: 12, fontWeight: 600 }}
                                >
                                  {(parsedOptions[tenor]?.[delta_strike]?.[
                                    cellKey
                                  ] ?? 0) === 0
                                    ? "-"
                                    : `${numberWithCommas(
                                        parsedOptions[tenor][delta_strike][
                                          cellKey
                                        ]
                                      )}`}
                                </Typography.Text>
                              </TableData>
                            );
                          })
                        )}
                      </TableRow>
                    );
                  })
              )}
        </tbody>
      </Table>
    </Row>
  );
};

const Table = styled.table`
  background: transparent;
  width: 100%;
  padding: 5px 7px;

  & tbody tr:nth-child(odd) {
    // background: #192025; // layout
  }

  & tr th:first-child,
  & tbody tr td:first-child {
    // padding-left: 7px;
  }

  & tr th {
    // padding-bottom: 5px;
  }
`;

const TableRow = styled.tr`
  text-align: center;
`;

const TableData = styled.td`
  vertical-align: middle;
  text-align: center;
  border: 1px solid gray;
  background: #00000010;
`;

const TableHeader = styled.th`
  padding: 3px;
  vertical-align: middle;
  text-align: center;
  border: 1px solid gray;
  font-weight: 800;
  background: #00000030;
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-content {
    height: 100% !important;
  }
`;
export default PositionGrid;
