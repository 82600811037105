import React, { useEffect, useState } from "react";
import GenericModal from "../../../Modal/GenericModal";
import styled from "styled-components";
import {
  Column,
  FloatInput,
  Row,
} from "components/reusable/GenericStyledComponents";
import StyledText from "../../../reusable/StyledText";
import CloseButton from "assets/NavIcons/clear-button.svg";
import {
  TraderPersistent,
  MassRegisterPayload,
  TrainerClassesRoom,
  TrainerEditPayload,
  TrainerClassesPersistent,
} from "types";
import { Input, message, Select, Switch, Button } from "antd";
import FloatLabel from "components/reusable/FloatLabel";
import { adminService } from "services";
import EditClassList from "../EditClassList";
import EditClassXlsx from "../EditClassXlsx";

const { Option } = Select;

type Props = {
  isShown: boolean;
  closeModal: () => void;
  cls: TrainerClassesPersistent;
};

const CreateClassModal: React.FC<Props> = ({ isShown, cls, closeModal }) => {
  const windowUrl = window.location.pathname.split("/");
  const customer_id = parseInt(windowUrl[windowUrl.length - 1]);
  const [editedCls, setEditedCls] = useState<TrainerClassesPersistent>(cls);
  const [trainers, setTrainers] = useState<TraderPersistent[]>([]);
  const [useList, setUseList] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<Blob>(new Blob());
  const [users, setUsers] = useState<TrainerEditPayload[]>([
    {
      id: -1,
      first_name: "",
      last_name: "",
      username: "",
      password: "",
      customer_id,
    },
  ]);
  const [uploadTry, setUploadTry] = useState<boolean>(false);
  const [uploadConflicts, setUploadConflicts] = useState<boolean>(false);
  const [apiMessage, setApiMessage] = useState<MassRegisterPayload>({
    created_users: [],
    failed_users: [],
  });

  const handleSave = async () => {
    const new_class = await adminService.createClass({
      trainer: editedCls.owner.id,
      name: editedCls.name,
      customer: customer_id,
    });
    let msg;
    let conflicts = false;
    let trial = false;

    if (selectedFile.size !== 0 || users.length !== 0) {
      trial = true;

      if (useList) {
        msg = await adminService.tryRegisterUsersList(
          new_class.id,
          users,
          customer_id
        );
        setApiMessage(msg);
        if (msg.failed_users.length === 0) {
          const msg2 = await adminService.registerUsersList(
            new_class.id,
            users,
            customer_id
          );
        } else {
          conflicts = true;
          setUploadConflicts(true);
        }
        setUploadTry(true);
      } else {
        const formData = new FormData();
        if (selectedFile) {
          formData.append("file", selectedFile);
          formData.append("class_id", new_class.id.toString());
          formData.append("customer_id", customer_id.toString());
        }
        msg = await adminService.tryRegisterUsersXlsx(formData);
        setApiMessage(msg);

        if (msg.failed_users.length === 0) {
          const msg2 = await adminService.registerUsersXlsx(formData);
        } else {
          conflicts = true;
          setUploadConflicts(true);
        }
        setUploadTry(true);
      }
      if (conflicts) {
        message.error(
          `${msg?.failed_users.length} conflicts. Please make sure all usernames are unique!`
        );
      } else {
        message.success(`All ${msg?.created_users.length} were created!`);
      }
    }
    if (trial) {
      if (!conflicts) {
        closeModal();
      }
    } else {
      closeModal();
    }
  };

  const fetchTrainers = async () => {
    const _trainers = await adminService.fetchAllTrainers();
    setTrainers(_trainers);
  };

  useEffect(() => {
    fetchTrainers();
  }, []);

  useEffect(() => {
    setEditedCls(cls);
  }, [cls]);

  return (
    <GenericModal
      isShown={isShown}
      onRequestClose={closeModal}
      customClassName="EditClass__Content"
      customOverlayClassName="EditClass__Overlay"
      overlayBackground="#00000060"
    >
      <Container style={{ overflowY: "auto" }}>
        <Row className="justify-content-between align-items-center">
          <StyledText
            fontSize={14}
            fontWeight={500}
            fontFamily="IBMPlexSans"
            color="#FFFFFF50"
          >
            Create class
          </StyledText>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
          <img
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
            role="button"
            src={CloseButton}
            alt="delete"
            onClick={() => {
              closeModal();
            }}
          />
        </Row>
        <Column
          style={{
            gap: ".5rem",
            marginTop: "1rem",
            marginLeft: "10%",
            marginRight: "10%",
          }}
        >
          <FloatLabel label="Class name" value={editedCls.name}>
            <FloatInput
              value={editedCls.name}
              onChange={(e) => {
                setEditedCls({ ...editedCls, name: e.target.value });
              }}
            />
          </FloatLabel>
          <StyledText
            fontSize={14}
            fontWeight={500}
            fontFamily="IBMPlexSans"
            color="#FFFFFF50"
          >
            Trainer:
          </StyledText>
          <Select
            showSearch
            dropdownStyle={{ zIndex: 100001 }}
            labelInValue
            style={{ width: "25ch" }}
            placeholder="Select trainer..."
            optionFilterProp="children"
            loading={trainers.length === 0}
            onChange={(val: any) => {
              const trainer_ = trainers.find((tr) => tr.id === val.value);
              if (trainer_ !== undefined) {
                setEditedCls({ ...editedCls, owner: trainer_ });
              } else {
                message.error("Trainer not found error");
              }
            }}
            filterOption={(input, option) =>
              ((option?.label.toLocaleLowerCase() ?? "") as string).includes(
                input.toLocaleLowerCase()
              )
            }
            filterSort={(optionA: any, optionB: any) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={trainers.map((trainer) => {
              return { label: trainer.username, value: trainer.id };
            })}
          />
          <Column style={{ alignItems: "center", paddingTop: ".25rem" }}>
            {/* eslint-disable-next-line */}
            <label>
              <Switch
                size="small"
                defaultChecked
                onChange={(event) => {
                  setUseList(!useList);
                }}
              />
              <span>List / CSV</span>
            </label>
          </Column>
          {useList ? (
            <EditClassList
              customer_id={customer_id}
              users={users}
              setUsers={setUsers}
            />
          ) : (
            <EditClassXlsx
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
            />
          )}
        </Column>
        <Column style={{ alignItems: "center" }}>
          {uploadTry && (
            <>
              <p
                style={{
                  color: apiMessage.failed_users ? "inherit" : "green",
                }}
              >
                {`${apiMessage.created_users.length} users ${
                  apiMessage.failed_users.length ? "can be created" : "created"
                }`}
              </p>
              {apiMessage.failed_users.map((f) => (
                <p style={{ color: "red" }}>
                  Username {f.username} already exists
                </p>
              ))}
            </>
          )}
          <Button style={{ marginTop: ".5rem" }} onClick={handleSave}>
            Save
          </Button>
        </Column>
      </Container>
    </GenericModal>
  );
};
const Container = styled(Column)`
  width: 100%;
`;
export default CreateClassModal;
