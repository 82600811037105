/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import {
  FallOutlined,
  MinusOutlined,
  RiseOutlined,
  StarFilled,
  StarOutlined,
} from "@ant-design/icons";
import styled from "styled-components";

interface CustomRatingProps {
  value: number | undefined;
  onChange: (newValue: number) => void;
}

export const ReadOnlyRatingDisplay: React.FC<{ value: number }> = ({
  value,
}) => {
  return (
    <StarContainer>
      {React.Children.toArray(
        [...Array(5)].map((_, index) => {
          const isActive = starMapping[value.toString()][index];

          const IconComponent =
            index === 2
              ? MinusOutlined
              : index < 2
              ? FallOutlined
              : RiseOutlined;

          return (
            <IconContainer>
              <IconComponent
                style={{
                  color: isActive ? "gold" : "grey",
                }}
              />
            </IconContainer>
          );
        })
      )}
    </StarContainer>
  );
};

const CustomRating: React.FC<CustomRatingProps> = ({ value, onChange }) => {
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);

  const handleClick = (newValue: number) => {
    onChange(newValue);
  };

  const handleMouseEnter = (index: number) => {
    setHoverIndex(index);
  };

  const handleMouseLeave = () => {
    setHoverIndex(null);
  };

  return (
    <StarContainer>
      {React.Children.toArray(
        [...Array(5)].map((_, index) => {
          const isActive =
            value !== undefined &&
            hoverIndex === null &&
            starMapping[value.toString()][index];
          const isHighlighted =
            hoverIndex !== null && starMapping[indexMapping[hoverIndex]][index];

          const IconComponent =
            index === 2
              ? MinusOutlined
              : index < 2
              ? FallOutlined
              : RiseOutlined;

          return (
            <StarIcon
              IconComponent={IconComponent} // Assign the correct icon component
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleClick(indexMapping[index])}
              isHighlighted={isHighlighted}
              isActive={isActive}
            />
          );
        })
      )}
    </StarContainer>
  );
};

const starMapping: { [key: string]: boolean[] } = {
  "-2": [true, true, false, false, false],
  "-1": [false, true, false, false, false],
  "0": [false, false, true, false, false],
  "1": [false, false, false, true, false],
  "2": [false, false, false, true, true],
};

const indexMapping: { [key: number]: number } = {
  0: -2,
  1: -1,
  2: 0,
  3: 1,
  4: 2,
};

interface StarIconProps {
  isActive: boolean;
  isHighlighted: boolean;
  IconComponent:
    | typeof StarFilled
    | typeof StarOutlined
    | typeof FallOutlined
    | typeof MinusOutlined
    | typeof RiseOutlined; // Add type for IconComponent
  onClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

const StarIcon: React.FC<StarIconProps> = ({
  isActive,
  isHighlighted,
  IconComponent, // Destructure IconComponent
  ...props
}) => (
  <IconContainer {...props}>
    <IconComponent
      style={{
        color: isActive ? "gold" : isHighlighted ? "#ffd700b3" : "grey",
      }}
    />
  </IconContainer>
);

const IconContainer = styled.span`
  cursor: pointer;
  margin-right: 4px;

  & svg {
    vertical-align: middle;
  }
`;
const StarContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
export default CustomRating;
