import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import createSagaMiddleware, { SagaMiddlewareOptions } from "redux-saga";

import { useDispatch } from "react-redux";
import rootSaga from "./rootSaga";
import rootReducer from "./rootReducer";

const initialState = {};

const reduxSagaMonitorOptions: SagaMiddlewareOptions = {};
const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);

// sagaMiddleware: Makes redux-sagas work
const middlewares = [
  ...getDefaultMiddleware({
    serializableCheck: false,
  }),
  sagaMiddleware,
];

const store = configureStore({
  reducer: rootReducer,
  middleware: [...middlewares],
  preloadedState: initialState,
  devTools: true,
});

sagaMiddleware
  .run(rootSaga)
  .toPromise()
  .catch((e) => {
    // eslint-disable-next-line no-console
    console.warn(e.message, e.sagaStack);
  });

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export default store;
export const { getState } = store;
