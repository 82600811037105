import React from "react";
import { ThemeProvider } from "styled-components";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { useTheme } from "./hooks/useTheme";
import { GlobalStyles } from "./theme/GlobalStyles";
import Lobby from "./routes/Lobby";
import GameRoom from "./routes/GameRoom";
import { useConfigureServices } from "./services";
import { useAuth } from "./hooks/useAuth";
import PrivateRoute from "./components/PrivateRoute";
import Trainer from "./routes/Trainer";
import CentralBank from "./routes/CentralBank";
import Admin from "routes/Admin";
import AdminCustomers from "routes/AdminCustomer";
import UserReports from "routes/UserReports";
import RoomReports from "routes/RoomReports";
import { message } from "antd";
import settings from "settings";

function Router() {
  const { theme, themeLoaded } = useTheme();

  useConfigureServices(settings.REACT_APP_API_URL);
  useAuth();
  message.config({
    maxCount: 1,
  });
  if (!themeLoaded) {
    return null;
  }

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Switch>
          <PrivateRoute path="/trainer" exact>
            <Trainer />
          </PrivateRoute>
          <PrivateRoute path="/admin" exact>
            <Admin />
          </PrivateRoute>
          <PrivateRoute path="/admin/customer/:id" exact>
            <AdminCustomers />
          </PrivateRoute>
          <PrivateRoute path="/centralbank/:id" exact>
            <CentralBank />
          </PrivateRoute>
          <PrivateRoute path="/game/:id" exact>
            <GameRoom />
          </PrivateRoute>
          <PrivateRoute path="/reports/:room_id/:trader_id" exact>
            <UserReports />
          </PrivateRoute>
          <PrivateRoute path="/reports/:room_id" exact>
            <RoomReports />
          </PrivateRoute>
          <Route path="/" exact>
            <Lobby />
          </Route>
          <Redirect to="/" />
        </Switch>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default Router;
