import { Pylons } from "types";

export const parsePylonsIndexKey = (pylons: Pylons) => {
  const newPylons: Pylons = {};
  Object.keys(pylons).forEach((k, idx) => {
    newPylons[idx] = pylons[k];
  });
  return newPylons;
};

export const parsePylonsTenorKeys = (
  pylons: Pylons,
  relative_tenors: number[]
) => {
  const newPylons: Pylons = {};
  relative_tenors.forEach((tenor, idx) => {
    newPylons[tenor] = { ...pylons[idx] };
  });
  return newPylons;
};
