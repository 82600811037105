import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import AddNewsModal from "components/Modal/AddNewsModal";
import LoadingSpinner from "components/reusable/LoadingSpinner";
import { Column } from "components/reusable/GenericStyledComponents";
import { Space, Table, Button, Modal, Typography } from "antd";
import AddNewsExpanded from "./utils/AddNewsExpanded";
import { secondsToTimestampPretty } from "utils/time";
import styled from "styled-components";
import { CBNewsRows, Permission } from "types";

function getColorForNumber(value: any) {
  // Define the max opacity
  const maxOpacity = 1;

  // Define the colors for the min and max values
  const redColor = [255, 0, 0]; // RGB for red
  const greenColor = [0, 128, 0]; // RGB for green (dark green for better visibility)

  // Clamp the value between -3 and 3
  const clampedValue = Math.max(-3, Math.min(3, value));

  // Calculate the opacity based on the distance from 0
  const opacity = (Math.abs(clampedValue) / 3) * maxOpacity;

  // Interpolate between red and green based on the sign of the value
  const color = clampedValue < 0 ? redColor : greenColor;

  // If value is less than -3 or greater than 3, use the maximum opacity
  const finalOpacity = value < -3 || value > 3 ? maxOpacity : opacity;

  return `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${finalOpacity.toFixed(
    2
  )})`;
}

const tableColors: { [key: number]: string } = {};

for (let i = -5; i <= 5; i += 1) {
  tableColors[i] = getColorForNumber(i);
}
const CBMacro: React.FC = () => {
  const events = useSelector((state: RootState) => state.centralbank.events);
  const permission = useSelector((state: RootState) => state.user.permission);
  const [newsRows, setNews] = useState<CBNewsRows[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const [currentNews, setCurrentNews] = useState<CBNewsRows>(newsRows[0]);

  useEffect(() => {
    if (events && events[0] && events[0].market_impact) {
      const swift = Object.keys(events[0].market_impact)[0];
      setNews(
        events.map((nnn: any) => {
          return {
            id: nnn.id.toString(),
            headline: nnn.event_data.headline,
            body: nnn.event_data.body,
            delta_seconds: nnn.room_time,
            ...nnn.market_impact[swift],
            published: nnn.published,
            index: nnn.index,
          };
        })
      );
    }
  }, [events]);

  return events ? (
    <Container>
      {events && newsRows ? (
        <Table
          rowKey="id"
          bordered
          sticky
          scroll={{ x: "max-content" }}
          dataSource={newsRows}
          pagination={false}
          expandIcon={undefined}
          expandable={{
            // expandedRowKeys: expandedRows,
            expandedRowRender: (record, index) => (
              <Space style={{ marginLeft: "2rem", width: "80ch" }}>
                <Typography.Text>{record.body}</Typography.Text>
              </Space>
            ),
            // showExpandColumn: false,
          }}
          columns={[
            {
              width: "80ch",
              // title: "Headline and Body",
              title: "Headline",
              key: "headline",
              render: (record: any) => (
                <div>
                  {/* Bold the headline */}
                  <div style={{ fontWeight: "bold" }}>{record.headline}</div>
                  {/* Render the body text on a new line */}
                  {/* <div>{record.body}</div> */}
                </div>
              ),
            },
            {
              dataIndex: "delta_seconds",
              title: "Time",
              render: (_, record) => (
                <div>{secondsToTimestampPretty(record.delta_seconds)}</div>
              ),
            },
            {
              dataIndex: "spot",
              title: "Spot impact",
              render: (_: any, record: any) => {
                return {
                  props: {
                    style: {
                      background: tableColors[record.spot as number],
                    },
                  },
                  children: (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {record.spot}
                    </div>
                  ),
                };
              },
            },
            {
              dataIndex: "vol_parallel",
              title: "VOL parallel shift",
              render: (_: any, record: any) => {
                return {
                  props: {
                    style: {
                      background: tableColors[record.vol_parallel as number],
                    },
                  },
                  children: (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {record.vol_parallel}
                    </div>
                  ),
                };
              },
            },
            ...(permission === Permission.Admin
              ? [
                  {
                    dataIndex: "put_skew",
                    title: "Put skew shift",
                    render: (_: any, record: any) => {
                      return {
                        props: {
                          style: {
                            background: tableColors[record.put_skew as number],
                          },
                        },
                        children: (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {record.put_skew}
                          </div>
                        ),
                      };
                    },
                  },
                  {
                    dataIndex: "call_skew",
                    title: "Call skew shift",
                    render: (_: any, record: any) => {
                      return {
                        props: {
                          style: {
                            background: tableColors[record.call_skew as number],
                          },
                        },
                        children: (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {record.call_skew}
                          </div>
                        ),
                      };
                    },
                  },
                ]
              : [
                  {
                    dataIndex: "call_skew",
                    title: "Skew shift",
                    render: (_: any, record: any) => {
                      return {
                        props: {
                          style: {
                            background:
                              tableColors[
                                (record.call_skew - record.put_skew) as number
                              ],
                          },
                        },
                        children: (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {record.call_skew - record.put_skew}
                          </div>
                        ),
                      };
                    },
                  },
                ]),
            ...(permission === Permission.Admin
              ? [
                  {
                    dataIndex: "lt_steep",
                    title: "Short term steepness",
                    render: (_: any, record: any) => {
                      return {
                        props: {
                          style: {
                            background: tableColors[record.lt_steep as number],
                          },
                        },
                        children: (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {record.lt_steep}
                          </div>
                        ),
                      };
                    },
                  },
                  {
                    dataIndex: "st_steep",
                    title: "Long term steepness",
                    render: (_: any, record: any) => {
                      return {
                        props: {
                          style: {
                            background: tableColors[record.st_steep as number],
                          },
                        },
                        children: (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {record.st_steep}
                          </div>
                        ),
                      };
                    },
                  },
                ]
              : [
                  {
                    dataIndex: "st_steep",
                    title: "Steepness",
                    render: (_: any, record: any) => {
                      return {
                        props: {
                          style: {
                            background:
                              tableColors[
                                (record.lt_steep - record.st_steep) as number
                              ],
                          },
                        },
                        children: (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {record.lt_steep - record.st_steep}
                          </div>
                        ),
                      };
                    },
                  },
                ]),
            {
              dataIndex: "id",
              title: "Actions",
              width: "15ch",
              render: (_: any, record: any) => {
                if (record.published)
                  return (
                    <Space style={{ color: "lightgray" }}>
                      Event passed, no action available
                    </Space>
                  );
                return (
                  <Space>
                    <Button
                      onClick={() => {
                        // setExpandedRows([...expandedRows, record.id]);
                        setShowModal(true);
                        setCurrentNews(record);
                      }}
                    >
                      Add below
                    </Button>
                  </Space>
                );
              },
            },
          ]}
        />
      ) : (
        <LoadingSpinner message="Fetching news..." />
      )}
      <Modal
        open={showModal}
        title="Add news"
        onCancel={() => setShowModal(false)}
        footer={null}
        destroyOnClose
        bodyStyle={{ padding: "2rem 1rem" }}
        width="70vw"
      >
        <AddNewsExpanded
          // prevNews={newsRows[index - 1] || null}
          // nextNews={newsRows[index + 1] || null}
          currentNews={currentNews}
          expandedRows={expandedRows}
          setExpandedRows={setExpandedRows}
          closeModal={() => setShowModal(false)}
        />
      </Modal>
      {/* <Column style={{ justifyContent: "center", alignItems: "center" }}>
        <Button onClick={() => setShowModal(true)}>Add news</Button>
      </Column> */}
      {/* <AddNewsModal shown={showModal} setShowModal={setShowModal} /> */}
    </Container>
  ) : (
    <LoadingSpinner message="Fetching events..." />
  );
};

const Container = styled.div``;

export default CBMacro;
