import { put, select, takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import { socketSendMessage } from "../socket/actions";
import {
  SPOT_CANCEL_ALL_EB_ORDERS,
  SPOT_PLACE_ORDER,
  SPOT_HIT_ORDER,
  SPOT_CANCEL_EB_ORDER,
  SPOT_VOL_EB_HIT,
  CMD_VOL_EB_HIT,
  SPOT_PLACE_VOL_EB_ORDER,
  CMD_VOL_EB_CANCEL_ORDER,
  VOL_EB_CANCEL_ORDER,
} from "./constants";
import { RootState } from "../rootReducer";
import {
  SellBuy,
  WebSocketRequest,
  SpotPlaceOrderPayload,
  SpotState,
  SpotCancelEbOrderPayload,
  SpotVolEbHitPayload,
  SpotPlaceOrderVolEbPayload,
  VolEbCancelOrderPayload,
  // TradableVol,
  // VolatilityState,
} from "types";
// import { SHORT } from 'stateConstants';
// import { updateTradableVol } from './reducers';

function* hitOrderSaga({
  payload: { amount, price, direction },
}: PayloadAction<{ amount: number; price: number; direction: SellBuy }>) {
  const { swiftId } = yield select((state: RootState) => state.spot);

  const requestData = {
    headers: { command: "eb.cmd.order.hit" },
    swift_id: swiftId,
    direction,
    amount,
    price,
  };

  yield put(socketSendMessage(JSON.stringify(requestData)));
}

function* cancelAllOrdersSaga() {
  const { swiftId }: SpotState = yield select((state: RootState) => state.spot);

  const request: WebSocketRequest = {
    headers: {
      command: "eb.cmd.order.cancel_all",
    },
    swift_id: swiftId,
  };

  yield put(socketSendMessage(JSON.stringify(request)));
}

function* cancelOrderSaga({
  payload,
}: PayloadAction<SpotCancelEbOrderPayload>) {
  const request: WebSocketRequest = {
    headers: {
      command: "eb.cmd.order.cancel",
    },
    ...payload,
  };

  yield put(socketSendMessage(JSON.stringify(request)));
}

function* placeOrderSaga({ payload }: PayloadAction<SpotPlaceOrderPayload>) {
  const requestData = {
    headers: {
      command: "eb.cmd.order.add",
    },
    ...payload,
  };

  yield put(socketSendMessage(JSON.stringify(requestData)));
}

function* placeOrderVolEbSaga({
  payload,
}: PayloadAction<SpotPlaceOrderVolEbPayload>) {
  const requestData = {
    headers: {
      command: "vol_eb.cmd.order.add",
    },
    ...payload,
  };

  yield put(socketSendMessage(JSON.stringify(requestData)));
}

function* volEbHitSaga(action: PayloadAction<SpotVolEbHitPayload>) {
  const {
    tenor,
    amount,
    swift_id,
    delta_strike,
    strategy,
    volatility,
    short_or_long,
    put_or_call,
  } = action.payload;

  const requestData = {
    headers: {
      command: CMD_VOL_EB_HIT,
    },
    put_or_call,
    tenor,
    amount,
    delta_strike,
    swift_id,
    strategy,
    volatility,
    short_or_long,
  };

  yield put(socketSendMessage(JSON.stringify(requestData)));
}

function* volEbCancelOrderSaga({
  payload,
}: PayloadAction<VolEbCancelOrderPayload>) {
  const requestData = {
    headers: {
      command: CMD_VOL_EB_CANCEL_ORDER,
    },
    ...payload,
  };

  yield put(socketSendMessage(JSON.stringify(requestData)));
}

export function* watchSpotAsync() {
  yield takeEvery(SPOT_HIT_ORDER, hitOrderSaga);
  yield takeEvery(SPOT_PLACE_ORDER, placeOrderSaga);
  yield takeEvery(SPOT_CANCEL_EB_ORDER, cancelOrderSaga);
  yield takeEvery(SPOT_CANCEL_ALL_EB_ORDERS, cancelAllOrdersSaga);
  yield takeEvery(SPOT_VOL_EB_HIT, volEbHitSaga);
  yield takeEvery(SPOT_PLACE_VOL_EB_ORDER, placeOrderVolEbSaga);
  yield takeEvery(VOL_EB_CANCEL_ORDER, volEbCancelOrderSaga);
}
