import { DAYS } from "utils/time";

const { SAT, SUN } = DAYS;

const offsetDate = (date: Date, offset: number) => {
  date.setDate(date.getDate() + offset);
  return date;
};

const nextWeekday = (date: Date) => {
  const offsets = [1, 1, 1, 1, 1, 3, 2];
  const day = date.getDay();
  offsetDate(date, offsets[day]);
  return date;
};

const parseRoomDate = (
  startDate: string,
  roomSeconds: number,
  seconds_to_days_ratio: number
) => {
  const date = new Date(startDate);
  if (date.toString() === "Invalid Date") {
    return;
  }
  let daysToAdd = Math.floor(roomSeconds / seconds_to_days_ratio);

  if ([SAT, SUN].includes(date.getDay())) {
    nextWeekday(date);
  }

  // -1 to fix js date offset (SUN = 0 in js)
  const startDay = date.getDay() - 1;

  // One week
  if (startDay + daysToAdd < 5) {
    return offsetDate(date, daysToAdd);
  }

  // More weeks
  let totalDays = 7 - startDay;
  daysToAdd -= 5 - startDay;

  const weeks = Math.floor(daysToAdd / 5);
  const remainingDays = daysToAdd % 5;
  totalDays += weeks * 7 + remainingDays;

  return offsetDate(date, totalDays);
};

export default parseRoomDate;
