// notificationSaga.js
import { takeEvery, put, select } from "redux-saga/effects";
import { message } from "antd";
import { PayloadAction } from "@reduxjs/toolkit";

function showMessage(action: PayloadAction<{ type: string; message: string }>) {
  const { message: notificationMessage } = action.payload;
  const type = "error";
  if (notificationMessage) {
    switch (type) {
      // case 'success':
      //     message.success(notificationMessage);
      //     break;
      case "error":
        message.error(notificationMessage);
        break;
      // case 'info':
      //     message.info(notificationMessage);
      //     break;
      // case 'warning':
      //     message.warning(notificationMessage);
      //     break;
      default:
        break;
    }
  }
}

export function* watchShowNotification() {
  yield takeEvery("notification/showPopUp", showMessage);
}
