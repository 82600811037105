/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import { InputNumber, Select, Space, Tag, message } from "antd";
import {
  BearSpreadOptions,
  BullSpreadOptions,
  ButterflyOptions,
  ButterflyValidators,
  CallCalendarSpreadOptions,
  IndividualOptionOptions,
  MATURITY,
  PutCalendarSpreadOptions,
  RFQMakeCallPayload,
  StraddleOptions,
  StranggleOptions,
  StrategyOptions,
  StrategyType,
} from "types";
import { Column } from "components/reusable/GenericStyledComponents";
import { timeUnixToDatePretty } from "utils/time";
import { RootState } from "store/rootReducer";
import { shallowEqual, useSelector } from "react-redux";
import { CALL, PUT } from "stateConstants";
import styled from "styled-components";
import { roomService } from "services";
import LoadingSpinner from "components/reusable/LoadingSpinner";

const useStrategyState = () => {
  const {
    delta_strike_values,
    sticky_model,
    visible_tenors,
    visible_delta_strike_values,
    tenors,
    swiftId,
  } = useSelector(
    (state: RootState) => ({
      delta_strike_values: state.spot.delta_strike_values,
      sticky_model: state.spot.sticky_model,
      visible_tenors: state.spot.visible_tenors,
      visible_delta_strike_values: state.spot.visible_delta_strike_values,
      tenors: state.spot.tenors,
      swiftId: state.spot.swiftId,
    }),
    shallowEqual
  );

  const { PLAYERS_VOL_MIN_TICKET, PLAYERS_VOL_MAX_TICKET } = useSelector(
    (state: RootState) => state.settings.parameters
  );

  const [maturityOptions, setMaturityOptions] = useState<
    { label: string; value: number }[]
  >(
    visible_tenors[swiftId].map((k) => {
      return {
        value: tenors[swiftId][k],
        label: sticky_model
          ? MATURITY[tenors[swiftId][k]]
          : timeUnixToDatePretty(tenors[swiftId][k]),
      };
    })
  );

  const [strikeOptions, setStrikeOptions] = useState<
    { label: number; value: number }[]
  >(
    visible_delta_strike_values[swiftId].map((v) => {
      return { value: delta_strike_values[v], label: delta_strike_values[v] };
    })
  );
  useEffect(() => {
    setMaturityOptions(
      visible_tenors[swiftId].map((k) => {
        return {
          value: tenors[swiftId][k],
          label: sticky_model
            ? MATURITY[tenors[swiftId][k]]
            : timeUnixToDatePretty(tenors[swiftId][k]),
        };
      })
    );
  }, [tenors, visible_tenors]);

  useEffect(() => {
    setStrikeOptions(
      visible_delta_strike_values[swiftId].map((v) => {
        return { value: delta_strike_values[v], label: delta_strike_values[v] };
      })
    );
  }, [delta_strike_values, visible_delta_strike_values]);

  return {
    maturityOptions,
    strikeOptions,
    sticky_model,
    delta_strike_values,
    tenors,
    visible_tenors,
    visible_delta_strike_values,
    swiftId,
    PLAYERS_VOL_MAX_TICKET,
    PLAYERS_VOL_MIN_TICKET,
  };
};

type IndividualOptionProps = {
  options: IndividualOptionOptions;
  onChange: (options: StrategyOptions, strategy: StrategyType) => void;
};

export const IndividualOption: React.FC<IndividualOptionProps> = ({
  options,
  onChange,
}) => {
  const {
    delta_strike_values,
    tenors,
    visible_tenors,
    swiftId,
    visible_delta_strike_values,
    strikeOptions,
    maturityOptions,
    sticky_model,
    PLAYERS_VOL_MIN_TICKET,
    PLAYERS_VOL_MAX_TICKET,
  } = useStrategyState();
  useEffect(() => {
    onChange(
      {
        amount: PLAYERS_VOL_MIN_TICKET,
        strike: delta_strike_values[visible_delta_strike_values[swiftId][0]],
        tenor: tenors[swiftId][visible_tenors[swiftId][0]],
        put_or_call: CALL,
        short_or_long: 0,
      },
      StrategyType.INDIVIDUAL_OPTION
    );
  }, []);

  return (
    <Column
      style={{
        display: "grid",
        gridTemplateColumns: "auto auto", // Two columns: one for labels and one for inputs
        gap: ".5rem",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Label style={{ margin: 0, padding: 0 }}>CALL/PUT</Label>

      <Select
        dropdownStyle={{ zIndex: 10000 }}
        style={{ width: "15ch" }}
        options={[
          { label: "PUT", value: PUT },
          { label: "CALL", value: CALL },
        ]}
        value={options.put_or_call}
        onChange={(value) =>
          onChange(
            { ...options, put_or_call: value },
            StrategyType.INDIVIDUAL_OPTION
          )
        }
      />
      <Label>Notional</Label>

      <InputNumber
        min={PLAYERS_VOL_MIN_TICKET}
        max={PLAYERS_VOL_MAX_TICKET}
        step={50}
        style={{ width: "15ch" }}
        value={options.amount}
        onChange={(value) =>
          value &&
          onChange(
            { ...options, amount: value },
            StrategyType.INDIVIDUAL_OPTION
          )
        }
      />
      <Label>Strike</Label>

      {sticky_model ? (
        <InputNumber
          style={{ width: "15ch" }}
          value={options.strike}
          onChange={(value) =>
            onChange(
              { ...options, strike: value || 0 },
              StrategyType.INDIVIDUAL_OPTION
            )
          }
        />
      ) : (
        <Select
          style={{ width: "15ch" }}
          dropdownStyle={{ zIndex: 10000 }}
          options={strikeOptions}
          value={options.strike}
          onChange={(value) =>
            onChange(
              { ...options, strike: value },
              StrategyType.INDIVIDUAL_OPTION
            )
          }
        />
      )}
      <Label>Tenor</Label>

      <Select
        style={{ width: "15ch" }}
        dropdownStyle={{ zIndex: 10000 }}
        options={maturityOptions}
        value={options.tenor}
        onChange={(value) =>
          onChange({ ...options, tenor: value }, StrategyType.INDIVIDUAL_OPTION)
        }
      />
    </Column>
  );
};

type StraddleOptionProps = {
  options: StraddleOptions;
  onChange: (options: StrategyOptions, strategy: StrategyType) => void;
};

export const StraddleOption: React.FC<StraddleOptionProps> = ({
  options,
  onChange,
}) => {
  const {
    delta_strike_values,
    tenors,
    visible_tenors,
    swiftId,
    visible_delta_strike_values,
    // strikeOptions,
    // sticky_model,
    maturityOptions,
    PLAYERS_VOL_MAX_TICKET,
    PLAYERS_VOL_MIN_TICKET,
  } = useStrategyState();
  useEffect(() => {
    onChange(
      {
        amount: PLAYERS_VOL_MIN_TICKET,
        strike: delta_strike_values[visible_delta_strike_values[swiftId][0]],
        tenor: tenors[swiftId][visible_tenors[swiftId][0]],
        short_or_long: 0,
      },
      StrategyType.STRADDLE
    );
  }, []);

  return (
    <Column
      style={{
        display: "grid",
        gridTemplateColumns: "auto auto", // Two columns: one for labels and one for inputs
        gap: ".5rem",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Label>Notional</Label>
      <InputNumber
        min={PLAYERS_VOL_MIN_TICKET}
        max={PLAYERS_VOL_MAX_TICKET}
        step={50}
        style={{ width: "15ch" }}
        value={options.amount}
        onChange={(value) =>
          value &&
          onChange({ ...options, amount: value }, StrategyType.STRADDLE)
        }
      />
      {/* Strike will be ATM (BE handled) */}
      {/* <Label>Strike</Label>
      {sticky_model ? (
        <InputNumber
          style={{ width: "15ch" }}
          value={options.strike}
          onChange={(value) =>
            onChange({ ...options, strike: value || 0 }, StrategyType.STRADDLE)
          }
        />
      ) : (
        <Select
          style={{ width: "15ch" }}
          dropdownStyle={{ zIndex: 10000 }}
          options={strikeOptions}
          value={options.strike}
          onChange={(value) =>
            value &&
            onChange({ ...options, strike: value }, StrategyType.STRADDLE)
          }
        />
      )} */}

      <Label>Tenor</Label>
      <Select
        style={{ width: "15ch" }}
        dropdownStyle={{ zIndex: 10000 }}
        options={maturityOptions}
        value={options.tenor}
        onChange={(value) =>
          onChange({ ...options, tenor: value }, StrategyType.STRADDLE)
        }
      />
    </Column>
  );
};

type StranggleOptionProps = {
  options: StranggleOptions;
  onChange: (options: StrategyOptions, strategy: StrategyType) => void;
};

export const StranggleOption: React.FC<StranggleOptionProps> = ({
  options,
  onChange,
}) => {
  const {
    delta_strike_values,
    tenors,
    visible_tenors,
    swiftId,
    visible_delta_strike_values,
    strikeOptions,
    sticky_model,
    maturityOptions,
    PLAYERS_VOL_MIN_TICKET,
    PLAYERS_VOL_MAX_TICKET,
  } = useStrategyState();

  useEffect(() => {
    if (sticky_model)
      onChange(
        {
          amount: PLAYERS_VOL_MIN_TICKET,
          strike1: delta_strike_values[visible_delta_strike_values[swiftId][0]],
          strike2: delta_strike_values[visible_delta_strike_values[swiftId][1]],
          tenor: tenors[swiftId][visible_tenors[swiftId][0]],
          short_or_long: 0,
        },
        StrategyType.STRANGLE
      );
    else
      onChange(
        {
          amount: PLAYERS_VOL_MIN_TICKET,
          strike1: delta_strike_values[visible_delta_strike_values[swiftId][0]],
          strike2: delta_strike_values[visible_delta_strike_values[swiftId][1]],
          tenor: tenors[swiftId][visible_tenors[swiftId][0]],
          short_or_long: 0,
        },
        StrategyType.STRANGLE
      );
  }, []);

  return (
    <Column
      style={{
        display: "grid",
        gridTemplateColumns: "auto auto", // Two columns: one for labels and one for inputs
        gap: ".5rem",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Label>Notional</Label>
      <InputNumber
        min={PLAYERS_VOL_MIN_TICKET}
        max={PLAYERS_VOL_MAX_TICKET}
        step={50}
        style={{ width: "15ch" }}
        value={options.amount}
        onChange={(value) =>
          value &&
          onChange({ ...options, amount: value }, StrategyType.STRANGLE)
        }
      />
      {sticky_model ? (
        <>
          <Label>Strike 1</Label>
          <InputNumber
            style={{ width: "15ch" }}
            value={options.strike1}
            onChange={(value) =>
              onChange(
                { ...options, strike1: value || 0 },
                StrategyType.STRANGLE
              )
            }
          />
          <Label>Strike 2</Label>
          <InputNumber
            style={{ width: "15ch" }}
            value={options.strike2}
            onChange={(value) =>
              onChange(
                { ...options, strike2: value || 0 },
                StrategyType.STRANGLE
              )
            }
          />
        </>
      ) : (
        <>
          <Label>Strike 1</Label>
          <Select
            style={{ width: "15ch" }}
            dropdownStyle={{ zIndex: 10000 }}
            options={strikeOptions}
            value={options.strike1}
            onChange={(value) =>
              value &&
              onChange({ ...options, strike1: value }, StrategyType.STRANGLE)
            }
          />

          <Label>Strike 2</Label>
          <Select
            style={{ width: "15ch" }}
            dropdownStyle={{ zIndex: 10000 }}
            options={strikeOptions}
            value={options.strike2}
            onChange={(value) =>
              value &&
              onChange({ ...options, strike2: value }, StrategyType.STRANGLE)
            }
          />
        </>
      )}

      <Label>Tenor</Label>
      {sticky_model ? (
        <Select
          style={{ width: "15ch" }}
          dropdownStyle={{ zIndex: 10000 }}
          options={maturityOptions}
          value={options.tenor}
          onChange={(value) =>
            onChange({ ...options, tenor: value }, StrategyType.STRANGLE)
          }
        />
      ) : (
        <Select
          style={{ width: "15ch" }}
          dropdownStyle={{ zIndex: 10000 }}
          options={maturityOptions}
          value={options.tenor}
          onChange={(value) =>
            onChange({ ...options, tenor: value }, StrategyType.STRANGLE)
          }
        />
      )}
    </Column>
  );
};

type CallCalendarOptionProps = {
  options: CallCalendarSpreadOptions;
  onChange: (
    options:
      | IndividualOptionOptions
      | StraddleOptions
      | StranggleOptions
      | CallCalendarSpreadOptions,
    strategy: StrategyType
  ) => void;
};

export const CallCalendarOption: React.FC<CallCalendarOptionProps> = ({
  options,
  onChange,
}) => {
  const {
    delta_strike_values,
    tenors,
    visible_tenors,
    swiftId,
    visible_delta_strike_values,
    strikeOptions,
    sticky_model,
    maturityOptions,
    PLAYERS_VOL_MIN_TICKET,
    PLAYERS_VOL_MAX_TICKET,
  } = useStrategyState();

  useEffect(() => {
    if (sticky_model)
      onChange(
        {
          amount: PLAYERS_VOL_MIN_TICKET,
          strike: delta_strike_values[visible_delta_strike_values[swiftId][0]],
          tenor1: tenors[swiftId][visible_tenors[swiftId][0]],
          tenor2: tenors[swiftId][visible_tenors[swiftId][1]],
          put_or_call: CALL,
          short_or_long: 0,
        },
        StrategyType.CALL_CALENDAR_SPREAD
      );
    else
      onChange(
        {
          amount: PLAYERS_VOL_MIN_TICKET,
          strike: delta_strike_values[visible_delta_strike_values[swiftId][0]],
          tenor1: tenors[swiftId][visible_tenors[swiftId][0]],
          tenor2: tenors[swiftId][visible_tenors[swiftId][1]],
          put_or_call: CALL,
          short_or_long: 0,
        },
        StrategyType.CALL_CALENDAR_SPREAD
      );
  }, []);

  return (
    <Column
      style={{
        display: "grid",
        gridTemplateColumns: "auto auto", // Two columns: one for labels and one for inputs
        gap: ".5rem",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Label>Notional</Label>
      <InputNumber
        min={PLAYERS_VOL_MIN_TICKET}
        max={PLAYERS_VOL_MAX_TICKET}
        step={50}
        style={{ width: "15ch" }}
        value={options.amount}
        onChange={(value) =>
          value &&
          onChange(
            { ...options, amount: value },
            StrategyType.CALL_CALENDAR_SPREAD
          )
        }
      />
      <Label>Strike</Label>
      {sticky_model ? (
        <InputNumber
          style={{ width: "15ch" }}
          value={options.strike}
          onChange={(value) =>
            onChange(
              { ...options, strike: value || 0 },
              StrategyType.CALL_CALENDAR_SPREAD
            )
          }
        />
      ) : (
        <Select
          style={{ width: "15ch" }}
          dropdownStyle={{ zIndex: 10000 }}
          options={strikeOptions}
          value={options.strike}
          onChange={(value) =>
            value &&
            onChange(
              { ...options, strike: value },
              StrategyType.CALL_CALENDAR_SPREAD
            )
          }
        />
      )}
      {sticky_model ? (
        <>
          <Label>Short-Term</Label>
          <Select
            style={{ width: "15ch" }}
            dropdownStyle={{ zIndex: 10000 }}
            value={options.tenor1}
            options={maturityOptions}
            onChange={(value) =>
              value &&
              onChange(
                { ...options, tenor1: value },
                StrategyType.CALL_CALENDAR_SPREAD
              )
            }
          />
          <Label>Long-Term</Label>
          <Select
            style={{ width: "15ch" }}
            dropdownStyle={{ zIndex: 10000 }}
            value={options.tenor2}
            options={maturityOptions}
            onChange={(value) =>
              value &&
              onChange(
                { ...options, tenor2: value },
                StrategyType.CALL_CALENDAR_SPREAD
              )
            }
          />
        </>
      ) : (
        <>
          <Label>Short-Term</Label>
          <Select
            style={{ width: "15ch" }}
            dropdownStyle={{ zIndex: 10000 }}
            value={options.tenor1}
            options={maturityOptions}
            onChange={(value) =>
              value &&
              onChange(
                { ...options, tenor1: value },
                StrategyType.CALL_CALENDAR_SPREAD
              )
            }
          />
          <Label>Long-Term</Label>
          <Select
            style={{ width: "15ch" }}
            dropdownStyle={{ zIndex: 10000 }}
            value={options.tenor2}
            options={maturityOptions}
            onChange={(value) =>
              value &&
              onChange(
                { ...options, tenor2: value },
                StrategyType.CALL_CALENDAR_SPREAD
              )
            }
          />
        </>
      )}
    </Column>
  );
};

type PutCalendarOptionProps = {
  options: PutCalendarSpreadOptions;
  onChange: (
    options:
      | IndividualOptionOptions
      | StraddleOptions
      | StranggleOptions
      | PutCalendarSpreadOptions,
    strategy: StrategyType
  ) => void;
};

export const PutCalendarOption: React.FC<PutCalendarOptionProps> = ({
  options,
  onChange,
}) => {
  const {
    delta_strike_values,
    sticky_model,
    visible_tenors,
    visible_delta_strike_values,
    tenors,
    swiftId,
  } = useSelector(
    (state: RootState) => ({
      delta_strike_values: state.spot.delta_strike_values,
      sticky_model: state.spot.sticky_model,
      visible_tenors: state.spot.visible_tenors,
      visible_delta_strike_values: state.spot.visible_delta_strike_values,
      tenors: state.spot.tenors,
      swiftId: state.spot.swiftId,
    }),
    shallowEqual
  );
  const { PLAYERS_VOL_MIN_TICKET, PLAYERS_VOL_MAX_TICKET } = useSelector(
    (state: RootState) => state.settings.parameters
  );

  const [maturityOptions, setMaturityOptions] = useState<
    { label: string; value: number }[]
  >(
    visible_tenors[swiftId].map((k) => {
      return {
        value: tenors[swiftId][k],
        label: sticky_model
          ? MATURITY[tenors[swiftId][k]]
          : timeUnixToDatePretty(tenors[swiftId][k]),
      };
    })
  );

  const [strikeOptions, setStrikeOptions] = useState<
    { label: number; value: number }[]
  >(
    visible_delta_strike_values[swiftId].map((v) => {
      return { value: delta_strike_values[v], label: delta_strike_values[v] };
    })
  );
  useEffect(() => {
    setMaturityOptions(
      visible_tenors[swiftId].map((k) => {
        return {
          value: tenors[swiftId][k],
          label: sticky_model
            ? MATURITY[tenors[swiftId][k]]
            : timeUnixToDatePretty(tenors[swiftId][k]),
        };
      })
    );
  }, [tenors, visible_tenors]);

  useEffect(() => {
    setStrikeOptions(
      visible_delta_strike_values[swiftId].map((v) => {
        return { value: delta_strike_values[v], label: delta_strike_values[v] };
      })
    );
  }, [delta_strike_values, visible_delta_strike_values]);

  useEffect(() => {
    if (sticky_model)
      onChange(
        {
          amount: PLAYERS_VOL_MIN_TICKET,
          strike: delta_strike_values[visible_delta_strike_values[swiftId][0]],
          tenor1: tenors[swiftId][visible_tenors[swiftId][0]],
          tenor2: tenors[swiftId][visible_tenors[swiftId][1]],
          put_or_call: CALL,
          short_or_long: 0,
        },
        StrategyType.PUT_CALENDAR_SPREAD
      );
    else
      onChange(
        {
          amount: PLAYERS_VOL_MIN_TICKET,
          strike: delta_strike_values[visible_delta_strike_values[swiftId][0]],
          tenor1: tenors[swiftId][visible_tenors[swiftId][0]],
          tenor2: tenors[swiftId][visible_tenors[swiftId][1]],
          put_or_call: CALL,
          short_or_long: 0,
        },
        StrategyType.PUT_CALENDAR_SPREAD
      );
  }, []);

  return (
    <Column
      style={{
        display: "grid",
        gridTemplateColumns: "auto auto", // Two columns: one for labels and one for inputs
        gap: ".5rem",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Label>Notional</Label>
      <InputNumber
        min={PLAYERS_VOL_MIN_TICKET}
        max={PLAYERS_VOL_MAX_TICKET}
        step={50}
        style={{ width: "15ch" }}
        value={options.amount}
        onChange={(value) =>
          value &&
          onChange(
            { ...options, amount: value },
            StrategyType.PUT_CALENDAR_SPREAD
          )
        }
      />
      <Label>Strike</Label>
      {sticky_model ? (
        <InputNumber
          style={{ width: "15ch" }}
          value={options.strike}
          onChange={(value) =>
            onChange(
              { ...options, strike: value || 0 },
              StrategyType.PUT_CALENDAR_SPREAD
            )
          }
        />
      ) : (
        <Select
          style={{ width: "15ch" }}
          dropdownStyle={{ zIndex: 10000 }}
          options={strikeOptions}
          value={options.strike}
          onChange={(value) =>
            value &&
            onChange(
              { ...options, strike: value },
              StrategyType.PUT_CALENDAR_SPREAD
            )
          }
        />
      )}

      {sticky_model ? (
        <>
          <Label>Short-Term</Label>
          <Select
            style={{ width: "15ch" }}
            dropdownStyle={{ zIndex: 10000 }}
            options={maturityOptions}
            value={options.tenor1}
            onChange={(value) =>
              value &&
              onChange(
                { ...options, tenor1: value },
                StrategyType.PUT_CALENDAR_SPREAD
              )
            }
          />
          <Label>Long-Term</Label>
          <Select
            style={{ width: "15ch" }}
            dropdownStyle={{ zIndex: 10000 }}
            options={maturityOptions}
            value={options.tenor2}
            onChange={(value) =>
              value &&
              onChange(
                { ...options, tenor2: value },
                StrategyType.PUT_CALENDAR_SPREAD
              )
            }
          />
        </>
      ) : (
        <>
          <Label>Short-Term</Label>
          <Select
            style={{ width: "15ch" }}
            options={maturityOptions}
            dropdownStyle={{ zIndex: 10000 }}
            value={options.tenor1}
            onChange={(value) =>
              value &&
              onChange(
                { ...options, tenor1: value },
                StrategyType.PUT_CALENDAR_SPREAD
              )
            }
          />
          <Label>Long-Term</Label>
          <Select
            style={{ width: "15ch" }}
            dropdownStyle={{ zIndex: 10000 }}
            options={maturityOptions}
            value={options.tenor2}
            onChange={(value) =>
              value &&
              onChange(
                { ...options, tenor2: value },
                StrategyType.PUT_CALENDAR_SPREAD
              )
            }
          />
        </>
      )}
    </Column>
  );
};

type BearSpreadOptionProps = {
  options: BearSpreadOptions;
  onChange: (options: StrategyOptions, strategy: StrategyType) => void;
};

export const BearSpreadOption: React.FC<BearSpreadOptionProps> = ({
  options,
  onChange,
}) => {
  const {
    delta_strike_values,
    tenors,
    visible_tenors,
    swiftId,
    visible_delta_strike_values,
    strikeOptions,
    sticky_model,
    maturityOptions,
    PLAYERS_VOL_MIN_TICKET,
    PLAYERS_VOL_MAX_TICKET,
  } = useStrategyState();

  useEffect(() => {
    if (sticky_model)
      onChange(
        {
          put_or_call: 1,
          amount: PLAYERS_VOL_MIN_TICKET,
          strike1: delta_strike_values[visible_delta_strike_values[swiftId][1]],
          strike2: delta_strike_values[visible_delta_strike_values[swiftId][0]],
          tenor: tenors[swiftId][visible_tenors[swiftId][0]],
          short_or_long: 0,
        },
        StrategyType.BEAR_SPREAD
      );
    else
      onChange(
        {
          put_or_call: 1,
          amount: PLAYERS_VOL_MIN_TICKET,
          strike1: delta_strike_values[visible_delta_strike_values[swiftId][1]],
          strike2: delta_strike_values[visible_delta_strike_values[swiftId][0]],
          tenor: tenors[swiftId][visible_tenors[swiftId][0]],
          short_or_long: 0,
        },
        StrategyType.BEAR_SPREAD
      );
  }, []);

  return (
    <Column
      style={{
        display: "grid",
        gridTemplateColumns: "auto auto", // Two columns: one for labels and one for inputs
        gap: ".5rem",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Label>Notional</Label>
      <InputNumber
        min={PLAYERS_VOL_MIN_TICKET}
        max={PLAYERS_VOL_MAX_TICKET}
        step={50}
        style={{ width: "15ch" }}
        value={options.amount}
        onChange={(value) =>
          value &&
          onChange({ ...options, amount: value }, StrategyType.BEAR_SPREAD)
        }
      />

      <Label>CALL/PUT</Label>
      <Select
        dropdownStyle={{ zIndex: 10000 }}
        style={{ width: "15ch" }}
        options={[
          { label: "PUT", value: PUT },
          { label: "CALL", value: CALL },
        ]}
        value={options.put_or_call}
        onChange={(value) =>
          onChange({ ...options, put_or_call: value }, StrategyType.BEAR_SPREAD)
        }
      />
      {sticky_model ? (
        <>
          <Label>Strike 1</Label>
          <InputNumber
            style={{ width: "15ch" }}
            value={options.strike1}
            onChange={(value) => {
              if (value && options.strike2 && value <= options.strike2) {
                message.error("Strike 1 must be higher than Strike 2.");
              } else {
                onChange(
                  { ...options, strike1: value || 0 },
                  StrategyType.BEAR_SPREAD
                );
              }
            }}
          />
          <Label>Strike 2</Label>
          <InputNumber
            style={{ width: "15ch" }}
            value={options.strike2}
            onChange={(value) => {
              if (value && options.strike1 && value >= options.strike1) {
                message.error("Strike 2 must be lower than Strike 1.");
              } else {
                onChange(
                  { ...options, strike1: value || 0 },
                  StrategyType.BEAR_SPREAD
                );
              }
            }}
          />
        </>
      ) : (
        <>
          <Label>Strike 1</Label>
          <Select
            style={{ width: "15ch" }}
            dropdownStyle={{ zIndex: 10000 }}
            options={strikeOptions}
            value={options.strike1}
            onChange={(value) =>
              value &&
              onChange({ ...options, strike1: value }, StrategyType.BEAR_SPREAD)
            }
          />

          <Label>Strike 2</Label>
          <Select
            style={{ width: "15ch" }}
            dropdownStyle={{ zIndex: 10000 }}
            options={strikeOptions.filter((s) => s.value < options.strike1)}
            value={options.strike2}
            onChange={(value) =>
              value &&
              onChange({ ...options, strike2: value }, StrategyType.BEAR_SPREAD)
            }
          />
        </>
      )}

      <Label>Tenor</Label>
      {sticky_model ? (
        <Select
          style={{ width: "15ch" }}
          dropdownStyle={{ zIndex: 10000 }}
          options={maturityOptions}
          value={options.tenor}
          onChange={(value) =>
            onChange({ ...options, tenor: value }, StrategyType.BEAR_SPREAD)
          }
        />
      ) : (
        <Select
          style={{ width: "15ch" }}
          dropdownStyle={{ zIndex: 10000 }}
          options={maturityOptions}
          value={options.tenor}
          onChange={(value) =>
            onChange({ ...options, tenor: value }, StrategyType.BEAR_SPREAD)
          }
        />
      )}
    </Column>
  );
};
type BullSpreadOptionProps = {
  options: BullSpreadOptions;
  onChange: (options: StrategyOptions, strategy: StrategyType) => void;
};

export const BullSpreadOption: React.FC<BullSpreadOptionProps> = ({
  options,
  onChange,
}) => {
  const {
    delta_strike_values,
    tenors,
    visible_tenors,
    swiftId,
    visible_delta_strike_values,
    strikeOptions,
    sticky_model,
    maturityOptions,
    PLAYERS_VOL_MIN_TICKET,
    PLAYERS_VOL_MAX_TICKET,
  } = useStrategyState();

  useEffect(() => {
    if (sticky_model)
      onChange(
        {
          put_or_call: 1,
          amount: PLAYERS_VOL_MIN_TICKET,
          strike1: delta_strike_values[visible_delta_strike_values[swiftId][0]],
          strike2: delta_strike_values[visible_delta_strike_values[swiftId][1]],
          tenor: tenors[swiftId][visible_tenors[swiftId][0]],
          short_or_long: 0,
        },
        StrategyType.BULL_SPREAD
      );
    else
      onChange(
        {
          put_or_call: 1,
          amount: PLAYERS_VOL_MIN_TICKET,
          strike1: delta_strike_values[visible_delta_strike_values[swiftId][0]],
          strike2: delta_strike_values[visible_delta_strike_values[swiftId][1]],
          tenor: tenors[swiftId][visible_tenors[swiftId][0]],
          short_or_long: 0,
        },
        StrategyType.BULL_SPREAD
      );
  }, []);

  return (
    <Column
      style={{
        display: "grid",
        gridTemplateColumns: "auto auto", // Two columns: one for labels and one for inputs
        gap: ".5rem",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Label>Notional</Label>
      <InputNumber
        min={PLAYERS_VOL_MIN_TICKET}
        max={PLAYERS_VOL_MAX_TICKET}
        step={50}
        style={{ width: "15ch" }}
        value={options.amount}
        onChange={(value) =>
          value &&
          onChange({ ...options, amount: value }, StrategyType.BULL_SPREAD)
        }
      />

      <Label>CALL/PUT</Label>
      <Select
        dropdownStyle={{ zIndex: 10000 }}
        style={{ width: "15ch" }}
        options={[
          { label: "PUT", value: PUT },
          { label: "CALL", value: CALL },
        ]}
        value={options.put_or_call}
        onChange={(value) =>
          onChange({ ...options, put_or_call: value }, StrategyType.BULL_SPREAD)
        }
      />
      {sticky_model ? (
        <>
          <Label>Strike 1</Label>
          <InputNumber
            style={{ width: "15ch" }}
            value={options.strike1}
            onChange={(value) => {
              if (value && options.strike2 && value >= options.strike2) {
                message.error("Strike 1 must be lower than Strike 2.");
              } else {
                onChange(
                  { ...options, strike1: value || 0 },
                  StrategyType.BULL_SPREAD
                );
              }
            }}
          />
          <Label>Strike 2</Label>
          <InputNumber
            style={{ width: "15ch" }}
            value={options.strike2}
            onChange={(value) => {
              if (value && options.strike1 && value <= options.strike1) {
                message.error("Strike 2 must be greater than Strike 1.");
              } else {
                onChange(
                  { ...options, strike2: value || 0 },
                  StrategyType.BULL_SPREAD
                );
              }
            }}
          />
        </>
      ) : (
        <>
          <Label>Strike 1</Label>
          <Select
            style={{ width: "15ch" }}
            dropdownStyle={{ zIndex: 10000 }}
            options={strikeOptions}
            value={options.strike1}
            onChange={(value) =>
              value &&
              onChange({ ...options, strike1: value }, StrategyType.BULL_SPREAD)
            }
          />

          <Label>Strike 2</Label>
          <Select
            style={{ width: "15ch" }}
            dropdownStyle={{ zIndex: 10000 }}
            options={strikeOptions.filter((s) => s.value > options.strike1)}
            value={options.strike2}
            onChange={(value) =>
              value &&
              onChange({ ...options, strike2: value }, StrategyType.BULL_SPREAD)
            }
          />
        </>
      )}

      <Label>Tenor</Label>
      {sticky_model ? (
        <Select
          style={{ width: "15ch" }}
          dropdownStyle={{ zIndex: 10000 }}
          options={maturityOptions}
          value={options.tenor}
          onChange={(value) =>
            onChange({ ...options, tenor: value }, StrategyType.BULL_SPREAD)
          }
        />
      ) : (
        <Select
          style={{ width: "15ch" }}
          dropdownStyle={{ zIndex: 10000 }}
          options={maturityOptions}
          value={options.tenor}
          onChange={(value) =>
            onChange({ ...options, tenor: value }, StrategyType.BULL_SPREAD)
          }
        />
      )}
    </Column>
  );
};
type ButterflyOptionProps = {
  options: ButterflyOptions;
  onChange: (options: StrategyOptions, strategy: StrategyType) => void;
};

export const ButterflyOption: React.FC<ButterflyOptionProps> = ({
  options,
  onChange,
}) => {
  const {
    delta_strike_values,
    tenors,
    visible_tenors,
    swiftId,
    visible_delta_strike_values,
    strikeOptions,
    sticky_model,
    maturityOptions,
    PLAYERS_VOL_MIN_TICKET,
    PLAYERS_VOL_MAX_TICKET,
  } = useStrategyState();
  const room_id = useSelector((state: RootState) => state.room.room_id);
  const [validators, setValidators] = useState<ButterflyValidators | null>(
    null
  );

  const getValidators = async () => {
    const _validators = await roomService.getAvailableButterfly(room_id);
    console.log(_validators);
    setValidators(_validators);
  };

  useEffect(() => {
    getValidators();
  }, []);

  useEffect(() => {
    if (sticky_model)
      onChange(
        {
          put_or_call: 1,
          amount: PLAYERS_VOL_MIN_TICKET,
          strike1: delta_strike_values[visible_delta_strike_values[swiftId][0]],
          strike2: delta_strike_values[visible_delta_strike_values[swiftId][1]],
          strike3: delta_strike_values[visible_delta_strike_values[swiftId][3]],
          tenor: tenors[swiftId][visible_tenors[swiftId][0]],
          short_or_long: 0,
        },
        StrategyType.BUTTERFLY
      );
    else if (validators !== null)
      onChange(
        {
          put_or_call: 1,
          amount: PLAYERS_VOL_MIN_TICKET,
          strike1: validators[swiftId].left_strikes[0],
          strike2: validators[swiftId].atm_strike,
          strike3: validators[swiftId].right_strikes[0],
          tenor: tenors[swiftId][visible_tenors[swiftId][0]],
          short_or_long: 0,
        },
        StrategyType.BUTTERFLY
      );
  }, [validators]);

  if (validators === null)
    return <LoadingSpinner message="Loading options..." />;
  if (!validators[swiftId].butterfly_available)
    return (
      <Space style={{ marginTop: "1rem" }}>
        You need at least 3 {sticky_model ? "delta values" : "strikes"} in order
        to create a butterfly!
      </Space>
    );
  return (
    <Column
      style={{
        display: "grid",
        gridTemplateColumns: "auto auto", // Two columns: one for labels and one for inputs
        gap: ".5rem",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Label>Notional</Label>
      <InputNumber
        min={PLAYERS_VOL_MIN_TICKET}
        max={PLAYERS_VOL_MAX_TICKET}
        step={50}
        style={{ width: "15ch" }}
        value={options.amount}
        onChange={(value) =>
          value &&
          onChange({ ...options, amount: value }, StrategyType.BUTTERFLY)
        }
      />

      <Label>CALL/PUT</Label>
      <Select
        dropdownStyle={{ zIndex: 10000 }}
        style={{ width: "15ch" }}
        options={[
          { label: "PUT", value: PUT },
          { label: "CALL", value: CALL },
        ]}
        value={options.put_or_call}
        onChange={(value) =>
          onChange({ ...options, put_or_call: value }, StrategyType.BUTTERFLY)
        }
      />
      {sticky_model ? (
        <>
          <Label>Strike 1</Label>
          <InputNumber
            style={{ width: "15ch" }}
            value={options.strike1}
            onChange={(value) => {
              onChange(
                { ...options, strike1: value || 0 },
                StrategyType.BUTTERFLY
              );
            }}
          />
          <Label>Strike 2</Label>
          <InputNumber
            style={{ width: "15ch" }}
            value={options.strike2}
            onChange={(value) => {
              onChange(
                { ...options, strike2: value || 0 },
                StrategyType.BUTTERFLY
              );
            }}
          />
          <Label>Strike 3</Label>
          <InputNumber
            style={{ width: "15ch" }}
            value={options.strike3}
            onChange={(value) => {
              onChange(
                { ...options, strike3: value || 0 },
                StrategyType.BUTTERFLY
              );
            }}
          />
        </>
      ) : (
        <>
          <Label>Strike 1</Label>
          <Select
            style={{ width: "15ch" }}
            dropdownStyle={{ zIndex: 10000 }}
            options={validators[swiftId].left_strikes.map((s) => ({
              label: s.toString(),
              value: s,
            }))}
            value={options.strike1}
            onChange={(value) =>
              value &&
              onChange({ ...options, strike1: value }, StrategyType.BUTTERFLY)
            }
          />

          <Label>Strike 2</Label>
          <Tag>{validators[swiftId].atm_strike}</Tag>
          <Label>Strike 3</Label>
          <Select
            style={{ width: "15ch" }}
            dropdownStyle={{ zIndex: 10000 }}
            options={validators[swiftId].right_strikes.map((s) => ({
              label: s.toString(),
              value: s,
            }))}
            value={options.strike3}
            onChange={(value) =>
              value &&
              onChange({ ...options, strike3: value }, StrategyType.BUTTERFLY)
            }
          />
        </>
      )}

      <Label>Tenor</Label>
      {sticky_model ? (
        <Select
          style={{ width: "15ch" }}
          dropdownStyle={{ zIndex: 10000 }}
          options={maturityOptions}
          value={options.tenor}
          onChange={(value) =>
            onChange({ ...options, tenor: value }, StrategyType.BUTTERFLY)
          }
        />
      ) : (
        <Select
          style={{ width: "15ch" }}
          dropdownStyle={{ zIndex: 10000 }}
          options={maturityOptions}
          value={options.tenor}
          onChange={(value) =>
            onChange({ ...options, tenor: value }, StrategyType.BUTTERFLY)
          }
        />
      )}
    </Column>
  );
};

const Label = styled.label`
  margin: 0;
  padding: 0;
`;
