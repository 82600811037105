import { createAction } from "@reduxjs/toolkit";
import {
  WS_CONNECT,
  WS_CONNECT_SUCCESS,
  WS_DISCONNECT,
  WS_SEND_MESSAGE,
} from "./constants";

export const socketConnect = createAction<string>(WS_CONNECT);

export const browserVisiblityChange = createAction<{
  isVisible: boolean;
  socketUrl: string;
}>("BROWSER_VISIBILITY_CHANGE");

export const socketDisconnect = createAction(WS_DISCONNECT);

export const socketDisconnected = createAction("SOCKET_CLOSED");

export const socketSendMessage = createAction<any>(WS_SEND_MESSAGE);

export const socketConnectSuccess = createAction(WS_CONNECT_SUCCESS);

export const socketConnectFailure = createAction<string>(
  "SOCKET_CONNECT_FAILURE"
);
