import { put, takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import { CMD_OPTION_TOGGLE, OPTION_TOGGLE } from "./constants";
import { OptionTogglePayload } from "types";
import { socketSendMessage } from "../socket/actions";

function* optionToggleSaga({ payload }: PayloadAction<OptionTogglePayload>) {
  const { optionId, toggle } = payload;

  const request = {
    headers: {
      command: CMD_OPTION_TOGGLE,
    },
    option_id: optionId,
    toggle,
  };

  yield put(socketSendMessage(JSON.stringify(request)));
}

export function* watchBlotterAsync() {
  yield takeEvery(OPTION_TOGGLE, optionToggleSaga);
}
