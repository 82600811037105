import React, { useState } from "react";
import { TrainerSwift } from "types";
import {
  Column,
  FloatInputNumber,
  Row,
} from "components/reusable/GenericStyledComponents";
import styled from "styled-components";
import { InputNumber } from "antd";
import FloatLabel from "components/reusable/FloatLabel";
import { CaretUpOutlined } from "@ant-design/icons";

type Props = {
  swift: TrainerSwift;
  setSwift: (val: TrainerSwift) => void;
};

const SwiftEdit: React.FC<Props> = ({ swift, setSwift }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <Column style={{ gap: "2rem", width: "100%", alignItems: "center" }}>
        <Line />
        <ListTitle
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <h1>Swift</h1>
          <div>
            <CaretUpOutlined
              style={{
                transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                transition: "all ease 300ms",
              }}
            />
          </div>
        </ListTitle>
        {expanded && (
          <Column style={{ gap: ".5rem" }}>
            <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
              <FloatLabel label="Spot tick" value={swift.tick}>
                <FloatInputNumber
                  style={{ width: "100%" }}
                  type="number"
                  value={swift.tick}
                  onChange={(value) => {
                    setSwift({ ...swift, tick: (value as number) || 0 });
                  }}
                />
              </FloatLabel>
              <FloatLabel label="Spot lot size" value={swift.spot_lot_size}>
                <FloatInputNumber
                  style={{ width: "100%" }}
                  type="number"
                  value={swift.spot_lot_size}
                  onChange={(value) => {
                    setSwift({
                      ...swift,
                      spot_lot_size: (value as number) || 0,
                    });
                  }}
                />
              </FloatLabel>

              <FloatLabel label="Vol lot size" value={swift.vol_lot_size}>
                <FloatInputNumber
                  style={{ width: "100%" }}
                  type="number"
                  value={swift.vol_lot_size}
                  onChange={(value) => {
                    setSwift({
                      ...swift,
                      vol_lot_size: (value as number) || 0,
                    });
                  }}
                />
              </FloatLabel>
              <FloatLabel
                label="Spot ticket unit"
                value={swift.spot_ticket_unit}
              >
                <FloatInputNumber
                  style={{ width: "100%" }}
                  type="number"
                  value={swift.spot_ticket_unit}
                  onChange={(value) => {
                    setSwift({
                      ...swift,
                      spot_ticket_unit: (value as number) || 0,
                    });
                  }}
                />
              </FloatLabel>
              <FloatLabel
                label="Spot decimal places"
                value={swift.spot_decimal_places}
              >
                <FloatInputNumber
                  style={{ width: "100%" }}
                  type="number"
                  value={swift.spot_decimal_places}
                  onChange={(value) => {
                    setSwift({
                      ...swift,
                      spot_decimal_places: (value as number) || 0,
                    });
                  }}
                />
              </FloatLabel>
            </div>
            <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
              <FloatLabel label="Volatility tick" value={swift.tick}>
                <FloatInputNumber
                  style={{ width: "100%" }}
                  type="number"
                  value={swift.volatility_tick}
                  onChange={(value) => {
                    setSwift({
                      ...swift,
                      volatility_tick: (value as number) || 0,
                    });
                  }}
                />
              </FloatLabel>
              <FloatLabel
                label="Volatility ticket unit"
                value={swift.vol_ticket_unit}
              >
                <FloatInputNumber
                  style={{ width: "100%" }}
                  type="number"
                  value={swift.vol_ticket_unit}
                  onChange={(value) => {
                    setSwift({
                      ...swift,
                      vol_ticket_unit: (value as number) || 0,
                    });
                  }}
                />
              </FloatLabel>
              <FloatLabel
                label="Volatility decimal places"
                value={swift.volatility_decimal_places}
              >
                <FloatInputNumber
                  style={{ width: "100%" }}
                  type="number"
                  value={swift.volatility_decimal_places}
                  onChange={(value) => {
                    setSwift({
                      ...swift,
                      volatility_decimal_places: (value as number) || 0,
                    });
                  }}
                />
              </FloatLabel>
              <FloatLabel label="Strike tick" value={swift.strike_tick}>
                <FloatInputNumber
                  style={{ width: "100%" }}
                  type="number"
                  value={swift.strike_tick}
                  onChange={(value) => {
                    setSwift({ ...swift, strike_tick: (value as number) || 0 });
                  }}
                />
              </FloatLabel>
            </div>
          </Column>
        )}
      </Column>
    </>
  );
};

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #2d363b;
  margin-left: 14px;
  margin-right: 14px;
`;

const ListTitle = styled(Row)`
  &:hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
  }
  position: sticky;
  top: 0;
`;

export default SwiftEdit;
