import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { RootState } from "store/rootReducer";
import VolatilityRows from "./VolatilityRows";

interface Props {
  type: "Sell" | "Buy";
}

const TradableVols: React.FC<Props> = ({ type }) => {
  const {
    tradableVols,
    delta_strike_values,
    selectedDelta,
    visible_vol_eb_tenors,
    tenors,
    initial_state_loaded,
    swiftId,
  } = useSelector(
    (state: RootState) => ({
      tradableVols: state.spot.tradableVols,
      delta_strike_values: state.spot.delta_strike_values,
      selectedDelta: state.spot.selectedDelta,
      visible_vol_eb_tenors: state.spot.visible_vol_eb_tenors,
      tenors: state.spot.tenors,
      initial_state_loaded: state.spot.initial_state_loaded,
      swiftId: state.spot.swiftId,
    }),
    shallowEqual
  );
  const { traderId } = useSelector((state: RootState) => state.user);

  if (
    !tradableVols ||
    !delta_strike_values ||
    !delta_strike_values.length ||
    !selectedDelta ||
    !initial_state_loaded
  ) {
    return <Skeleton />;
  }

  return (
    <Container>
      {visible_vol_eb_tenors[swiftId].map((tenor_idx): any => {
        const tenor = tenors[swiftId][tenor_idx];
        if (!tenor) return null;

        const current_delta_strike_values = tradableVols[tenor];
        if (
          !current_delta_strike_values ||
          !current_delta_strike_values[selectedDelta]
        )
          return null;

        if (
          Object.keys(current_delta_strike_values[selectedDelta]).length !== 0
        ) {
          return (
            <VolatilityRows
              key={tenor}
              tenor={tenor}
              delta_strike_values={current_delta_strike_values}
              traderId={traderId}
              type={type}
            />
          );
        }

        return null;
      })}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #141414;
`;

export default TradableVols;
