import AdminScenarioModal from "components/Modal/AdminScenarioModal";
import ConfirmationPromptModal from "components/Modal/ConfirmationPromptModal";
import CustomTable from "components/reusable/CustomTable";
import {
  Column,
  Row,
  ViewClassButton,
  getScrollBarStyle,
} from "components/reusable/GenericStyledComponents";
import StyledText from "components/reusable/StyledText";
import React, { useEffect, useState } from "react";
import { adminService, roomService } from "services";
import styled from "styled-components";
import {
  DataRow,
  AdminScenarioBE,
  SpotChartPriceUpdatedPayload,
  PriceCurveDTO,
  VolChartPointPayload,
  MATURITY,
} from "types";
import { formatTime, timeUnixToDatePretty } from "utils/time";
import AddNewScenario from "./AddNewScenario";
import { empty_scenario_defaults } from "./constants";
import {
  Button,
  Modal,
  Tabs,
  UploadFile,
  UploadProps,
  message,
  Row as AntdRow,
  Col,
  Switch,
} from "antd";
import Dragger from "antd/es/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";
import { setSelectedBuyTenor } from "store/spot/reducers";
import LoadingSpinner from "components/reusable/LoadingSpinner";
import ReactApexChart from "react-apexcharts";
import { volChartOptions } from "components/GameRoom/Charts/chartUtils";
import { convertDelta } from "utils/delta";

type SimulatedChartsPayload = {
  spot_charts: PriceCurveDTO[];
  vol_charts: {
    [key: string]: { [key: string]: VolChartPointPayload[] };
  };
};

const AdminScenario: React.FC<{
  scenarios: AdminScenarioBE[];
  fetchScenarios: () => Promise<void>;
}> = ({ scenarios, fetchScenarios }) => {
  const [selectedAdminScenario, setSelectedAdminScenario] = useState(
    empty_scenario_defaults
  );
  const [deletionId, setDeletionId] = useState<number>(-1);
  const [isShown, setIsShown] = useState(false);
  const [deletePromptShown, setDeletePromptShown] = useState(false);
  const [simulateVolModal, setSimulateVolModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState<Blob>();
  const [simulatedVols, setSimulatedVols] =
    useState<SimulatedChartsPayload | null>(null);
  const [simulationLoading, setSimulationLoading] = useState<boolean>(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [chartOptions, setChartOptions] = useState<any>({});
  const [chartSeries, setChartSeries] = useState<any>([]);
  const [stickyValue, setStickyValue] = useState(0);

  useEffect(() => {
    if (
      simulatedVols &&
      simulatedVols.spot_charts[0] &&
      simulatedVols.spot_charts[0].points
    ) {
      const seriesData = simulatedVols.spot_charts[0].points.map((point) => ({
        x: point.room_time, // Assuming room_time can be your x-axis value
        y: parseFloat(point.price), // Ensure price is a number
      }));

      setChartOptions({
        chart: {
          type: "line",
          width: "100%",
        },
        xaxis: {
          type: "numeric",
          title: {
            text: "Room Time",
          },
        },
        yaxis: {
          title: {
            text: "Price",
          },
        },
      });

      setChartSeries([
        {
          name: "Price Evolution",
          data: seriesData,
        },
      ]);
    }
  }, [simulatedVols]);

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      setSelectedFile(file as Blob);
      return false;
    },
    fileList,
  };

  const uploadFile = async () => {
    const formData = new FormData();
    if (selectedFile) {
      formData.append("file", selectedFile);
    }
    try {
      const response = await adminService.simulateVol(formData);
      const blob = new Blob([response], { type: "text/csv" });

      // Create an object URL for the Blob object
      const fileURL = window.URL.createObjectURL(blob);

      // Create a temporary anchor element and trigger the download
      const fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", "volatility_evolution_simulation.csv");
      document.body.appendChild(fileLink);
      fileLink.click();

      message.success("File downloaded successfully!");
      // Clean up by revoking the object URL and removing the temporary link
      window.URL.revokeObjectURL(fileURL);
      document.body.removeChild(fileLink);
      message.success("File uploaded!");
    } catch (e) {
      message.error("Error");
    }
  };

  return (
    <Column style={{ height: "100%", overflow: "auto" }}>
      <ConfirmationPromptModal
        prompt="Are you sure you want to delete this scenario"
        isShown={deletePromptShown}
        onClose={() => {
          setDeletePromptShown(false);
        }}
        action={() =>
          adminService.deleteScenario(deletionId).then(async (res) => {
            if (res === "ok") {
              await fetchScenarios();
            }
          })
        }
      />
      <StyledText fontSize={20} fontWeight={600} fontFamily="IBMPlexSans">
        Create or edit scenarios
      </StyledText>
      <DataContainer>
        <Column
          style={{
            alignItems: "center",
            gap: ".75rem",
            height: "100%",
            overflow: "auto",
          }}
        >
          <CustomTable
            header={[
              "Scenario name",
              "Asset class",
              "Instrument",
              "Created",
              "Updated",
              "By",
              "Visible",
              "Actions",
            ]}
            data={[
              ...scenarios.map(
                (scenario): DataRow => ({
                  data: [
                    () => (
                      <Column style={{ alignItems: "center" }}>
                        <StyledText
                          fontSize={14}
                          fontWeight={500}
                          color={scenario.visible ? "#FFFFFF90" : "red"}
                          fontFamily="IBMPlexSans"
                        >
                          {scenario.name} --{" "}
                          {scenario.visible ? "visible" : "not visible"}
                        </StyledText>
                      </Column>
                    ),
                    () => (
                      <Column style={{ alignItems: "center" }}>
                        <StyledText
                          fontSize={14}
                          fontWeight={400}
                          color={scenario.visible ? "#FFFFFF70" : "red"}
                          fontFamily="IBMPlexSans"
                        >
                          {scenario.swift[0].asset_class}
                        </StyledText>
                      </Column>
                    ),
                    () => (
                      <Column style={{ alignItems: "center" }}>
                        <StyledText
                          fontSize={14}
                          fontWeight={400}
                          color={scenario.visible ? "#FFFFFF70" : "red"}
                          fontFamily="IBMPlexSans"
                        >
                          {scenario.swift[0].code}
                        </StyledText>
                      </Column>
                    ),
                    () => (
                      <Column style={{ alignItems: "center" }}>
                        <StyledText
                          fontSize={14}
                          fontWeight={400}
                          color={scenario.visible ? "#FFFFFF70" : "red"}
                          fontFamily="IBMPlexSans"
                        >
                          {formatTime(scenario.created)}
                        </StyledText>
                      </Column>
                    ),
                    () => (
                      <Column style={{ alignItems: "center" }}>
                        <StyledText
                          fontSize={14}
                          fontWeight={400}
                          color={scenario.visible ? "#FFFFFF70" : "red"}
                          fontFamily="IBMPlexSans"
                        >
                          {scenario.last_updated_on}
                        </StyledText>
                      </Column>
                    ),
                    () => (
                      <Column style={{ alignItems: "center" }}>
                        <StyledText
                          fontSize={14}
                          fontWeight={400}
                          color={scenario.visible ? "#FFFFFF70" : "red"}
                          fontFamily="IBMPlexSans"
                        >
                          {scenario.last_updated_by}
                        </StyledText>
                      </Column>
                    ),
                    () => (
                      <Column style={{ alignItems: "center" }}>
                        <StyledText
                          fontSize={14}
                          fontWeight={400}
                          color={scenario.visible ? "#FFFFFF70" : "red"}
                          fontFamily="IBMPlexSans"
                        >
                          {scenario.visible ? "Yes" : "No"}
                        </StyledText>
                      </Column>
                    ),
                    () => (
                      <Row style={{ gap: ".5rem" }}>
                        <ActionButton
                          onClick={async (e) => {
                            e.stopPropagation();
                            try {
                              setSimulationLoading(true);
                              const sim_vol =
                                await roomService.pregenerateChartsId(
                                  scenario.id
                                );
                              setSimulatedVols(sim_vol);
                            } catch {
                              message.error(
                                "Request timed out, please try again later."
                              );
                            }
                            setSimulationLoading(false);
                          }}
                        >
                          <StyledText
                            fontSize={14}
                            fontWeight={400}
                            color="#FFFFFF70"
                            fontFamily="IBMPlexSans"
                          >
                            Generate charts
                          </StyledText>
                        </ActionButton>
                        <ActionButton
                          onClick={async (e) => {
                            e.stopPropagation();
                            const response = await roomService.downloadChartsId(
                              scenario.id
                            );
                            const blob = new Blob([response], {
                              type: "application/vnd.ms-excel",
                            });
                            const downloadUrl =
                              window.URL.createObjectURL(blob);
                            const link = document.createElement("a");
                            link.href = downloadUrl;
                            link.setAttribute(
                              "download",
                              "simulated_vol_charts.xls"
                            ); // The file extension should be .xlsx if it's a modern Excel file
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                            window.URL.revokeObjectURL(downloadUrl); // Clean up the object URL
                          }}
                        >
                          <StyledText
                            fontSize={14}
                            fontWeight={400}
                            color="#FFFFFF70"
                            fontFamily="IBMPlexSans"
                          >
                            Download charts
                          </StyledText>
                        </ActionButton>
                        <ActionButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setDeletePromptShown(true);
                            setDeletionId(scenario.id);
                          }}
                        >
                          <StyledText
                            fontSize={14}
                            fontWeight={400}
                            color="#FFFFFF70"
                            fontFamily="IBMPlexSans"
                          >
                            Delete scenario
                          </StyledText>
                        </ActionButton>
                        <ActionButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedAdminScenario(scenario);
                            setIsShown(true);
                          }}
                        >
                          <StyledText
                            fontSize={14}
                            fontWeight={400}
                            color="#FFFFFF70"
                            fontFamily="IBMPlexSans"
                          >
                            Edit scenario
                          </StyledText>
                        </ActionButton>
                      </Row>
                    ),
                  ],
                })
              ),
            ]}
          />
          <AddNewScenario
            setSelectedScenario={() => {
              setSelectedAdminScenario(empty_scenario_defaults);
            }}
            setIsShown={() => {
              setIsShown(true);
            }}
          />
        </Column>
      </DataContainer>
      <Button
        style={{ marginTop: "2rem", width: "8rem" }}
        onClick={() => {
          setSimulateVolModal(true);
        }}
      >
        Simulate VOL
      </Button>
      <AdminScenarioModal
        setIsShown={setIsShown}
        selectedScenario={selectedAdminScenario}
        setSelectedScenario={setSelectedAdminScenario}
        scenarioModalShown={isShown}
        fetchScenarios={fetchScenarios}
      />
      <Modal
        open={simulateVolModal}
        onOk={() => {
          uploadFile();
        }}
        onCancel={() => {
          setSimulateVolModal(false);
        }}
      >
        <Column style={{ margin: "1rem", padding: "1rem" }}>
          <Dragger {...props} accept=".xlsx">
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag the file to this area to upload
            </p>
          </Dragger>
        </Column>
      </Modal>
      <Modal
        open={simulationLoading || simulatedVols !== null}
        footer={null}
        width="80vw"
        onCancel={() => {
          setSimulatedVols(null);
          setSimulationLoading(false);
        }}
      >
        <Column
          style={{
            margin: "1rem",
            padding: "1rem",
            width: "80vw",
            height: "80vh",
            overflow: "scroll",
          }}
        >
          {simulationLoading || !simulatedVols ? (
            <LoadingSpinner message="Please wait for the simulation to finish. This might take up to 1 minute..." />
          ) : (
            <Column
              style={{
                justifyContent: "center",
                alignItems: "center",
                paddingRight: ".5rem",
              }}
            >
              <Row
                style={{
                  width: "100%",
                  height: "50%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ReactApexChart
                  height={700}
                  width={1000}
                  options={{
                    ...volChartOptions(true),
                    title: {
                      text: "Spot",
                      align: "left",
                    },
                  }}
                  series={chartSeries}
                  type="line"
                />
              </Row>

              <Switch
                checkedChildren="Sticky Delta"
                unCheckedChildren="Sticky Strike"
                onChange={(checked) => {
                  setStickyValue(checked ? 1 : 0);
                }}
                checked={stickyValue === 1}
              />
              <Tabs centered>
                {Object.keys(simulatedVols?.vol_charts).map((k) => (
                  <Tabs.TabPane
                    tab={
                      !stickyValue
                        ? MATURITY[parseInt(k)]
                        : timeUnixToDatePretty(parseInt(k))
                    }
                    key={k}
                  >
                    <AntdRow gutter={[16, 16]} style={{ width: "100%" }}>
                      {Object.keys(simulatedVols.vol_charts[k]).map((k2) => (
                        <Col
                          xs={24}
                          sm={12}
                          key={k2}
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {`Pylon ${
                            !stickyValue
                              ? MATURITY[parseInt(k)]
                              : timeUnixToDatePretty(parseInt(k))
                          }-${
                            !stickyValue ? convertDelta[parseFloat(k2)] : parseFloat(k2)
                          }`}
                          <ReactApexChart
                            options={volChartOptions(true)}
                            series={[
                              {
                                name: `VOL Evolution ${
                                  !stickyValue
                                    ? convertDelta[parseFloat(k2)]
                                    : parseFloat(k2)
                                }`,
                                data: simulatedVols.vol_charts[k][k2].map(
                                  (point) => ({
                                    x: point.room_time,
                                    y: parseFloat(point.volatility),
                                  })
                                ),
                              },
                            ]}
                            type="line"
                          />
                        </Col>
                      ))}
                    </AntdRow>
                  </Tabs.TabPane>
                ))}
              </Tabs>
            </Column>
          )}
        </Column>
      </Modal>
    </Column>
  );
};
const ActionButton = styled(Button)`
  border: 1px solid #ffffff40;
  border-radius: 6px;
  height: 50px;
  margin: 0;
  transition: 0.2s ease-in-out;

  &:hover {
    border-color:{scenario.visible ?  white;
 : "red"}
    & p {
      color:{scenario.visible ?  white;
    } : "red"}
  }
`;

export const DataContainer = styled(Row)`
  gap: 15px;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-bottom: 10px;
  width: 100%;

  ${getScrollBarStyle(false)};
`;

export default AdminScenario;
