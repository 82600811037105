import React, { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";
import { useSelector } from "react-redux";
import { roomService } from "services";
import { RootState } from "store/rootReducer";
import { setSpotChartValues } from "store/spot/actions";
import { ApexChartCandlestick, LightweightOHLCPoint } from "types";
import { calculateApexOHLCInterval } from "./utils";
import { useAppDispatch } from "store/store";
import { apexChartOptions, intervals } from "./chartUtils";
import LoadingSpinner from "components/reusable/LoadingSpinner";
import { Column } from "components/reusable/GenericStyledComponents";
import { Modal, Typography } from "antd";
import StyledText from "components/reusable/StyledText";

const NewCandlestickSpotChart = () => {
  const dispatch = useAppDispatch();
  const { SINGLE_OR_MULTIPLE_DAYS_TRADING } = useSelector(
    (state: RootState) => state.settings.parameters
  );
  const spotChartValues = useSelector(
    (state: RootState) => state.spot.spotChartValues
  );
  const room_id = useSelector((state: RootState) => state.room.room_id);
  const [showCandlestickModal, setShowCandlestickModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const [interval, setTimeInterval] = useState<string>(
    SINGLE_OR_MULTIPLE_DAYS_TRADING ? "1W" : "2m"
  );
  const [ohlcData, setOhlcData] = useState<ApexChartCandlestick[]>([]);
  const [globalStats, setGlobalStats] = useState<{
    globalHigh: number;
    globalLow: number;
    firstOpen: number;
  }>({ globalHigh: 0, globalLow: 0, firstOpen: 0 });

  const fetchData = async () => {
    const _spotChartValues = await roomService.getSpotChartValues(room_id);
    dispatch(setSpotChartValues(_spotChartValues));
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (spotChartValues && spotChartValues.length) {
      const [_ohlcData, _globalStats] = calculateApexOHLCInterval(
        spotChartValues,
        intervals[interval as keyof typeof intervals],
        showCandlestickModal ? 50 : 20
      );
      setOhlcData(_ohlcData);
      setGlobalStats(_globalStats);
    }
  }, [spotChartValues, interval, showCandlestickModal]);

  const candlestickSeries = [
    {
      name: "Candlestick",
      data: ohlcData, // Ensure this is correctly formatted as [{ x: timestamp, y: [open, high, low, close] }, ...]
    },
  ];

  if (loading) return <LoadingSpinner />;

  return (
    <Column
      onClick={() => {
        setShowCandlestickModal(true);
      }}
    >
      <ApexCharts
        options={apexChartOptions(false,  `O: ${globalStats.firstOpen} H: ${globalStats.globalHigh} L:${globalStats.globalLow}`)}
        series={candlestickSeries}
        type="candlestick"
      />
      <Modal
        open={showCandlestickModal}
        title="Spot chart"
        onCancel={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setShowCandlestickModal(false);
        }}
        footer={null}
        // destroyOnClose
        bodyStyle={{ padding: "2rem 1rem" }}
        width="60vw"
      >
        {showCandlestickModal && !loading && (
          <Column>
          <Column style={{ alignItems: "center" }}>
            <Typography.Text>
              {`O: ${globalStats.firstOpen} H: ${globalStats.globalHigh} L:${globalStats.globalLow}`}
            </Typography.Text>
            </Column>
            <ApexCharts
              options={apexChartOptions(true)}
              series={candlestickSeries}
              type="candlestick"
            />
          </Column>
        )}
      </Modal>
    </Column>
  );
};

export default NewCandlestickSpotChart;
