import axios, { AxiosInstance } from "axios";
import {
  TrainerClassesRoom,
  TrainerLiveSession,
  TrainerScenarioBE,
} from "types";

export interface ClassDTO {
  id: number;
  name: string;
  assigned_students: number[];
}

export default class TrainerService {
  private httpClient: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.httpClient = client;
  }

  async getTrainerClasses(): Promise<TrainerClassesRoom[]> {
    const response = await this.httpClient.get<TrainerClassesRoom[]>(
      "/api/get_trainer_classes"
    );
    return response.data;
  }

  async getTrainerScenarios(): Promise<TrainerScenarioBE[]> {
    const response = await this.httpClient.get<TrainerScenarioBE[]>(
      "/api/get_scenarios"
    );
    return response.data;
  }

  async getTrainerLiveRooms(): Promise<TrainerLiveSession[]> {
    const response = await this.httpClient.get<TrainerLiveSession[]>(
      "/api/get_rooms/ongoing/contains_class/1"
    );
    return response.data;
  }
  async getTrainerHistoryRooms(): Promise<TrainerLiveSession[]> {
    const response = await this.httpClient.get<TrainerLiveSession[]>(
      "/api/get_rooms/history/contains_class/1"
    );
    return response.data;
  }

  async getAllTraders(roomId: any): Promise<any> {
    const response = await this.httpClient.get<any>(
      `/api/get_room_traders/room=${roomId}`
    );
    return response.data;
  }

  async historical_vols(roomId: any): Promise<any> {
    const response = await this.httpClient.get(
      `/api/historical_volatilities/room=${roomId}`,
      {
        responseType: "text",
      }
    );
    return response.data;
  }

  async updateMetrics(roomId: string, data: any): Promise<any> {
    const response = await this.httpClient.post(
      `/api/metrics/${roomId}/`,
      data
    );
    return response.data;
  }
}

export const trainerService = new TrainerService(axios);
