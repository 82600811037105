import { createReducer } from "@reduxjs/toolkit";

import { SocketState } from "types";
import {
  socketConnectFailure,
  socketConnectSuccess,
  socketDisconnect,
  socketDisconnected,
} from "./actions";

const initialState: SocketState = {
  connected: false,
  socketError: "",
};

export const socketReducer = createReducer(initialState, (builder) => {
  builder.addCase(socketConnectSuccess, (state) => {
    state.connected = true;
  });
  builder.addCase(socketDisconnect, (state) => {
    console.log("socket disconect");
    state.connected = false;
  });
  builder.addCase(socketDisconnected, (state) => {
    console.log("socket disconected");

    state.connected = false;
  });
  builder.addCase(socketConnectFailure, (state, action) => {
    console.log("socket disconected");

    state.connected = false;
    state.socketError = action.payload;
  });
});
