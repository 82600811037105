import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled, { css, keyframes } from "styled-components";
import { Typography } from "antd";

import { Row } from "../../reusable/GenericStyledComponents";
import { RootState } from "store/rootReducer";
import { useAppDispatch } from "store/store";
import { selectCallQuote } from "store/callQuote/reducers";
import {
  generateClientCallHeader,
  generateRfqHeader,
} from "../ClientCalls/utils";
import { ClientCall, RFQCall } from "types";

const Header: React.FC = () => {
  const dispatch = useAppDispatch();
  const { selectedCalls, selectedCall } = useSelector(
    (state: RootState) => state.callQuotes
  );
  const { sticky_model } = useSelector((state: RootState) => state.spot);
  return (
    <Row className="align-items-center" style={{ gap: 5 }}>
      {/* <Typography.Text style={{ fontWeight: "bold" }}>Calls:</Typography.Text> */}
      {selectedCalls.map((call: RFQCall | ClientCall) => {
        return (
          <RFQButton
            active={selectedCall?.id === call.id}
            onClick={() => {
              dispatch(selectCallQuote({ call }));
            }}
          >
            {/* TODO: Add type in call */}
            {Object.keys(call).includes("strategy")
              ? generateRfqHeader(call as RFQCall, sticky_model)
              : generateClientCallHeader(call as ClientCall, sticky_model)}
          </RFQButton>
        );
      })}
    </Row>
  );
};

const popup = keyframes`
  from {
    transform: scale(150%);
  }

  to {
    transform: scale(100%);
  }
`;

const RFQButton = styled.button<{ active: boolean; animate?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #000;
  color: #c7c7c7;
  border-radius: 4px;

  ${({ active }) => {
    if (active) {
      return css`
        border: 1px solid ${(p) => p.theme.primaryDark1};
      `;
    }
    return css`
      border: 1px solid #8c8c8c;
    `;
  }}

  ${({ animate }) => {
    return animate
      ? css`
          animation: ${popup} 0.4s cubic-bezier(0.52, 0.24, 0.37, 0.85);
        `
      : null;
  }}
`;

export default Header;
