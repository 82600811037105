import React from "react";
import AdminScenarioModalContentForm from "./AdminScenarioModalContentForm";
import AdminScenarioModalContentXls from "./AdminScenarioModalContentXls";
import Toggle from "react-toggle";
import { Column, Row } from "components/reusable/GenericStyledComponents";
import styled from "styled-components";
import { TrainerScenarioBE } from "types";
import { Switch } from "antd";

type Props = {
  useForm: boolean;
  closeModal: () => void;
  setUseForm: React.Dispatch<React.SetStateAction<boolean>>;
  selectedScenario: TrainerScenarioBE;
  fetchScenarios: () => Promise<void>;
};

const EditScenario: React.FC<Props> = ({
  useForm,
  selectedScenario,
  setUseForm,
  closeModal,
  fetchScenarios,
}) => {
  return (
    <Column style={{ alignItems: "center", marginTop: "1rem", gap: "1rem" }}>
      <Line />
      {selectedScenario.id !== -1 && (
        <>
          <Row>
            {/* eslint-disable-next-line */}
            <label>
              <Switch
                size="small"
                defaultChecked
                onChange={(event) => {
                  setUseForm(!useForm);
                }}
              />
              <span>CSV / Form</span>
            </label>
          </Row>
          <Line />
        </>
      )}
      {useForm ? (
        <AdminScenarioModalContentForm
          closeModal={closeModal}
          scenario={selectedScenario}
          fetchScenarios={fetchScenarios}
        />
      ) : (
        <AdminScenarioModalContentXls
          closeModal={closeModal}
          fetchScenarios={fetchScenarios}
          scenario={selectedScenario}
        />
      )}
    </Column>
  );
};

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #2d363b;
  margin-left: 14px;
  margin-right: 14px;
`;

export default EditScenario;
