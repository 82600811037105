import { put, takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import { authService } from "services";
import { AUTH_AUTHENTICATE, AUTH_REGISTER } from "./constants";
import { userSuccessAuth } from "../user/reducers";

import type { LoginDTO, LoginResponse, RegisterDTO } from "types";
import { userFailAuth } from "./reducers";

function* authenticate(action: PayloadAction<LoginDTO>) {
  try {
    const response: LoginResponse = yield authService.login(action.payload);

    yield put(
      userSuccessAuth({
        name: action.payload.username,
        access: response.access,
        refresh: response.refresh,
      })
    );
  } catch (err) {
    yield put(
      userFailAuth({
        error: "The username or password is invalid",
      })
    );
  }
}

function* register(action: PayloadAction<RegisterDTO>) {
  try {
    yield authService.register(action.payload);
  } catch (err) {
    console.error(err);
  }
}

export function* watchAuthAsync() {
  yield takeEvery(AUTH_AUTHENTICATE, authenticate);
  yield takeEvery(AUTH_REGISTER, register);
}
