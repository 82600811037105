import { ROOM_REQUEST_CB_DATA } from "./constants";
import { put, takeEvery } from "redux-saga/effects";
import { socketSendMessage } from "../socket/actions";

function* requestCentralBankData() {
  const request = {
    headers: {
      command: "room.cmd.cb_state.get",
    },
    body: {},
  };
  yield put(socketSendMessage(JSON.stringify(request)));
}

export function* watchCentralBankAsync() {
  yield takeEvery(ROOM_REQUEST_CB_DATA, requestCentralBankData);
}
