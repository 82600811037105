/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  ComponentType,
  Dispatch,
  ReactElement,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled, { css, keyframes } from "styled-components";
import { useSelector } from "react-redux";

import LoadingSpinner from "components/reusable/LoadingSpinner";
import BrokerDealer from "./BrokerDealer";
import ChartsSection from "./Charts/ChartsSection";
import ClientCalls from "./ClientCalls";
import GeneralInfo from "./GeneralInfo/GeneralInfo";
import PortfolioInfo from "./GeneralInfo/PortfolioInfo";
import BrokerArea from "./BrokerArea/BrokerArea";
import { RootState } from "store/rootReducer";
import NewPricerSection from "./Pricer/NewPricerSection";
import ExpandRightSide from "./ExpandRightSide";
import QuoteSection from "./QuoteSection";
import GridLayout, { WidthProvider, Responsive } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import _ from "lodash";
import { selectActiveLayout } from "store/layout/selectors";
import { setLayout } from "store/layout/reducers";
import { useAppDispatch } from "store/store";
import { LayoutComponentKey } from "types";
import PositionGrid from "components/Modal/PositionGrid";

const FullWidthGridLayout = WidthProvider(GridLayout);

const DraggingContext = createContext<{
  isDragging: boolean;
  setIsDragging: Dispatch<SetStateAction<boolean>>;
}>({
  isDragging: false,
  setIsDragging: () => {},
});

const useDragging = () => useContext(DraggingContext);

interface WithEditableCheckProps {
  [key: string]: any;
}

const withEditableCheck = <P extends object>(
  WrappedComponent: ComponentType<P>
): ComponentType<P> => {
  // Component with explicit return type
  const WithEditableCheck: ComponentType<P> = (
    props: P
  ): ReactElement | null => {
    const editing = useSelector((state: RootState) => state.layout.editing);
    const { isDragging } = useDragging();

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const { current } = ref;
      const eventHandler = (e: MouseEvent) => {
        if (editing || isDragging) {
          e.stopPropagation();
          console.log("Event stopped");
          return false; // Prevent the default action
        }
      };

      // Listen for click events on this element and capture them before they propagate
      current?.addEventListener("click", eventHandler, true);

      return () => {
        current?.removeEventListener("click", eventHandler, true);
      };
    }, [editing, isDragging]);

    return (
      <div style={{ height: "100%" }} ref={ref}>
        <WrappedComponent {...props} />
      </div>
    );
  };

  return WithEditableCheck;
};
const getComponentMap = (): Record<LayoutComponentKey, ComponentType<any>> => ({
  PortfolioInfo: withEditableCheck(PortfolioInfo),
  ChartsSection: withEditableCheck(ChartsSection),
  BrokerArea: withEditableCheck(BrokerArea),
  GeneralInfo: withEditableCheck(PositionGrid),
  NewPricerSection: withEditableCheck(NewPricerSection),
  BrokerDealer: withEditableCheck(BrokerDealer),
  QuoteSection: withEditableCheck(QuoteSection),
});
const componentMap = getComponentMap();

const GameLayout: React.FC = () => {
  const dispatch = useAppDispatch();
  const cb = window.location.pathname.split("/")[1] === "centralbank";
  const editing = useSelector((state: RootState) => state.layout.editing);
  const layout = useSelector(selectActiveLayout);
  const initial_state_loaded = useSelector(
    (state: RootState) => state.spot.initial_state_loaded
  );
  const { connected: socketConnected } = useSelector(
    (state: RootState) => state.socket
  );
  const { settingsLoaded } = useSelector((state: RootState) => state.settings);
  const { cb_state_loaded } = useSelector(
    (state: RootState) => state.centralbank
  );
  const largePricer = useSelector(
    (state: RootState) => state.newpricer.largePricer
  );
  const [collapsed, setCollapsed] = useState<boolean>(largePricer);
  const [willColapse, setWillColapse] = useState<boolean>(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const handleResize = _.throttle(() => {
      setWidth(window.innerWidth);
    }, 100); // Throttle with lodash or similar

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (largePricer) {
      setWillColapse(true);
      setTimeout(() => {
        setCollapsed(true);
        setWillColapse(false);
      }, 300);
    } else {
      setCollapsed(false);
    }
  }, [largePricer]);

  const onLayoutChange = (newLayout: GridLayout.Layout[]) => {
    console.log("[component] Setting new layout", newLayout);

    dispatch(setLayout(newLayout));
  };

  if (!socketConnected)
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoadingSpinner message="Connecting to services..." />
      </div>
    );

  if (!initial_state_loaded || !settingsLoaded || (cb && !cb_state_loaded))
    return (
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoadingSpinner message="Room data is loading..." />
      </div>
    );

  // The number of columns in the grid, row height, and container padding
  const cols = 6;
  const rowHeight = 100;
  const margin: [number, number] = [10, 10]; // Margin between items [horizontal, vertical]

  return (
    <DraggingContext.Provider value={{ isDragging, setIsDragging }}>
      <FullWidthGridLayout
        resizeHandles={editing ? ["se", "ne", "sw", "nw"] : []}
        className="layout"
        layout={layout}
        cols={cols}
        rowHeight={rowHeight}
        width={width}
        margin={margin}
        isDraggable={editing}
        isResizable={editing}
        onLayoutChange={onLayoutChange}
        onDragStart={() => setIsDragging(true)}
        onDragStop={() => {
          setTimeout(() => {
            setIsDragging(false);
            console.log("not dragging");
          }, 500);
        }} // 100ms or experiment with this value
      >
        {layout.map((item) => {
          const Component = componentMap[item.i as LayoutComponentKey];
          return (
            <div
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              key={item.i}
            >
              {Component ? <Component /> : null}
            </div>
          );
        })}
      </FullWidthGridLayout>
    </DraggingContext.Provider>
  );
};

export default GameLayout;
