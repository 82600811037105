import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  SettingsState,
  InitialDataPayload,
  ParamUpdatedPayload,
  SettingsStateParameters,
} from "types";
import { clearRoom, retrieveInitialData } from "../room/actions";
import { alterSettings, paramUpdated } from "./actions";

const initialState: () => SettingsState = () => ({
  settingsLoaded: 0,
  game_rules: [],
  parameters: {
    TRADABLE_VOLS_PUT_CALL_OR_STRADDLE: 0,
    CLIENT_CALL_LIFETIME_SECONDS: 0,
    RFQ_USER_DECIDES_SECONDS: 0,
    CLIENT_CALLS_ENABLED: 0,
    DARKPOOL_ENABLED: 0,
    RFQ_ENABLED: 0,
    RFQ_STRATEGIES_ENABLED: 0,
    SPOT_DECIMAL_PLACES: 2,
    IMPACT_VOTE_SECONDS: 0,
    PLAYERS_RFQ_PLAYERS_QUOTE_MAX_SPREAD: 0,
    PLAYERS_SPOT_MAX_TICKET: 0,
    PLAYERS_SPOT_MIN_TICKET: 0,
    PLAYERS_VOL_MAX_TICKET: 0,
    PLAYERS_VOL_MIN_TICKET: 0,
    PLAYERS_TOTALORDERS_LIMIT: 0,
    TICK: 0,
    TICKET_UNIT: 0,
    PLAYERS_DELTA_LIMIT: 0,
    PLAYERS_GAMMA_LIMIT: 0,
    PLAYERS_VEGA_LIMIT: 0,
    PLAYERS_THETA_LIMIT: 0,
    CLIENT_CALL_LARGE_AMOUNT_PROB: 0,
    CLIENT_CALL_WITH_QUOTE_PROB: 0,
    CLIENT_CALL_BINDING_SPREAD_PROB: 0,
    RFQ_BOTS_CALL_FREQUENCY: 0,
    EB_BOTS_HIT_FREQUENCY: 0,
    BOTS_SPOT_MAX_TICKET_MULTIPLIER: 0,
    RFQ_BOT_QUOTES_SECONDS: 0,
    RFQ_PLAYERS_QUOTE_MAX_POINTS_OFF_MARKET: 0,
    EB_PLAYERS_QUOTE_MAX_POINTS_OFF_MARKET: 0,
    CLIENT_CALL_PLAYERS_QUOTE_MAX_POINTS_OFF_MARKET: 0,
    TRADABLE_VOLS_BOTS_HIT_FREQUENCY: 0,
    REAL_SECONDS_TO_GAME_DAYS_RATIO: 10,
    SINGLE_OR_MULTIPLE_DAYS_TRADING: 0,
    AUTO_EXERCISE: 0,
    PNL_DISPLAY_DIVIDER: 1,
    TRADABLE_VOLS_MOVE_MARKET_FREQUENCY: 0,
    TRADABLE_VOLS_MAX_SPREAD: 0,
    BOTS_TRADABLE_VOLS_MAX_TICKET_MULTIPLIER: 0,
  },
});

const settingsSlice = createSlice({
  initialState,
  name: "settings",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        retrieveInitialData,
        (state, action: PayloadAction<InitialDataPayload>) => {
          const {
            swifts,
            game_rules,
            settings: { general, asset_settings: assets },
          } = action.payload;
          state.game_rules = game_rules;
          console.log({ game_rules });

          state.parameters.SPOT_DECIMAL_PLACES = swifts[0].spot_decimal_places;
          state.parameters.TICKET_UNIT = swifts[0].vol_ticket_unit;
          state.parameters.TICK = swifts[0].tick;
          state.parameters.PNL_DISPLAY_DIVIDER = general.PNL_DISPLAY_DIVIDER;
          state.parameters.DARKPOOL_ENABLED = general.DARKPOOL_ENABLED;
          state.parameters.RFQ_USER_DECIDES_SECONDS =
            general.RFQ_USER_DECIDES_SECONDS;
          state.parameters.RFQ_ENABLED = general.RFQ_ENABLED;
          state.parameters.RFQ_STRATEGIES_ENABLED =
            general.RFQ_STRATEGIES_ENABLED;
          state.parameters.CLIENT_CALLS_ENABLED = general.CLIENT_CALLS_ENABLED;
          state.parameters.CLIENT_CALL_LIFETIME_SECONDS =
            general.CLIENT_CALL_LIFETIME_SECONDS;
          state.parameters.IMPACT_VOTE_SECONDS = general.IMPACT_VOTE_SECONDS;
          state.parameters.PLAYERS_RFQ_PLAYERS_QUOTE_MAX_SPREAD =
            assets[0].parameters.PLAYERS_RFQ_PLAYERS_QUOTE_MAX_SPREAD;
          state.parameters.EB_PLAYERS_QUOTE_MAX_POINTS_OFF_MARKET =
            assets[0].parameters.EB_PLAYERS_QUOTE_MAX_POINTS_OFF_MARKET;
          state.parameters.PLAYERS_SPOT_MAX_TICKET =
            assets[0].parameters.PLAYERS_SPOT_MAX_TICKET;
          state.parameters.PLAYERS_SPOT_MIN_TICKET =
            assets[0].parameters.PLAYERS_SPOT_MIN_TICKET;
          state.parameters.PLAYERS_VOL_MAX_TICKET =
            assets[0].parameters.PLAYERS_VOL_MAX_TICKET;
          state.parameters.PLAYERS_VOL_MIN_TICKET =
            assets[0].parameters.PLAYERS_VOL_MIN_TICKET;
          state.parameters.PLAYERS_TOTALORDERS_LIMIT =
            assets[0].parameters.PLAYERS_TOTALORDERS_LIMIT;
          state.parameters.TRADABLE_VOLS_PUT_CALL_OR_STRADDLE =
            assets[0].parameters.TRADABLE_VOLS_PUT_CALL_OR_STRADDLE;
          state.parameters.PLAYERS_DELTA_LIMIT = general.PLAYERS_DELTA_LIMIT;
          state.parameters.PLAYERS_GAMMA_LIMIT = general.PLAYERS_GAMMA_LIMIT;
          state.parameters.PLAYERS_VEGA_LIMIT = general.PLAYERS_VEGA_LIMIT;
          state.parameters.PLAYERS_THETA_LIMIT = general.PLAYERS_THETA_LIMIT;
          state.parameters.REAL_SECONDS_TO_GAME_DAYS_RATIO =
            general.REAL_SECONDS_TO_GAME_DAYS_RATIO;
          state.parameters.SINGLE_OR_MULTIPLE_DAYS_TRADING =
            general.SINGLE_OR_MULTIPLE_DAYS_TRADING;
          state.parameters.AUTO_EXERCISE = general.AUTO_EXERCISE;
          state.settingsLoaded = 1;
        }
      )
      .addCase(
        paramUpdated,
        (state, action: PayloadAction<ParamUpdatedPayload>) => {
          const { param } = action.payload;
          const { code, value } = param;
          state.parameters[code as keyof SettingsStateParameters] = value;
          const rulesIdx = state.game_rules.findIndex((r) => r.code === code);
          if (rulesIdx !== -1) {
            state.game_rules[rulesIdx].value = value;
          }
        }
      )
      .addCase(clearRoom, initialState);
  },
});

export default settingsSlice.reducer;
