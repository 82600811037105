import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
// import { Chart as ChartJS, registerables, ScatterDataPoint } from 'chart.js';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  ScatterDataPoint,
} from "chart.js";
import type { ChartData, ChartArea } from "chart.js";
import { Card, Skeleton } from "antd";
import {
  EvolutionReport,
  EvolutionReportKeys,
  SwiftReport,
  TraderReportsType,
} from "types/reports";

// Chrt.defaults.scale.grid.display = false;
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

interface IChartProps {
  title: string;
  graphColor: string;
  report: SwiftReport | undefined;
  field: EvolutionReportKeys;
}

const ReportChart: React.FC<IChartProps> = ({
  title,
  graphColor,
  field,
  report,
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);
  if (!report || !report[field])
    return (
      <>
        <Skeleton active />
      </>
    );

  if (!report[field].x_values) {
    return (
      <Card style={{ width: "100%" }} title={title} loading={loading}>
        <h1>The user did not have activity in this area</h1>
      </Card>
    );
  }
  const chartValues = report[field].x_values.map(
    (point, idx): ScatterDataPoint => ({
      x: point,
      y: report[field].y_values[idx],
    })
  );
  return (
    <Card style={{ width: "100%" }} title={title} loading={loading}>
      <Line
        data={{
          labels: new Array(chartValues.length).fill(""),
          datasets: [
            {
              label: title,
              yAxisID: "A",
              tension: 0.4,
              showLine: true,
              borderWidth: 1,
              pointBorderWidth: 0,
              pointBackgroundColor: (context) => {
                const value = context.parsed?.y || null;
                if (value) {
                  return value < 0 ? "red" : "green";
                }
                return "red";
              },
              data: chartValues,
              fill: true,
              backgroundColor: (context) => {
                const { ctx }: { ctx: CanvasRenderingContext2D } =
                  context.chart;
                const gradient = ctx.createLinearGradient(0, 0, 0, 200);
                gradient.addColorStop(0, `${graphColor}50`);
                gradient.addColorStop(1, `${graphColor}10`);
                return gradient;
              },
              borderColor: graphColor,
            },
          ],
        }}
        height={180}
        width={317}
        options={{
          animation: { duration: 0 },
          // plugins: { legend: { display: false } },
          scales: {
            A: {
              type: "linear",
              position: "right",
            },
          },
          elements: {
            point: {
              radius: 3,
            },
          },
        }}
        style={{ border: "1px solid white" }}
      />
    </Card>
  );
};

export default ReportChart;
