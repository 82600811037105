import { BUY, CALL } from "stateConstants";
import { NewPricerColumn } from "types";

export const createEmptyPricer: (correlation_id: number) => NewPricerColumn = (
  correlation_id: number
) => {
  return {
    linked_to: "",
    correlation_id,
    direction: BUY,
    type: CALL,
    maturity: 0,
    notional: 0,
    strike: 0,
    spot: 0,
    interestRates: 0,
    divYield: 0,
    forward: 0,
    volatility: 0,
    price: 0,
    pricePercentOfSpot: 0,
    premium: 0,
    delta: 0,
    gamma: 0,
    vega: 0,
    theta: 0,
    noOfDelta: 0,
    noOfGamma: 0,
    vegaDollars: 0,
    call_theta: 0,
    put_theta: 0,
    lockVol: 0,
  };
};
