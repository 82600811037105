import { createAction } from "@reduxjs/toolkit";
import {
  RFQ_CALL_ANSWERED,
  RFQ_CALL_CREATED,
  RFQ_CALL_REJECTED,
  RFQ_CALL_EXPIRED,
  RFQ_MAKE_CALL,
  RFQ_ANSWER_CALL,
  RFQ_NTG_CALL,
  RFQ_HIT_CALL,
  RFQ_CALL_HIT,
  RFQ_ERROR,
} from "./constants";
import {
  GetVolatilityPayload,
  PricerUpdateDataPayload,
  RFQAnswerCallPayload,
  RFQAnswerStrategyCallPayload,
  RFQCallGenericPayload,
  RFQCallHitPayload,
  RFQHitCallPayload,
  RFQMakeCallPayload,
  RFQNtgCallPayload,
} from "types";

export const rfqCallCreated =
  createAction<RFQCallGenericPayload>(RFQ_CALL_CREATED);
export const rfqCallAnswered =
  createAction<RFQCallGenericPayload>(RFQ_CALL_ANSWERED);
export const rfqCallHit = createAction<RFQCallHitPayload>(RFQ_CALL_HIT);
export const rfqCallRejected =
  createAction<RFQCallGenericPayload>(RFQ_CALL_REJECTED);
export const rfqCallExpired =
  createAction<RFQCallGenericPayload>(RFQ_CALL_EXPIRED);
export const rfqError = createAction<{
  call: { id: number; trader_id: string };
  message: string;
}>(RFQ_ERROR);

export const rfqMakeCall = createAction<RFQMakeCallPayload>(RFQ_MAKE_CALL);
export const rfqAnswerCall =
  createAction<RFQAnswerCallPayload>(RFQ_ANSWER_CALL);
export const rfqAnswerStrategyCall = createAction<RFQAnswerStrategyCallPayload>(
  "rfq/cmdAnswerStrategyCall"
);
export const rfqHitCall = createAction<RFQHitCallPayload>(RFQ_HIT_CALL);
export const rfqNtgCall = createAction<RFQNtgCallPayload>(RFQ_NTG_CALL);

export const rfqPricerData =
  createAction<PricerUpdateDataPayload>("rfq/getPricerData");
export const rfqGetVolatility =
  createAction<GetVolatilityPayload>("rfq/GetVolatility");
