import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { Row } from "components/reusable/GenericStyledComponents";
import React, { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { setNbCols } from "store/newpricer/reducers";
import { RootState } from "store/rootReducer";
import { useAppDispatch } from "store/store";
import styled from "styled-components";
import { StateToColumn, StateToInfo, visiblePricerKeys } from "./utils";
import { updatePricerField } from "store/newpricer/actions";
import { NewPricerColumn } from "types";
import { newSelectPricerData } from "store/newpricer/selectors";
import { usePricerDebouncers } from "./debouncers";
import { switchCBNavHidden } from "store/centralbank/reducers";

const NewPricer: React.FC = () => {
  const dispatch = useAppDispatch();
  const { room_id } = useSelector((state: RootState) => state.room);
  const { cb_nav_hidden } = useSelector(
    (state: RootState) => state.centralbank
  );
  const { nbCols, pricerToCallsLinks } = useSelector(
    (state: RootState) => ({
      nbCols: state.newpricer.nbCols,
      pricerToCallsLinks: state.newpricer.pricerToCallsLinks,
    }),
    shallowEqual
  );
  const {
    initial_state_loaded,
    sticky_model,
    delta_strike_values,
    tradableVols,
  } = useSelector((state: RootState) => state.spot);
  const bestBid = useSelector((state: RootState) => state.spot.bestBid);
  const bestAsk = useSelector((state: RootState) => state.spot.bestAsk);
  const pricers = useSelector(newSelectPricerData);
  const debouncers = usePricerDebouncers();

  const onChange = (pricer: NewPricerColumn) => {
    if (!pricer.maturity) {
      dispatch(
        updatePricerField({
          key: "maturity",
          correlation_id: pricer.correlation_id,
          value: parseInt(Object.keys(tradableVols)[0]),
        })
      );
    }

    if (!pricer.strike) {
      dispatch(
        updatePricerField({
          key: "strike",
          correlation_id: pricer.correlation_id,
          value: sticky_model ? bestAsk?.price || 0 : delta_strike_values[0],
        })
      );
    }

    if (
      pricer.strike &&
      pricer.maturity &&
      pricer.notional &&
      room_id !== "" &&
      !pricer.lockVol
    ) {
      debouncers.volatility[pricer.correlation_id].current(
        room_id,
        pricer.strike,
        pricer.maturity,
        pricer.correlation_id
      );
    }

    debouncers.pricerSendData[pricer.correlation_id].current(
      pricer,
      sticky_model
    );
  };

  // for vol and spot dependent values
  useEffect(() => {
    Object.keys(pricers).forEach((key) => {
      onChange(pricers[Number(key)]);
    });
  }, [bestAsk?.price, bestBid?.price]);

  if (!initial_state_loaded) return null;

  return (
    <Row style={{ gap: 5 }}>
      <GridContainer columns={nbCols}>
        {React.Children.toArray(
          visiblePricerKeys.map((k) => {
            return (
              <>
                <StateToInfo stateKey={k} />

                {React.Children.toArray(
                  [...Array(nbCols)].map((nr, i) => {
                    return (
                      <StateToColumn
                        pricer={pricers[i]}
                        linked={pricerToCallsLinks[i]}
                        debouncer={onChange}
                        stateKey={k}
                        col={i}
                      />
                    );
                  })
                )}
              </>
            );
          })
        )}
      </GridContainer>

      <div>
        {nbCols < 4 && (
          <Button
            size="small"
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
            onClick={() => {
              if (nbCols === 3 && !cb_nav_hidden) {
                dispatch(switchCBNavHidden({}));
              }
              dispatch(setNbCols({ nbCols: nbCols + 1 }));
            }}
          >
            <PlusOutlined />
          </Button>
        )}
      </div>
    </Row>
  );
};

const GridContainer = styled.div<{ columns: number }>`
  width: 100%;
  display: grid;
  grid-template-columns: max-content repeat(${({ columns }) => columns}, 110px);
  grid-auto-columns: 100px;
  grid-template-rows: min-content;
  grid-auto-rows: min-content;
  column-gap: 5px;
  align-items: center;
`;

export default NewPricer;
