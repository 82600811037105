import React, { useState } from "react";
import { TrainerScenarioBE } from "types";
import { Column, Row } from "components/reusable/GenericStyledComponents";
import * as xlsx from "xlsx";
import { adminService } from "services";
import { Button } from "antd";

const AdminScenarioModalContentXls: React.FC<{
  scenario: TrainerScenarioBE;
  fetchScenarios: () => Promise<void>;
  closeModal: () => void;
}> = ({ scenario, fetchScenarios, closeModal }) => {
  const [selectedFile, setSelectedFile] = useState<Blob>();

  const readUploadFile = (e: any) => {
    e.preventDefault();
    if (e.target.files) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    if (selectedFile) {
      formData.append("file", selectedFile);
    }
    if (scenario.id === -1)
      adminService
        .uploadScenarioXLSX(formData)
        .then(async (res) => {
          await fetchScenarios();
          closeModal();
        })
        .catch((err) => {
          alert(err);
        });
    else {
      adminService
        .updateScenarioXLSX(scenario.id, formData)
        .then(async (res) => {
          await fetchScenarios();
          closeModal();
        })
        .catch((err) => {
          alert(err);
        });
    }
  };
  return (
    <Column
      style={{
        padding: "1rem",
        borderRadius: ".5rem",
        border: "1px solid #ffffff50",
        alignItems: "center",
      }}
    >
      <form
        style={{
          display: "flex",
          gap: "1rem",
          flexDirection: "column",
          alignItems: "center",
        }}
        onSubmit={handleSubmit}
      >
        <input
          type="file"
          name="upload"
          id="upload"
          onChange={readUploadFile}
          style={{ paddingLeft: "6rem" }}
        />
        <Button onClick={handleSubmit}>Post</Button>
      </form>
    </Column>
  );
};

export default AdminScenarioModalContentXls;
