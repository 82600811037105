import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Modal, Progress, Typography, Button } from "antd";

import { useServerStatusContext } from "contexts/ServerStatusProvider";
import { RootState } from "store/rootReducer";

const { Paragraph } = Typography;

type Props = {
  open: boolean;
  hide: () => void;
};

const normalise = (value: number, maxim: number, offset = 0) => {
  const percent = ((value - offset) * 100) / maxim;
  return Math.floor(percent);
};

const ServerStatusModal: React.FC<Props> = ({ open, hide }) => {
  const {
    workersUp,
    nbWorkersUp,
    maxWorkers,
    loading,
    actionTaken,
    status,
    setActionTaken,
    startServers,
    stopServers,
    setLoading,
  } = useServerStatusContext();
  const { permission } = useSelector((state: RootState) => state.user);

  return (
    <Modal
      title="Server status"
      open={open}
      onCancel={hide}
      footer={null}
      width="400px"
    >
      <Container>
        <Progress type="circle" percent={normalise(nbWorkersUp, maxWorkers)} />

        <div>
          <Paragraph>
            {loading ? "Fetching..." : `${nbWorkersUp}/${maxWorkers}`}
          </Paragraph>

          {!loading && <Paragraph>{status}</Paragraph>}

          {!workersUp &&
            !loading &&
            !actionTaken &&
            ["All workers are up!", "Workers are stopped"].includes(status) && (
              <Button
                disabled={workersUp}
                onClick={() => {
                  setLoading(true);
                  setActionTaken(true);
                  startServers();
                }}
              >
                Start services
              </Button>
            )}

          {permission === "Admin" &&
            workersUp &&
            !loading &&
            !actionTaken &&
            ["All workers are up!", "Workers are stopped"].includes(status) && (
              <Button
                disabled={!workersUp}
                onClick={() => {
                  setLoading(true);
                  setActionTaken(true);
                  stopServers();
                }}
              >
                Stop services
              </Button>
            )}
        </div>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  padding-top: 15px;
  width: 100%;
  display: flex;
  gap: 30px;

  .ant-typography {
    margin-bottom: 0.5rem !important;
  }

  .ant-btn {
    margin-bottom: 0.5rem;
  }
`;

export default ServerStatusModal;
