import React, { useState } from "react";

import styled from "styled-components";

import {
  CC_ACCEPTED,
  CC_CANCELLED,
  CC_STATUS_STR,
  CC_WAITING,
  PUT_CALL_STR,
} from "stateConstants";
import { useAppDispatch } from "store/store";
import { clientCallCancel } from "store/clientCalls/actions";
import { ClientCall, MATURITY } from "types";
import UnquotedActions from "./UnquotedActions";
import { generateClientCallHeader } from "./utils";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "store/rootReducer";

interface Props {
  call: ClientCall;
}

const UnquotedCC: React.FC<Props> = ({ call }) => {
  const dispatch = useAppDispatch();
  const sticky_model = useSelector(
    (state: RootState) => state.spot.sticky_model,
    shallowEqual
  );

  const [expanded, setExpanded] = useState(false);

  const getStatus = () => {
    if (call.status === CC_ACCEPTED) {
      return call.short_or_long ? "Sold to client" : "Bought from client";
    }
    return CC_STATUS_STR[call.status];
  };

  if (!call) return null;
  return (
    <Container>
      <Header>
        <GreySpan>{call.client_name}</GreySpan>
        <Details>
          <YellowSpan>
            {generateClientCallHeader(call, sticky_model)}
          </YellowSpan>

          <GreySpan>{getStatus()}</GreySpan>

          {!expanded && (
            <Group>
              <PriceButton
                onClick={() => {
                  setExpanded(true);
                }}
                disabled={getStatus() !== CC_STATUS_STR[CC_WAITING]}
              >
                Price it
              </PriceButton>

              <CancelButton
                onClick={() => {
                  dispatch(clientCallCancel({ callId: call.id }));
                }}
                disabled={getStatus() !== CC_STATUS_STR[CC_WAITING]}
              >
                Cancel
              </CancelButton>
            </Group>
          )}
        </Details>
      </Header>

      {expanded && <UnquotedActions call={call} />}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 13px;
`;

const Details = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const YellowSpan = styled.span`
  color: ${(p) => p.theme.primaryDark1};
`;

const GreySpan = styled.span`
  color: #8c8c8c;
`;

const ActionButton = styled.button`
  border: none;
  padding: 2px 8px;
  margin: 0;
  border-radius: initial;

  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
  cursor: pointer;
`;

const PriceButton = styled(ActionButton)`
  color: #b7eb8f95;
  border: 1px solid #b7eb8f95;

  transition: all 0.2s;

  &:hover {
    color: #b7eb8f;
    border: 1px solid #b7eb8f;
  }
`;

const CancelButton = styled(ActionButton)`
  color: #ff7875aa;
  border: 1px solid #ff7875aa;

  transition: all 0.2s;

  &:hover {
    color: #ff7875;
    border: 1px solid #ff7875;
  }
`;

const Group = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export default UnquotedCC;
