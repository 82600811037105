import { Column, Row } from "components/reusable/GenericStyledComponents";
import StyledText from "components/reusable/StyledText";
import React from "react";
import { Swift, TrainerScenarioBE } from "types";
import AdminScenarioModalContentXls from "../EditScenario/AdminScenarioModalContentXls";
import SwiftCard from "./SwiftCard";
import { Button } from "antd";

type Props = {
  swiftList: Swift[];
  selectedSwift: Swift | null;
  selectedScenario: TrainerScenarioBE;
  setSelectedSwift: React.Dispatch<React.SetStateAction<Swift | null>>;
  setSwiftEditView: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedScenario: React.Dispatch<React.SetStateAction<TrainerScenarioBE>>;
  fetchScenarios: () => Promise<void>;
  closeModal: () => void;
};

const CreateScenario: React.FC<Props> = ({
  swiftList,
  selectedSwift,
  selectedScenario,
  closeModal,
  setSelectedSwift,
  setSwiftEditView,
  setSelectedScenario,
  fetchScenarios,
}) => {
  return (
    <Column style={{ gap: "1rem", alignItems: "center", marginTop: "2rem" }}>
      <StyledText fontWeight={500} fontSize={24}>
        Select a swift
      </StyledText>
      <Row style={{ gap: "1rem", alignItems: "center", marginTop: "2rem" }}>
        {swiftList.map((s) => {
          return (
            <SwiftCard
              key={s.id}
              swift={s}
              selectedSwift={selectedSwift}
              setSelectedSwift={setSelectedSwift}
              setSelectedScenario={setSelectedScenario}
              selectedScenario={selectedScenario}
            />
          );
        })}
      </Row>
      {selectedSwift !== null && (
        <Button
          onClick={() => {
            setSwiftEditView(false);
          }}
        >
          Edit scenario
        </Button>
      )}
      <StyledText fontWeight={500} fontSize={24}>
        Or upload from file
      </StyledText>
      <AdminScenarioModalContentXls
        closeModal={closeModal}
        fetchScenarios={fetchScenarios}
        scenario={selectedScenario}
      />
    </Column>
  );
};

export default CreateScenario;
