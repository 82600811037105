import { Button, InputNumber, Typography, message } from "antd";
import { addNews } from "store/events/actions";
import TextArea from "antd/es/input/TextArea";
import FloatLabel from "components/reusable/FloatLabel";
import {
  Column,
  FloatInputNumber,
  Row,
} from "components/reusable/GenericStyledComponents";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { RootState } from "store/rootReducer";
import { useAppDispatch } from "store/store";
import { CBEvent, CBNewsRows, Permission } from "types";

type Props = {
  // prevNews: CBNewsRows;
  // nextNews: CBNewsRows;
  currentNews: CBNewsRows;
  expandedRows: string[];
  setExpandedRows: React.Dispatch<React.SetStateAction<string[]>>;
  closeModal: () => void;
};

const AddNewsExpanded: React.FC<Props> = ({
  // prevNews,
  // nextNews,
  currentNews,
  expandedRows,
  setExpandedRows,
  closeModal,
}) => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const swift_ = useSelector((state: RootState) => state.swifts.selectedSwift);
  const permission = useSelector((state: RootState) => state.user.permission);
  // const first = !prevNews;
  // const last = !nextNews;

  const [newsForm, setNewsForm] = useState({
    room_time: 0,
    body: currentNews.body,
    headline: currentNews.headline,
    // eslint-disable-next-line no-nested-ternary
    // deltaseconds: last
    //   ? currentNews.delta_seconds
    //   : Math.round((nextNews.delta_seconds + currentNews.delta_seconds) / 2),
    deltaseconds: currentNews.delta_seconds,
    index: currentNews.index,
    market_impact: {
      call_skew: 0,
      lt_steep: 0,
      put_skew: 0,
      st_steep: 0,
      vol_parallel: 0,
      spot: 0,
    },
  });
  return (
    <div>
      <Column style={{ alignItems: "center", justifyContent: "space-between" }}>
        <Row style={{ gap: "1rem" }}>
          <Row style={{ gap: ".5rem" }}>
            <Column
              style={{
                width: "45ch",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography.Text>Headline</Typography.Text>
              <TextArea
                autoSize
                value={newsForm.headline}
                style={{ height: "10ch" }}
                onChange={(e) => {
                  setNewsForm({ ...newsForm, headline: e.target.value });
                }}
              />
            </Column>

            <Column
              style={{
                width: "45ch",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography.Text>Body</Typography.Text>
              <TextArea
                autoSize
                value={newsForm.body}
                style={{ height: "10ch" }}
                onChange={(e) => {
                  setNewsForm({ ...newsForm, body: e.target.value });
                }}
              />
            </Column>
          </Row>
          <Row>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gridTemplateRows: "1fr 1fr 1fr",
                columnGap: "1rem",
                rowGap: ".5rem",
                width: "50%",
              }}
            >
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <FloatLabel label="Time(s)" value={newsForm.deltaseconds}>
                  <FloatInputNumber
                    // min={currentNews.delta_seconds || 0}
                    // max={last ? undefined : nextNews?.delta_seconds || 0}
                    type="number"
                    onChange={(value) => {
                      setNewsForm({
                        ...newsForm,
                        deltaseconds: (value as number) || 0,
                      });
                    }}
                    value={newsForm.deltaseconds}
                  />
                </FloatLabel>
              </div>
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <FloatLabel label="Time(m)" value={newsForm.deltaseconds / 60}>
                  <FloatInputNumber
                    type="number"
                    // min={currentNews.delta_seconds / 60 || 0}
                    // max={last ? undefined : nextNews?.delta_seconds / 60 || 0}
                    onChange={(value: any) => {
                      setNewsForm({
                        ...newsForm,
                        deltaseconds: ((value * 60) as number) || 0,
                      });
                    }}
                    value={Number((newsForm.deltaseconds / 60).toFixed(2))}
                  />
                </FloatLabel>
              </div>
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <FloatLabel
                  label="Time(h)"
                  value={newsForm.deltaseconds / 3600}
                >
                  <FloatInputNumber
                    type="number"
                    // min={currentNews.delta_seconds / 3600 || 0}
                    // max={last ? undefined : nextNews?.delta_seconds / 3600 || 0}
                    onChange={(value: any) => {
                      setNewsForm({
                        ...newsForm,
                        deltaseconds: ((value * 3600) as number) || 0,
                      });
                    }}
                    value={Number((newsForm.deltaseconds / 3600).toFixed(2))}
                  />
                </FloatLabel>
              </div>
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <FloatLabel label="Spot" value={newsForm.market_impact.spot}>
                  <FloatInputNumber
                    onChange={(value) => {
                      setNewsForm({
                        ...newsForm,
                        market_impact: {
                          ...newsForm.market_impact,
                          spot: (value as number) || 0,
                        },
                      });
                    }}
                    value={newsForm.market_impact.spot}
                  />
                </FloatLabel>
              </div>
              { permission === Permission.Admin && (
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <FloatLabel
                  label="Lt. steepness"
                  value={newsForm.market_impact.lt_steep}
                >
                  <FloatInputNumber
                    onChange={(value) => {
                      setNewsForm({
                        ...newsForm,
                        market_impact: {
                          ...newsForm.market_impact,
                          lt_steep: (value as number) || 0,
                        },
                      });
                    }}
                    value={newsForm.market_impact.lt_steep}
                  />
                </FloatLabel>
              </div>)}
              { permission === Permission.Admin && (
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <FloatLabel
                  label="Put skew"
                  value={newsForm.market_impact.put_skew}
                >
                  <FloatInputNumber
                    onChange={(value) => {
                      setNewsForm({
                        ...newsForm,
                        market_impact: {
                          ...newsForm.market_impact,
                          put_skew: (value as number) || 0,
                        },
                      });
                    }}
                    value={newsForm.market_impact.put_skew}
                  />
                </FloatLabel>
              </div>)}
              { permission === Permission.Admin && (
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <FloatLabel
                  label="St. steepness"
                  value={newsForm.market_impact.st_steep}
                >
                  <FloatInputNumber
                    onChange={(value) => {
                      setNewsForm({
                        ...newsForm,
                        market_impact: {
                          ...newsForm.market_impact,
                          st_steep: (value as number) || 0,
                        },
                      });
                    }}
                    value={newsForm.market_impact.st_steep}
                  />
                </FloatLabel>
              </div>)}
              { permission === Permission.Admin && (
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <FloatLabel
                  label="Call skew"
                  value={newsForm.market_impact.call_skew}
                >
                  <FloatInputNumber
                    onChange={(value) => {
                      setNewsForm({
                        ...newsForm,
                        market_impact: {
                          ...newsForm.market_impact,
                          call_skew: (value as number) || 0,
                        },
                      });
                    }}
                    value={newsForm.market_impact.call_skew}
                  />
                </FloatLabel>
              </div>)}
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <FloatLabel
                  label="VOL Parallel.."
                  value={newsForm.market_impact.vol_parallel}
                >
                  <FloatInputNumber
                    onChange={(value) => {
                      setNewsForm({
                        ...newsForm,
                        market_impact: {
                          ...newsForm.market_impact,
                          vol_parallel: (value as number) || 0,
                        },
                      });
                    }}
                    value={newsForm.market_impact.vol_parallel}
                  />
                </FloatLabel>
              </div>
            </div>
          </Row>
        </Row>

        <Row style={{ gap: "1rem", marginTop: "1rem" }}>
          <Button
            style={{ borderColor: "red" }}
            onClick={(e) => {
              e.preventDefault();
              setExpandedRows([
                ...expandedRows.filter(
                  (exp) => exp !== currentNews.id.toString()
                ),
              ]);
              closeModal();
            }}
          >
            Cancel
          </Button>
          <Button
            style={{ borderColor: "green" }}
            onClick={(e) => {
              e.preventDefault();
              dispatch(
                addNews({
                  room_id: id,
                  swift: swift_,
                  news: { ...newsForm, index: newsForm.index + 1 },
                })
              );
              message.success(
                `News with headline ${newsForm.headline} added at time ${newsForm.deltaseconds}`
              );

              setExpandedRows([
                ...expandedRows.filter(
                  (exp) => exp !== currentNews.id.toString()
                ),
              ]);
              closeModal();
            }}
          >
            Save
          </Button>
        </Row>
      </Column>
    </div>
  );
};

export default AddNewsExpanded;
