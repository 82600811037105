import { createSelector } from "@reduxjs/toolkit";
import {
  generateClientCallHeader,
  generateRfqHeader,
} from "components/GameRoom/ClientCalls/utils";

import { BUY } from "stateConstants";
import { ClientCall, NewPricerColumn, NewPricerState } from "types";
import { RootState } from "../rootReducer";

const objectMap: (
  obj: { [key: string]: NewPricerColumn },
  fn: any
) => { [key: string]: NewPricerColumn } = (
  obj: { [key: string]: NewPricerColumn },
  fn: any
) =>
  Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]));

export const selectLinkedClientCalls = createSelector(
  [
    (state: RootState) => state.newpricer,
    (state: RootState) => state.clientCalls,
    (state: RootState) => state.spot,
  ],
  (pricer, clientCalls, { sticky_model }) => {
    return Object.entries(pricer.callsToPricerLinks)
      .filter(([callId, link]) => callId.split("_")[1] === "cc")
      .map(([callId, link]) => {
        const call_id = parseInt(callId.split("_")[0]);
        const call = clientCalls[call_id];
        return {
          ...link,
          callHeader: generateClientCallHeader(call, sticky_model),
        };
      });
  }
);

export const selectLinkedRFQCalls = createSelector(
  [
    (state: RootState) => state.newpricer,
    (state: RootState) => state.rfq,
    (state: RootState) => state.spot,
  ],
  (pricer, rfq, { sticky_model }) => {
    return Object.entries(pricer.callsToPricerLinks)
      .filter(([callId, link]) => callId.split("_")[1] === "rfq")
      .map(([callId, link]) => {
        const call_id = parseInt(callId.split("_")[0]);
        const call = rfq[call_id];
        return {
          ...link,
          callHeader: generateRfqHeader(call, sticky_model),
        };
      });
  }
);

export const selectedLinkedCall = createSelector(
  [
    (state: RootState) => state.newpricer,
    (state: RootState) => state.rfq,
    (state: RootState) => state.clientCalls,
  ],
  (pricer, rfq, clientCalls) => {
    const { selectedCall } = pricer;

    const { callId } = selectedCall;
    if (!callId) return { selectedCall: null, call_type: null };
    const call_id = parseInt(callId.split("_")[0]);
    const call_type = callId.split("_")[1];

    if (call_type === "rfq") return { selectedCall: rfq[call_id], call_type };
    return { selectedCall: clientCalls[call_id], call_type };
  }
);

export const selectLinkedCalls = createSelector(
  [
    (state: RootState) => state.newpricer,
    (state: RootState) => state.rfq,
    (state: RootState) => state.clientCalls,
    (state: RootState) => state.spot,
  ],
  (pricer, rfq, clientCalls, { sticky_model }) => {
    return Object.entries(pricer.callsToPricerLinks).map(([callId, link]) => {
      const call_id = parseInt(callId.split("_")[0]);
      const call_type = callId.split("_")[1];
      if (call_type === "rfq") {
        const call = rfq[call_id];
        return {
          ...link,
          callHeader: generateRfqHeader(call, sticky_model),
          callId,
        };
      }
      const call = clientCalls[call_id];
      return {
        ...link,
        callHeader: generateClientCallHeader(call, sticky_model),
        callId,
      };
    });
  }
);

export const newSelectPricerData = createSelector(
  [
    (state: RootState) => state.newpricer,
    (state: RootState) => state.settings.parameters,
    (state: RootState) => state.room,
    (state: RootState) => state.swifts,
    (state: RootState) => state.spot,
  ],
  (pricer, { TICKET_UNIT }, { yield_curves }, swifts, { bestAsk }) => {
    const parseColumns = (column: NewPricerColumn) => {
      const swift = Object.values(swifts)[0];
      const sign = column.direction === BUY ? 1 : -1;
      const { notional } = column;
      if (swift && swift.swift_id) {
        const curves = yield_curves[swift.swift_id] || [];
        if (yield_curves[swift.swift_id.toString()]) {
          return {
            ...column,
            noOfGamma:
              (column.gamma * notional * sign * TICKET_UNIT) /
              (100 / (bestAsk?.price || 1)),
            noOfDelta: column.delta * notional * sign * TICKET_UNIT,
            vegaDollars: column.vega * notional * sign * TICKET_UNIT,
            thetaDollars: column.theta * notional * sign * TICKET_UNIT,
            divYield: parseFloat(curves.variable_yc[column.maturity]),
            interestRates: parseFloat(curves.base_yc[column.maturity]),
          };
        }
      }

      return {
        ...column,
        noOfGamma: (
          (column.gamma * notional * sign * TICKET_UNIT) /
          (100 / (bestAsk?.price || 1))
        ).toString(),
        noOfDelta: (column.delta * notional * sign * TICKET_UNIT).toString(),
        vegaDollars: (column.vega * notional * sign * TICKET_UNIT).toString(),
        thetaDollars: (column.theta * notional * sign * TICKET_UNIT).toString(),
      };
    };

    return objectMap(pricer.pricers, parseColumns);
  }
);
