import axios, { AxiosInstance } from "axios";
import { getRoomData } from "utils/rooms";
import {
  ButterflyValidators,
  CreateRoomPlayer,
  CreateRoomTrainer,
  PriceCurveDTO,
  RFQCall,
  Room,
  TrainerScenarioBE,
  VolChartPointPayload,
  VolatilityCurves,
} from "types";

export default class RoomsService {
  private httpClient: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.httpClient = client;
  }

  async createRoom(
    classId: number,
    players: CreateRoomPlayer[],
    trainers: CreateRoomTrainer[],
    ownerId: number,
    scenario: TrainerScenarioBE
  ): Promise<string> {
    const fswift = parseInt(Object.keys(scenario.swift)[0]);

    const roomData = getRoomData(classId, players, trainers, ownerId, {
      ...scenario,
      swifts: [scenario.swift[fswift]],
    });
    const response = await this.httpClient.post<{ id: string }>(
      "/api/create_room",
      roomData
    );
    return response.data.id;
  }

  async getVolatility(
    roomId: string,
    strike: number,
    tenor: number
  ): Promise<number> {
    const response = await this.httpClient.post<{ volatility: number }>(
      "/api/get_volatility",
      {
        room_id: roomId,
        strike,
        tenor,
        percent: null,
      }
    );
    return response.data.volatility * 100;
  }
  async getPremium(
    call_id: number
  ): Promise<{ strategy_bid: number; strategy_ask: number }> {
    const response = await this.httpClient.post<{
      strategy_bid: number;
      strategy_ask: number;
    }>("/api/get_strategy_price", {
      call_id,
    });
    return response.data;
  }
  async getQuotedPremium(call: RFQCall): Promise<{
    options: { [key: string]: { premium_bid: number; premium_ask: number } };
    strategy_price_bid: number;
    strategy_price_ask: number;
  }> {
    const response = await this.httpClient.post<{
      options: { [key: string]: { premium_bid: number; premium_ask: number } };
      strategy_price_bid: number;
      strategy_price_ask: number;
    }>("/api/get_strategy_price", {
      call,
    });

    return response.data;
  }

  async getRooms(): Promise<Room[]> {
    const response = await this.httpClient.get<Room[]>(
      "/api/get_rooms/ongoing"
    );
    return response.data;
  }
  async getVolatilityCurves(
    room_id: string
  ): Promise<{ [key: string]: { [key: string]: VolChartPointPayload[] } }> {
    const response = await this.httpClient.get<{
      [key: string]: { [key: string]: VolChartPointPayload[] };
    }>(`/api/get_volatility_curves/${room_id}`);

    return response.data;
  }
  async getSpotChartValues(room_id: string): Promise<PriceCurveDTO[]> {
    const response = await this.httpClient.get<PriceCurveDTO[]>(
      `/api/get_price_curves/${room_id}`
    );

    return response.data;
  }
  async getMtM(traderId: string): Promise<any> {
    const response = await this.httpClient.get<any>(
      `/api/get_blotter/${traderId}`
    );

    return response.data;
  }
  async getAvailableButterfly(room_id: string): Promise<ButterflyValidators> {
    const response = await this.httpClient.get<ButterflyValidators>(
      `/api/get_available_butterflies/${room_id}`
    );

    return response.data;
  }

  async pregenerateChartsId(scenario_id: number): Promise<any> {
    const response = await this.httpClient.post<any>(
      `/api/pregenerate_charts`,
      { scenario_id }
    );
    return response.data;
  }
  async downloadChartsId(scenario_id: number): Promise<any> {
    const response = await this.httpClient.post<any>(
      `/api/pregenerate_charts?return_xlsx=True`,
      { scenario_id },
      {
        responseType: 'blob', // Important: It tells Axios to expect a binary response
      }
    );
    return response.data;
  }
}

export const roomsService = new RoomsService(axios);
