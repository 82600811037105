import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, InputNumber, Modal, Row, Tag } from "antd";
import MarketImpactSliders from "./MarketImpactSliders";
import { MarketImpactPayload, VolImpact } from "types";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { useAppDispatch } from "store/store";
import {
  getDirectionConfidenceSpot,
  getDirectionConfidenceVol,
  updateDirectionConfidenceSpot,
  updateDirectionConfidenceVol,
} from "store/events/actions";
import { UndoOutlined } from "@ant-design/icons";
import { Column } from "components/reusable/GenericStyledComponents";

type Props = {
  showSliders: boolean;
  setShowSliders: React.Dispatch<React.SetStateAction<boolean>>;
};

const MarketImpactSlidersModal: React.FC<Props> = ({
  showSliders,
  setShowSliders,
}) => {
  const dispatch = useAppDispatch();
  const swifts = useSelector((state: RootState) => state.swifts);
  const swift_id = Object.keys(swifts)[0];
  const { market_impact, market_impact_loaded } = useSelector(
    (state: RootState) => state.events
  );
  const { sticky_model } = useSelector((state: RootState) => state.spot);
  const [marketImpact, setMarketImpact] =
    useState<MarketImpactPayload>(market_impact);
  const { room_id } = useSelector((state: RootState) => state.room);
  const [spotChange, setSpotChange] = useState<number>(0);

  const [initialMarketImpact, setInitialMarketImpact] =
    useState<MarketImpactPayload>(market_impact);
  const [form] = Form.useForm();

  const onValuesChange = (changedValues: any, allValues: any) => {
    setMarketImpact((prev) => ({ ...prev, ...allValues }));
  };
  useEffect(() => {
    if (showSliders) {
      // setInitialMarketImpact(market_impact);
      setMarketImpact(market_impact);
    }
  }, [market_impact, showSliders]);

  useEffect(() => {
    setMarketImpact(market_impact);
  }, [market_impact]);

  if (!market_impact_loaded) return null;
  return (
    <Modal
      open={showSliders}
      title="Market impact"
      okText="Submit"
      onOk={(e) => {
        // e.preventDefault();
        // e.stopPropagation();
        const currentMarketImpact = form.getFieldsValue(
          true
        ) as MarketImpactPayload;
        dispatch(
          updateDirectionConfidenceSpot({
            swift_id,
            impact: spotChange,
          })
        );

        Object.entries(currentMarketImpact.vol || {}).forEach(
          ([deltaStrike, tenors]) => {
            Object.entries(tenors).forEach(([tenor, newValue]) => {
              const oldValue = initialMarketImpact.vol?.[deltaStrike]?.[tenor];
              if (newValue !== oldValue) {
                dispatch(
                  updateDirectionConfidenceVol({
                    swift_id,
                    impact: newValue,
                    tenor: parseFloat(tenor),
                    delta_strike: sticky_model
                      ? deltaStrike
                      : parseFloat(deltaStrike),
                  })
                );
              }
            });
          }
        );
        setShowSliders(false);
      }}
      onCancel={() => {
        setShowSliders(false);
      }}
      style={{ minWidth: "90vw" }}
      destroyOnClose
    >
      <Button
        onClick={() => {
          dispatch(getDirectionConfidenceSpot({ swift_id, room_id }));
          dispatch(getDirectionConfidenceVol({ swift_id, room_id }));
        }}
      >
        <UndoOutlined />
      </Button>
      <Form form={form} onValuesChange={onValuesChange}>
        <Form.Item style={{ marginBottom: "1rem", marginTop: "1rem" }}>
          <Row gutter={16} align="middle" justify="center">
            <Column
              style={{
                marginRight: "1rem",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Current spot value: {marketImpact.spot.toFixed(2)}
            </Column>
            <Column
              style={{
                marginRight: "1rem",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <InputNumber
                min={-100}
                max={100}
                step={0.5}
                style={{ width: "100%" }}
                value={spotChange}
                onChange={(value) => {
                  setSpotChange(value || 0);
                }}
              />
            </Column>
            <Column
              style={{
                marginRight: "1rem",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Tag color="primary">
                New Spot: {(marketImpact.spot + spotChange).toFixed(2)}
              </Tag>
            </Column>
          </Row>
        </Form.Item>
        <MarketImpactSliders
          marketImpact={marketImpact}
          // setMarketImpact={setMarketImpact}
          form={form}
          sticky_model={sticky_model}
        />
      </Form>
    </Modal>
  );
};

export default MarketImpactSlidersModal;
