import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import styled from "styled-components";
import { Typography } from "antd";

import { Column, Row, Line } from "components/reusable/GenericStyledComponents";
import { RootState } from "store/rootReducer";
import LinkedCalls from "./LinkedCalls";
import NewPricer from "./NewPricer";
import PricerSummary from "./PricerSummary";
import SelectedCall from "./SelectedCall";
import PricerNewSummary from "./PricerNewSummary";

const { Text } = Typography;

const NewPricerSection: React.FC = () => {
  // const cb = window.location.pathname.split("/")[1] === "centralbank";
  const selectedCall = useSelector(
    (state: RootState) => state.newpricer.selectedCall,
    shallowEqual
  );

  return (
    <Container>
      <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
        <Text
          style={{
            fontSize: 14,
            fontWeight: "bold",
            textTransform: "uppercase",
            whiteSpace: "nowrap",
          }}
        >
          Pricer
        </Text>

        <PricerNewSummary/>
        
        {/* <LinkedCalls /> */}
      </div>

      <Line />

      <NewPricer />
      {/* 
      {selectedCall.callId && (
        <>
          <Line />
          <SelectedCall {...selectedCall} />
        </>
      )} */}

      <Line />

      <PricerSummary />
    </Container>
  );
};

const Container = styled.div`
  margin: 1rem;

  & > * {
    margin-bottom: 5px;
  }
`;

export default NewPricerSection;
