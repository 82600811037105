import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

import styled from "styled-components";

import { RootState } from "store/rootReducer";
import { numberWithCommas } from "utils/numbers";
import { Modal, Typography } from "antd";

const PortfolioInfo: React.FC = () => {
  const editing = useSelector((state: RootState) => state.layout.editing);

  const { spotUnrealisedPNL, spotRealisedPNL, positions } = useSelector(
    (state: RootState) => state.trader
  );
  const {
    delta,
    gamma,
    vega,
    theta,
    optRealisedPNL,
    optUnrealisedPNL,
    net_premium,
    market_value,
  } = useSelector(
    (state: RootState) => ({
      delta: state.trader.delta,
      gamma: state.trader.gamma,
      vega: state.trader.vega,
      theta: state.trader.theta,
      optRealisedPNL: state.trader.optRealisedPNL,
      optUnrealisedPNL: state.trader.optUnrealisedPNL,
      net_premium: state.trader.net_premium,
      market_value: state.trader.market_value,
    }),
    shallowEqual
  );
  const swift = useSelector((state: RootState) => state.swifts.selectedSwift);
  const {
    PLAYERS_DELTA_LIMIT,
    PLAYERS_GAMMA_LIMIT,
    PLAYERS_VEGA_LIMIT,
    PLAYERS_THETA_LIMIT,
    PNL_DISPLAY_DIVIDER,
    SINGLE_OR_MULTIPLE_DAYS_TRADING,
  } = useSelector((state: RootState) => state.settings.parameters);

  const [showPnlInfo, setShowPnlInfo] = useState(false);
  // const spotPosition = positions; // / swift?.vol_ticket_unit || 0;

  const limitExceeded = (greek: number, limit: number) => {
    // console.log(`DEGUG GREEK LIMIT ${greek} ${limit} ${Math.abs(greek) > Math.abs(limit)}`);

    return Math.abs(greek) > Math.abs(limit);
  };

  let greekUnit = "";
  if (swift) {
    greekUnit = swift.sticky_model ? "(M)" : "(#S)";
  }
  const [realized_pnl, setRealizedPnl] = useState(
    spotRealisedPNL + optRealisedPNL
  );
  const [unrealized_pnl, setUnrealizedPnl] = useState(
    spotUnrealisedPNL + optUnrealisedPNL
  );

  useEffect(() => {
    setRealizedPnl(spotRealisedPNL + optRealisedPNL);
    setUnrealizedPnl(spotUnrealisedPNL + optUnrealisedPNL);
  }, [
    spotRealisedPNL,
    optRealisedPNL,
    spotUnrealisedPNL,
    optUnrealisedPNL,
    net_premium,
  ]);

  const deltaSum = Number(delta) + Number(positions);

  return (
    <Container
      onClick={(e) => {
        if (editing) e.stopPropagation();
      }}
    >
      <Column>
        <TextRow
          onClick={() => {
            setShowPnlInfo(!showPnlInfo);
          }}
        >
          <Typography.Text style={{ fontSize: 14 }}>
            <GreySpan>rPnL</GreySpan>
          </Typography.Text>
          <Typography.Text style={{ fontSize: 14 }}>
            {numberWithCommas(
              PNL_DISPLAY_DIVIDER
                ? realized_pnl / PNL_DISPLAY_DIVIDER
                : realized_pnl,
              0
            )}
          </Typography.Text>
        </TextRow>

        <TextRow
          onClick={() => {
            setShowPnlInfo(!showPnlInfo);
          }}
        >
          <Typography.Text style={{ fontSize: 14 }}>
            <GreySpan>uPnL</GreySpan>
          </Typography.Text>
          <Typography.Text style={{ fontSize: 14 }}>
            {numberWithCommas(
              PNL_DISPLAY_DIVIDER
                ? unrealized_pnl / PNL_DISPLAY_DIVIDER
                : unrealized_pnl,
              0
            )}
          </Typography.Text>
        </TextRow>
      </Column>

      <Column>
        <Typography.Text style={{ fontSize: 14 }}>
          <GreySpan>Delta {greekUnit}</GreySpan>
        </Typography.Text>
        <Typography.Text
          style={{
            color: limitExceeded(deltaSum, PLAYERS_DELTA_LIMIT)
              ? "#ff7875"
              : "#e1e1e1",
          }}
        >
          {numberWithCommas(deltaSum, 0)}
        </Typography.Text>
      </Column>

      <Column>
        <Typography.Text style={{ fontSize: 14 }}>
          <GreySpan>Gamma {greekUnit}</GreySpan>
        </Typography.Text>
        <Typography.Text
          style={{
            color: limitExceeded(gamma, PLAYERS_GAMMA_LIMIT)
              ? "#ff7875"
              : "#e1e1e1",
          }}
        >
          {numberWithCommas(gamma, 0)}
        </Typography.Text>
      </Column>

      <Column>
        <Typography.Text style={{ fontSize: 14 }}>
          <GreySpan>Vega ($)</GreySpan>
        </Typography.Text>
        <Typography.Text
          style={{
            color: limitExceeded(vega, PLAYERS_VEGA_LIMIT) ? "#ff7875" : "#e1e1e1",
          }}
        >
          {numberWithCommas(vega, 0)}
        </Typography.Text>
      </Column>

      {SINGLE_OR_MULTIPLE_DAYS_TRADING ? (
        <Column>
          <Typography.Text style={{ fontSize: 14 }}>
            <GreySpan>Theta ($)</GreySpan>
          </Typography.Text>
          <Typography.Text
            style={{
              color: limitExceeded(theta, PLAYERS_THETA_LIMIT)
                ? "#ff7875"
                : "#e1e1e1",
            }}
          >
            {numberWithCommas(theta, 0)}
          </Typography.Text>
        </Column>
      ) : null}

      <Modal
        open={showPnlInfo}
        title="Portfolio info"
        onCancel={() => {
          setShowPnlInfo(false);
        }}
        footer={null}
        destroyOnClose
      >
        <AdditionalInfo>
          <TextRow>
            <Typography.Text style={{ fontSize: 14 }}>
              <GreySpan>Opt Unrealised PnL</GreySpan>
            </Typography.Text>
            <Typography.Text style={{ fontSize: 14 }}>
              {numberWithCommas(optUnrealisedPNL)}
            </Typography.Text>
          </TextRow>
          <TextRow>
            <Typography.Text style={{ fontSize: 14 }}>
              <GreySpan>Opt Realised PnL</GreySpan>
            </Typography.Text>
            <Typography.Text style={{ fontSize: 14 }}>
              {numberWithCommas(optRealisedPNL, 0)}
            </Typography.Text>
          </TextRow>
          <TextRow>
            <Typography.Text style={{ fontSize: 14 }}>
              <GreySpan>Spot Unrealised PnL</GreySpan>
            </Typography.Text>
            <Typography.Text style={{ fontSize: 14 }}>
              {numberWithCommas(spotUnrealisedPNL, 0)}
            </Typography.Text>
          </TextRow>
          <TextRow>
            <Typography.Text style={{ fontSize: 14 }}>
              <GreySpan>Spot Realised PnL</GreySpan>
            </Typography.Text>
            <Typography.Text style={{ fontSize: 14 }}>
              {numberWithCommas(spotRealisedPNL, 0)}
            </Typography.Text>
          </TextRow>
          <TextRow>
            <Typography.Text style={{ fontSize: 14 }}>
              <GreySpan>Net premium</GreySpan>
            </Typography.Text>
            <Typography.Text style={{ fontSize: 14 }}>
              {numberWithCommas(net_premium, 0)}
            </Typography.Text>
          </TextRow>
          <TextRow>
            <Typography.Text style={{ fontSize: 14 }}>
              <GreySpan>Market value</GreySpan>
            </Typography.Text>
            <Typography.Text style={{ fontSize: 14 }}>
              {numberWithCommas(market_value, 0)}
            </Typography.Text>
          </TextRow>
        </AdditionalInfo>
      </Modal>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  position: relative;

  & div:nth-child(odd) {
    background: #141414;
  }

  & > div {
    flex: 1;
    padding: 5px 10px;
  }

  & > div:first-child {
    flex: 1.5;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  & > div:nth-last-child(2) {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled(Typography.Text)`
  margin: 0;
  font-size: inherit;
`;

const GreySpan = styled.span`
  color: #bfbfbf;
`;

const TextRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12;
`;

const AdditionalInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  border-radius: 5px;
  // background: ${(p) => p.theme.colors.body};
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
  z-index: 999999999;

  padding: 0 !important;
  & > div {
    padding: 1px 10px;
    z-index: 999999999;
  }

  & > div:nth-child(odd) {
    background: #00000040;
  }
`;

export default PortfolioInfo;
