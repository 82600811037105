import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import StickyDeltaDltskArea from "../StickyDelta/StickyDeltaDltskArea";
import StickyStrikeDltskArea from "../StickyStrike/StickyStrikeDltskArea";

const DltskArea: React.FC = () => {
  const { sticky_model } = useSelector((state: RootState) => state.spot);

  if (sticky_model) {
    return <StickyDeltaDltskArea />;
  }
  return <StickyStrikeDltskArea />;
};

export default DltskArea;
