import { useAppDispatch } from "store/store";
import { NewPricerColumn } from "types";
import {
  pricerSendData,
  removePricer,
  sendVolRequest,
  updatePricerField,
} from "store/newpricer/actions";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { timeUnixToDate } from "utils/time";
import React, { useRef, MutableRefObject } from "react";
import _ from "lodash";
import { roomsService } from "services/rooms.service";

type PricerDebouncers = {
  pricerSendData: {
    [key: number]: any;
  };
  volatility: {
    [key: number]: any;
  };
};

export const usePricerDebouncers: () => PricerDebouncers = () => {
  const dispatch = useAppDispatch();
  const { room_id } = useSelector((state: RootState) => state.room);

  const volatilityWrapper = (id: number) => {
    return async (
      localRoomId: string,
      strike: number,
      maturity: any,
      col: number
    ) => {
      if (maturity)
        dispatch(
          sendVolRequest({
            strike,
            tenor: maturity,
            routing_key: "pricer",
            correlation_id: `${col}`,
          })
        );
    };
  };

  const sendDataWrapper = (id: number) => {
    return (form: NewPricerColumn, stickyModel: number) => {
      if (form.strike && form.maturity && form.notional && room_id !== "") {
        if (stickyModel) {
          const payload = {
            ...form,
            tenor: form.maturity,
            correlationId: id.toString(),
            routing_key: "pricer",
          };
          dispatch(pricerSendData(payload));
        } else {
          const { maturity, ...rest } = form;
          const payload = {
            ...form,
            date_tenor: timeUnixToDate(maturity),
            correlationId: id.toString(),
            routing_key: "pricer",
          };
          dispatch(pricerSendData(payload));
        }
      }
    };
  };

  return {
    pricerSendData: {
      0: useRef(_.throttle(sendDataWrapper(0), 2000)),
      1: useRef(_.throttle(sendDataWrapper(1), 2000)),
      2: useRef(_.throttle(sendDataWrapper(2), 2000)),
      3: useRef(_.throttle(sendDataWrapper(3), 2000)),
    },
    volatility: {
      0: useRef(_.throttle(volatilityWrapper(0), 2000)),
      1: useRef(_.throttle(volatilityWrapper(1), 2000)),
      2: useRef(_.throttle(volatilityWrapper(2), 2000)),
      3: useRef(_.throttle(volatilityWrapper(3), 2000)),
    },
  };
};
