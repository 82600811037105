import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";

import styled from "styled-components";

import CentralBankNav from "components/CentralBank/CentralBankNav";
import CBTraders from "components/CentralBank/CBTraders";
import CBParameters from "components/CentralBank/CBParameters";
import CBRoomCommandLine from "components/CentralBank/CBRoomCommandLine";
import CBMacro from "components/CentralBank/CBMacro";
import PausedModal from "components/Modal/PausedModal";
import GameRoomHeader from "components/GameRoom/GameRoomHeader";
import NewsTicker from "components/GameRoom/NewsTicker";

import { useAppDispatch } from "store/store";
import { useConnectToWS } from "hooks/useConnectToWS";
import { RootState } from "store/rootReducer";
import {
  clearRoom,
  connectToRoom,
  requestInitialData,
} from "store/room/actions";
import {
  getTrainerHistoryRooms,
  getTrainerLiveRooms,
  setTraderStats,
} from "store/trainer/reducers";
import { trainerService } from "services";
import { requestCBState } from "store/centralbank/actions";
import RoomTimeProvider from "contexts/RoomTimeProvider";
import { getFromLS, setToLS } from "utils";
import LoadingSpinner from "components/reusable/LoadingSpinner";
import { Column, Row } from "components/reusable/GenericStyledComponents";
import GameControls from "components/CentralBank/GameControls/GameControls";
import CBLeaderboard from "components/CentralBank/CBLeaderboard";
import GameLayout from "components/GameRoom/GameLayout";
import Game from "components/GameRoom/Game";

const initNav = () => {
  const nav = getFromLS("cbnav");
  return nav !== null ? nav : 0;
};

const CentralBank: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  useConnectToWS(id);
  const dispatch = useAppDispatch();
  const [selectedNav, setSelectedNav] = useState(initNav());
  const changeNav = (idx: number) => {
    setToLS("cbnav", idx);
    setSelectedNav(idx);
  };
  const {
    settings: { parameters: settings },
    cb_state_loaded,
    parameter_tags,
  } = useSelector((state: RootState) => state.centralbank);

  const { connected: socketConnected } = useSelector(
    (state: RootState) => state.socket
  );
  const { connected: roomConnected, status } = useSelector(
    (state: RootState) => state.room
  );

  const fetchTraders = async () => {
    const traders = await trainerService.getAllTraders(id);
    if (traders !== undefined) {
      // const traderRows = traders.map((t)=> {t.name, t.online,})
      dispatch(setTraderStats(traders));
    }
  };

  useEffect(() => {
    if (socketConnected && !roomConnected && status !== 3) {
      dispatch(connectToRoom(id));
    }

    if (socketConnected && roomConnected) {
      // dispatch(resumeRoom());
      dispatch(requestInitialData());
      dispatch(requestCBState());
      fetchTraders();
    }
  }, [socketConnected, roomConnected]);

  // clear state after unmount
  useEffect(() => {
    return () => {
      dispatch(clearRoom());
    };
  }, []);

  if (!socketConnected)
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoadingSpinner message="Connecting to services..." />
      </div>
    );

  if (!settings || !cb_state_loaded)
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoadingSpinner message="Room data is loading..." />
      </div>
    );

  return (
    <RoomTimeProvider>
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100vh",
        }}
      >
        <CentralBankNav activeNav={selectedNav} changeNav={changeNav} />

        <div style={{ width: "100%", height: "100%", overflowY: "auto" }}>
          {selectedNav === 0 && (
            <Column style={{ gap: "2rem", padding: "1.5rem" }}>
              <CBRoomCommandLine />
              <CBTraders />
            </Column>
          )}

          {selectedNav === 1 && (
            <Column style={{ gap: "2rem", padding: "1.5rem" }}>
              <CBRoomCommandLine />
              <CBLeaderboard />
            </Column>
          )}

          {selectedNav === 2 && (
            <Column
              style={{
                padding: "1.5rem",
                gap: "2rem",
              }}
            >
              <CBRoomCommandLine />
              <CBParameters
                settings={settings}
                parameter_tags={parameter_tags}
              />
            </Column>
          )}

          {selectedNav === 3 && (
            <Column style={{ padding: "1.5rem", gap: "2rem" }}>
              <CBRoomCommandLine />
              <CBMacro />
            </Column>
          )}

          {selectedNav === 4 && (
            <GameContainer>
              <GameRoomHeader />
              <NewsTicker />
              {/* <Game /> */}
              <GameLayout />
              <PausedModal />
            </GameContainer>
          )}

          {selectedNav === 5 && (
            <GameContainer>
              <GameRoomHeader />
              <GameControls />
              <PausedModal />
            </GameContainer>
          )}
        </div>
      </div>
    </RoomTimeProvider>
  );
};

const GameContainer = styled.div`
  padding: 10px 15px;
`;

export default CentralBank;
