import React, { ComponentType } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";

interface WithStickyModelProps {
  sticky_model?: number; // sticky strike 0 | sticky delta 1
}

export const withStickyModel = <StickyDeltaProps, StickyStrikeProps>(
  ComponentStickyDelta: ComponentType<StickyDeltaProps>,
  ComponentStickyStrike: ComponentType<StickyStrikeProps>
): ComponentType<
  (StickyDeltaProps | StickyStrikeProps) & WithStickyModelProps
> => {
  const WrappedComponent: React.FC<
    (StickyDeltaProps | StickyStrikeProps) & WithStickyModelProps
  > = (props) => {
    const { sticky_model: providedStickyModel, ...rest } = props;
    const { sticky_model } = useSelector((state: RootState) => state.spot);
    const currentModel =
      providedStickyModel !== undefined ? providedStickyModel : sticky_model;
    return currentModel === 1 ? (
      <ComponentStickyDelta {...(rest as StickyDeltaProps)} />
    ) : (
      <ComponentStickyStrike {...(rest as StickyStrikeProps)} />
    );
  };

  WrappedComponent.displayName = `withStickyModel(${
    ComponentStickyDelta.displayName || ComponentStickyDelta.name
  }, ${ComponentStickyStrike.displayName || ComponentStickyStrike.name})`;

  return WrappedComponent;
};
