import { InfoCircleOutlined } from "@ant-design/icons";
import { Switch, Typography, Tooltip } from "antd";
import React from "react";
import { MATURITY, TrainerScenarioBE } from "types";
import { withStickyModel } from "utils/withStickyModel";
import styled from "styled-components";

type ComponentProps = {
  modifiedScenario: TrainerScenarioBE;
  setModifiedScenario: React.Dispatch<React.SetStateAction<TrainerScenarioBE>>;
};

const ActiveTenorsStickyDelta: React.FC<ComponentProps> = ({
  modifiedScenario,
  setModifiedScenario,
}) => {
  return (
    <Container>
      <Typography.Title level={5} style={{ fontWeight: "bold" }}>
        Tenors
      </Typography.Title>

      <Wrapper>
        <div>
          <Tooltip title="Choose what tenors are available for trading in the electronic brokers.">
            <Typography.Title level={5}>
              Tradable vols <InfoCircleOutlined />
            </Typography.Title>
          </Tooltip>

          <div>
            {React.Children.toArray(
              modifiedScenario.relative_tenors.map((tenor, idx) => {
                return (
                  <Group>
                    <Switch
                      checked={modifiedScenario.visible_vol_eb_tenors[idx]}
                      checkedChildren="ACTIVE"
                      unCheckedChildren="DISABLED"
                      onChange={(checked) => {
                        const checkedCount =
                          modifiedScenario.visible_vol_eb_tenors.filter(
                            (t) => !!t
                          ).length;

                        // If trying to uncheck the last item, prevent it.
                        if (!checked && checkedCount === 1) {
                          return;
                        }
                        const newTenors =
                          modifiedScenario.visible_vol_eb_tenors.map(
                            (value, i) => (i === idx ? checked : value)
                          );
                        setModifiedScenario({
                          ...modifiedScenario,
                          visible_vol_eb_tenors: newTenors,
                        });
                      }}
                    />

                    <span>
                      {MATURITY[modifiedScenario.relative_tenors[idx]]}
                    </span>
                  </Group>
                );
              })
            )}
          </div>
        </div>

        <div>
          <Tooltip title="Choose what tenors are available for trading in all calls.">
            <Typography.Title level={5}>
              All calls <InfoCircleOutlined />
            </Typography.Title>
          </Tooltip>

          <div>
            {React.Children.toArray(
              modifiedScenario.relative_tenors.map((tenor, idx) => {
                return (
                  <Group>
                    <Switch
                      checked={modifiedScenario.visible_tenors[idx]}
                      checkedChildren="ACTIVE"
                      unCheckedChildren="DISABLED"
                      onChange={(checked) => {
                        const checkedCount =
                          modifiedScenario.visible_tenors.filter(
                            (t) => !!t
                          ).length;

                        // If trying to uncheck the last item, prevent it.
                        if (!checked && checkedCount === 1) {
                          return;
                        }
                        const newTenors = modifiedScenario.visible_tenors.map(
                          (value, i) => (i === idx ? checked : value)
                        );
                        setModifiedScenario({
                          ...modifiedScenario,
                          visible_tenors: newTenors,
                        });
                      }}
                    />
                    <span>
                      {MATURITY[modifiedScenario.relative_tenors[idx]]}
                    </span>
                  </Group>
                );
              })
            )}
          </div>
        </div>
      </Wrapper>
    </Container>
  );
};

const ActiveTenorsStickyStrike: React.FC<ComponentProps> = ({
  modifiedScenario,
  setModifiedScenario,
}) => {
  return (
    <Container>
      <Typography.Title level={5} style={{ fontWeight: "bold" }}>
        Tenors
      </Typography.Title>

      <Wrapper>
        <div>
          <Tooltip title="Choose what tenors are available for trading in the electronic brokers.">
            <Typography.Title level={5}>
              Tradable vols <InfoCircleOutlined />
            </Typography.Title>
          </Tooltip>

          <div>
            {React.Children.toArray(
              modifiedScenario.visible_vol_eb_tenors.map((tenor, idx) => {
                return (
                  <Group>
                    <Switch
                      checked={modifiedScenario.visible_vol_eb_tenors[idx]}
                      checkedChildren="ACTIVE"
                      unCheckedChildren="DISABLED"
                      onChange={(checked) => {
                        const checkedCount =
                          modifiedScenario.visible_vol_eb_tenors.filter(
                            (t) => !!t
                          ).length;

                        // If trying to uncheck the last item, prevent it.
                        if (!checked && checkedCount === 1) {
                          return;
                        }
                        const newTenors =
                          modifiedScenario.visible_vol_eb_tenors.map(
                            (value, i) => (i === idx ? checked : value)
                          );
                        setModifiedScenario({
                          ...modifiedScenario,
                          visible_vol_eb_tenors: newTenors,
                        });
                      }}
                    />
                    <span>{`+${(idx + 1) * 3}M`}</span>
                  </Group>
                );
              })
            )}
          </div>
        </div>

        <div>
          <Tooltip title="Choose what tenors are available for trading when banks or clients request for quotes. To increase difficulty, you can choose more tenors for all calls vs. tradable vols tenors.">
            <Typography.Title level={5}>
              All calls <InfoCircleOutlined />
            </Typography.Title>
          </Tooltip>

          <div>
            {React.Children.toArray(
              modifiedScenario.visible_tenors.map((tenor, idx) => {
                return (
                  <Group>
                    <Switch
                      checked={modifiedScenario.visible_tenors[idx]}
                      checkedChildren="ACTIVE"
                      unCheckedChildren="DISABLED"
                      onChange={(checked) => {
                        const checkedCount =
                          modifiedScenario.visible_tenors.filter(
                            (t) => !!t
                          ).length;

                        // If trying to uncheck the last item, prevent it.
                        if (!checked && checkedCount === 1) {
                          return;
                        }
                        const newTenors = modifiedScenario.visible_tenors.map(
                          (value, i) => (i === idx ? checked : value)
                        );
                        setModifiedScenario({
                          ...modifiedScenario,
                          visible_tenors: newTenors,
                        });
                      }}
                    />
                    <span>{`+${(idx + 1) * 3}M`}</span>
                  </Group>
                );
              })
            )}
          </div>
        </div>
      </Wrapper>
    </Container>
  );
};

const ActiveTenors = withStickyModel<ComponentProps, ComponentProps>(
  ActiveTenorsStickyDelta,
  ActiveTenorsStickyStrike
);

const Container = styled.div`
  margin-top: 1rem;
`;

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
`;

const Group = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 0.5rem;
`;

export default ActiveTenors;
