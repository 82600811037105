import {
  GenericSellBuyButton,
  Row,
} from "components/reusable/GenericStyledComponents";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { SimpleEbOrder } from "types";
import { BUY, SELL } from "stateConstants";
import { spotHitOrder } from "store/spot/actions";
import { useAppDispatch } from "store/store";
import { numberWithCommas } from "utils/numbers";

type Props = {
  order: SimpleEbOrder | null;
  type: "Sell" | "Buy";
};

const SpotButtons: React.FC<Props> = ({ order, type }) => {
  const dispatch = useAppDispatch();

  const { spotQuantity: quantity, validSpotQuantity } = useSelector(
    (state: RootState) => ({
      spotQuantity: state.spot.spotQuantity,
      validSpotQuantity: state.spot.validSpotQuantity,
    }),
    shallowEqual
  );

  const handleButtonPress = () => {
    // Spot
    if (!order) return;
    dispatch(
      spotHitOrder({
        price: order.price,
        amount: quantity,
        direction: type === "Sell" ? SELL : BUY,
      })
    );
  };

  return (
    <Row style={{ flex: "1" }}>
      <GenericSellBuyButton
        disabled={!validSpotQuantity}
        onClick={handleButtonPress}
      >
        {`${type} ${numberWithCommas(quantity)} X Spot`}
      </GenericSellBuyButton>
    </Row>
  );
};

export default SpotButtons;
