import { BUY, CALL, LEFT, PUT, RIGHT, SELL } from "stateConstants";

export type NewPricerState = {
  pricers: {
    [key: string]: NewPricerColumn;
  };
  nbCols: number;
  largePricer: boolean;
  selectedCall: { callId: string; color: string };
  callsToPricerLinks: { [key: string]: { pricers: string[]; color: string } };
  pricerToCallsLinks: { [key: string]: { call_id: string; color: string } };
  available_colors: string[];
};

export type NewPricerColumn = {
  linked_to: string; // ${id}_${"cc"/"rfq"}
  correlation_id: number;
  direction: number;
  type: number;
  notional: number;
  maturity: number;
  strike: number;
  spot: number;
  interestRates: number;
  divYield: number;
  forward: number;
  volatility: number;
  price: number;
  pricePercentOfSpot: number;
  premium: number;

  delta: number;
  gamma: number;
  vega: number;
  theta: number;

  noOfDelta: number;
  noOfGamma: number;
  vegaDollars: number;
  call_theta: number;
  put_theta: number;

  lockVol: number;
};

export type CommonPayloadRFQ = {
  type: number;
  notional: number;
  maturity: number;
  strike: number;
};

export type CommonPayloadCC = {
  direction: number;
  type: number;
  notional: number;
  maturity: number;
  strike: number;
};

export type SetPricerPayload = {
  correlation_id: number;
  common: CommonPayloadRFQ | CommonPayloadCC;
};

export interface PricerParameters {
  spot: number;
  vol: number;
  strike: number;
  tenor: number;
  date_tenor: string;
  rfr: number;
  dvd: number;
  notional: number;
  forward: number;
}

export interface SendPricerDataRequest {
  user_routing_key: string;
  pricer_parameters: Omit<PricerParameters, "forward">;
  correlation_id: string;
  routing_key: string;
}

export enum Maturity {
  "1W",
  "2W",
  "1M",
  "2M",
  "3M",
  "6M",
  "9M",
  "1Y",
  "2Y",
  "3Y",
  "4Y",
  "5Y",
  "6Y",
  "7Y",
  "10Y",
}

export enum MATURITY {
  "1W" = 5,
  "2W" = 10,
  "1M" = 21,
  "2M" = 42,
  "3M" = 63,
  "6M" = 126,
  "9M" = 189,
  "1Y" = 252,
  "2Y" = 504,
  "3Y" = 756,
  "4Y" = 1008,
  "5Y" = 1260,
  "6Y" = 1512,
  "7Y" = 1764,
  "10Y" = 2520,
}

export type PricerUpdateDataPayload = {
  type: "pricer/getPricerData";
  price_data: {
    correlation_id: string;
    d1: number;
    d2: number;
    call_delta: number;
    put_delta: number;
    gamma: number;
    vega: number;
    call_theta: number;
    put_theta: number;
    forward: number;
    call_price: number;
    put_price: number;
    call_price_as_percentage_of_spot: number;
    put_price_as_percentage_of_spot: number;
    premium: {
      call: number;
      put: number;
    };
    trader_id: string;
  };
};

export type RequestVolatilityPayload = {
  correlation_id: string;
  routing_key: string;
  strike: number;
  tenor: number;
};

export type NewPricerSendPayload = Pick<
  NewPricerColumn,
  | "direction"
  | "type"
  | "notional"
  | "maturity"
  | "strike"
  | "volatility"
  | "divYield"
  | "interestRates"
> & {
  divYield: number;
  interestRates: number;
  correlationId: string;
  date_tenor?: string;
  routing_key: string;
};

export type PricerUpdateFromHooksPayload = {
  partialState: Partial<NewPricerColumn>;
  correlationId: number;
};

export type NewPricerUpdateFromHooksPayload = {
  partialState: any;
  correlationId: number;
};

export type GetVolatilityPayload = {
  value: string;
  routing_key: string;
  correlation_id: string;
};
