import React from "react";
import { useSelector } from "react-redux";

import styled from "styled-components";

import { RootState } from "store/rootReducer";
import OptionsBlotterTable from "./OptionsBlotterTable";
import { Column } from "components/reusable/GenericStyledComponents";

const OptionsBlotter: React.FC = () => {
  const options_blotter = useSelector(
    (state: RootState) => state.blotter.options_blotter
  );

  return (
    <Container>
      <OptionsBlotterTable options={options_blotter} />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export default React.memo(OptionsBlotter);
