import { PayloadAction } from "@reduxjs/toolkit";
import { put, takeEvery } from "redux-saga/effects";
import { socketSendMessage } from "store/socket/actions";
import { visibleRestConfirmation, visibleVolEbConfirmation } from "./actions";

function* updateTenorsRestSaga({
  payload,
}: PayloadAction<{ pattern: number[] }>) {
  const request = {
    headers: {
      command: "tenors.cmd.visible.update",
    },
    ...payload,
  };
  yield put(socketSendMessage(JSON.stringify(request)));
}

function* updateTenorsVolEbSaga({
  payload,
}: PayloadAction<{ pattern: number[] }>) {
  const request = {
    headers: {
      command: "tenors.cmd.vol_eb_visible.update",
    },
    ...payload,
  };
  yield put(socketSendMessage(JSON.stringify(request)));
}

function* visibleRestConfirmationSaga({
  payload,
}: PayloadAction<{ pattern: number[]; swift_id: number }>) {
  yield put(visibleRestConfirmation(payload));
}

function* visibleVolEbConfirmationSaga({
  payload,
}: PayloadAction<{ pattern: number[]; swift_id: number }>) {
  yield put(visibleVolEbConfirmation(payload));
}

export function* watchActiveTenorsAsync() {
  yield takeEvery("tenors/updateTenorsRest", updateTenorsRestSaga);
  yield takeEvery("tenors/updateTenorsVolEb", updateTenorsVolEbSaga);
  yield takeEvery("tenors/VisibleUpdated", visibleRestConfirmationSaga);
  yield takeEvery("tenors/VisibleVolEbUpdated", visibleVolEbConfirmationSaga);
}
