import { put, select, takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  User,
  SendPricerDataRequest,
  WebSocketRequestWithBody,
  SimpleEbOrder,
  NewPricerSendPayload,
  RequestVolatilityPayload,
} from "types";
import { CALL } from "stateConstants";
import { RootState } from "../rootReducer";
import { socketSendMessage } from "../socket/actions";
import { PRICER_SEND_DATA } from "./constants";

function* sendPricerData({ payload }: PayloadAction<NewPricerSendPayload>) {
  const {
    type,
    correlationId,
    volatility,
    strike,
    maturity,
    divYield,
    notional,
    interestRates,
    date_tenor,
    routing_key,
  } = payload;
  const userData: User = yield select((state: RootState) => state.user);
  const {
    bestAsk,
    bestBid,
  }: { bestBid: SimpleEbOrder; bestAsk: SimpleEbOrder } = yield select(
    (state: RootState) => state.spot
  );
  const spotValue = type === CALL ? bestAsk?.price : bestBid?.price;
  const request: WebSocketRequestWithBody<SendPricerDataRequest> = {
    headers: {
      room_id: userData.roomId,
      user_id: Number(userData.persistentId),
      command: "compute.event.option.price",
      request: "",
      request_id: null,
    },
    body: {
      user_routing_key: userData.username,
      correlation_id: correlationId,
      routing_key,
      pricer_parameters: {
        spot: spotValue || 0,
        vol: volatility / 100,
        strike,
        tenor: maturity,
        date_tenor: date_tenor || "",
        rfr: divYield,
        dvd: interestRates,
        notional,
      },
    },
  };

  yield put(socketSendMessage(JSON.stringify(request)));
}

function* sendVolRequest({ payload }: PayloadAction<RequestVolatilityPayload>) {
  const request = {
    headers: {
      command: "volatility.cmd.value.get",
    },
    ...payload,
  };

  yield put(socketSendMessage(JSON.stringify(request)));
}

export function* watchNewPricerAsync() {
  yield takeEvery("pricer/sendData", sendPricerData);
  yield takeEvery("pricer/sendVolRequest", sendVolRequest);
}
