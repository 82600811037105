import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthState } from "types";

const initialState: AuthState = {
  error: "",
};

const authSlice = createSlice({
  initialState,
  name: "auth",
  reducers: {
    userFailAuth(state, action: PayloadAction<{ error: string }>) {
      state.error = action.payload.error;
    },
  },
});

export const { userFailAuth } = authSlice.actions;
export default authSlice.reducer;
