import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { Modal } from "antd";

import { RootState } from "store/rootReducer";
import { MacroEvent } from "types";
import Background from "assets/news-history-background.png";
import { ModalProps } from "hooks/useModal";
import LoadingSpinner from "components/reusable/LoadingSpinner";
import { Row } from "components/reusable/GenericStyledComponents";
import CustomRating, {
  ReadOnlyRatingDisplay,
} from "components/GameRoom/CustomRating";

type Props = ModalProps;

const NewsHistoryModal: React.FC<Props> = ({ hide, open }) => {
  const { news, impact_votes } = useSelector(
    (state: RootState) => state.events
  );
  const [selectedNews, setSelectedNews] = useState<MacroEvent | undefined>(
    news[0]
  );
  const { swifts } = useSelector((state: RootState) => state.spot);

  useEffect(() => {
    if (!selectedNews && news.length > 0) {
      setSelectedNews(news[0]);
    }
    if (!news.length) {
      setSelectedNews(undefined);
    }
  }, [news]);

  if (!swifts[0]) return <LoadingSpinner />;

  return (
    <Modal
      title="News History"
      open={open}
      onCancel={hide}
      footer={null}
      width={1000}
    >
      <Container>
        <CardList>
          {news.map((newsEvent) => (
            <Card
              key={newsEvent.id}
              selected={newsEvent.id === selectedNews?.id}
              onClick={() => setSelectedNews(newsEvent)}
            >
              <CardTitle>{newsEvent.headline}</CardTitle>
              <CardDescription>{newsEvent.body}</CardDescription>
              {impact_votes[newsEvent.id] &&
              impact_votes[newsEvent.id][swifts[0].swift_id] ? (
                <CardVotes>
                  <div>
                    Spot:{" "}
                    <ReadOnlyRatingDisplay
                      value={
                        impact_votes[newsEvent.id][swifts[0].swift_id].spot || 0
                      }
                    />
                  </div>
                  <div>
                    Vol:{" "}
                    <ReadOnlyRatingDisplay
                      value={
                        impact_votes[newsEvent.id][swifts[0].swift_id].vol || 0
                      }
                    />
                  </div>
                </CardVotes>
              ) : (
                <CardVotes>Not voted</CardVotes>
              )}
            </Card>
          ))}
        </CardList>

        <Displayer>
          {selectedNews && (
            <>
              <DisplayerTitle>{selectedNews.headline}</DisplayerTitle>
              <DisplayerDescription>{selectedNews.body}</DisplayerDescription>
              {/* <DisplayerDescription>
                {selectedNews.trader_spot_votes[swifts[0].swift_id].correct
                  ? "Correct"
                  : "Incorrect"}
              </DisplayerDescription> */}
            </>
          )}

          {!selectedNews && (
            <DisplayerTitle>There is no news available</DisplayerTitle>
          )}
        </Displayer>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-rows: minmax(0, 100%);
  grid-template-columns: 6fr 6fr;
  gap: 20px;
`;

const CardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
  max-height: 500px;
`;

const Card = styled.div<{ selected: boolean }>`
  padding: 0 10px;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: transparent;
  cursor: pointer;

  border-left: 1px solid
    ${(p) => {
      return p.selected ? p.theme.primary : "transparent";
    }};
`;

const TwoLineClamp = styled.p`
  display: -webkit-box;
  -webkit-box-orient: VERTICAL;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;

  &:not(:last-child) {
    margin: 0 0 5px 0;
  }
`;

const CardTitle = styled(TwoLineClamp)`
  font-size: 14px;
  font-weight: 500;
  color: #a0a7ab;
`;

const CardDescription = styled(TwoLineClamp)`
  font-size: 13px;
  font-weight: 300;
  font-family: IBMPlexSans, sans-serif;
  color: #777c80;
  display: -webkit-box;
  -webkit-box-orient: VERTICAL;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Displayer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
`;

const DisplayerTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: ${(p) => p.theme.primary};
  font-family: IBMPlexSans, sans-serif;
`;

const DisplayerDescription = styled.div`
  font-size: 16px;
  font-weight: 400;
  font-family: Roboto, sans-serif;
`;

const CardVotes = styled(Row)`
  gap: 0.5rem;
  font-size: 13px;
  font-weight: 300;
  font-family: IBMPlexSans, sans-serif;
  color: #777c90;
`;

export default NewsHistoryModal;
