import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useAppDispatch } from "store/store";
import { RootState } from "store/rootReducer";
import { numberWithCommas, percent2Digits, precision } from "utils/numbers";
import {
  setDeltaHedge,
  setSelectedSellTenor,
  setSelectedBuyTenor,
} from "store/spot/reducers";
import styled, { css } from "styled-components";
import { Tenor } from "types";
import { timeUnixToDatePretty } from "utils/time";
import { Skeleton, Typography } from "antd";

type Props = {
  tenor: number;
  delta_strike_values: {
    [key: number]: {
      sellVolume: number;
      buyVolume: number;
      sell: number;
      buy: number;
      buyTraders: string[];
      sellTraders: string[];
    };
  };
  traderId: string;
  type: "Sell" | "Buy";
};

const VolatilityRows: React.FC<Props> = ({
  tenor,
  delta_strike_values,
  traderId,
  type,
}) => {
  const dispatch = useAppDispatch();
  const { selectedDelta, selectedSellTenor, selectedBuyTenor, sticky_model } =
    useSelector(
      (state: RootState) => ({
        selectedDelta: state.spot.selectedDelta,
        selectedSellTenor: state.spot.selectedSellTenor,
        selectedBuyTenor: state.spot.selectedBuyTenor,
        sticky_model: state.spot.sticky_model,
      }),
      shallowEqual
    );
  const isSell = type === "Sell";
  const pylon = delta_strike_values[selectedDelta];
  const traders = isSell ? pylon?.sellTraders || [] : pylon?.buyTraders || [];
  const selectedLine = isSell ? selectedSellTenor : selectedBuyTenor;
  if (!pylon || !pylon.sell || !pylon.buy) return null;

  return (
    <TVolsRow
      type={type}
      selected={tenor === selectedLine}
      onClick={() => {
        const setSelectedTenor = isSell
          ? setSelectedSellTenor
          : setSelectedBuyTenor;
        if (sticky_model) dispatch(setSelectedTenor(tenor));
        else dispatch(setSelectedTenor(tenor));
      }}
    >
      <TVolItem color={traders.includes(traderId) ? "#896e0f" : ""}>
        <Typography.Text>
          {sticky_model ? Tenor[tenor] : timeUnixToDatePretty(tenor)}
        </Typography.Text>
      </TVolItem>
      <TVolItem color={traders.includes(traderId) ? "#896e0f" : ""}>
        <Typography.Text>
          {isSell
            ? `${numberWithCommas(pylon?.sellVolume || 666)} x`
            : `x ${numberWithCommas(pylon?.buyVolume || 666)}`}
        </Typography.Text>
      </TVolItem>

      <TVolItem color={traders.includes(traderId) ? "#896e0f" : ""}>
        <Typography.Text>
          {percent2Digits(isSell ? pylon?.sell || 666 : pylon?.buy || 666)}
        </Typography.Text>
      </TVolItem>
    </TVolsRow>
  );
};

const TVolsRow = styled.div<{ selected: boolean; type: Props["type"] }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1px 10px;
  cursor: pointer;

  ${(props) => {
    if (props.selected) {
      return css`
        border: 1px solid ${(p) => p.theme.primary};
      `;
    }
    return css`
      border: 1px solid transparent;
    `;
  }};

  ${(props) => {
    if (props.type === "Buy") {
      return css`
        flex-direction: row-reverse;
        text-align: right;
      `;
    }
  }}

  &:hover {
    background: #1f1f1f;
  }
`;

const TVolItem = styled.div<{ color?: string }>`
  ${(props) => {
    if (props.color) {
      return css`
        color: ${props.color};
      `;
    }
  }}

  &:not(:last-child) {
    flex: 1;
  }
`;

export default VolatilityRows;
