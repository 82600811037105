import React from "react";
import Modal from "react-modal";
import "./Modal.css";

interface IGenericModalProps {
  isShown: boolean;
  customClassName?: string;
  customOverlayClassName?: string;
  overlayBackground?: string;
  onRequestClose?: () => void;
}

const GenericModal: React.FC<IGenericModalProps> = ({
  isShown,
  customClassName,
  customOverlayClassName,
  children,
  overlayBackground,
  onRequestClose,
}) => (
  <Modal
    overlayClassName={
      customOverlayClassName
        ? {
            base: customOverlayClassName,
            afterOpen: `${customOverlayClassName}--after-open`,
            beforeClose: `${customOverlayClassName}--before-close`,
          }
        : undefined
    }
    className={
      customClassName
        ? {
            base: customClassName,
            afterOpen: `${customClassName}--after-open`,
            beforeClose: `${customClassName}--before-close`,
          }
        : undefined
    }
    isOpen={isShown}
    style={{
      content: {
        position: "relative",
        overflow: "hidden",
        inset: 0,
        backgroundColor: "#1f1f1f",
        zIndex: 500,
        outline: "none",
      },
      overlay: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: overlayBackground || "#21292E",
        zIndex: 5,
        outline: "none",
      },
    }}
    closeTimeoutMS={300}
    onRequestClose={onRequestClose || (() => {})}
  >
    {children}
  </Modal>
);

export default GenericModal;
