import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { numberWithCommas } from "utils/numbers";
import { Checkbox, Table, Tabs, Typography } from "antd";
import type { ColumnsType } from "antd/es/table";
import { Permission, TraderStat } from "types";
import CBMetrics from "./CBMetrics";
import { Column } from "components/reusable/GenericStyledComponents";

const CBLeaderboard: React.FC = () => {
  const leaderboard = useSelector(
    (state: RootState) => state.centralbank.leaderboard
  );
  const PNL_DISPLAY_DIVIDER = useSelector(
    (state: RootState) => state.settings.parameters.PNL_DISPLAY_DIVIDER
  );

  const permission = useSelector((state: RootState) => state.user.permission);
  const [filterActive, setFilterActive] = useState<boolean>(false);

  console.log({ leaderboard });

  const [rows, setRows] = useState<TraderStat[]>([]);

  useEffect(() => {
    if (!leaderboard) {
      return;
    }

    setRows(
      leaderboard
        // .filter((t: any) => t.pnl_percentile_ranking !== 0)
        .map((trader: any) => {
          return {
            ...trader,
            realized_pnl: trader.realized_pnl / PNL_DISPLAY_DIVIDER || 0,
            unrealized_pnl: trader.unrealized_pnl / PNL_DISPLAY_DIVIDER || 0,
            pnl: trader.pnl / PNL_DISPLAY_DIVIDER || 0,
          };
        })
    );
  }, [leaderboard]);

  if (!leaderboard) {
    return (
      <div>
        <Tabs defaultActiveKey="table" size="small">
          <Tabs.TabPane tab="Leaderboard" key="table">
            <Typography.Title level={5} style={{ textAlign: "center" }}>
              The leaderboard will be displayed after the session is finished!
            </Typography.Title>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Leaderboard metrics" key="metrics">
            <CBMetrics />
          </Tabs.TabPane>
        </Tabs>
      </div>
    );
  }

  return (
    <Column>
      {PNL_DISPLAY_DIVIDER > 1 && (
        <Typography.Paragraph>
          Note: The PnL values are divided by {PNL_DISPLAY_DIVIDER} (
          PNL_DISPLAY_DIVIDER parameter )
        </Typography.Paragraph>
      )}
      <Tabs defaultActiveKey="table" size="small">
        <Tabs.TabPane tab="Leaderboard" key="table">
        <Checkbox checked={filterActive} onChange={(e) => {setFilterActive(!filterActive)}}>Only show active traders</Checkbox>
          <Table
            bordered
            sticky
            loading={!!rows && Object.keys(rows).length === 0}
            rowKey="name"
            columns={columns(permission)}
            dataSource={filterActive ? rows.filter((row)=>row.trader_was_active) :  rows}
            pagination={false}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Leaderboard metrics" key="metrics">
          <CBMetrics />
        </Tabs.TabPane>
      </Tabs>
    </Column>
  );
};

const columns: (permission: Permission) => ColumnsType<TraderStat> = (
  permission
) => [
  // {
  //     dataIndex: "online",
  //     title: "Online",
  //     width: "100px",
  //     render: (online) => (online ? <GreenCircle /> : <RedCircle />),
  //     sorter: (a, b) => (a.online ? 1 : 0),
  //     filters: [
  //         {
  //             text: "Online",
  //             value: true,
  //         },
  //         {
  //             text: "Offline",
  //             value: false,
  //         },
  //     ],
  //     onFilter: (value: string | number | boolean, record: TraderStat) => {
  //         return record.online === value;
  //     },
  // },
  {
    dataIndex: "name",
    title: "Trader name",
    sorter: (a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    },
  },
  // {
  //   dataIndex: "pnl_percentile_ranking",
  //   title: "Ranking percentile",
  // },
  // {
  //     dataIndex: "client_deals",
  //     title: "Client deals",
  // },
  // {
  //     dataIndex: "client_volume",
  //     title: "Client deals",
  // },
  // {
  //   dataIndex: "unrealized_pnl",
  //   title: "Unrealized PnL",
  //   render: (v) => numberWithCommas(v, 0),
  // },
  // {
  //   dataIndex: "realized_pnl",
  //   title: "Realized PnL",
  //   render: (v) => numberWithCommas(v, 0),
  // },
  // {
  //   dataIndex: "pnl",
  //   title: "PnL",
  //   render: (v) => numberWithCommas(v, 0),
  //   defaultSortOrder: "descend",
  //   sorter: (a, b) => a.pnl - b.pnl,
  // },
  {
    dataIndex: "pnl_percentile",
    title: "PnL",
    render: (v: any) => numberWithCommas(v, 0),
    sorter: (a: any, b: any) => a.pnl_percentile - b.pnl_percentile,
  },
  {
    dataIndex: "news_percentile",
    title: "News Accuracy",
    render: (v: any) => numberWithCommas(v, 0),
    sorter: (a: any, b: any) => a.news_percentile - b.news_percentile,
  },
  {
    dataIndex: "client_dealing_percentile",
    title: "Client dealing",
    render: (v: any) => numberWithCommas(v, 0),
    sorter: (a: any, b: any) =>
      a.client_dealing_percentile - b.client_dealing_percentile,
  },
  {
    dataIndex: "risk_management_percentile",
    title: "Risk management",
    render: (v: any) => numberWithCommas(v, 0),
    sorter: (a: any, b: any) =>
      a.risk_management_percentile - b.risk_management_percentile,
  },

  {
    dataIndex: "final_score",
    title: "Final score",
    render: (v) => numberWithCommas(v, 0),
    defaultSortOrder: "descend",
    sorter: (a, b) => a.final_score - b.final_score,
  },
];

export default CBLeaderboard;
