import { Alert, Input, InputProps } from "antd";
import FloatLabel from "components/reusable/FloatLabel";
import {
  Column,
  FloatInput,
} from "components/reusable/GenericStyledComponents";
import React, { useState } from "react";
import { ModifiedUserType } from "types";

type Props = {
  modifiedUser: ModifiedUserType;
  setModifiedUser: React.Dispatch<React.SetStateAction<ModifiedUserType>>;
};

const EditUserModal: React.FC<Props> = ({ modifiedUser, setModifiedUser }) => {
  const [errors, setErrors] = useState<
    Partial<Record<keyof ModifiedUserType, string>>
  >({});

  const isEmail = (_email: string) => {
    const re = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return re.test(_email);
  };

  const handleChange = (property: keyof ModifiedUserType, value: string) => {
    setModifiedUser((prevState) => ({
      ...prevState,
      [property]: value,
    }));

    switch (property) {
      case "email":
        if (!isEmail(value)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            email: "Invalid email",
          }));
        } else {
          setErrors((prevErrors) => {
            const { [property]: removed, ...rest } = prevErrors;
            return rest;
          });
        }
        break;
      case "oldPassword":
      case "password":
        if (value.length < 8) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [property]: "Password must be at least 8 characters long",
          }));
        } else {
          setErrors((prevErrors) => {
            const { [property]: removed, ...rest } = prevErrors;
            return rest;
          });
        }
        break;
      case "confirmPassword":
        if (modifiedUser.password !== value) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            confirmPassword: "Passwords do not match",
          }));
        } else {
          setErrors((prevErrors) => {
            const { [property]: removed, ...rest } = prevErrors;
            return rest;
          });
        }
        break;
    }
  };

  // The rest of your component remains the same until the return statement...

  return (
    <Column style={{ gap: ".5rem" }}>
      <FloatLabel label="First Name:" value={modifiedUser.first_name}>
        <FloatInput
          type="text"
          value={modifiedUser.first_name}
          onChange={(e) => handleChange("first_name", e.target.value)}
        />
      </FloatLabel>

      <FloatLabel label="Last Name:" value={modifiedUser.last_name}>
        <FloatInput
          type="text"
          value={modifiedUser.last_name}
          onChange={(e) => handleChange("last_name", e.target.value)}
        />
      </FloatLabel>

      <FloatLabel label="Email:" value={modifiedUser.email}>
        <FloatInput
          type="email"
          value={modifiedUser.email}
          onChange={(e) => handleChange("email", e.target.value)}
        />
        {errors.email && <Alert message={errors.email} type="error" showIcon />}
      </FloatLabel>

      <FloatLabel label="Username:" value={modifiedUser.username}>
        <FloatInput
          type="text"
          value={modifiedUser.username}
          onChange={(e) => handleChange("username", e.target.value)}
        />
      </FloatLabel>

      <Input.Password
        value={modifiedUser.oldPassword}
        placeholder="Old password"
        onChange={(e) => handleChange("oldPassword", e.target.value)}
      />
      {errors.oldPassword && (
        <Alert message={errors.oldPassword} type="error" showIcon />
      )}

      <Input.Password
        value={modifiedUser.password}
        placeholder="New password"
        onChange={(e) => handleChange("password", e.target.value)}
      />
      {errors.password && (
        <Alert message={errors.password} type="error" showIcon />
      )}

      <Input.Password
        value={modifiedUser.confirmPassword}
        placeholder="Confirm new password"
        onChange={(e) => handleChange("confirmPassword", e.target.value)}
      />
      {errors.confirmPassword && (
        <Alert message={errors.confirmPassword} type="error" showIcon />
      )}
    </Column>
  );
};

export default EditUserModal;
