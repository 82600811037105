import {
  BaseGameContainer,
  Column,
} from "components/reusable/GenericStyledComponents";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import styled from "styled-components";
import Header from "./Header";
import { RootState } from "store/rootReducer";
import { Typography } from "antd";
import { ClientCall, RFQCall } from "types";
import QuoteRFQ from "./QuoteRFQ";
import QuoteCC from "./QuoteCC";
import NewQuoteRFQ from "./NewQuoteRFQ";

const QuoteSection: React.FC = () => {
  const selectedCall = useSelector(
    (state: RootState) => state.callQuotes.selectedCall,
    shallowEqual
  );

  if (!selectedCall) return null;
  return (
    <Container>
      <Typography.Text style={{ fontWeight: "bold" }}>
        Selected for quote
      </Typography.Text>

      <Header />
      {selectedCall && (
        <>
          {Object.keys(selectedCall).includes("strategy") ? (
            // <QuoteRFQ call={selectedCall as RFQCall} />
            <NewQuoteRFQ call={selectedCall as RFQCall} />
          ) : (
            <QuoteCC call={selectedCall as ClientCall} />
          )}
        </>
      )}
    </Container>
  );
};

const Container = styled(BaseGameContainer)`
  flex: 1;
  margin-top: 1rem;
  height: 100%;
`;

export default QuoteSection;
