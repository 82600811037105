import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import StyledText from "../reusable/StyledText";
import { RootState } from "store/rootReducer";
import GenericModal from "./GenericModal";
import { useAppDispatch } from "store/store";
import { Permission } from "../../types";
import { Typography } from "antd";

const PausedModal = () => {
  const { status } = useSelector((state: RootState) => state.room);
  const { permission } = useSelector((state: RootState) => state.user);
  const dispatch = useAppDispatch();
  if (permission === Permission.Trainer || permission === Permission.Admin) {
    // if (status === 2)
    //   message.info("The game is currently paused.", 5);
    return <></>;
  }
  return (
    <GenericModal
      isShown={status === 2}
      customClassName="PausedModal__Content"
      customOverlayClassName="PausedModal__Overlay"
      overlayBackground="#00000060"
    >
      <ModalContentContainer>
        <Typography.Text style={{ fontWeight: 800, fontSize: 30 }}>
          Room is currently paused, please wait for your trainer to resume the
          simulation!
        </Typography.Text>
        {/* 
        {(permission === Permission.Trainer ||
          permission === Permission.Admin) && (
          <Button
            style={{ backgroundColor: '#007e4d' }}
            onClick={() => {
              dispatch(resumeRoom());
            }}
          >
            Resume
          </Button>
        )} */}
      </ModalContentContainer>
    </GenericModal>
  );
};

const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  margin: 0.5rem;
  border: 1px solid grey;
  border-radius: 0.5rem;
`;

export default PausedModal;
