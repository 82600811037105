import React, { useState } from "react";

import styled from "styled-components";

import { numberWithCommas } from "utils/numbers";
import { useAppDispatch } from "store/store";
import { cancelEbOrder } from "store/spot/actions";
import { BUY, DIRECTION_STR, SELL } from "stateConstants";
import { EbOrder } from "types";
import { Row } from "components/reusable/GenericStyledComponents";
import StyledText from "components/reusable/StyledText";
import { Button } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { CloseCircleOutlined } from "@ant-design/icons";

const Text: React.FC<{ toRight?: boolean }> = ({ children, toRight }) => (
  <StyledText
    fontSize={10}
    fontWeight={500}
    fontFamily="Roboto"
    color="#F4F4F470"
    style={{ flex: 1, textAlign: toRight ? "right" : "left" }}
  >
    {children}
  </StyledText>
);

type Props = {
  order: EbOrder;
};

const SpotRow: React.FC<Props> = ({ order }) => {
  const dispatch = useAppDispatch();
  const [isHovered, setIsHovered] = useState(false);
  const { SPOT_DECIMAL_PLACES } = useSelector(
    (state: RootState) => state.settings.parameters
  );

  return (
    <Container
      isHovered={isHovered}
      style={{
        padding: "2px 7px",
        direction: order.direction === BUY ? "ltr" : "rtl",
      }}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      <Text toRight={order.direction === SELL}>
        {DIRECTION_STR[order.direction as keyof typeof DIRECTION_STR]}
      </Text>
      <Text toRight={order.direction === SELL}>
        {numberWithCommas(order.amount)}
      </Text>
      <Text toRight={order.direction === SELL}>
        {order.price.toFixed(SPOT_DECIMAL_PLACES)}
      </Text>
      <Button
        type="link"
        onClick={() => {
          dispatch(cancelEbOrder({ order_id: order.id }));
        }}
        style={{
          // visibility: isHovered ? "visible" : "hidden",
          padding: 0,
          margin: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CloseCircleOutlined />
      </Button>
    </Container>
  );
};

const Container = styled(Row)<{ isHovered: boolean }>`
  background: ${({ isHovered }) =>
    isHovered ? "#782F39 !important" : "inherit"};
  border: 1px solid #ffffff20;
  border-radius: 0.25rem;
  width: 100%;

  justify-content: space-between;
  align-items: center;
`;

export default SpotRow;
