import React from "react";
import { useSelector } from "react-redux";

import styled from "styled-components";

import { ASK, BID } from "stateConstants";
import * as selectors from "store/spot/selectors";
import { Row } from "components/reusable/GenericStyledComponents";
import SpotRow from "./SpotRow";

const SpotOrders = () => {
  const activeOrders = useSelector(selectors.activeOrders);
  const bidEbOrders = activeOrders.filter((order) => order.direction === BID);
  const askEbOrders = activeOrders.filter((order) => order.direction === ASK);

  return (
    <Row style={{ height: "100%", width: "100%", overflow: "hidden", gap: 10 }}>
      <Container>
        {bidEbOrders.map((order) => (
          <SpotRow key={order.id} order={order} />
        ))}
      </Container>

      <Container>
        {askEbOrders.map((order) => (
          <SpotRow key={order.id} order={order} />
        ))}
      </Container>
    </Row>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100px;
  overflow-y: auto;
  overflow-x: hidden;

  & div:nth-child(odd) {
    // background: #222b31; // layout
  }

  & div:nth-child(even) {
    // background: #192025; // layout
  }
`;

export default SpotOrders;
