import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Table } from "antd";

import { RootState } from "store/rootReducer";
import CustomTable from "components/reusable/CustomTable";
import { DataRow } from "types";
import { genericSearch } from "utils/search";
import StyledText from "components/reusable/StyledText";
import { trainerService } from "services";
import { useAppDispatch } from "store/store";
import { getTrainerClasses } from "store/trainer/reducers";
import styled from "styled-components";
import { LeftOutlined } from "@ant-design/icons";
import { Column } from "components/reusable/GenericStyledComponents";

const ClassView: React.FC<{ id: number; setViewClasses?: any }> = ({
  id,
  setViewClasses,
}) => {
  const { classes } = useSelector((state: RootState) => state.trainer);
  const klass = classes.find((cls) => cls.id === id);
  const [query, setQuery] = useState("");
  const dispatch = useAppDispatch();

  const fetchClasses = async () => {
    const cls = await trainerService.getTrainerClasses();
    dispatch(getTrainerClasses(cls));
  };

  useEffect(() => {
    fetchClasses();
  }, []);

  if (klass === undefined) {
    return <div>PROBLEM FETCHING CLASS</div>;
  }

  const students = klass.assigned_students;

  const dataSource = students.filter((student) =>
    genericSearch<{
      id: number;
      first_name: string;
      last_name: string;
    }>(student, ["first_name", "last_name"], query)
  );

  return (
    <Column style={{ height: "95vh", overflow: "auto" }}>
      <BackButton
        type="link"
        onClick={() => {
          setViewClasses(false);
        }}
      >
        <LeftOutlined /> Back to classes
      </BackButton>

      <Table
        dataSource={dataSource}
        columns={[
          { title: "Username", dataIndex: "username" },
          { title: "First Name", dataIndex: "first_name" },
          { title: "Last Name", dataIndex: "last_name" },
        ]}
        pagination={false}
      />
    </Column>
  );
};

const BackButton = styled(Button)`
  padding: 0;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

export default ClassView;
