import { Column } from "components/reusable/GenericStyledComponents";
import React from "react";
import styled from "styled-components";

const AddNewClass: React.FC<{ setIsShown: () => void }> = ({ setIsShown }) => {
  return (
    <ColumnButton
      style={{ alignItems: "center" }}
      onClick={() => {
        setIsShown();
        // setSelectedScenario();
      }}
    >
      +
    </ColumnButton>
  );
};
const ColumnButton = styled(Column)`
  background: #ffffff10;
  border-radius: 0.25rem;
  transition: 0.2s ease-in-out;
  &:hover {
    background: #ffffff20;
    transform: scale(105%);
  }
  padding: 0.25rem 2rem;
  margin: 0;
`;

export default AddNewClass;
