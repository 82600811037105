import { Row } from "components/reusable/GenericStyledComponents";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import OptionStrikeButtons from "./OptionStrikeButtons";
import StraddleStrikeButtons from "./StraddleStrikeButtons";

const StickyStrikeDeltaStrikeArea: React.FC = () => {
  const { TRADABLE_VOLS_PUT_CALL_OR_STRADDLE: strategy } = useSelector(
    (state: RootState) => state.settings.parameters
  );

  return (
    <Row
      style={{
        justifyContent: strategy ? "center" : "space-around",
        height: "2rem",
      }}
    >
      {strategy ? <StraddleStrikeButtons /> : <OptionStrikeButtons />}
    </Row>
  );
};

export default StickyStrikeDeltaStrikeArea;
