import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled, { css, keyframes } from "styled-components";
import { Button, Typography } from "antd";

import * as selectors from "store/rfq/selectors";
import { Row } from "../../reusable/GenericStyledComponents";

import StrategyModal from "./StrategyModal";
import { listClientCalls } from "store/clientCalls/selectors";

interface Props {
  tab: "incoming" | "outgoing" | "cc";
  setTab: (tab: "incoming" | "outgoing" | "cc") => void;
}

const Header: React.FC<Props> = ({ tab, setTab }) => {
  const incomingList = useSelector(selectors.incomingRFQs);
  const outgoingList = useSelector(selectors.outgoingRFQs);
  const ccList = useSelector(listClientCalls);

  const [animateIncoming, setAnimateIncoming] = useState<boolean>(false);
  const [animateClientCalls, setAnimateClientCalls] = useState<boolean>(false);

  const prevIncomingListLength = useRef(incomingList.length);
  const prevCcListLength = useRef(ccList.length);

  useEffect(() => {
    if (incomingList.length !== prevIncomingListLength.current) {
      setAnimateIncoming(true);
      setTimeout(() => setAnimateIncoming(false), 400);
      prevIncomingListLength.current = incomingList.length;
    }

    if (outgoingList.length !== prevCcListLength.current) {
      setAnimateClientCalls(true);
      setTimeout(() => setAnimateClientCalls(false), 400);
      prevCcListLength.current = outgoingList.length;
    }
  }, [incomingList, ccList]);

  return (
    <Row
      style={{
        alignItems: "center",
        gap: ".25rem",
        height: "2rem",
      }}
    >
      <Typography.Text
        style={{ fontWeight: "bold", display: "block", wordBreak: "keep-all" }}
      >
        BROKER DEALER
      </Typography.Text>

      <RFQButton
        key="rfq-button-incoming"
        active={tab === "incoming"}
        onClick={() => {
          setTab("incoming");
        }}
      >
        Incoming ({incomingList.length})
      </RFQButton>
      {!!outgoingList.length && (
        <RFQButton
          style={{
            background: "#FFD369",
            color: "black",
            fontWeight: "700",
          }}
          animate
          active={tab === "outgoing"}
          onClick={() => {
            setTab("outgoing");
          }}
        >
          RFQ
        </RFQButton>
      )}
      <RFQButton
        animate={animateClientCalls}
        key="rfq-button-cc"
        active={tab === "cc"}
        onClick={() => {
          setTab("cc");
        }}
      >
        Client Calls ({ccList.length})
      </RFQButton>
      <StrategyModal />
    </Row>
  );
};

const breatheAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
`;

const RFQButton = styled(Button)<{ active: boolean; animate?: boolean }>`
  margin: 0;
  background: #000;
  color: #c7c7c7;
  border-radius: 4px;
  padding: 0 0.75rem;

  ${({ active }) => {
    if (active) {
      return css`
        border: 1px solid ${(p) => p.theme.primaryDark1};
      `;
    }
    return css`
      border: 1px solid #8c8c8c;
    `;
  }}

  ${({ animate }) => {
    return animate
      ? css`
          animation: ${breatheAnimation} 1s cubic-bezier(0.52, 0.24, 0.37, 0.85)
            infinite;
        `
      : null;
  }}
`;
export default Header;
