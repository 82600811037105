import ReportCard from "components/Reports/ReportCard";
import ReportChart from "components/Reports/ReportChart";
import ReportsHeader from "components/Reports/ReportsHeader";
import { Column, Row } from "components/reusable/GenericStyledComponents";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { reportsService } from "services";
import {
  EvolutionReportKeys,
  SwiftReport,
  TraderReportsType,
} from "types/reports";

const UserReports: React.FC = () => {
  const [content, setContent] = useState<Partial<SwiftReport>>();
  const [reports, setReports] = useState<Partial<TraderReportsType>>();
  const location = useLocation();
  const room_id = window.location.pathname.split("/")[2];
  const trader_id = window.location.pathname.split("/")[3];

  const fetchReports = async () => {
    const _reports = await reportsService.getTraderReports(room_id, trader_id);
    // setTradersReports(reports.map((r) => r.user));

    setContent(_reports.swift_reports[0]);
    setReports(_reports);
  };

  useEffect(() => {
    fetchReports();
  }, []);

  if (!reports || !reports.swift_reports) return <></>;
  return (
    <div>
      <div style={{ overflowY: "auto", maxHeight: "100vh", margin: "auto" }}>
        <ReportsHeader />
        <Column
          style={{
            alignItems: "center",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          <h1>Reports for {reports.user?.name}</h1>
        </Column>
        <Row
          style={{ margin: "2rem 3rem", overflowY: "auto", maxHeight: "100%" }}
        >
          <Column
            style={{ alignItems: "center", width: "50%", overflowY: "auto" }}
          >
            <h1>Numerical reports</h1>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "1rem",
              }}
            >
              <ReportCard
                title="Full reports (DEMO)"
                loading={!content}
                content={content}
              />
              <ReportCard
                title="Full reports (DEMO)"
                loading={!content}
                content={content}
              />
              <ReportCard
                title="Full reports (DEMO)"
                loading={!content}
                content={content}
              />
              <ReportCard
                title="Full reports (DEMO)"
                loading={!content}
                content={content}
              />
            </div>
          </Column>
          <Column style={{ alignItems: "center", width: "50%" }}>
            <h1>Evolution</h1>
            <div
              style={{
                display: "grid",
                width: "100%",
                gridTemplateColumns: "1fr",
                gap: "1rem",
              }}
            >
              {Object.keys(reports?.swift_reports[0])
                .filter((k) => k.includes("evolution"))
                .map((k) => {
                  return reports &&
                    reports.swift_reports &&
                    reports.swift_reports[0] ? (
                    <ReportChart
                      key={k}
                      title={k}
                      graphColor="#22B793"
                      report={reports.swift_reports[0]}
                      field={k as EvolutionReportKeys}
                    />
                  ) : (
                    <></>
                  );
                })}
            </div>
          </Column>
        </Row>
      </div>
    </div>
  );
};

export default UserReports;
