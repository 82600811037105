export type EventState = {
  news: MacroEvent[];
  forecasts: MacroEvent[];
  market_impact: MarketImpactPayload;
  market_impact_loaded: boolean;
  impact_votes: {
    [key: number]: {
      [key: number]: {
        spot: number;
        vol: number;
      };
    };
  };
  latestNews: MacroEvent | undefined;
};

export type MacroEvent = {
  id: number;
  room_time: number;
  body: string;
  headline: string;
  event_type: MacroEventTypes;

  published: boolean;
  disabled: boolean;
  passed: boolean;

  published_at: number;

  vote_expired?: boolean;
  votes?: {
    [key: string]: {
      voted_spot: number;
      voted_vol: number;
    };
  };
};

export type MacroEventPayload = Omit<MacroEvent, "body" | "headline"> & {
  event_data: {
    body: string;
    headline: string;
  };
};

export type EventPublishedPayload = {
  event: MacroEventPayload;
};

export enum MacroEventTypes {
  NEWS,
  FORECAST,
}

export type VolImpact = {
  [key: string]: {
    [key: string]: number;
  };
};

export type MarketImpactPayload = {
  spot: number;
  vol: VolImpact;
};

export type VoteNewsPayload = {
  event_id: number;
  swift_id: number;
  vote: number;
  spot_or_vol: "spot" | "vol";
};

export type NewsVotedConfimationPayload = {
  vote: number;
  trader_vote: {
    id: number;
    trader_id: string;
    event_id: number;
    swift_id: number;
    vote: number;
    room_time: number;
  };
};

export type CBNewsRows = {
  id: string;
  headline: string;
  body: string;
  delta_seconds: number;
  spot: number;
  vol_parallel: number;
  put_skew: number;
  call_skew: number;
  lt_steep: number;
  st_steep: number;
  published: boolean;
  index: number;
};

export type AdminNews = {
  body: string;
  headline: string;
  delta_seconds: number;
  market_impact: {
    [ticker: string]: {
      spot: number;
      lt_steep: number;
      put_skew: number;
      st_steep: number;
      call_skew: number;
      vol_parallel: number;
    };
  };
};
