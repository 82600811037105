import ReportsHeader from "components/Reports/ReportsHeader";
import { Button } from "antd";
import { Column, Row } from "components/reusable/GenericStyledComponents";
import StyledText from "components/reusable/StyledText";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { reportsService } from "services";
import CustomTable from "components/reusable/CustomTable";
import { DataRow } from "types";
import styled from "styled-components";
import SkeletonRows from "components/reusable/SkeletonRows";

const DataContainer = styled(Row)`
  gap: 15px;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-bottom: 10px;
`;

const RoomReports: React.FC = () => {
  const [tradersReports, setTradersReports] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const room_id = window.location.pathname.split("/")[2];

  const fetchReports = async () => {
    const reports = await reportsService.getRoomReports(room_id);
    setTradersReports(reports.map((r) => r.user));
    setLoading(false);
  };

  useEffect(() => {
    fetchReports();
  }, []);

  return (
    <div
      style={{
        alignItems: "center",
        overflowY: "auto",
        maxHeight: "100vh",
        margin: "auto",
      }}
    >
      <ReportsHeader />
      <Column style={{ alignItems: "center", marginBottom: "2rem" }}>
        <div style={{ marginTop: "1rem" }}>
          <StyledText fontSize={20} fontWeight={600} fontFamily="IBMPlexSans">
            Room Traders
          </StyledText>
        </div>
        {!loading ? (
          <DataContainer>
            <CustomTable
              header={["name"]}
              data={[
                ...tradersReports.map(
                  (trader): DataRow => ({
                    data: [
                      () => (
                        <StyledText
                          fontSize={14}
                          fontWeight={500}
                          color="#FFFFFF90"
                          fontFamily="IBMPlexSans"
                        >
                          {trader.name}
                        </StyledText>
                      ),
                      () => (
                        <Row style={{ gap: ".5rem" }}>
                          <Link
                            to={`/reports/${room_id}/${trader.info.trader}`}
                          >
                            <Button>
                              <StyledText
                                fontSize={14}
                                fontWeight={400}
                                color="#FFFFFF70"
                                fontFamily="IBMPlexSans"
                              >
                                Go to report
                              </StyledText>
                            </Button>
                          </Link>
                        </Row>
                      ),
                    ],
                  })
                ),
              ]}
            />
          </DataContainer>
        ) : (
          <SkeletonRows rows={5} />
        )}
      </Column>
    </div>
  );
};

export default RoomReports;
