export interface Room {
  id: number;
  uuid: string;
  status: RoomStatus;
  name: string;
  owner: string;
  room_type: number;
  training_class_id: string;
  training_class: { name: string; id: number };
  duration: number;
  created: string;
  start_date: string;
  end_date: string;
  last_started: string;
  timestamp: number;
}

export interface ConnectedRoomInfoDTO {
  still_connected: number;
  room_id?: number;
  room_status?: RoomStatus;
}

export interface ConnectedRoomInfo {
  stillConnected: number;
  roomId?: number;
  roomStatus?: RoomStatus;
}

export interface RoomsState {
  dataReceived: boolean;
  rooms: Room[];
  room_connected: ConnectedRoomInfo;
  classes_of_user: number[];
}

export interface Class {
  class_id: number;
}

export enum RoomStatus {
  PENDING,
  PLAYING,
  PAUSED,
  FINISHED,
}

export interface CreateRoomPlayer {
  id: number;
  username: string;
}

export interface CreateRoomTrainer {
  id: number;
  username: string;
}
