import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { NewPricerState, NewPricerUpdateFromHooksPayload } from "types";
import { CALL } from "stateConstants";
import { clearRoom } from "../room/actions";
import {
  linkCallToPricer,
  pricerGetVolatility,
  pricerUpdateData,
  removePricer,
  setPricer,
  unlinkPricerCall,
  updatePricerField,
} from "./actions";
import { createEmptyPricer } from "./utils";

const initialState: () => NewPricerState = () => ({
  pricers: {
    "0": createEmptyPricer(0),
    "1": createEmptyPricer(1),
    "2": createEmptyPricer(2),
    "3": createEmptyPricer(3),
    // "4": createEmptyPricer(4),
  },
  nbCols: 2,
  largePricer: false,
  callsToPricerLinks: {},
  selectedCall: { callId: "", color: "" },
  pricerToCallsLinks: {
    "0": { call_id: "", color: "" },
    "1": { call_id: "", color: "" },
    "2": { call_id: "", color: "" },
    "3": { call_id: "", color: "" },
    // "4": { call_id: "", color: "" },
  },
  available_colors: ["#ad6800", "#389e0d", "#9e1068", "#006d75"],
});

const newpricerSlice = createSlice({
  initialState,
  name: "pricer",
  reducers: {
    pricerUpdateFromHooks(
      state,
      action: PayloadAction<NewPricerUpdateFromHooksPayload>
    ) {
      const { correlationId, partialState } = action.payload;
      state.pricers[correlationId] = {
        ...state.pricers[correlationId],
        ...partialState,
      };
    },
    setNbCols(state, action: PayloadAction<{ nbCols: number }>) {
      const { nbCols } = action.payload;
      if (nbCols <= 4 || nbCols >= 2) {
        state.nbCols = nbCols;
        state.largePricer = nbCols > 2;
      }
    },
    selectCall(
      state,
      action: PayloadAction<{ callId: string; color: string }>
    ) {
      const { callId, color } = action.payload;
      state.selectedCall = { callId, color };
      const pricers = state.callsToPricerLinks[callId].pricers.filter(
        (pricerId) => parseInt(pricerId) > 1
      );
      if (pricers.length) {
        state.nbCols = Math.max(...pricers.map((p) => parseInt(p))) + 1;
        state.largePricer = state.nbCols > 2;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(pricerUpdateData, (state, action) => {
        const {
          correlation_id,
          call_delta,
          put_delta,
          gamma,
          vega,
          call_theta,
          put_theta,
          call_price,
          put_price,
          premium,
          call_price_as_percentage_of_spot,
          put_price_as_percentage_of_spot,
          forward,
        } = action.payload.price_data;
        const isCall = state.pricers[correlation_id].type === CALL;
        state.pricers[correlation_id].forward = forward;
        state.pricers[correlation_id].delta = isCall ? call_delta : put_delta;
        state.pricers[correlation_id].gamma = gamma;
        state.pricers[correlation_id].vega = vega;
        state.pricers[correlation_id].call_theta = call_theta;
        state.pricers[correlation_id].put_theta = put_theta;
        state.pricers[correlation_id].price = isCall ? call_price : put_price;
        state.pricers[correlation_id].premium = isCall
          ? premium.call
          : premium.put;
        state.pricers[correlation_id].pricePercentOfSpot = isCall
          ? call_price_as_percentage_of_spot
          : put_price_as_percentage_of_spot;
      })
      .addCase(updatePricerField, (state, action) => {
        const { key, value, correlation_id } = action.payload;
        state.pricers[correlation_id][key] = value;
      })
      .addCase(removePricer, (state, action) => {
        const { col } = action.payload;
        if (state.pricerToCallsLinks[col].call_id) {
          state.callsToPricerLinks[
            state.pricerToCallsLinks[col].call_id
          ].pricers = state.callsToPricerLinks[
            state.pricerToCallsLinks[col].call_id
          ].pricers.filter((p) => p !== col);
          state.pricerToCallsLinks[col] = { call_id: "", color: "" };
        }
        const nb_pricers = Object.keys(state.pricers).length;
        delete state.pricers[col];
        for (let i = parseInt(col); i <= nb_pricers - 2; i += 1) {
          state.pricers[i] = { ...state.pricers[i + 1] };
          state.pricers[i].correlation_id = i;
          state.pricerToCallsLinks[i] = { ...state.pricerToCallsLinks[i + 1] };
        }
        state.pricers[nb_pricers - 1] = createEmptyPricer(nb_pricers - 1);
        if (state.nbCols !== 1) state.nbCols -= 1;
        state.largePricer = state.nbCols > 2;
      })
      .addCase(setPricer, (state, action) => {
        const { correlation_id, common } = action.payload;

        state.pricers[correlation_id] = {
          ...state.pricers[correlation_id],
          ...common,
          lockVol: 0,
        };
      })
      .addCase(pricerGetVolatility, (state, action) => {
        const { value, correlation_id } = action.payload;
        const parsedValue = parseFloat(value);
        state.pricers[parseInt(correlation_id)].volatility = Number(
          (parsedValue * 100).toFixed(2)
        );
      })
      .addCase(linkCallToPricer, (state, action) => {
        const { callId, pricerId } = action.payload;

        let color;
        if (!state.callsToPricerLinks[callId]) {
          color = state.available_colors.pop() || "";
          state.callsToPricerLinks[callId] = { pricers: [pricerId], color };
        } else {
          color = state.callsToPricerLinks[callId].color;
          state.callsToPricerLinks[callId].pricers.push(pricerId);
        }

        state.pricerToCallsLinks[pricerId].color = color;
        state.pricerToCallsLinks[pricerId].call_id = callId;
        state.selectedCall.callId = callId;
        state.selectedCall.color = color;

        if (parseInt(pricerId) > 1) {
          state.nbCols = parseInt(pricerId) + 1;
          state.largePricer = state.nbCols > 2;
        }
        state.pricers[pricerId].linked_to = callId;
      })
      .addCase(unlinkPricerCall, (state, action) => {
        const { callId } = action.payload;
        if (Object.keys(state.callsToPricerLinks).includes(callId)) {
          for (const [pricerId, link] of Object.entries(
            state.pricerToCallsLinks
          )) {
            if (link.call_id === callId) {
              state.pricerToCallsLinks[pricerId] = { call_id: "", color: "" };
              state.pricers[pricerId].linked_to = "";
            }
          }
          if (callId === state.selectedCall.callId)
            state.selectedCall = {
              callId:
                Object.values(state.pricerToCallsLinks).map(
                  (l) => l.call_id
                )[0] || "",
              color:
                Object.values(state.pricerToCallsLinks).map(
                  (l) => l.color
                )[0] || "",
            };
          state.available_colors = [
            state.callsToPricerLinks[callId].color,
            ...state.available_colors,
          ];
          delete state.callsToPricerLinks[callId];
        }
      })
      .addCase(clearRoom, initialState);
  },
});

export const { pricerUpdateFromHooks, setNbCols, selectCall } =
  newpricerSlice.actions;
export default newpricerSlice.reducer;
