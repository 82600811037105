import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";

export const incomingRFQs = createSelector(
  (state: RootState) => state.rfq,
  (state: RootState) => state.user,
  (indexedRfq, { traderId }) => {
    return Object.values(indexedRfq).filter(
      (rfq) => rfq.market_maker_id === traderId
    );
  }
);

export const outgoingRFQs = createSelector(
  (state: RootState) => state.rfq,
  (state: RootState) => state.user,
  (indexedRfq, { traderId }) => {
    return Object.values(indexedRfq).filter(
      (rfq) => rfq.market_taker_id === traderId
    );
  }
);
