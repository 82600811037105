import { PayloadAction } from "@reduxjs/toolkit";
import { put, takeEvery } from "redux-saga/effects";
import { socketSendMessage } from "store/socket/actions";
import {
  closedForVoting,
  directionConfidenceSpotRefreshed,
  newsVotedAction,
  UpdateSpotDirectionConfidencePayload,
  UpdateVolEbDirectionConfidencePayload,
} from "./actions";
import {
  GET_DIRECTION_CONFIDENCE_SPOT,
  GET_DIRECTION_CONFIDENCE_VOL,
} from "./constants";
import { NewsVotedConfimationPayload, VoteNewsPayload } from "types";

function* addNewEvent({ payload }: any) {
  const request = {
    headers: {
      command: "macro.cmd.event.add",
    },
    room_id: payload.room_id,
    news: [
      {
        delta_seconds: parseInt(payload.news.deltaseconds),
        event_type: 0,
        headline: payload.news.headline,
        body: payload.news.body,
        index: payload.news.index,
        market_impact: Object.fromEntries(
          new Map([[payload.swift.code, { ...payload.news.market_impact }]])
        ),
      },
    ],
    forecasts: [],
  };
  yield put(socketSendMessage(JSON.stringify(request)));
}

function* voteNews(action: PayloadAction<VoteNewsPayload>) {
  const { event_id, swift_id, vote, spot_or_vol } = action.payload;
  const request = {
    headers: {
      command: `macro.cmd.${spot_or_vol}.impact.vote`,
    },
    event_id,
    swift_id,
    vote,
  };
  yield put(socketSendMessage(JSON.stringify(request)));
}

function* getDirectionConfidenceSpot({
  payload,
}: PayloadAction<{ room_id: number; swift_id: number }>) {
  const request = {
    headers: {
      command: GET_DIRECTION_CONFIDENCE_SPOT,
    },
    room_id: payload.room_id,
    swift_id: payload.swift_id,
  };
  yield put(socketSendMessage(JSON.stringify(request)));
}

function* getDirectionConfidenceVol({
  payload,
}: PayloadAction<{ room_id: number; swift_id: number }>) {
  const request = {
    headers: {
      command: GET_DIRECTION_CONFIDENCE_VOL,
    },
    room_id: payload.room_id,
    swift_id: payload.swift_id,
  };
  yield put(socketSendMessage(JSON.stringify(request)));
}

function* updateDirectionConfidenceSpot({
  payload,
}: PayloadAction<UpdateSpotDirectionConfidencePayload>) {
  const request = {
    headers: {
      command: "eb.cmd.direction_confidence.update",
    },
    ...payload,
  };
  yield put(socketSendMessage(JSON.stringify(request)));
}

function* updateDirectionConfidenceVol({
  payload,
}: PayloadAction<UpdateVolEbDirectionConfidencePayload>) {
  const request = {
    headers: {
      command: "vol_eb.cmd.direction_confidence.update",
    },
    ...payload,
  };
  yield put(socketSendMessage(JSON.stringify(request)));
}

function* volVoted({ payload }: PayloadAction<NewsVotedConfimationPayload>) {
  yield put(newsVotedAction({ ...payload, spot_or_vol: "vol" }));
}
function* spotVoted({ payload }: PayloadAction<NewsVotedConfimationPayload>) {
  yield put(newsVotedAction({ ...payload, spot_or_vol: "spot" }));
}
function* closedForVotingSaga({
  payload,
}: PayloadAction<{ event: { id: number } }>) {
  yield put(closedForVoting({ ...payload }));
}

export function* watchEventsAsync() {
  yield takeEvery("macro/newsAdded", addNewEvent);
  yield takeEvery("macro/volVoted", volVoted);
  yield takeEvery("macro/spotVoted", spotVoted);
  yield takeEvery("events/closedForVoting", closedForVotingSaga);
  yield takeEvery("macro/voteNews", voteNews);
  yield takeEvery(
    "spot/request_direction_confidence",
    getDirectionConfidenceSpot
  );
  yield takeEvery(
    "spot/update_direction_confidence",
    updateDirectionConfidenceSpot
  );
  yield takeEvery(
    "vol_eb/request_direction_confidence",
    getDirectionConfidenceVol
  );
  yield takeEvery(
    "vol_eb/update_direction_confidence",
    updateDirectionConfidenceVol
  );
}
