export const SPOT_CANCEL_ALL_EB_ORDERS = "spot/cancelAllEbOrders";
export const SPOT_CANCEL_EB_ORDER = "spot/cancelEbOrder";
export const SPOT_DEAL_DONE = "spot/SpotDealDone";
export const SPOT_HIT_ORDER = "spot/hitOrder";
export const SPOT_PLACE_ORDER = "spot/placeOrder";
export const SPOT_POSITION_UPDATED = "spotPosition/SpotPositionUpdated";
export const SPOT_POSITION_PNL_UPDATED = "spotPosition/PNLUpdated";

export const SPOT_VOL_EB_HIT = "spot/volEbHit";
export const REMOVE_TENOR = "tenor/Removed";
export const CREATE_TENOR = "tenor/Created";

export const CMD_VOL_EB_HIT = "vol_eb.cmd.order.hit";
export const VOL_EB_DEPTH_CHANGED = "vol_eb/VolEbDepthChanged";
export const VOL_EB_DEPTHS_CHANGED = "vol_eb/VolEbDepthsChanged";
export const VOL_EB_DEPTH_CHANGED_CMD = "eb.event.depth.changed";
export const STRIKES_UPDATED = "vol_eb/strikesUpdated";
export const SPOT_PLACE_VOL_EB_ORDER = "vol_eb/placeOrder";

export const VOL_EB_ORDER_CANCELED = "vol_eb/OrderCanceled";
export const VOL_EB_ORDER_ADDED = "vol_eb/OrderAdded";
export const VOL_EB_ORDER_EXECUTED = "vol_eb/OrderExercised";

export const VOL_EB_CANCEL_ORDER = "vol_eb/CancelOrder";
export const CMD_VOL_EB_CANCEL_ORDER = "vol_eb.cmd.order.cancel";
