import React, { useEffect, useMemo, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

import { Line } from "react-chartjs-2";
import _, { isEqual } from "lodash";
import { RootState } from "store/rootReducer";
import { MATURITY, Tenor, VolatilityCurves } from "types";
import StyledText from "../../reusable/StyledText";
import { Column, Row } from "../../reusable/GenericStyledComponents";
import CustomSelect from "../../reusable/CustomSelect";
import { timeUnixToDatePretty } from "utils/time";
import { Modal, Select, SelectProps } from "antd";
import styled from "styled-components";
import { roomService } from "services";
import {
  setVolatilityCurves,
  volChartUpdated,
  volChartsUpdated,
} from "store/volatility/actions";
import { useAppDispatch } from "store/store";
import LoadingSpinner from "components/reusable/LoadingSpinner";
import ApexCharts from "react-apexcharts";
import { volChartOptions } from "./chartUtils";

const generateKey = (
  firstTenor: any,
  selectedDeltaStrike: any,
  decimals: number
) => {
  return `${firstTenor} ${parseDeltaStrike(selectedDeltaStrike, decimals)}`;
};

const parseDeltaStrike = (p: any, decimals: number) => parseFloat(p); // .toFixed(decimals);

const ApexVolchart: React.FC = () => {
  const dispatch = useAppDispatch();
  const { volatility_curves } = useSelector(
    (state: RootState) => state.volatility,
    isEqual
  );
  const { room_id } = useSelector((state: RootState) => state.room, isEqual);
  const initial_state_loaded = useSelector(
    (state: RootState) => state.spot.initial_state_loaded
  );
  const sticky_model = useSelector(
    (state: RootState) => state.spot.sticky_model
  );
  const selectedDeltaStrike = useSelector(
    (state: RootState) => state.spot.selectedDelta
  );
  const visible_vol_eb_tenors = useSelector(
    (state: RootState) => state.spot.visible_vol_eb_tenors
  );
  const tenors = useSelector((state: RootState) => state.spot.tenors);
  const swiftId = useSelector((state: RootState) => state.spot.swiftId);

  const [showVolchartModal, setShowVolchartModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const [firstTenor, setFirstTenor] = useState(
    tenors[swiftId][visible_vol_eb_tenors[swiftId][0]]
  );
  // const [secondTenor, setSecondTenor] = useState(tenorChoices[1]);

  const [firstGraph, setFirstGraph] = useState<{ x: number; y: number }[]>([]);

  const getVolCurves = async () => {
    const _vol_curves = await roomService.getVolatilityCurves(room_id);
    dispatch(setVolatilityCurves(_vol_curves));
    setLoading(false);
  };

  useEffect(() => {
    getVolCurves();
  }, []);

  useEffect(() => {
    const fg =
      volatility_curves[firstTenor]?.[selectedDeltaStrike]?.map((p, idx) => ({
        x: p.room_date * 1000,
        y: p.volatility,
      })) || [];

    setFirstGraph(fg);

    // setSecondGraph(sg);
  }, [firstTenor, selectedDeltaStrike, volatility_curves]);

  const apexSeries = [
    {
      name: "Volatility",
      data: firstGraph.map((item) => ({ x: item.x, y: item.y.toFixed(2) })), // Convert to expected format
    },
  ];

  const selectOptions = useMemo(
    () =>
      visible_vol_eb_tenors[swiftId].map((t) => ({
        value: tenors[swiftId][t],
        label: sticky_model
          ? MATURITY[tenors[swiftId][t]]
          : timeUnixToDatePretty(tenors[swiftId][t]),
      })),
    [visible_vol_eb_tenors, swiftId, sticky_model, tenors]
  );

  if (loading || !initial_state_loaded || !firstGraph || !tenors)
    return <LoadingSpinner />;
  return (
    <Column>
      <Row
        style={{
          paddingTop: 0,
          paddingLeft: 20,
          paddingBottom: 5,
          position: "absolute",
          left: 30,
          gap: ".25rem",
          zIndex: 2000,
        }}
      >
        <StyledSelect
          dropdownMatchSelectWidth={false}
          color="0097D880" // "#d73c5180"
          value={
            sticky_model
              ? MATURITY[firstTenor]
              : timeUnixToDatePretty(firstTenor)
          }
          options={selectOptions}
          onChange={(val: string) => {
            setFirstTenor(parseFloat(val));
          }}
        />
      </Row>

      <ApexCharts
        onClick={() => {
          setShowVolchartModal(true);
        }}
        series={apexSeries}
        options={volChartOptions(false)}
      />
      <Modal
        open={showVolchartModal}
        title="VOL chart"
        onCancel={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setShowVolchartModal(false);
        }}
        footer={null}
        bodyStyle={{ padding: "2rem 1rem" }}
        width="70vw"
      >
        <ApexCharts series={apexSeries} options={volChartOptions(true)} />
      </Modal>
    </Column>
  );
};

export default React.memo(ApexVolchart, isEqual);

const StyledSelect = styled(
  (
    props: SelectProps<any> & {
      color?: string;
    }
  ) => <Select {...props} />
)`
  .ant-select-selector {
    background-color: ${(props) => props.color || "#ffcccb"} !important;
    border-radius: 8px !important;
    color: white !important;
    width: fit-content !important;
    height: 20px !important;
    padding: 0 !important;
    margin: 0 !important;
    text-align: center !important;

    &:hover {
      border-color: ${(props) => props.color + "20" || "#ffcccb"} !important;
    }
  }
  .ant-select-selection-item {
    font-size: 10px !important;
    line-height: 20px !important; // Added line
    padding: 0 0.25rem !important;
  }
  .ant-select-focused .ant-select-selector,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: #ff6f61 !important;
    box-shadow: 0 0 0 2px rgba(255, 111, 97, 0.2) !important;
  }
`;
