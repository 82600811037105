import { delay, put, takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  CCAnswerPayload,
  CCIdPayload,
  ClientCall,
  CCGenericPayload,
} from "types";
import { socketSendMessage } from "../socket/actions";
import {
  CC_ACCEPT_CALL,
  CC_ACCEPTED,
  CC_ANSWER_CALL,
  CC_ANSWERED,
  CC_CANCELED,
  CC_CANCEL_CALL,
  CC_DECLINE_CALL,
  CC_DECLINED,
  CC_EXPIRED,
  CMD_CC_ACCEPT_CALL,
  CMD_CC_ANSWER_CALL,
  CMD_CC_CANCEL_CALL,
  CMD_CC_DECLINE_CALL,
} from "./constants";
import { removeClientCall } from "./reducers";
import { unlinkPricerCall } from "store/newpricer/actions";

function* ccAcceptCallSaga({ payload }: PayloadAction<CCIdPayload>) {
  const request = {
    headers: {
      command: CMD_CC_ACCEPT_CALL,
    },
    call_id: payload.callId,
  };

  yield put(socketSendMessage(JSON.stringify(request)));
}

function* ccDeclineCallSaga({ payload }: PayloadAction<CCIdPayload>) {
  const { callId } = payload;

  const request = {
    headers: {
      command: CMD_CC_DECLINE_CALL,
    },
    call_id: callId,
  };
  yield put(socketSendMessage(JSON.stringify(request)));
}

function* ccAnswerSaga({ payload }: PayloadAction<CCAnswerPayload>) {
  const { callId, traderAskQuote, traderBidQuote } = payload;

  const request = {
    headers: {
      command: CMD_CC_ANSWER_CALL,
    },
    call_id: callId,
    trader_bid_quote: traderBidQuote,
    trader_ask_quote: traderAskQuote,
  };

  yield put(socketSendMessage(JSON.stringify(request)));
}

function* ccCancelSaga({ payload }: PayloadAction<CCIdPayload>) {
  const { callId } = payload;

  const request = {
    headers: {
      command: CMD_CC_CANCEL_CALL,
    },
    call_id: callId,
  };

  yield put(socketSendMessage(JSON.stringify(request)));
}

function* ccRemoveDelaySaga({ payload }: PayloadAction<CCGenericPayload>) {
  yield delay(10000);
  yield put(unlinkPricerCall({ callId: `${payload.call.id}_cc` }));
  yield put(removeClientCall(payload.call.id));
}

export function* watchClientCallsAsync() {
  yield takeEvery(CC_ACCEPT_CALL, ccAcceptCallSaga);
  yield takeEvery(CC_DECLINE_CALL, ccDeclineCallSaga);
  yield takeEvery(CC_ANSWER_CALL, ccAnswerSaga);
  yield takeEvery(CC_CANCEL_CALL, ccCancelSaga);
  yield takeEvery(CC_ACCEPTED, ccRemoveDelaySaga);
  yield takeEvery(CC_DECLINED, ccRemoveDelaySaga);
  yield takeEvery(CC_EXPIRED, ccRemoveDelaySaga);
  yield takeEvery(CC_CANCELED, ccRemoveDelaySaga);
}
