import { message, Table, Button } from "antd";
import CreateClassModal from "components/AdminCustomers/Classes/Modals/CreateClassModal";
import ConfirmationPromptModal from "components/Modal/ConfirmationPromptModal";
import CustomTable from "components/reusable/CustomTable";
import {
  Column,
  Row,
  DataContainer,
  ViewClassButton,
} from "components/reusable/GenericStyledComponents";
import StyledText from "components/reusable/StyledText";
import ClassView from "components/Trainer/ClassView";
import React, { useEffect, useState } from "react";
import { adminService } from "services";
import styled from "styled-components";
import {
  Customer,
  DataRow,
  TraderPersistent,
  TrainerClassesPersistent,
  TrainerClassesRoom,
} from "types";
import AddNewClass from "./AddNewClass";
import EditClassModal from "./Modals/EditClassModal";

const clsPayloadSeed: TrainerClassesRoom = {
  id: -1,
  name: "",
  created: Date.now().toString(),
  owner: { id: -1, uuid: "-1", first_name: "", last_name: "", username: "" },
  assigned_students: [],
};

const AdminClassesPanel: React.FC<{ customer: Customer }> = ({ customer }) => {
  const [classes, setClasses] = useState<TrainerClassesPersistent[]>([]);
  const [newClassModalShown, setNewClassModalShown] = useState<boolean>(false);
  const [editClassModalShown, setEditClassModalShown] =
    useState<boolean>(false);
  const [clsPayload, setClsPayload] =
    useState<TrainerClassesPersistent>(clsPayloadSeed);
  const [editedClass, setEditedClass] =
    useState<TrainerClassesPersistent>(clsPayloadSeed);
  const [showPrompt, setShowPrompt] = useState<boolean>(false);

  const fetchClasses = async () => {
    const cls = await adminService.fetchAllClasses(customer.id);
    setClasses(cls);
  };

  useEffect(() => {
    fetchClasses();
  }, []);
  return (
    <Column>
      <ConfirmationPromptModal
        prompt={`Are you sure you want to delete ${editedClass.name}?`}
        onClose={() => setShowPrompt(false)}
        isShown={showPrompt}
        action={async () => {
          try {
            await adminService.deleteClass(editedClass.id);
            message.success(`Class ${editedClass.name} deleted!`);
          } catch {
            message.error(`Class ${editedClass.name} could not be deleted!`);
          }
          await fetchClasses();
        }}
      />
      <CreateClassModal
        isShown={newClassModalShown}
        closeModal={async () => {
          await fetchClasses();
          setNewClassModalShown(false);
        }}
        cls={clsPayload}
      />
      <EditClassModal
        isShown={editClassModalShown}
        closeModal={async () => {
          await fetchClasses();
          setEditClassModalShown(false);
        }}
        cls={editedClass}
      />
      <StyledText fontSize={20} fontWeight={600} fontFamily="IBMPlexSans">
        Create and edit classes
      </StyledText>
      <DataContainer>
        <Column style={{ gap: ".75rem", alignItems: "center" }}>
          <CustomTable
            header={["Class name", "No. students", "owner", "actions"]}
            data={[
              ...classes
                .sort((a, b) => {
                  const a_date = new Date(a.created);
                  const b_date = new Date(b.created);
                  return a_date.getTime() - b_date.getTime();
                })
                .map(
                  (cls): DataRow => ({
                    ExpandedComponent: () => (
                      <ExpandedClassInfo key={cls.id} cls={cls} />
                    ),
                    data: [
                      () => (
                        <StyledText
                          fontSize={14}
                          fontWeight={500}
                          color="#FFFFFF90"
                          fontFamily="IBMPlexSans"
                        >
                          {cls.name}
                        </StyledText>
                      ),
                      () => (
                        <StyledText
                          fontSize={14}
                          fontWeight={400}
                          color="#FFFFFF70"
                          fontFamily="IBMPlexSans"
                        >
                          {cls.assigned_students.length}
                        </StyledText>
                      ),
                      () => (
                        <StyledText
                          fontSize={14}
                          fontWeight={400}
                          color="#FFFFFF70"
                          fontFamily="IBMPlexSans"
                        >
                          {cls.owner?.username}
                        </StyledText>
                      ),
                      () => (
                        <Row style={{ gap: ".5rem" }}>
                          {/* <ViewClassButton
                                                    transparent
                                                    style={{ color: 'red' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setEditedClass(cls);
                                                        setShowPrompt(true);
                                                    }}
                                                >
                                                    <StyledText
                                                        fontSize={14}
                                                        fontWeight={400}
                                                        color="#FFFFFF70"
                                                        fontFamily="IBMPlexSans"
                                                    >
                                                        Delete class
                                                    </StyledText>
                                                </ViewClassButton> */}
                          <ViewClassButton
                            // transparent
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditedClass(cls);
                              setEditClassModalShown(true);
                            }}
                          >
                            <StyledText
                              fontSize={14}
                              fontWeight={400}
                              color="#FFFFFF70"
                              fontFamily="IBMPlexSans"
                            >
                              Edit class
                            </StyledText>
                          </ViewClassButton>
                        </Row>
                      ),
                    ],
                  })
                ),
            ]}
          />
          <AddNewClass
            // setSelectedScenario={() => {
            //     // setSelectedAdminScenario(empty_scenario_defaults);
            // }}
            setIsShown={() => {
              setNewClassModalShown(true);
            }}
          />
        </Column>
      </DataContainer>
    </Column>
  );
};

const ExpandedClassInfo: React.FC<{
  cls: TrainerClassesPersistent;
}> = ({ cls }) => {
  const [searchText, setSearchText] = useState<string>("");

  return (
    <Container>
      <Usertable
        rowKey="username"
        columns={[
          {
            dataIndex: "username",
            title: "Username",
            filteredValue: [searchText],
            onFilter: (value, record) => {
              return (
                (record as TraderPersistent).username
                  .toLowerCase()
                  .includes(value.toString().toLowerCase()) ||
                (record as TraderPersistent).first_name
                  .toLowerCase()
                  .includes(value.toString().toLowerCase()) ||
                (record as TraderPersistent).last_name
                  .toLowerCase()
                  .includes(value.toString().toLowerCase())
              );
            },
          },
          {
            dataIndex: "first_name",
            title: "First name",
            filteredValue: [searchText],
            onFilter: (value, record) => {
              return (
                (record as TraderPersistent).username
                  .toLowerCase()
                  .includes(value.toString().toLowerCase()) ||
                (record as TraderPersistent).first_name
                  .toLowerCase()
                  .includes(value.toString().toLowerCase()) ||
                (record as TraderPersistent).last_name
                  .toLowerCase()
                  .includes(value.toString().toLowerCase())
              );
            },
          },
          {
            dataIndex: "last_name",
            title: "Last name",
            filteredValue: [searchText],
            onFilter: (value, record) => {
              return (
                (record as TraderPersistent).username
                  .toLowerCase()
                  .includes(value.toString().toLowerCase()) ||
                (record as TraderPersistent).first_name
                  .toLowerCase()
                  .includes(value.toString().toLowerCase()) ||
                (record as TraderPersistent).last_name
                  .toLowerCase()
                  .includes(value.toString().toLowerCase())
              );
            },
          },
          // {
          //     title: 'Actions',
          //     render: (_, record) => (
          //         <Space size="middle">
          //             <Button onClick={async () => {
          //                 await adminService.deleteTrainer(record.id)
          //                 await fetchTrainers();
          //             }}>Delete</Button>
          //             <Button onClick={() => {
          //                 setSelectedEditTrainer({ ...record, customer_id: 1 })
          //                 setEditTrainerModalShown(true);
          //             }}>Edit</Button>
          //         </Space>
          //     ),
          // },
        ]}
        dataSource={cls.assigned_students}
        pagination={false}
        // footer={() => <AddNewButton
        //     modalShown={setNewTrainerModalShown}
        // />}
      />
      {/* <Column>
                    {
                        React.Children.toArray(
                            cls.assigned_students.map((student) => {
                                return (
                                    <Row>
                                        {student.first_name} {student.last_name} {student.username}
                                    </Row>
                                )
                            }
                            )
                        )
                    }
                </Column> */}
    </Container>
  );
};

const Container = styled(Row)`
  gap: 5px;
  background: #1f272d;
  border-radius: 5px;
  padding: 11px 9px;
  margin-right: 5px;
`;

const Usertable = styled(Table)`
  .ant-table {
    &-wrapper {
      width: 100%;
    }
    &-cell {
      background: #2d3942 !important;
      color: #f8f7f7 !important;
    }
    &-thead &-cell {
      color: white !important;
      background: #3d4d59 !important;
      border-bottom-color: #ded9d9 !important;
      text-decoration: underline;
    }
    &-footer {
      background: #4e6373 !important;
    }
  }

  .ant-table-tbody > tr:nth-of-type(even) > td {
    background: #3b4b57 !important;
  }

  .ant-table-thead > tr > th {
    padding: 0.25rem;
    background: #4d6373;
    color: #f8f7f7;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #556e80 !important;
  }
`;
export default AdminClassesPanel;
