import { createAction } from "@reduxjs/toolkit";
import {
  ROOM_CLEAR,
  ROOM_CONNECT,
  ROOM_REQUEST_INITIAL_DATA,
  ROOM_RETRIEVE_INITIAL_DATA,
  ROOM_RESUME,
  ROOM_UPDATE,
  ROOM_PAUSE,
  ROOM_FINISH,
} from "./constants";
import {
  InitialDataPayload,
  RoomPayload,
  SwiftYieldCurve,
  YieldCurve,
} from "types";

export const retrieveInitialData = createAction<InitialDataPayload>(
  ROOM_RETRIEVE_INITIAL_DATA
);
export const requestInitialData = createAction(ROOM_REQUEST_INITIAL_DATA);
export const updateRoom = createAction<RoomPayload>(ROOM_UPDATE);

export const resumeRoom = createAction(ROOM_RESUME);
export const connectToRoom = createAction<string>(ROOM_CONNECT);
export const roomPause = createAction(ROOM_PAUSE);
export const clearRoom = createAction(ROOM_CLEAR);
export const finishRoom = createAction(ROOM_FINISH);
export const strategyChanged = createAction<0 | 1>("room/strategyChanged");
export const recievePong = createAction<{ room_time: number }>("traders/PONG");
export const yieldsUpdated =
  createAction<{ yield_curves: SwiftYieldCurve }>("room/yieldsUpdated");
