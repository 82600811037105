import React, { ReactNode } from "react";
import styled from "styled-components";
import { Typography } from "antd";

interface Props {
  icon?: ReactNode;
  title: string;
  active: boolean;
  changeNav: () => void;
  isHidden: boolean;
}

const CBNavItem: React.FC<Props> = ({
  icon,
  title,
  active,
  changeNav,
  isHidden,
}) => {
  return (
    <Container onClick={changeNav} active={active}>
      {icon || <div />}
      <ItemTitle isHidden={isHidden}>{title}</ItemTitle>
    </Container>
  );
};

const Container = styled.div<{ active: boolean }>`
  display: grid;
  grid-template-columns: 20px 1fr;
  align-items: center;
  padding: 10px 15px;
  gap: 10px;

  color: ${(p) => (p.active ? p.theme.color3 : p.theme.gray6)};

  &:hover {
    cursor: pointer;

    svg {
      color: ${(p) => p.theme.color3};
    }
  }
`;

const ItemTitle = styled(Typography.Text)<{ isHidden: boolean }>`
  font-size: 15px;
  font-weight: 500;
  font-family: IBMPlexSans, sans-serif;
  color: inherit;
  white-space: nowrap;

  animation: ${(p) => (p.isHidden ? "none" : "showText 500ms forwards")};

  @keyframes showText {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

export default CBNavItem;
