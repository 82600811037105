import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";

interface PrivateRouteProps extends RouteProps {
  // tslint:disable-next-line:no-any
  // eslint-disable-next-line react/require-default-props
  component?: any;
  // tslint:disable-next-line:no-any
  // eslint-disable-next-line react/require-default-props
  children?: any;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, children, ...rest } = props;

  const isAuthenticated = useSelector(
    (state: RootState) => state.user.isAuthenticated
  );

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        // eslint-disable-next-line no-nested-ternary
        isAuthenticated ? (
          Component ? (
            <Component {...routeProps} />
          ) : (
            children
          )
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: routeProps.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
