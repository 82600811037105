import React from "react";

import styled from "styled-components";

import {
  CC_ACCEPTED,
  CC_STATUS_STR,
  PUT_CALL_STR,
  VOLATILITY_QUOTE,
} from "stateConstants";
import { numberWithCommas, percent2Digits } from "utils/numbers";
import { ClientCall, MATURITY, Tenor } from "types";
import QuotedActions from "./QuotedActions";
import { timeBEtoPretty, timeUnixToDatePretty } from "utils/time";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "store/rootReducer";

type Props = {
  call: ClientCall;
};

const QuotedCC: React.FC<Props> = ({ call }) => {
  const getStatus = () => {
    if (call.status === CC_ACCEPTED) {
      return !call.short_or_long ? "Bought from client" : "Sold to client";
    }
    return CC_STATUS_STR[call.status];
  };
  const sticky_model = useSelector(
    (state: RootState) => state.spot.sticky_model,
    shallowEqual
  );

  return (
    <Container>
      <Header>
        <Details>
          <GreySpan>{call.client_name}</GreySpan>
          <GreySpan>{getStatus()}</GreySpan>
        </Details>
        <Details>
          <YellowSpan>
            {`${
              sticky_model
                ? MATURITY[call.tenor]
                : timeUnixToDatePretty(call.tenor)
            } / ${call.strike} ${
              PUT_CALL_STR[call.put_or_call]
            } / x ${numberWithCommas(call.amount)}`}
          </YellowSpan>

          <GreySpan>
            Bid:{" "}
            {call.quote_type === VOLATILITY_QUOTE ? (
              <YellowSpan>{percent2Digits(call.client_vol_bid)}</YellowSpan>
            ) : (
              <YellowSpan>{call.trader_premium_bid_quote}$</YellowSpan>
            )}{" "}
            / Ask:{" "}
            {call.quote_type === VOLATILITY_QUOTE ? (
              <YellowSpan>{percent2Digits(call.client_vol_ask)}</YellowSpan>
            ) : (
              <YellowSpan>{call.trader_premium_ask_quote}$</YellowSpan>
            )}
          </GreySpan>
        </Details>
      </Header>

      <QuotedActions call={call} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 13px;
`;

const Details = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const YellowSpan = styled.span`
  color: ${(p) => p.theme.primaryDark1};
`;

const GreySpan = styled.span`
  color: #8c8c8c;
`;

export default QuotedCC;
