import { put, takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  ROOM_CONNECT,
  ROOM_NOTIFY,
  ROOM_REQUEST_INITIAL_DATA,
  ROOM_RESUME,
  ROOM_PAUSE,
  ROOM_FINISH,
} from "./constants";
import { socketSendMessage } from "../socket/actions";
import { roomConnectedSuccess, roomDisconnect } from "./reducers";
import { clearRoom } from "./actions";

function* resumeGame() {
  const request = {
    headers: {
      command: "room.cmd.resume",
    },
    body: {},
  };
  yield put(socketSendMessage(JSON.stringify(request)));
}

function* pauseGame() {
  const request = {
    headers: {
      command: "room.cmd.pause",
    },
    body: {},
  };
  yield put(socketSendMessage(JSON.stringify(request)));
}

function* finishGame() {
  const request = {
    headers: {
      command: "room.cmd.finish",
    },
    body: {},
  };
  yield put(socketSendMessage(JSON.stringify(request)));
}

function* requestInitialDataSaga() {
  const request = {
    headers: {
      command: "room.cmd.state.get",
    },
    body: {},
  };
  yield put(socketSendMessage(JSON.stringify(request)));
}

function* connectToRoomSaga(action: PayloadAction<string>) {
  yield put(
    socketSendMessage(
      JSON.stringify({
        headers: {
          command: "connect_to_room",
        },
        room_id: Number(action.payload),
      })
    )
  );
}

function* notifyConnectedSaga() {
  yield put(roomConnectedSuccess());
}

export function* watchRoomAsync() {
  yield takeEvery(ROOM_RESUME, resumeGame);
  yield takeEvery(ROOM_CONNECT, connectToRoomSaga);
  yield takeEvery(ROOM_NOTIFY, notifyConnectedSaga);
  yield takeEvery(ROOM_REQUEST_INITIAL_DATA, requestInitialDataSaga);
  yield takeEvery(ROOM_PAUSE, pauseGame);
  yield takeEvery(ROOM_FINISH, finishGame);
  yield takeEvery(roomDisconnect.type, function* () {
    yield put(clearRoom());
  });
}
