import React, { useEffect, useState } from "react";
import { Button, Modal, InputNumber, Select } from "antd";
import { useAppDispatch } from "store/store";
import {
  RFQMakeCallPayload,
  StrategyType,
  IndividualOptionOptions,
  StraddleOptions,
  StranggleOptions,
  CallCalendarSpreadOptions,
  PutCalendarSpreadOptions,
  BearSpreadOptions,
  BullSpreadOptions,
  ButterflyOptions,
} from "types";
import { CALL } from "stateConstants";
import { rfqMakeCall } from "store/rfq/actions";

// The components
import {
  BearSpreadOption,
  BullSpreadOption,
  ButterflyOption,
  CallCalendarOption,
  IndividualOption,
  PutCalendarOption,
  StraddleOption,
  StranggleOption,
} from "./StrategyInputs";
import { Column } from "components/reusable/GenericStyledComponents";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "store/rootReducer";

const defaultOption: RFQMakeCallPayload = {
  strategy: StrategyType.INDIVIDUAL_OPTION,
  swiftId: 1,
  options: {
    short_or_long: 0,
    amount: 100,
    strike: 250,
    put_or_call: CALL,
    tenor: 0,
  },
};

const StrategyTypeNames = {
  [StrategyType.INDIVIDUAL_OPTION]: "Individual Option",
  [StrategyType.STRADDLE]: "Straddle",
  [StrategyType.STRANGLE]: "Strangle",
  [StrategyType.CALL_CALENDAR_SPREAD]: "Call Calendar Spread",
  [StrategyType.PUT_CALENDAR_SPREAD]: "Put Calendar Spread",
  [StrategyType.BEAR_SPREAD]: "Bear Spread",
  [StrategyType.BULL_SPREAD]: "Bull Spread",
  [StrategyType.BUTTERFLY]: "Butterfly",
};

const StrategyModal: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const [option, setOption] = useState<RFQMakeCallPayload>(defaultOption);
  const swiftId = useSelector(
    (state: RootState) => state.spot.swiftId,
    shallowEqual
  );
  const RFQ_STRATEGIES_ENABLED = useSelector(
    (state: RootState) => state.settings.parameters.RFQ_STRATEGIES_ENABLED,
    shallowEqual
  );
  const visible_vol_eb_tenors = useSelector(
    (state: RootState) => state.spot.visible_vol_eb_tenors[swiftId],
    shallowEqual
  );
  const visible_vol_eb_delta_strike_values = useSelector(
    (state: RootState) =>
      state.spot.visible_vol_eb_delta_strike_values[swiftId],
    shallowEqual
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    setOption((prev) => ({
      ...prev,
      swiftId,
    }));
  }, [swiftId, option.strategy]);

  const handleOk = () => {
    dispatch(rfqMakeCall(option));
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const selectOnChangeGuarded = (value: StrategyType) => {
    switch (value) {
      case StrategyType.INDIVIDUAL_OPTION:
        setOption((prev) => ({
          ...prev,
          strategy: value,
          options: { ...prev.options } as IndividualOptionOptions,
        }));
        break;
      case StrategyType.STRADDLE:
        setOption((prev) => ({
          ...prev,
          strategy: value,
          options: { ...prev.options } as StraddleOptions,
        }));
        break;
      case StrategyType.STRANGLE:
        setOption((prev) => ({
          ...prev,
          strategy: value,
          options: { ...prev.options } as StranggleOptions,
        }));
        break;
      case StrategyType.CALL_CALENDAR_SPREAD:
        setOption((prev) => ({
          ...prev,
          strategy: value,
          options: { ...prev.options } as CallCalendarSpreadOptions,
        }));
        break;
      case StrategyType.PUT_CALENDAR_SPREAD:
        setOption((prev) => ({
          ...prev,
          strategy: value,
          options: { ...prev.options } as PutCalendarSpreadOptions,
        }));
        break;
      case StrategyType.BEAR_SPREAD:
        setOption((prev) => ({
          ...prev,
          strategy: value,
          options: { ...prev.options } as BearSpreadOptions,
        }));
        break;
      case StrategyType.BULL_SPREAD:
        setOption((prev) => ({
          ...prev,
          strategy: value,
          options: { ...prev.options } as BullSpreadOptions,
        }));
        break;
      case StrategyType.BUTTERFLY:
        setOption((prev) => ({
          ...prev,
          strategy: value,
          options: { ...prev.options } as ButterflyOptions,
        }));
        break;
    }
  };

  const setOptions = (
    options:
      | IndividualOptionOptions
      | StraddleOptions
      | StranggleOptions
      | CallCalendarSpreadOptions
      | PutCalendarSpreadOptions
      | BearSpreadOptions
      | BullSpreadOptions
      | ButterflyOptions,
    strategy: StrategyType
  ) => {
    switch (strategy) {
      case StrategyType.INDIVIDUAL_OPTION:
        setOption((prev) => ({
          ...prev,
          strategy,
          options: options as IndividualOptionOptions,
        }));

        break;
      case StrategyType.STRADDLE:
        setOption((prev) => ({
          ...prev,
          strategy,
          options: options as StraddleOptions,
        }));

        break;
      case StrategyType.STRANGLE:
        setOption((prev) => ({
          ...prev,
          strategy,
          options: options as StranggleOptions,
        }));

        break;
      case StrategyType.CALL_CALENDAR_SPREAD:
        setOption((prev) => ({
          ...prev,
          strategy,
          options: options as CallCalendarSpreadOptions,
        }));
        break;
      case StrategyType.PUT_CALENDAR_SPREAD:
        setOption((prev) => ({
          ...prev,
          strategy,
          options: options as PutCalendarSpreadOptions,
        }));
        break;
      case StrategyType.BEAR_SPREAD:
        setOption((prev) => ({
          ...prev,
          strategy,
          options: options as BearSpreadOptions,
        }));
        break;
      case StrategyType.BULL_SPREAD:
        setOption((prev) => ({
          ...prev,
          strategy,
          options: options as BullSpreadOptions,
        }));
        break;
      case StrategyType.BUTTERFLY:
        setOption((prev) => ({
          ...prev,
          strategy,
          options: options as ButterflyOptions,
        }));
        break;
    }
  };

  return (
    <div>
      {RFQ_STRATEGIES_ENABLED === 1 && (
        <Button type="primary" onClick={() => setVisible(true)}>
          Strategies
        </Button>
      )}
      <Modal
        title="Create strategy"
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Column
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            gap: ".5rem",
          }}
        >
          <Select
            defaultValue={option.strategy}
            onChange={(value: StrategyType) => selectOnChangeGuarded(value)}
            dropdownStyle={{
              zIndex: 10000,
              width: "auto",
              minWidth: "max-content",
            }}
          >
            {Object.values(StrategyType)
              .filter((value) => {
                if (typeof value !== "number") return false;
                if (visible_vol_eb_tenors.length <= 1) {
                  if (
                    value === StrategyType.CALL_CALENDAR_SPREAD ||
                    value === StrategyType.PUT_CALENDAR_SPREAD
                  )
                    return false;
                }
                if (visible_vol_eb_delta_strike_values.length <= 1) {
                  if (value === StrategyType.STRANGLE) return false;
                }
                return true;
              })
              .map((type) => (
                <Select.Option key={type} value={type}>
                  {StrategyTypeNames[type as keyof typeof StrategyTypeNames]}
                </Select.Option>
              ))}
          </Select>
          {(() => {
            switch (option.strategy) {
              case StrategyType.INDIVIDUAL_OPTION:
                return (
                  <IndividualOption
                    options={option.options as IndividualOptionOptions}
                    onChange={setOptions}
                  />
                );
              case StrategyType.STRADDLE:
                return (
                  <StraddleOption
                    options={option.options as StraddleOptions}
                    onChange={setOptions}
                  />
                );
              case StrategyType.STRANGLE:
                return (
                  <StranggleOption
                    options={option.options as StranggleOptions}
                    onChange={setOptions}
                  />
                );
              case StrategyType.CALL_CALENDAR_SPREAD:
                return (
                  <CallCalendarOption
                    options={option.options as CallCalendarSpreadOptions}
                    onChange={setOptions}
                  />
                );
              case StrategyType.PUT_CALENDAR_SPREAD:
                return (
                  <PutCalendarOption
                    options={option.options as PutCalendarSpreadOptions}
                    onChange={setOptions}
                  />
                );
              case StrategyType.BEAR_SPREAD:
                return (
                  <BearSpreadOption
                    options={option.options as BearSpreadOptions}
                    onChange={setOptions}
                  />
                );
              case StrategyType.BULL_SPREAD:
                return (
                  <BullSpreadOption
                    options={option.options as BearSpreadOptions}
                    onChange={setOptions}
                  />
                );
              case StrategyType.BUTTERFLY:
                return (
                  <ButterflyOption
                    options={option.options as ButterflyOptions}
                    onChange={setOptions}
                  />
                );
              default:
                return null;
            }
          })()}
        </Column>
      </Modal>
    </div>
  );
};

export default StrategyModal;
