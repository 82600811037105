import React from "react";
import styled from "styled-components";
import { TrainerScenarioBE } from "types";
import StyledText from "../reusable/StyledText";
import TrainerCreateSession from "./TrainerCreateSession";

interface ITrainerCreateSessionCardProps {
  scenario: TrainerScenarioBE;
  // deleteScenario: (id: number) => void;
}

const TrainerCreateSessionCard: React.FC<ITrainerCreateSessionCardProps> = ({
  scenario,
}) => {
  return (
    <div>
      <TrainerCreateSession />
    </div>
  );
};

export default TrainerCreateSessionCard;
