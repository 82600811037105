import React from "react";
import {
  Row,
  Column,
  FloatInputNumber,
} from "components/reusable/GenericStyledComponents";
import StyledText from "components/reusable/StyledText";
import FloatLabel from "components/reusable/FloatLabel";

type ParamProps = {
  data: any;
  dataList: any[];
  setDataList: (val: any) => void;
  idx: number;
};

const ParametersComponent: React.FC<ParamProps> = ({
  data: param,
  dataList: params,
  setDataList,
  idx,
}) => {
  return (
    <Column style={{ margin: "1rem" }}>
      <div
        style={{
          display: "inline-grid",
          gridAutoFlow: "column",
          gridAutoColumns: "1fr 1fr 1fr 1fr",
          columnGap: "1rem",
        }}
      >
        <div>
          <div
            style={{
              wordWrap: "break-word",
              wordBreak: "break-all",
              display: "flex",
              gap: ".25rem",
            }}
          >
            {/* <Tooltip placement='top' title={<span>Testa</span>} arrowPointAtCenter> 
              <InfoCircleOutlined />
            </Tooltip> */}
            <StyledText fontSize={20} fontWeight={20}>
              {param.code}
            </StyledText>
          </div>
        </div>
        <div
          style={{
            wordWrap: "break-word",
            wordBreak: "break-all",
            display: "flex",
            gap: ".25rem",
          }}
        >
          {param.description}
        </div>
        <FloatLabel label="Current" value={param.value}>
          <FloatInputNumber
            value={param.value}
            onChange={(value) => {
              const newParams = [...params];
              newParams[idx] = { ...param, value: value || "0" };
              setDataList(newParams);
            }}
          />
        </FloatLabel>
        <FloatLabel label="Min" value={param.min_value}>
          <FloatInputNumber
            value={param.min_value}
            onChange={(value) => {
              const newParams = [...params];
              newParams[idx] = { ...param, min_value: value || "0" };
              setDataList(newParams);
            }}
          />
        </FloatLabel>
        <FloatLabel label="Max" value={param.max_value}>
          <FloatInputNumber
            value={param.max_value}
            onChange={(value) => {
              const newParams = [...params];
              newParams[idx] = { ...param, max_value: value || "0" };
              setDataList(newParams);
            }}
          />
        </FloatLabel>
      </div>
    </Column>
  );
};

export default ParametersComponent;
