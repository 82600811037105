import {
  BUY,
  CALL,
  PUT,
  SELL,
  SHORT,
  LONG,
  VOLATILITY_QUOTE,
  PREMIUM_QUOTE,
} from "stateConstants";
import { MATURITY } from "./pricer";

export type RFQState = {
  [id: number]: RFQCall;
};

export type StateStrategy =
  | {
    options: { [id: number]: StateIndividualOptionOptions };
    room: string;
    swift: number;
    strategy_name: StrategyType.INDIVIDUAL_OPTION;
  }
  | {
    options: { [id: number]: StateStraddleOptions };
    room: string;
    swift: number;
    strategy_name: StrategyType.STRADDLE;
  }
  | {
    options: { [id: number]: StateStranggleOptions };
    room: string;
    swift: number;
    strategy_name: StrategyType.STRANGLE;
  }
  | {
    options: { [id: number]: StateCallCalendarSpreadOptions };
    room: string;
    swift: number;
    strategy_name: StrategyType.CALL_CALENDAR_SPREAD;
  }
  | {
    options: { [id: number]: StatePutCalendarSpreadOptions };
    room: string;
    swift: number;
    strategy_name: StrategyType.PUT_CALENDAR_SPREAD;
  }
  | {
    options: { [id: number]: StateBullSpreadOptions };
    room: string;
    swift: number;
    strategy_name: StrategyType.BULL_SPREAD;
  }
  | {
    options: { [id: number]: StateBearSpreadOptions };
    room: string;
    swift: number;
    strategy_name: StrategyType.BEAR_SPREAD;
  }
  | {
    options: { [id: number]: StateButterflyOptions };
    room: string;
    swift: number;
    strategy_name: StrategyType.BUTTERFLY;
    low_strike: string;
    atm_strike: string;
    high_strike: string;
    put_or_call: typeof PUT | typeof CALL;
    tenor: number;
  };

export type RFQCall = {
  id: number;
  room_id: string;
  room_time: number;
  market_taker_id: string;
  market_taker_name: string;
  market_maker_id: string;
  market_maker_name: string;
  swift_id: number;
  amount: number;
  strike: number;
  tenor: number;
  put_or_call: typeof PUT | typeof CALL;
  short_or_long: typeof SHORT | typeof LONG;
  status: 0 | 1 | 2 | 3 | 4 | 5;
  strategy_volatility_bid: number;
  strategy_volatility_ask: number;
  strategy_price_bid: number;
  strategy_price_ask: number;
  strategy_name: StrategyType;
  strategy: StateStrategy;
  quote_type: typeof VOLATILITY_QUOTE | typeof PREMIUM_QUOTE;
  volatility: number;
  errorMessage: string;
};

export type RFQCallPayload = Omit<
  RFQCall,
  "strike" | "volatility_bid" | "volatility_ask" | "premium_bid" | "premium_ask"
> & {
  strike: number;
  strategy_volatility_bid: number;
  strategy_volatility_ask: number;
  strategy_price_bid: number;
  strategy_price_ask: number;
};

export type RFQCallGenericPayload = {
  room_id: string;
  room_time: number;
  call: RFQCallPayload;
};

export type RFQCallHitPayload = RFQCallGenericPayload & {
  volatility: string;
};

export type RFQAnswerCallPayload = {
  callId: number;
  bid: number;
  ask: number;
};
export type RFQAnswerStrategyCallPayload = {
  callId: number;
  quotes: {
    [id: string]: {
      volatility_bid: number;
      volatility_ask: number;
    };
  };
};

export type RFQHitCallPayload = {
  callId: number;
  direction: typeof BUY | typeof SELL;
};

export type RFQNtgCallPayload = {
  callId: number;
};

interface BasePayload {
  swiftId: number;
}

type OptionsBase = {
  amount: number;
  short_or_long: typeof SHORT | typeof LONG;
  put_or_call?: typeof PUT | typeof CALL;
};
export type StateOptionsBase = {
  id: number;
  amount: number;
  short_or_long: typeof SHORT | typeof LONG;
  put_or_call?: typeof PUT | typeof CALL;

  volatility_bid: number;
  volatility_ask: number;
  premium_bid: number;
  premium_ask: number;
};

export type IndividualOptionOptions = OptionsBase & {
  strike: number;
  tenor: number;
  put_or_call: typeof PUT | typeof CALL;
};

export type StraddleOptions = OptionsBase & {
  strike: number;
  tenor: number;
};

export type StranggleOptions = OptionsBase & {
  strike1: number;
  strike2: number;
  tenor: number;
};

export type CallCalendarSpreadOptions = OptionsBase & {
  strike: number;
  tenor1: number;
  tenor2: number;
};

export type PutCalendarSpreadOptions = OptionsBase & {
  strike: number;
  tenor1: number;
  tenor2: number;
};
export type BullSpreadOptions = OptionsBase & {
  put_or_call: typeof PUT | typeof CALL;
  strike1: number;
  strike2: number;
  tenor: number;
};
export type BearSpreadOptions = OptionsBase & {
  put_or_call: typeof PUT | typeof CALL;
  strike1: number;
  strike2: number;
  tenor: number;
};
export type ButterflyOptions = OptionsBase & {
  put_or_call: typeof PUT | typeof CALL;
  strike1: number;
  strike2: number;
  strike3: number;
  tenor: number;
};

export type StateIndividualOptionOptions = StateOptionsBase & {
  strike: number;
  tenor: number;
  put_or_call: typeof PUT | typeof CALL;
};

export type StateStraddleOptions = StateOptionsBase & {
  strike: number;
  tenor: number;
  put_or_call: typeof PUT | typeof CALL;
};

export type StateStranggleOptions = StateOptionsBase & {
  strike: number;
  tenor: number;
  put_or_call: typeof PUT | typeof CALL;
};

export type StateCallCalendarSpreadOptions = StateOptionsBase & {
  strike: number;
  tenor: number;
  put_or_call: typeof PUT | typeof CALL;
};

export type StatePutCalendarSpreadOptions = StateOptionsBase & {
  strike: number;
  tenor: number;
  put_or_call: typeof PUT | typeof CALL;
};
export type StateBullSpreadOptions = StateOptionsBase & {
  strike: number;
  tenor: number;
  put_or_call: typeof PUT | typeof CALL;
};
export type StateBearSpreadOptions = StateOptionsBase & {
  strike: number;
  tenor: number;
  put_or_call: typeof PUT | typeof CALL;
};
export type StateButterflyOptions = StateOptionsBase & {
  strike: number;
  tenor: number;
  put_or_call: typeof PUT | typeof CALL;
};

export type RFQMakeIndividualOptionCallPayload = BasePayload & {
  strategy: StrategyType.INDIVIDUAL_OPTION;
  options: IndividualOptionOptions;
};

export type RFQMakeStraddleCallPayload = BasePayload & {
  strategy: StrategyType.STRADDLE;
  options: StraddleOptions;
};

export type RFQMakeStranggleCallPayload = BasePayload & {
  strategy: StrategyType.STRANGLE;
  options: StranggleOptions;
};

export type RFQMakeCallCalendarSpreadPayload = BasePayload & {
  strategy: StrategyType.CALL_CALENDAR_SPREAD;
  options: CallCalendarSpreadOptions;
};

export type RFQMakePutCalendarSpreadPayload = BasePayload & {
  strategy: StrategyType.PUT_CALENDAR_SPREAD;
  options: PutCalendarSpreadOptions;
};
export type RFQMakeBearSpreadPayload = BasePayload & {
  strategy: StrategyType.BEAR_SPREAD;
  options: BearSpreadOptions;
};
export type RFQMakeBullSpreadPayload = BasePayload & {
  strategy: StrategyType.BULL_SPREAD;
  options: BullSpreadOptions;
};
export type RFQMakeButterflyPayload = BasePayload & {
  strategy: StrategyType.BUTTERFLY;
  options: ButterflyOptions;
};

export type StrategyOptions =
  | IndividualOptionOptions
  | StraddleOptions
  | StranggleOptions
  | CallCalendarSpreadOptions
  | PutCalendarSpreadOptions
  | BullSpreadOptions
  | BearSpreadOptions
  | ButterflyOptions;

export type RFQMakeCallPayload =
  | RFQMakeIndividualOptionCallPayload
  | RFQMakeStraddleCallPayload
  | RFQMakeStranggleCallPayload
  | RFQMakeCallCalendarSpreadPayload
  | RFQMakePutCalendarSpreadPayload
  | RFQMakeBearSpreadPayload
  | RFQMakeBullSpreadPayload
  | RFQMakeButterflyPayload;

export enum StrategyType {
  INDIVIDUAL_OPTION,
  STRADDLE,
  STRANGLE,
  CALL_CALENDAR_SPREAD,
  PUT_CALENDAR_SPREAD,
  BULL_SPREAD,
  BEAR_SPREAD,
  BUTTERFLY,
}

export type ButterflyValidators = {
  [swift_id: string]: {
    left_strikes: number[];
    atm_strike: number;
    right_strikes: number[];
    butterfly_available: boolean;
  }
}