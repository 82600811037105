import axios, { AxiosInstance } from "axios";
import { Layout } from "react-grid-layout";
import {
  Customer,
  TraderPersistent,
  MassRegisterPayload,
  TrainerEditPayload,
  TrainerScenarioBE,
  AdminScenarioBE,
  NewTrainerScenarioBE,
} from "types";
import { MetricsArray } from "types/reports";

export default class AdminService {
  private httpClient: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.httpClient = client;
  }

  async updateScenarioJson(
    scenario: NewTrainerScenarioBE
  ): Promise<NewTrainerScenarioBE> {
    const response = await this.httpClient.put<NewTrainerScenarioBE>(
      `/api/update_scenario_json/${scenario.id}`,
      { scenario }
    );
    return response.data;
  }

  async updateScenarioXLSX(id: number, data: any): Promise<any> {
    const response = await this.httpClient.put<any>(
      `/api/update_scenario_excel/${id}`,
      data
    );
    return response.data;
  }
  async uploadScenarioJson(
    scenario: NewTrainerScenarioBE
  ): Promise<NewTrainerScenarioBE> {
    const response = await this.httpClient.put<NewTrainerScenarioBE>(
      `/api/upload_scenario_json`,
      { scenario }
    );
    return response.data;
  }

  async simulateVol(data: any): Promise<any> {
    const response = await this.httpClient.post<any>(
      `/api/simulate_volatility_evolution`,
      data
    );
    return response.data;
  }
  async uploadScenarioXLSX(data: any): Promise<any> {
    const response = await this.httpClient.put<any>(
      `/api/upload_scenario_excel`,
      data
    );
    return response.data;
  }

  async deleteScenario(id: number): Promise<any> {
    const response = await this.httpClient.delete<any>(
      `/api/get_scenario/${id}`
    );
    return response.data;
  }

  async fetchSwifts(): Promise<any> {
    const response = await this.httpClient.get<any>(`/api/swifts`);
    return response.data;
  }

  async fetchAllClasses(customer_id: number): Promise<any> {
    const response = await this.httpClient.get<any>(
      `/api/classes/customer_id=${customer_id}`
    );
    return response.data;
  }

  async editClass(class_id: number, edited_class: any): Promise<any> {
    const response = await this.httpClient.put<any>(
      `api/classes/edit/${class_id}`,
      edited_class
    );
    return response.data;
  }

  async createClass(cls: any): Promise<any> {
    const response = await this.httpClient.post<any>(`api/classes/create`, cls);
    return response.data;
  }

  async deleteClass(class_id: number): Promise<any> {
    const response = await this.httpClient.delete<any>(
      `api/classes/delete/${class_id}`
    );
    return response.data;
  }

  async fetchAllTrainers(): Promise<any> {
    const response = await this.httpClient.get<any>(`/api/trainers`);
    return response.data;
  }

  async fetchAllTrainerOfCustomer(customer_id: number): Promise<any> {
    const response = await this.httpClient.get<any>(
      `/api/trainers/customer_id=${customer_id}`
    );
    return response.data;
  }

  async registerTrainer(
    customer_id: number,
    trainer: Omit<TraderPersistent, "id">
  ): Promise<any> {
    const response = await this.httpClient.post<any>(`/api/trainers/register`, {
      ...trainer,
      customer_id,
    });
    return response.data;
  }
  async deleteTrainer(id: number): Promise<any> {
    const response = await this.httpClient.delete<any>(
      `/api/users/delete/${id}`
    );
    return response.data;
  }

  async editTrainer(trainer: TrainerEditPayload): Promise<any> {
    const response = await this.httpClient.put<any>(
      `/api/trainers/trainer_id=${trainer.id}`,
      trainer
    );
    return response.data;
  }

  async getAllCustomers(): Promise<Customer[]> {
    const response = await this.httpClient.get<Customer[]>(`/api/customers`);
    return response.data;
  }

  async getCustomerById(id: number): Promise<Customer> {
    const response = await this.httpClient.get<Customer>(
      `/api/customers/${id}`
    );
    return response.data;
  }
  async createCustomer(name: string): Promise<string> {
    const response = await this.httpClient.post<string>(`/api/customers`, {
      name,
    });
    return response.data;
  }

  async setScenarioLayout(
    components: Layout[],
    room_id: string
  ): Promise<Layout[]> {
    const response = await this.httpClient.post<Layout[]>(
      `/api/scenario/layout`,
      {
        components,
        room_id,
      }
    );
    return response.data;
  }
  async setPersonalLayout(
    components: Layout[],
    room_id: string
  ): Promise<Layout[]> {
    const response = await this.httpClient.post<Layout[]>(
      `/api/scenario/personal_layout`,
      {
        components,
        room_id,
      }
    );
    return response.data;
  }

  async tryRegisterUsersXlsx(users: FormData): Promise<MassRegisterPayload> {
    const config = { headers: { "Content-Type": "multipart/form-data" } };

    const response = await this.httpClient.post<any>(
      `/api/o/mass_register/dry=True`,
      users,
      config
    );
    return response.data;
  }

  async registerUsersXlsx(users: FormData): Promise<MassRegisterPayload> {
    const config = { headers: { "Content-Type": "multipart/form-data" } };

    const response = await this.httpClient.post<any>(
      `/api/o/mass_register`,
      users,
      config
    );
    return response.data;
  }

  async tryRegisterUsersList(
    class_id: number,
    users: TrainerEditPayload[],
    customer_id: number
  ): Promise<MassRegisterPayload> {
    const response = await this.httpClient.post<any>(
      `/api/o/mass_register/dry=True`,
      { class_id, users, customer_id }
    );
    return response.data;
  }

  async registerUsersList(
    class_id: number,
    users: TrainerEditPayload[],
    customer_id: number
  ): Promise<MassRegisterPayload> {
    const response = await this.httpClient.post<any>(`/api/o/mass_register`, {
      class_id,
      users,
      customer_id,
    });
    return response.data;
  }

  async getAllScenarios(): Promise<AdminScenarioBE[]> {
    const response = await this.httpClient.get<AdminScenarioBE[]>(
      "/api/get_scenarios"
    );
    return response.data;
  }
  async updateMetrics(data: any): Promise<MetricsArray> {
    const response = await this.httpClient.put(`/api/adminmetrics/`, data);
    return response.data;
  }
  async getMetrics(): Promise<MetricsArray> {
    const response = await this.httpClient.get<MetricsArray>(
      `/api/adminmetrics/`
    );
    return response.data;
  }

  async addMetric(metricData: any): Promise<any> {
    const response = await this.httpClient.post(
      `/api/adminmetrics/add/`,
      metricData
    );
    return response.data;
  }

  async deleteMetric(metricId: number): Promise<any> {
    const response = await this.httpClient.delete(
      `/api/adminmetrics/${metricId}/delete/`
    );
    return response.data;
  }

  async addSubmetric(metricId: number, submetricData: any): Promise<any> {
    const response = await this.httpClient.post(
      `/api/adminmetrics/${metricId}/add_submetric/`,
      submetricData
    );
    return response.data;
  }

  async deleteSubmetric(submetricId: number): Promise<any> {
    const response = await this.httpClient.delete(
      `/api/adminsubmetrics/${submetricId}/delete/`
    );
    return response.data;
  }
}

export const trainerService = new AdminService(axios);
