import {
  Row,
  Column,
  FloatInputNumber,
} from "components/reusable/GenericStyledComponents";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Close from "assets/close.svg";
import { secondsToHHMM } from "utils/time";
import StyledText from "components/reusable/StyledText";
import { Input, Button, Modal, Form, InputNumber, Tooltip } from "antd";
import FloatLabel from "components/reusable/FloatLabel";
import ConfirmationPromptModal from "components/Modal/ConfirmationPromptModal";
import { AdminNews } from "types";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";

const { TextArea } = Input;

interface NewsFormProps {
  isVisible: boolean;
  onSubmit: (news: AdminNews) => void;
  onCancel: () => void;
  initialNews: AdminNews;
}

const NewsForm: React.FC<NewsFormProps> = ({
  isVisible,
  onSubmit,
  onCancel,
  initialNews,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    // When initialNews or isVisible changes, reset and set the form fields
    form.setFieldsValue({
      headline: initialNews.headline,
      body: initialNews.body,
      delta_seconds: initialNews.delta_seconds,
      ...initialNews.market_impact[Object.keys(initialNews.market_impact)[0]], // Assuming a single ticker
    });
  }, [initialNews, form, isVisible]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const ticker = Object.keys(initialNews.market_impact)[0]; // Assuming a single ticker for simplicity

      const submittedNews: AdminNews = {
        headline: values.headline,
        body: values.body,
        delta_seconds: values.delta_seconds,
        market_impact: {
          [ticker]: {
            spot: values.spot,
            lt_steep: values.lt_steep,
            put_skew: values.put_skew,
            st_steep: values.st_steep,
            call_skew: values.call_skew,
            vol_parallel: values.vol_parallel,
          },
        },
      };

      onSubmit(submittedNews);
    } catch (info) {
      console.log("Validate Failed:", info);
    }
  };

  return (
    <Modal
      title="Add news"
      visible={isVisible}
      onOk={handleSubmit}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Save
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="headline"
          label="Headline"
          rules={[{ required: true, message: "Please input the headline!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="body"
          label="Body"
          rules={[{ required: true, message: "Please input the news body!" }]}
        >
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item
          name="delta_seconds"
          label="Time (seconds)"
          rules={[
            { required: true, message: "Please input the time in seconds!" },
          ]}
        >
          <InputNumber min={0} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name="spot"
          label="Spot"
          rules={[{ required: true, message: "Please input the spot impact!" }]}
        >
          <InputNumber min={-2} max={2} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          label="Lt. steepness"
          name="lt_steep"
          rules={[
            {
              required: true,
              message: "Please input the long term steepness!",
            },
          ]}
        >
          <InputNumber min={-2} max={2} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          label="Put skew"
          name="put_skew"
          rules={[
            { required: true, message: "Please input the spot put skew!" },
          ]}
        >
          <InputNumber min={-2} max={2} style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item
          label="St. steepness"
          name="st_steep"
          rules={[
            {
              required: true,
              message: "Please input the short term steepness!",
            },
          ]}
        >
          <InputNumber min={-2} max={2} style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item
          label="Call skew"
          name="call_skew"
          rules={[{ required: true, message: "Please input the call skew!" }]}
        >
          <InputNumber min={-2} max={2} style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item
          label="Vol parallel"
          name="vol_parallel"
          rules={[
            { required: true, message: "Please input the VOL parallel shift!" },
          ]}
        >
          <InputNumber min={-2} max={2} style={{ width: "100%" }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

type NewsProps = {
  data: AdminNews;
  dataList: AdminNews[];
  setDataList: (val: any) => void;
  idx: number;
  setExpanded?: (val: boolean) => void;
};
const NewsComponent: React.FC<NewsProps> = ({
  data: news,
  dataList: allNews,
  setDataList,
  idx,
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      {expanded ? (
        <Column
          style={{
            marginTop: ".5rem",
            marginBottom: ".5rem",
            alignItems: "center",
          }}
        >
          <Expanded
            data={news}
            dataList={allNews}
            setDataList={setDataList}
            idx={idx}
            setExpanded={setExpanded}
          />
        </Column>
      ) : (
        <Row
          style={{
            marginTop: ".5rem",
            marginBottom: ".5rem",
            alignItems: "center",
          }}
        >
          <Card
            data={news}
            dataList={allNews}
            setDataList={setDataList}
            idx={idx}
            setExpanded={setExpanded}
          />
        </Row>
      )}
    </>
  );
};

const Card: React.FC<
  Omit<NewsProps, "isEditing" | "setIsEditing" | "saveEdit">
> = ({ data: news, setExpanded, idx, dataList: allNews, setDataList }) => {
  const swift = Object.keys(news.market_impact)[0];
  const [showPrompt, setShowPrompt] = useState(false);
  const { spot, vol_parallel, put_skew, call_skew, st_steep, lt_steep } =
    news.market_impact[swift];
  const [insertionIndex, setInsertionIndex] = useState<number | null>(null);
  const [insertDirection, setInsertDirection] = useState<
    "above" | "below" | null
  >(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const insertNewsItem = (direction: "above" | "below", index: number) => {
    setInsertionIndex(index);
    setInsertDirection(direction);
    setShowModal(true);
  };

  const addNewsItem = (newItem: AdminNews) => {
    if (insertionIndex === null || insertDirection === null) return;
    if (insertionIndex !== null && insertDirection) {
      const newList = [...allNews];

      if (insertDirection === "below") {
        console.log(`Add below clicked item at idx ${insertionIndex + 1}`);
        newList.splice(insertionIndex + 1, 0, newItem);
      } else {
        console.log(`Add above clicked item at idx ${insertionIndex}`);
        newList.splice(insertionIndex, 0, newItem);
      }
      setDataList(newList);
      setInsertionIndex(null);
      setInsertDirection(null);
    }
    setShowModal(false);
  };
  return (
    <div style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
      <NewsForm
        isVisible={showModal}
        onSubmit={addNewsItem}
        onCancel={() => setShowModal(false)}
        initialNews={news}
      />
      <ConfirmationPromptModal
        prompt="Are you sure you want to delete this event?"
        isShown={showPrompt}
        onClose={() => setShowPrompt(false)}
        action={() => {
          const newDataList = [...allNews];
          newDataList.splice(idx, 1);
          setDataList(newDataList);
        }}
      />
      <Column style={{ gap: ".25rem" }}>
        <Tooltip title="Delete">
          <XButton
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
            onClick={() => setShowPrompt(true)}
          >
            <img src={Close} alt="close button" />
          </XButton>
        </Tooltip>
        <Tooltip title="Add above">
          <XButton onClick={() => insertNewsItem("above", idx)}>
            <PlusCircleOutlined />
            <ArrowUpOutlined role="button" />
          </XButton>
        </Tooltip>
        <Tooltip title="Add below">
          <XButton onClick={() => insertNewsItem("below", idx)}>
            <PlusCircleOutlined />
            <ArrowDownOutlined role="button" />
          </XButton>
        </Tooltip>
      </Column>
      <NewsCard
        onClick={() => {
          if (setExpanded) setExpanded(true);
        }}
      >
        <Row>
          <StyledText fontWeight={800} fontSize={20}>
            {secondsToHHMM(news.delta_seconds)}
          </StyledText>
        </Row>
        <Row>
          <StyledText fontWeight={400} fontSize={16}>
            Impact: Spot {spot > 0 && "+"}
            {spot}, Vol/P {vol_parallel > 0 && "+"}
            {vol_parallel}, CallSK {call_skew > 0 && "+"}
            {call_skew}, PutSK {put_skew > 0 && "+"}
            {put_skew}, SteepST {st_steep > 0 && "+"}
            {st_steep}, SteepLT {lt_steep}
          </StyledText>
        </Row>
        <Row>Headline: {news.headline}</Row>
      </NewsCard>
    </div>
  );
};

const Expanded: React.FC<
  NewsProps & {
    setExpanded: (val: boolean) => void;
  }
> = ({ data: news, dataList: allNews, setDataList, idx, setExpanded }) => {
  const [editedNews, setEditedNews] = useState(news);
  const swift = Object.keys(editedNews.market_impact)[0];

  const saveAndClose = () => {
    const newNewsList = [...allNews];
    newNewsList[idx] = editedNews;
    setDataList(newNewsList);
    if (setExpanded) setExpanded(false);
  };

  const updateNews = (newNews: any) => {
    setEditedNews((prevNews: any) => ({ ...prevNews, ...newNews }));
  };

  const updateMarketImpact = (newImpact: any) => {
    setEditedNews((prevNews: any) => ({
      ...prevNews,
      market_impact: {
        [swift]: { ...prevNews.market_impact[swift], ...newImpact },
      },
    }));
  };

  return (
    <Column
      style={{
        marginLeft: "5rem",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <Button onClick={saveAndClose}>Save</Button>
      <Button
        onClick={() => {
          if (setExpanded) setExpanded(false);
        }}
      >
        Minimize
      </Button>
      <FloatLabel label="Headline" value={editedNews.headline}>
        <TextArea
          autoSize
          value={editedNews.headline}
          onChange={(e) => {
            updateNews({ headline: e.target.value || "" });
          }}
        />
      </FloatLabel>
      <FloatLabel label="Body" value={news.body}>
        <TextArea
          autoSize
          value={news.body}
          onChange={(e) => {
            updateNews({ body: e.target.value || "" });
          }}
        />
      </FloatLabel>
      <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
        <FloatLabel label="Time(s)" value={news.delta_seconds}>
          <FloatInputNumber
            type="number"
            min={0}
            onChange={(value) => {
              const delta_seconds =
                typeof value === "number" && !Number.isNaN(value)
                  ? value
                  : news.delta_seconds;
              updateNews({ delta_seconds });
            }}
            value={news.delta_seconds}
          />
        </FloatLabel>
        <FloatLabel label="Time(m)" value={news.delta_seconds / 60}>
          <FloatInputNumber
            type="number"
            min={0}
            onChange={(value) => {
              // Convert minutes to seconds
              const delta_seconds =
                typeof value === "number" && !Number.isNaN(value)
                  ? value * 60
                  : news.delta_seconds;
              updateNews({ delta_seconds });
            }}
            value={news.delta_seconds / 60}
          />
        </FloatLabel>
        <FloatLabel label="Time(h)" value={news.delta_seconds / 3600}>
          <FloatInputNumber
            type="number"
            min={0}
            onChange={(value) => {
              // Convert hours to seconds
              const delta_seconds =
                typeof value === "number" && !Number.isNaN(value)
                  ? value * 3600
                  : news.delta_seconds;
              updateNews({ delta_seconds });
            }}
            value={news.delta_seconds / 3600}
          />
        </FloatLabel>
      </div>
      <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
        <FloatLabel label="Spot" value={news.market_impact[swift].spot}>
          <FloatInputNumber
            type="number"
            onChange={(value: any) => {
              updateMarketImpact({ spot: value || 0 });
            }}
            value={news.market_impact[swift].spot}
          />
        </FloatLabel>
        <FloatLabel
          label="Lt. steepness"
          value={news.market_impact[swift].lt_steep}
        >
          <FloatInputNumber
            type="number"
            onChange={(value: any) => {
              updateMarketImpact({ lt_steep: value || 0 });
            }}
            value={news.market_impact[swift].lt_steep}
          />
        </FloatLabel>
        <FloatLabel label="Put skew" value={news.market_impact[swift].put_skew}>
          <FloatInputNumber
            type="number"
            onChange={(value: any) => {
              updateMarketImpact({ put_skew: value || 0 });
            }}
            value={news.market_impact[swift].put_skew}
          />
        </FloatLabel>

        <FloatLabel
          label="St. steepness"
          value={news.market_impact[swift].st_steep}
        >
          <FloatInputNumber
            type="number"
            onChange={(value: any) => {
              updateMarketImpact({ st_steep: value || 0 });
            }}
            value={news.market_impact[swift].st_steep}
          />
        </FloatLabel>

        <FloatLabel
          label="Call skew"
          value={news.market_impact[swift].call_skew}
        >
          <FloatInputNumber
            type="number"
            onChange={(value: any) => {
              updateMarketImpact({ call_skew: value || 0 });
            }}
            value={news.market_impact[swift].call_skew}
          />
        </FloatLabel>

        <FloatLabel
          label="Vol parallel"
          value={news.market_impact[swift].vol_parallel}
        >
          <FloatInputNumber
            type="number"
            onChange={(value: any) => {
              updateMarketImpact({ vol_parallel: value || 0 });
            }}
            value={news.market_impact[swift].vol_parallel}
          />
        </FloatLabel>
      </div>
    </Column>
  );
};

const NewsCard = styled.div`
  &:hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
  }
`;

const XButton = styled.div`
  &:hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
  }
`;

export default NewsComponent;
