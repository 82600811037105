import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { useAppDispatch } from "store/store";
import { requestCBState } from "store/centralbank/actions";
import { numberWithCommas } from "utils/numbers";
import {
  Column,
  GreenCircle,
  RedCircle,
} from "components/reusable/GenericStyledComponents";
import { Checkbox, Table, Typography } from "antd";
import type { ColumnsType } from "antd/es/table";
import StyledText from "components/reusable/StyledText";
import { Permission, TraderStat } from "types";

const CBTraders: React.FC = () => {
  const { traders_stats } = useSelector(
    (state: RootState) => state.centralbank
  );
  const permission = useSelector((state: RootState) => state.user.permission);
  const { PNL_DISPLAY_DIVIDER } = useSelector(
    (state: RootState) => state.settings.parameters
  );
  const [filterActive, setFilterActive] = useState<boolean>(false);

  const [rows, setRows] = useState<TraderStat[]>([]);
  const dispatch = useAppDispatch();
  const getState = () => {
    dispatch(requestCBState());
  };

  console.log(traders_stats);
  

  useEffect(() => {
    getState();
    const req = setInterval(() => {
      getState();
    }, 4000);
    return () => {
      clearInterval(req);
    };
  }, []);

  useEffect(() => {
    if (traders_stats)
      setRows(
        traders_stats.map((trader) => {
          return {
            ...trader,
            realized_pnl: trader.realized_pnl / PNL_DISPLAY_DIVIDER || 0,
            unrealized_pnl: trader.unrealized_pnl / PNL_DISPLAY_DIVIDER || 0,
            pnl: trader.pnl / PNL_DISPLAY_DIVIDER || 0,
          };
        })
      );
  }, [traders_stats]);

  const columns: ColumnsType<TraderStat> = [
    {
      dataIndex: "online",
      title: "Online",
      width: "100px",
      render: (online) => (online ? <GreenCircle /> : <RedCircle />),
      sorter: (a, b) => (a.online ? 1 : 0),
      defaultSortOrder: "descend",
      filters: [
        {
          text: "Online",
          value: true,
        },
        {
          text: "Offline",
          value: false,
        },
      ],
      onFilter: (value: string | number | boolean, record: TraderStat) => {
        return record.online === value;
      },
    },
    {
      dataIndex: "name",
      title: "Trader name",
      sorter: (a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      },
    },
    {
      dataIndex: "pnl",
      title: "PnL",
      render: (v) => numberWithCommas(v, 0),
      defaultSortOrder: "descend",
      sorter: (a, b) => a.pnl - b.pnl,
    },
    {
      dataIndex: ["risks", "delta"],
      title: "Delta",
      render: (v, record) => numberWithCommas(Number(record.positions.amount)+Number(record.risks.delta), 0),
      // defaultSortOrder: "descend",
      sorter: (a, b) => a.risks.delta - b.risks.delta,
    },
    {
      dataIndex: ["risks", "gamma"],
      title: "Gamma",
      render: (v) => numberWithCommas(v, 0),
      // defaultSortOrder: "descend",
      sorter: (a, b) => a.risks.gamma - b.risks.gamma,
    },
    {
      dataIndex: ["risks", "vega"],
      title: "Vega",
      render: (v) => numberWithCommas(v, 0),
      // defaultSortOrder: "descend",
      sorter: (a, b) => a.risks.vega - b.risks.vega,
    },
    {
      dataIndex: ["risks", "theta"],
      title: "Theta",
      render: (v) => numberWithCommas(v, 0),
      // defaultSortOrder: "descend",
      sorter: (a, b) => a.risks.theta - b.risks.theta,
    },
    {
      dataIndex: "client_deals",
      title: "Client deals",
    },
    ...(permission === Permission.Admin
      ? [
          {
            dataIndex: "unrealized_pnl",
            title: "Unrealized PnL",
            render: (v:any) => numberWithCommas(v, 0),
          },
          {
            dataIndex: "realized_pnl",
            title: "Realized PnL",
            render: (v:any) => numberWithCommas(v, 0),
          },
        ]
      : []),
  ];

  return (
    <Column>
      {PNL_DISPLAY_DIVIDER > 1 && (
        <Typography.Paragraph>
          Note: The PnL values are divided by {PNL_DISPLAY_DIVIDER} (
          PNL_DISPLAY_DIVIDER parameter )
        </Typography.Paragraph>
      )}

      <Checkbox checked={filterActive} onChange={(e) => {console.log(e);
       setFilterActive(!filterActive)}}>Only show active traders</Checkbox>

      <Typography.Text>
        Traders online: {rows.filter((r) => r.online).length} / {rows.length}
      </Typography.Text>

      <Table
        bordered
        sticky
        loading={!!rows && Object.keys(rows).length === 0}
        rowKey="name"
        columns={columns}
        dataSource={filterActive ? rows.filter((row)=> row.pnl !==0) : rows}
        pagination={false}
      />
    </Column>
  );
};

export default CBTraders;
