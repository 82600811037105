import axios, { AxiosInstance } from "axios";
import { TraderReportsType } from "types/reports";

export default class ReportsService {
  private httpClient: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.httpClient = client;
  }

  async getRoomReports(room_id: string): Promise<any[]> {
    const response = await this.httpClient.get<any[]>(
      `/api/reports/${room_id}`
    );
    return response.data;
  }

  async getTraderReports(
    room_id: string,
    trader_id: string
  ): Promise<TraderReportsType> {
    const response = await this.httpClient.get<TraderReportsType>(
      `/api/reports/${room_id}/${trader_id}`
    );
    return response.data;
  }
}

export const reportsService = new ReportsService(axios);
