import { Typography } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { BUY } from "stateConstants";
import { newSelectPricerData } from "store/newpricer/selectors";
import { RootState } from "store/rootReducer";
import { NewPricerColumn } from "types";
import { numberWithCommas, roundPremium } from "utils/numbers";

const PricerNewSummary: React.FC = () => {
  const pricers = useSelector(newSelectPricerData);
  const swift = useSelector((state: RootState) => state.swifts.selectedSwift);

  // Convert pricers object to an array
  const pricerArray: NewPricerColumn[] = Object.values(pricers);

  // Calculate the sums with direction adjustment
  const totalPremium = pricerArray.reduce(
    (sum, pricer) =>
      sum +
      pricer.premium *
        (pricer.direction === BUY ? -1 : 1) *
        swift.vol_ticket_unit,
    0
  );
  const totalDelta = pricerArray.reduce(
    (sum, pricer) => sum + pricer.noOfDelta,
    0
  );
  const totalGamma = pricerArray.reduce(
    (sum, pricer) => sum + pricer.noOfGamma,
    0
  );
  const totalVega = pricerArray.reduce(
    (sum, pricer) => sum + pricer.vegaDollars,
    0
  );

  return (
    <div>
      <Typography.Text>
        P:
        {numberWithCommas(
          roundPremium(Number(totalPremium.toFixed(2)))
        )} D: {numberWithCommas(totalDelta.toFixed(2))} G:{" "}
        {numberWithCommas(totalGamma.toFixed(2))} V:{" "}
        {numberWithCommas(totalVega.toFixed(2))}
      </Typography.Text>
    </div>
  );
};

export default PricerNewSummary;
