import { Button, Input, Space, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import ConfirmationPromptModal from "components/Modal/ConfirmationPromptModal";
import { Column } from "components/reusable/GenericStyledComponents";
import StyledText from "components/reusable/StyledText";
import React, { useState, useEffect } from "react";
import { adminService } from "services";
import styled from "styled-components";
import { Customer, TraderPersistent, TrainerEditPayload } from "types";
import CreateTrainerModal from "./Modals/CreateTrainerModal";
import EditTrainerModal from "./Modals/EditTrainerModal";

const AdminTrainersPanel: React.FC<{ customer: Customer }> = ({ customer }) => {
  const [trainers, setTrainers] = useState<TraderPersistent[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [newTrainerModalShown, setNewTrainerModalShown] =
    useState<boolean>(false);
  const [editTrainerModalShown, setEditTrainerModalShown] =
    useState<boolean>(false);
  const [selectedEditTrainer, setSelectedEditTrainer] =
    useState<TrainerEditPayload>({
      id: -1,
      first_name: "",
      last_name: "",
      username: "",
      password: "",
      customer_id: 1,
    });
  const [showPrompt, setShowPrompt] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");

  const fetchTrainers = async () => {
    const fetchedTrainers = await adminService.fetchAllTrainerOfCustomer(
      customer.id
    );
    setTrainers(fetchedTrainers);
    setLoading(false);
  };

  useEffect(() => {
    fetchTrainers();
  }, []);

  return (
    <Column>
      <ConfirmationPromptModal
        prompt={`Are you sure you want to delete ${selectedEditTrainer.username}?`}
        onClose={() => setShowPrompt(false)}
        isShown={showPrompt}
        action={async () => {
          await adminService.deleteTrainer(selectedEditTrainer.id);
          await fetchTrainers();
        }}
      />
      <StyledText fontSize={20} fontWeight={600} fontFamily="IBMPlexSans">
        Create and edit trainers
      </StyledText>
      <CreateTrainerModal
        isShown={newTrainerModalShown}
        closeModal={async () => {
          await fetchTrainers();
          setNewTrainerModalShown(false);
        }}
      />
      <EditTrainerModal
        isShown={editTrainerModalShown}
        oldTrainer={selectedEditTrainer}
        closeModal={() => {
          setEditTrainerModalShown(false);
        }}
      />
      <div
        style={{
          marginTop: "1rem",
          marginLeft: ".25rem",
        }}
      >
        <div>
          <CustomInput
            placeholder="Search trainers..."
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            onSearch={(value) => {
              setSearchText(value);
            }}
          />
          <Table
            loading={loading}
            rowKey="username"
            columns={[
              {
                dataIndex: "username",
                title: "Username",
                filteredValue: [searchText],
                onFilter: (value, record) => {
                  return (
                    record.username
                      .toLowerCase()
                      .includes(value.toString().toLowerCase()) ||
                    record.first_name
                      .toLowerCase()
                      .includes(value.toString().toLowerCase()) ||
                    record.last_name
                      .toLowerCase()
                      .includes(value.toString().toLowerCase())
                  );
                },
              },
              {
                dataIndex: "first_name",
                title: "First name",
                filteredValue: [searchText],
                onFilter: (value, record) => {
                  return (
                    record.username
                      .toLowerCase()
                      .includes(value.toString().toLowerCase()) ||
                    record.first_name
                      .toLowerCase()
                      .includes(value.toString().toLowerCase()) ||
                    record.last_name
                      .toLowerCase()
                      .includes(value.toString().toLowerCase())
                  );
                },
              },
              {
                dataIndex: "last_name",
                title: "Last name",
                filteredValue: [searchText],
                onFilter: (value, record) => {
                  return (
                    record.username
                      .toLowerCase()
                      .includes(value.toString().toLowerCase()) ||
                    record.first_name
                      .toLowerCase()
                      .includes(value.toString().toLowerCase()) ||
                    record.last_name
                      .toLowerCase()
                      .includes(value.toString().toLowerCase())
                  );
                },
              },
              {
                title: "Actions",
                render: (_, record) => (
                  <Space size="middle">
                    <Button
                      onClick={() => {
                        setSelectedEditTrainer({ ...record, customer_id: 1 });
                        setShowPrompt(true);
                      }}
                    >
                      Delete
                    </Button>
                    <Button
                      onClick={() => {
                        setSelectedEditTrainer({ ...record, customer_id: 1 });
                        setEditTrainerModalShown(true);
                      }}
                    >
                      Edit
                    </Button>
                  </Space>
                ),
              },
            ]}
            dataSource={trainers}
            pagination={false}
            footer={() => <AddNewButton modalShown={setNewTrainerModalShown} />}
          />
        </div>
      </div>
    </Column>
  );
};

const AddNewButton: React.FC<{
  modalShown: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ modalShown }) => {
  return (
    <Button
      onClick={() => {
        modalShown(true);
      }}
    >
      Add new
    </Button>
  );
};

const CustomInput = styled(Input.Search)`
  .ant-input {
    padding-top: 0.5rem;
    background: white;
    color: black;
    border: 1px solid #232c3270;
  }
`;

export default AdminTrainersPanel;
