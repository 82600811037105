import { createGlobalStyle } from "styled-components";

export type GlobalStyleProps = {
  theme: {
    colors: {
      body: string;
      carouselBackground: string;
      primaryText: string;
      secondaryText: string;
      link: {
        text: string;
      };
      button: {
        background: string;
        text: string;
      };
    };
    font: string;

    // New variables
    primary: string;
    primaryDark1: string;

    color0: string;
    color1: string;
    color2: string;
    color3: string;
    color4: string;

    // antd
    gray4: string;
    gray5: string;
    gray6: string;
    gray7: string;
    gray9: string;
    gray11: string;
  };
};

export const GlobalStyles = createGlobalStyle<GlobalStyleProps>`
.react-resizable-handle {
  border-right: 2px solid gray;
  border-bottom: 2px solid gray; 
}

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #000 inset !important;
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: #FFD369 !important;
  }


  /* Scroll bar stylings */
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: #141414;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;

  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #434343;
    border-right: none;
    border-left: none;
    transition: background .5s ease-in-out;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.primary};
  }

  :root {
    --antd-wave-shadow-color: ${({ theme }) => theme.color3};
  }

  ::selection {
    color: #000000;
    background: ${({ theme }) => theme.color3};
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html {
    box-sizing: border-box;
  }
  
  .ant-input {
    ::-webkit-inner-spin-button::before {
      pointer-events: none;
    }

    ::before {
      color: red !important;
      background: red !important;
    }

    ::after {
      color: red !important;
      background: red !important;
    }
  }
  
  .ant-slider-rail {
    background-color: rgba(0, 0, 0, 0.13) !important;
  }

  .ant-table-filter-dropdown-btns .ant-btn-link {
    color: ${(p) => p.theme.primary};
    
    &[disabled] {
      color: rgba(217, 217, 217, 0.25);
    }
    
    &:hover,
    &:active,
    &:focus {
      color: ${(p) => p.theme.primaryDark1};
    }
  }
  

  .float-label {
    position: relative;
    color: ${({ theme }) => theme.colors.primaryText} !important;
  }

  .ant-progress-text {
    color: ${({ theme }) => theme.colors.primaryText} !important;
  }

  .label {
    color: ${({ theme }) => theme.colors.primaryText} !important;
    font-size: 12px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 12px;
    top: 25%;
    transition: 0.2s ease all;
  }

  .label-float {
    color: ${({ theme }) => theme.colors.primaryText + "98"} !important;
    top: 0;
    margin-bottom: 20px;
    font-size: 10px;
    text-decoration: underline;
  }

  body {
    background: ${({ theme }) => theme.colors.body};
    color: ${({ theme }) => theme.colors.primaryText} !important;
    font-family: ${({ theme }) => theme.font};
  }

  a {
    cursor: pointer;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.color3};
    border-color: ${({ theme }) => theme.color3};
  }

  .ant-checkbox:focus, .ant-checkbox:hover {
    border-color: ${({ theme }) => theme.color3} !important;
    outline: 0;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.color3} !important;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.color3} !important;
    outline: 0;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.color3} !important;
  }

  .ant-checkbox-input:focus, .ant-checkbox-input:hover {
    border-color: ${({ theme }) => theme.color3} !important;
    outline: 0;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.color3} !important;
  }
  
  .disabled-row {
    background-color: #dcdcdc;
    cursor: not-allowed;
  }

  .selected-row {
    background-color: #f5f5f5;
  }
  
  .ant-modal-wrap {
    z-index: 6000 !important;
  }

  .ant-switch-inner-checked {
    color: ${(p) => p.theme.gray11} !important;
  }

  .ant-btn-primary {
    color: ${(p) => p.theme.gray11} !important;
  }

  .ant-tooltip {
    z-index: 9999 !important; 
  }
  
  .ant-select-item {
    width: auto !important;  // Or use 'auto' if 'fit-content' doesn't work as expected
    white-space: nowrap;
  }

  .ant-message-notice-content {
    background: #0F0F0F !important;
    color:  #f0f0f0 !important;
    border: 1px solid gray;
  }

`;
