import React from "react";
import { ClientCall } from "types";

type Props = {
  call: ClientCall;
};

const QuoteCC: React.FC<Props> = ({ call }) => {
  return <div>"QuoteCC"</div>;
};

export default QuoteCC;
