import TrainerQuickActions from "components/GameRoom/TrainerQuickActions";
import { Column, Line } from "components/reusable/GenericStyledComponents";
import React from "react";
import ActiveStrikesGameControls from "./ActiveStrikesGameControls";
import ActiveTenorsGameControls from "./ActiveTenorsGameControls";

const GameControls: React.FC = () => {
  return (
    <Column
      style={{
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Line />
      <Column
        style={{
          width: "80%",
          border: "1px solid gray",
          borderRadius: "1rem",
          marginTop: "2rem",
          padding: "1rem 2rem",
        }}
      >
        <TrainerQuickActions />
        <Line />
        <ActiveTenorsGameControls />
        <Line />
        <ActiveStrikesGameControls />
      </Column>
    </Column>
  );
};

export default GameControls;
