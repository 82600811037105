import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import Modal from "react-modal";
import store from "store/store";
import Router from "./Router";
import themes from "./theme/schema.json";
import { setToLS } from "./utils";
import { ConfigProvider, theme } from "antd";

// CSS imports
// import 'antd/dist/antd.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./index.css";
import "./animations.css";
import "react-toggle/style.css";
import "./theme/fonts/fonts.css";

const Index = () => {
  setToLS("trad-all-themes", themes);

  return (
    <Provider store={store}>
      <ConfigProvider
        theme={{
          algorithm: theme.darkAlgorithm,
          token: {
            colorPrimary: "#FFD369",
            colorTextBase: "#dedede",
          },
          components: {
            Input: {
              colorBgBase: "#222222",
              colorBorder: "#000000",
              colorPrimaryBorder: "#FFD369",
            },
            Button: {
              colorPrimary: "#FFD369",
              colorLink: "#FFD369",
              colorLinkActive: "#FFC233",
              colorLinkHover: "#FFC233",
            },
            Switch: {},
          },
        }}
      >
        <Router />
      </ConfigProvider>
    </Provider>
  );
};

Modal.setAppElement("#root");

ReactDOM.render(<Index />, document.getElementById("root"));
