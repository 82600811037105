import React from "react";
import { useSelector } from "react-redux";

import styled from "styled-components";

import { ASK, BID } from "stateConstants";
import { Row } from "components/reusable/GenericStyledComponents";
import { RootState } from "store/rootReducer";
import OptionRow from "./OptionRow";

const OptionOrders = () => {
  const volEbOrders = useSelector((state: RootState) => state.spot.volEbOrders);
  const bidOrders = volEbOrders.filter(
    (order) => order.amount !== 0 && order.direction === BID
  );
  const askOrders = volEbOrders.filter(
    (order) => order.amount !== 0 && order.direction === ASK
  );

  return (
    <Row style={{ height: "100%", width: "100%", overflow: "hidden", gap: 10 }}>
      <Container>
        {bidOrders.map((order) => (
          <OptionRow key={order.id} order={order} />
        ))}
      </Container>

      <Container>
        {askOrders.map((order) => (
          <OptionRow key={order.id} order={order} />
        ))}
      </Container>
    </Row>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100px;
  overflow-y: auto;
  overflow-x: hidden;

  & div:nth-child(odd) {
    // background: #222b31; // layout
  }

  & div:nth-child(even) {
    // background: #192025; // layout
  }
`;

export default OptionOrders;
