import { Column, Row } from "components/reusable/GenericStyledComponents";
import React, { useState } from "react";
import { MATURITY, Pylon, Pylons } from "types";
import Toggle from "react-toggle";
import styled from "styled-components";
import { CaretUpOutlined } from "@ant-design/icons";

const ContinuousTenors: React.FC<{
  continuousTenors: number[];
  setContinuousTenors: (val: number[]) => void;
  pylons: Pylons;
  setPylons: React.Dispatch<React.SetStateAction<Pylons>>;
}> = ({ continuousTenors, pylons, setPylons, setContinuousTenors }) => {
  const maturities = Object.values(MATURITY).filter(
    (m) => typeof m === "string"
  ) as string[];
  const [expanded, setExpanded] = useState(false);

  return (
    <Column>
      <ListTitle
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        <h1>Relative tenors</h1>
        <div>
          <CaretUpOutlined
            style={{
              transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
              transition: "all ease 300ms",
            }}
          />
        </div>
      </ListTitle>
      {expanded &&
        maturities.map((mat, idx) => {
          return (
            <Row style={{ gap: "1rem" }} key={mat}>
              {/* eslint-disable-next-line */}
              <label>
                <Toggle
                  icons={false}
                  defaultChecked={continuousTenors.includes(
                    MATURITY[mat as keyof typeof MATURITY]
                  )}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setContinuousTenors([
                        ...continuousTenors,
                        MATURITY[mat as keyof typeof MATURITY],
                      ]);
                      const pylon: Pylon = {};
                      for (let i = 0; i < 5; i += 1) {
                        pylon[i.toString()] = 0;
                      }
                      setPylons({
                        ...pylons,
                        [MATURITY[mat as keyof typeof MATURITY]]: pylon,
                      });
                    } else {
                      setContinuousTenors(
                        continuousTenors.filter(
                          (c) => c !== MATURITY[mat as keyof typeof MATURITY]
                        )
                      );
                      const newPylons = { ...pylons };
                      delete newPylons[MATURITY[mat as keyof typeof MATURITY]];
                      setPylons(newPylons);
                    }
                  }}
                />
                <span>
                  &nbsp;&nbsp;{mat}&nbsp;&nbsp;
                  {MATURITY[mat as keyof typeof MATURITY]}
                </span>
              </label>
            </Row>
          );
        })}
    </Column>
  );
};

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #2d363b;
  margin-left: 14px;
  margin-right: 14px;
`;

const ListTitle = styled(Row)`
  &:hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
  }
  position: sticky;
  top: 0;
`;

export default ContinuousTenors;

/**
 *
 *
 *
 *
 * {'0': {'0':0, '1':0, ...}}
 * {'5': {}}
 */
