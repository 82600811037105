import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState } from "./initial_state";
import {
  TrainerClassesRoom,
  TrainerLiveSession,
  TrainerScenarioBE,
  TraderStatsUpdatedPayload,
} from "types";
import { traderStatsUpdated } from "./actions";
import { clearRoom } from "../room/actions";

const trainerSlice = createSlice({
  initialState,
  name: "trainer",
  reducers: {
    getTrainerLiveRooms: (
      state,
      action: PayloadAction<TrainerLiveSession[]>
    ) => {
      state.liveSessions = action.payload;
    },
    getTrainerHistoryRooms: (
      state,
      action: PayloadAction<TrainerLiveSession[]>
    ) => {
      state.historySessions = action.payload;
    },
    getTrainerClasses: (state, action: PayloadAction<TrainerClassesRoom[]>) => {
      state.classes = action.payload;
    },
    hideScenarioModal: (state) => {
      state.scenarioModalShown = false;
    },
    showScenarioModal: (state) => {
      state.scenarioModalShown = true;
    },
    setSelectedScenario: (state, action: PayloadAction<TrainerScenarioBE>) => {
      state.selectedScenario = action.payload;
    },
    getTrainerScenarios: (
      state,
      action: PayloadAction<TrainerScenarioBE[]>
    ) => {
      state.scenarios = action.payload;
      state.assetClasses = [
        ...new Set(
          state.scenarios.map((s) => {
            return s.swift[0].asset_class;
          })
        ),
      ];
      state.instruments = [
        ...new Set(
          state.scenarios.map((s) => {
            return s.swift[0].code;
          })
        ),
      ];
    },
    setTraderStats: (state, action) => {
      const stats = action.payload;
      const swift: string = Object.keys(stats)[0];
      state.currentLiveSession.trader_stats = Object.values(
        stats[parseInt(swift)]
      );
    },
    deleteScenario: () => {},
    addScenario: () => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        traderStatsUpdated,
        (state, action: PayloadAction<TraderStatsUpdatedPayload>) => {
          const stats = action.payload;
          if (stats) {
            const swift: string = Object.keys(stats.traders_stats)[0];
            state.currentLiveSession.trader_stats = Object.values(
              stats.traders_stats[parseInt(swift)]
            );
          }
        }
      )
      .addCase(clearRoom, initialState);
  },
});

export default trainerSlice.reducer;
export const {
  getTrainerLiveRooms,
  getTrainerHistoryRooms,
  getTrainerClasses,
  hideScenarioModal,
  showScenarioModal,
  setSelectedScenario,
  getTrainerScenarios,
  deleteScenario,
  addScenario,
  setTraderStats,
} = trainerSlice.actions;
