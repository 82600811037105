import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";

import { RootState } from "store/rootReducer";
import parseRoomDate from "./parseRoomDate";
import { RoomStatus } from "../../types";
import _ from "lodash";

type State = {
  throttledGameTime: number;
  gameTime: number;
  setGameTime: React.Dispatch<React.SetStateAction<number>>;
  getRoomDate: () => Date | undefined;
  getRoomDays: () => number;
};

const RoomTimeContext = createContext<State | undefined>(undefined);

const RoomTimeProvider: React.FC = ({ children }) => {
  const [gameTime, setGameTime] = useState(0);
  const [throttledGameTime, setThrottledGameTime] = useState(0);

  const { REAL_SECONDS_TO_GAME_DAYS_RATIO } = useSelector(
    (state: RootState) => state.settings.parameters
  );
  const { start_date, room_time, be_room_time, status } = useSelector(
    (state: RootState) => state.room
  );
  const [ratio, setRatio] = useState(REAL_SECONDS_TO_GAME_DAYS_RATIO);

  useEffect(() => {
    setRatio(REAL_SECONDS_TO_GAME_DAYS_RATIO);
  }, [REAL_SECONDS_TO_GAME_DAYS_RATIO]);

  useEffect(() => {
    setGameTime(room_time);
  }, [room_time]);

  const incrementTime = useCallback(() => {
    if (status !== RoomStatus.PLAYING) {
      return;
    }
    setGameTime((time) => {
      return time + 1;
    });
  }, [status]);

  useEffect(() => {
    // be_room_time is sent once every 10 seconds
    // we use this to reset the timer
    if (be_room_time !== 0) setGameTime(be_room_time);
    const interval = setInterval(incrementTime, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [incrementTime, be_room_time]);

  const getRoomDate = useCallback(
    () => parseRoomDate(start_date, gameTime, ratio),
    [gameTime]
  );
  const getRoomDays = useCallback(
    () => Math.floor(gameTime / REAL_SECONDS_TO_GAME_DAYS_RATIO),
    [gameTime]
  );

  useEffect(() => {
    _.throttle(() => {
      setThrottledGameTime(gameTime);
    }, 5000);
  }, [gameTime]);

  const state = {
    throttledGameTime,
    gameTime,
    setGameTime,
    getRoomDate,
    getRoomDays,
  };

  return (
    <RoomTimeContext.Provider value={state}>
      {children}
    </RoomTimeContext.Provider>
  );
};

export default RoomTimeProvider;

export const useRoomTimeContext = () => {
  const context = useContext(RoomTimeContext);
  if (context === undefined) {
    throw new Error("Cannot use useRoomTimeContext outside RoomTimeProvider");
  }
  return context;
};
