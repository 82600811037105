import { Column, Row } from "components/reusable/GenericStyledComponents";
import React, { ChangeEvent, useEffect, useState } from "react";
import {
  MATURITY,
  RFQCall,
  StateIndividualOptionOptions,
  StateOptionsBase,
  StateStraddleOptions,
  StateStranggleOptions,
  StrategyType,
} from "types";
import RfqQuote from "../BrokerDealer/RfqQuote";
import { useAppDispatch, getState } from "store/store";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import styled from "styled-components";
import NumberInput from "components/reusable/NumberInput";
import { Button, Typography } from "antd";
import { div10, numberWithCommas, precision } from "utils/numbers";
import { rfqAnswerStrategyCall } from "store/rfq/actions";
import {
  PUT_CALL_STR,
  RFQ_STATUS_STR,
  RFQ_WAITING,
  VOLATILITY_QUOTE,
} from "stateConstants";
import { generateOptionHeader } from "../ClientCalls/utils";
import { roomService } from "services";
import { convertDelta } from "utils/delta";
import { stickyModelAwareTenor, timeUnixToDatePretty } from "utils/time";

type Props = {
  call: RFQCall;
};

const StrategyQuote: React.FC<{
  rfq: RFQCall;
  quotedCall: RFQCall;
  setQuotedCall: React.Dispatch<React.SetStateAction<RFQCall>>;
  handleAnswerCall: () => void;
  originalRfqStatus: 0 | 1 | 2 | 3 | 4 | 5;
}> = ({
  rfq,
  quotedCall,
  setQuotedCall,
  originalRfqStatus,
  handleAnswerCall,
}) => {
  const sticky_model = useSelector(
    (state: RootState) => state.spot.sticky_model,
    shallowEqual
  );
  const vol_ticket_unit = useSelector(
    (state: RootState) => state.swifts.selectedSwift.vol_ticket_unit
  );
  const { SPOT_DECIMAL_PLACES } = useSelector(
    (state: RootState) => state.settings.parameters
  );

  const handleKeyPress = (
    key: string,
    setter: any,
    value: any,
    update_key:
      | "volatility_bid"
      | "volatility_ask"
      | "premium_bid"
      | "premium_ask",
    id: number,
    enterFn?: any
  ) => {
    let power = 0;
    if (rfq.quote_type === VOLATILITY_QUOTE) power = 2;
    else power = sticky_model ? SPOT_DECIMAL_PLACES : 2;
    switch (key) {
      case "ArrowUp":
        setter(
          update_key,
          precision(parseFloat(value) + 50 / 10 ** power, power),
          id
        );
        break;
      case "ArrowDown":
        setter(
          update_key,
          precision(parseFloat(value) - 50 / 10 ** power, power),
          id
        );
        break;
      case "ArrowLeft":
        setter(
          update_key,
          precision(parseFloat(value) - 5 / 10 ** power, power),
          id
        );
        break;
      case "ArrowRight":
        setter(
          update_key,
          precision(parseFloat(value) + 5 / 10 ** power, power),
          id
        );
        break;
      case "Enter":
        if (enterFn) enterFn();
        break;
    }
  };
  const updateOption = (
    key: "volatility_bid" | "volatility_ask" | "premium_bid" | "premium_ask",
    e: string,
    id: number
  ) => {
    const value = parseFloat(e || "0") || 0;
    setQuotedCall((prev) => {
      const newStrategy = JSON.parse(JSON.stringify(prev.strategy));
      if (newStrategy.options) {
        const option = newStrategy.options[id];
        if (option) {
          option[key] = value;
        }
      }
      return {
        ...prev,
        strategy: newStrategy,
      };
    });
  };

  const firstOption =
    quotedCall.strategy.options[
      parseInt(Object.keys(quotedCall.strategy.options)[0])
    ];

  if (quotedCall.strategy.strategy_name === StrategyType.INDIVIDUAL_OPTION)
    return (
      <>
        {Object.values(quotedCall.strategy.options).map((option, idx) => {
          return (
            <OptionContainer key={option.id}>
              <Row
                style={{
                  gap: "1rem",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <BidInput
                  autoFocus
                  fontSize={14}
                  fontWeight={500}
                  color="#F4F4F4"
                  value={
                    rfq.quote_type === VOLATILITY_QUOTE
                      ? precision(option.volatility_bid, 2)
                      : option.premium_bid
                  }
                  suffix={rfq.quote_type === VOLATILITY_QUOTE ? " %" : " $"}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value.replaceAll(/[ %]/g, "");
                    updateOption(
                      rfq.quote_type === VOLATILITY_QUOTE
                        ? "volatility_bid"
                        : "premium_bid",
                      value,
                      option.id
                    );
                  }}
                  onKeyDown={(e: any) => {
                    e.stopPropagation();
                    handleKeyPress(
                      e.key,
                      updateOption,
                      rfq.quote_type === VOLATILITY_QUOTE
                        ? option.volatility_bid
                        : option.premium_bid,
                      rfq.quote_type === VOLATILITY_QUOTE
                        ? "volatility_bid"
                        : "premium_bid",
                      option.id,
                      () => {}
                    );
                  }}
                />

                <AskInput
                  fontSize={14}
                  fontWeight={500}
                  color="#F4F4F4"
                  value={
                    rfq.quote_type === VOLATILITY_QUOTE
                      ? precision(option.volatility_ask, 2)
                      : option.premium_ask
                  }
                  suffix={rfq.quote_type === VOLATILITY_QUOTE ? " %" : " $"}
                  onKeyDown={(e) => {
                    e.stopPropagation();
                    handleKeyPress(
                      e.key,
                      updateOption,
                      rfq.quote_type === VOLATILITY_QUOTE
                        ? option.volatility_ask
                        : option.premium_ask,
                      rfq.quote_type === VOLATILITY_QUOTE
                        ? "volatility_ask"
                        : "premium_ask",
                      option.id,
                      () => {}
                    );
                  }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value.replaceAll(/[ %]/g, "");
                    updateOption(
                      rfq.quote_type === VOLATILITY_QUOTE
                        ? "volatility_ask"
                        : "premium_ask",
                      value,
                      option.id
                    );
                  }}
                />
                {originalRfqStatus === RFQ_WAITING && (
                  <Button
                    onClick={() => {
                      handleAnswerCall();
                    }}
                  >
                    Send
                  </Button>
                )}
              </Row>
            </OptionContainer>
          );
        })}
      </>
    );

  if (quotedCall.strategy.strategy_name === StrategyType.STRADDLE)
    return (
      <>
        <OptionContainer>
          {Object.values(quotedCall.strategy.options).map((option, idx) => {
            return (
              <Row
                style={{
                  gap: "1rem",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography.Text>
                  {PUT_CALL_STR[option.put_or_call]}
                </Typography.Text>
                <BidInput
                  autoFocus
                  fontSize={14}
                  fontWeight={500}
                  color="#F4F4F4"
                  value={
                    rfq.quote_type === VOLATILITY_QUOTE
                      ? precision(option.volatility_bid, 2)
                      : option.premium_bid
                  }
                  suffix={rfq.quote_type === VOLATILITY_QUOTE ? " %" : " $"}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value.replaceAll(/[ %]/g, "");
                    updateOption(
                      rfq.quote_type === VOLATILITY_QUOTE
                        ? "volatility_bid"
                        : "premium_bid",
                      value,
                      option.id
                    );
                  }}
                  onKeyDown={(e: any) => {
                    e.stopPropagation();
                    handleKeyPress(
                      e.key,
                      updateOption,
                      rfq.quote_type === VOLATILITY_QUOTE
                        ? option.volatility_bid
                        : option.premium_bid,
                      rfq.quote_type === VOLATILITY_QUOTE
                        ? "volatility_bid"
                        : "premium_bid",
                      option.id,
                      () => {}
                    );
                  }}
                />

                <AskInput
                  fontSize={14}
                  fontWeight={500}
                  color="#F4F4F4"
                  value={
                    rfq.quote_type === VOLATILITY_QUOTE
                      ? precision(option.volatility_ask, 2)
                      : option.premium_ask
                  }
                  suffix={rfq.quote_type === VOLATILITY_QUOTE ? " %" : " $"}
                  onKeyDown={(e) => {
                    e.stopPropagation();
                    handleKeyPress(
                      e.key,
                      updateOption,
                      rfq.quote_type === VOLATILITY_QUOTE
                        ? option.volatility_ask
                        : option.premium_ask,
                      rfq.quote_type === VOLATILITY_QUOTE
                        ? "volatility_ask"
                        : "premium_ask",
                      option.id,
                      () => {}
                    );
                  }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value.replaceAll(/[ %]/g, "");
                    updateOption(
                      rfq.quote_type === VOLATILITY_QUOTE
                        ? "volatility_ask"
                        : "premium_ask",
                      value,
                      option.id
                    );
                  }}
                />
              </Row>
            );
          })}
          {originalRfqStatus === RFQ_WAITING && (
            <Button
              onClick={() => {
                handleAnswerCall();
              }}
            >
              Send
            </Button>
          )}
        </OptionContainer>
      </>
    );
  if (quotedCall.strategy.strategy_name === StrategyType.STRANGLE)
    return (
      <>
        <OptionContainer>
          {Object.values(quotedCall.strategy.options).map((option, idx) => {
            return (
              <Row
                style={{
                  gap: "1rem",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography.Text>
                  {PUT_CALL_STR[option.put_or_call]} {option.strike}
                </Typography.Text>
                <BidInput
                  autoFocus
                  fontSize={14}
                  fontWeight={500}
                  color="#F4F4F4"
                  value={
                    rfq.quote_type === VOLATILITY_QUOTE
                      ? precision(option.volatility_bid, 2)
                      : option.premium_bid
                  }
                  suffix={rfq.quote_type === VOLATILITY_QUOTE ? " %" : " $"}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value.replaceAll(/[ %]/g, "");
                    updateOption(
                      rfq.quote_type === VOLATILITY_QUOTE
                        ? "volatility_bid"
                        : "premium_bid",
                      value,
                      option.id
                    );
                  }}
                  onKeyDown={(e: any) => {
                    e.stopPropagation();
                    handleKeyPress(
                      e.key,
                      updateOption,
                      rfq.quote_type === VOLATILITY_QUOTE
                        ? option.volatility_bid
                        : option.premium_bid,
                      rfq.quote_type === VOLATILITY_QUOTE
                        ? "volatility_bid"
                        : "premium_bid",
                      option.id,
                      () => {}
                    );
                  }}
                />

                <AskInput
                  fontSize={14}
                  fontWeight={500}
                  color="#F4F4F4"
                  value={
                    rfq.quote_type === VOLATILITY_QUOTE
                      ? precision(option.volatility_ask, 2)
                      : option.premium_ask
                  }
                  suffix={rfq.quote_type === VOLATILITY_QUOTE ? " %" : " $"}
                  onKeyDown={(e) => {
                    e.stopPropagation();
                    handleKeyPress(
                      e.key,
                      updateOption,
                      rfq.quote_type === VOLATILITY_QUOTE
                        ? option.volatility_ask
                        : option.premium_ask,
                      rfq.quote_type === VOLATILITY_QUOTE
                        ? "volatility_ask"
                        : "premium_ask",
                      option.id,
                      () => {}
                    );
                  }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value.replaceAll(/[ %]/g, "");
                    updateOption(
                      rfq.quote_type === VOLATILITY_QUOTE
                        ? "volatility_ask"
                        : "premium_ask",
                      value,
                      option.id
                    );
                  }}
                />
              </Row>
            );
          })}
          {originalRfqStatus === RFQ_WAITING && (
            <Button
              onClick={() => {
                handleAnswerCall();
              }}
            >
              Send
            </Button>
          )}
        </OptionContainer>
      </>
    );
  if (
    quotedCall.strategy.strategy_name === StrategyType.CALL_CALENDAR_SPREAD ||
    quotedCall.strategy.strategy_name === StrategyType.PUT_CALENDAR_SPREAD
  )
    return (
      <>
        <OptionContainer>
          {Object.values(quotedCall.strategy.options).map((option, idx) => {
            return (
              <Row
                style={{
                  gap: "1rem",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography.Text>
                  {PUT_CALL_STR[option.put_or_call]}{" "}
                  {stickyModelAwareTenor(option.tenor, sticky_model)}
                </Typography.Text>
                <BidInput
                  autoFocus
                  fontSize={14}
                  fontWeight={500}
                  color="#F4F4F4"
                  value={
                    rfq.quote_type === VOLATILITY_QUOTE
                      ? precision(option.volatility_bid, 2)
                      : option.premium_bid
                  }
                  suffix={rfq.quote_type === VOLATILITY_QUOTE ? " %" : " $"}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value.replaceAll(/[ %]/g, "");
                    updateOption(
                      rfq.quote_type === VOLATILITY_QUOTE
                        ? "volatility_bid"
                        : "premium_bid",
                      value,
                      option.id
                    );
                  }}
                  onKeyDown={(e: any) => {
                    e.stopPropagation();
                    handleKeyPress(
                      e.key,
                      updateOption,
                      rfq.quote_type === VOLATILITY_QUOTE
                        ? option.volatility_bid
                        : option.premium_bid,
                      rfq.quote_type === VOLATILITY_QUOTE
                        ? "volatility_bid"
                        : "premium_bid",
                      option.id,
                      () => {}
                    );
                  }}
                />

                <AskInput
                  fontSize={14}
                  fontWeight={500}
                  color="#F4F4F4"
                  value={
                    rfq.quote_type === VOLATILITY_QUOTE
                      ? precision(option.volatility_ask, 2)
                      : option.premium_ask
                  }
                  suffix={rfq.quote_type === VOLATILITY_QUOTE ? " %" : " $"}
                  onKeyDown={(e) => {
                    e.stopPropagation();
                    handleKeyPress(
                      e.key,
                      updateOption,
                      rfq.quote_type === VOLATILITY_QUOTE
                        ? option.volatility_ask
                        : option.premium_ask,
                      rfq.quote_type === VOLATILITY_QUOTE
                        ? "volatility_ask"
                        : "premium_ask",
                      option.id,
                      () => {}
                    );
                  }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value.replaceAll(/[ %]/g, "");
                    updateOption(
                      rfq.quote_type === VOLATILITY_QUOTE
                        ? "volatility_ask"
                        : "premium_ask",
                      value,
                      option.id
                    );
                  }}
                />
              </Row>
            );
          })}
          {originalRfqStatus === RFQ_WAITING && (
            <Button
              onClick={() => {
                handleAnswerCall();
              }}
            >
              Send
            </Button>
          )}
        </OptionContainer>
      </>
    );
  if (
    quotedCall.strategy.strategy_name === StrategyType.BULL_SPREAD ||
    quotedCall.strategy.strategy_name === StrategyType.BEAR_SPREAD
  )
    return (
      <>
        <OptionContainer>
          {Object.values(quotedCall.strategy.options).map((option, idx) => {
            return (
              <Row
                style={{
                  gap: "1rem",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography.Text>
                  {PUT_CALL_STR[option.put_or_call]} {option.strike}
                </Typography.Text>
                <BidInput
                  autoFocus
                  fontSize={14}
                  fontWeight={500}
                  color="#F4F4F4"
                  value={
                    rfq.quote_type === VOLATILITY_QUOTE
                      ? precision(option.volatility_bid, 2)
                      : option.premium_bid
                  }
                  suffix={rfq.quote_type === VOLATILITY_QUOTE ? " %" : " $"}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value.replaceAll(/[ %]/g, "");
                    updateOption(
                      rfq.quote_type === VOLATILITY_QUOTE
                        ? "volatility_bid"
                        : "premium_bid",
                      value,
                      option.id
                    );
                  }}
                  onKeyDown={(e: any) => {
                    e.stopPropagation();
                    handleKeyPress(
                      e.key,
                      updateOption,
                      rfq.quote_type === VOLATILITY_QUOTE
                        ? option.volatility_bid
                        : option.premium_bid,
                      rfq.quote_type === VOLATILITY_QUOTE
                        ? "volatility_bid"
                        : "premium_bid",
                      option.id,
                      () => {}
                    );
                  }}
                />

                <AskInput
                  fontSize={14}
                  fontWeight={500}
                  color="#F4F4F4"
                  value={
                    rfq.quote_type === VOLATILITY_QUOTE
                      ? precision(option.volatility_ask, 2)
                      : option.premium_ask
                  }
                  suffix={rfq.quote_type === VOLATILITY_QUOTE ? " %" : " $"}
                  onKeyDown={(e) => {
                    e.stopPropagation();
                    handleKeyPress(
                      e.key,
                      updateOption,
                      rfq.quote_type === VOLATILITY_QUOTE
                        ? option.volatility_ask
                        : option.premium_ask,
                      rfq.quote_type === VOLATILITY_QUOTE
                        ? "volatility_ask"
                        : "premium_ask",
                      option.id,
                      () => {}
                    );
                  }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value.replaceAll(/[ %]/g, "");
                    updateOption(
                      rfq.quote_type === VOLATILITY_QUOTE
                        ? "volatility_ask"
                        : "premium_ask",
                      value,
                      option.id
                    );
                  }}
                />
              </Row>
            );
          })}
          {originalRfqStatus === RFQ_WAITING && (
            <Button
              onClick={() => {
                handleAnswerCall();
              }}
            >
              Send
            </Button>
          )}
        </OptionContainer>
      </>
    );
  if (quotedCall.strategy.strategy_name === StrategyType.BUTTERFLY)
    return (
      <>
        <OptionContainer>
          {Object.values(quotedCall.strategy.options).map((option, idx) => {
            return (
              <Row
                style={{
                  gap: "1rem",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography.Text>
                  {PUT_CALL_STR[option.put_or_call]} {option.strike}
                </Typography.Text>
                <BidInput
                  autoFocus
                  fontSize={14}
                  fontWeight={500}
                  color="#F4F4F4"
                  value={
                    rfq.quote_type === VOLATILITY_QUOTE
                      ? precision(option.volatility_bid, 2)
                      : option.premium_bid
                  }
                  suffix={rfq.quote_type === VOLATILITY_QUOTE ? " %" : " $"}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value.replaceAll(/[ %]/g, "");
                    updateOption(
                      rfq.quote_type === VOLATILITY_QUOTE
                        ? "volatility_bid"
                        : "premium_bid",
                      value,
                      option.id
                    );
                  }}
                  onKeyDown={(e: any) => {
                    e.stopPropagation();
                    handleKeyPress(
                      e.key,
                      updateOption,
                      rfq.quote_type === VOLATILITY_QUOTE
                        ? option.volatility_bid
                        : option.premium_bid,
                      rfq.quote_type === VOLATILITY_QUOTE
                        ? "volatility_bid"
                        : "premium_bid",
                      option.id,
                      () => {}
                    );
                  }}
                />

                <AskInput
                  fontSize={14}
                  fontWeight={500}
                  color="#F4F4F4"
                  value={
                    rfq.quote_type === VOLATILITY_QUOTE
                      ? precision(option.volatility_ask, 2)
                      : option.premium_ask
                  }
                  suffix={rfq.quote_type === VOLATILITY_QUOTE ? " %" : " $"}
                  onKeyDown={(e) => {
                    e.stopPropagation();
                    handleKeyPress(
                      e.key,
                      updateOption,
                      rfq.quote_type === VOLATILITY_QUOTE
                        ? option.volatility_ask
                        : option.premium_ask,
                      rfq.quote_type === VOLATILITY_QUOTE
                        ? "volatility_ask"
                        : "premium_ask",
                      option.id,
                      () => {}
                    );
                  }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value.replaceAll(/[ %]/g, "");
                    updateOption(
                      rfq.quote_type === VOLATILITY_QUOTE
                        ? "volatility_ask"
                        : "premium_ask",
                      value,
                      option.id
                    );
                  }}
                />
              </Row>
            );
          })}
          {originalRfqStatus === RFQ_WAITING && (
            <Button
              onClick={() => {
                handleAnswerCall();
              }}
            >
              Send
            </Button>
          )}
        </OptionContainer>
      </>
    );
  return null;
};

const calendarSpreadLongShortTerm = (options: any, sticky_model: number) => {
  const optionIds = Object.keys(options);
  const firstOption = options[optionIds[0]];
  const secondOption = options[optionIds[1]];

  const formatter = sticky_model
    ? (tenor: number) => MATURITY[tenor]
    : timeUnixToDatePretty;

  if (firstOption.tenor < secondOption.tenor) {
    return {
      shortTerm: formatter(firstOption.tenor),
      longTerm: formatter(secondOption.tenor),
    };
  }

  return {
    shortTerm: formatter(secondOption.tenor),
    longTerm: formatter(firstOption.tenor),
  };
};

const butterflyOptionsFormatter = (options: any, sticky_model: number) => {
  const optionIds = Object.keys(options);
  const firstOption = options[optionIds[0]];
  const secondOption = options[optionIds[1]];

  const formatter = sticky_model
    ? (tenor: number) => MATURITY[tenor]
    : timeUnixToDatePretty;

  if (firstOption.tenor < secondOption.tenor) {
    return {
      shortTerm: formatter(firstOption.tenor),
      longTerm: formatter(secondOption.tenor),
    };
  }

  return {
    shortTerm: formatter(secondOption.tenor),
    longTerm: formatter(firstOption.tenor),
  };
};

const NewQuoteRFQ: React.FC<Props> = ({ call: rfq }) => {
  const dispatch = useAppDispatch();

  const sticky_model = useSelector(
    (state: RootState) => state.spot.sticky_model,
    shallowEqual
  );

  const [quotedCall, setQuotedCall] = useState<RFQCall>(rfq);
  const originalRfqStatus = useSelector(
    (state: RootState) => state.rfq[rfq.id].status,
    shallowEqual
  );

  useEffect(() => {
    setQuotedCall(rfq);
  }, [rfq]);

  const handleAnswerCall = () => {
    const parsedQuotes = { ...quotedCall.strategy.options };
    Object.keys(parsedQuotes).forEach((q) => {
      parsedQuotes[parseInt(q)] = {
        ...parsedQuotes[parseInt(q)],
        volatility_ask: parsedQuotes[parseInt(q)].volatility_ask / 100,
        volatility_bid: parsedQuotes[parseInt(q)].volatility_bid / 100,
      };
    });
    dispatch(
      rfqAnswerStrategyCall({
        callId: quotedCall.id,
        quotes: parsedQuotes,
      })
    );
  };
  console.log(rfq);

  return (
    <Column
      style={{
        justifyContent: "center",
        alignItems: "center",
        gap: ".5rem",
        width: "100%",
        padding: "1rem",
        overflow: "auto",
      }}
    >
      {(quotedCall.strategy.strategy_name ===
        StrategyType.CALL_CALENDAR_SPREAD ||
        quotedCall.strategy.strategy_name ===
          StrategyType.PUT_CALENDAR_SPREAD) && (
        <>
          {" "}
          <Typography.Text>
            Long: BUY{" "}
            {
              calendarSpreadLongShortTerm(
                quotedCall.strategy.options,
                sticky_model
              ).longTerm
            }{" "}
            & SELL{" "}
            {
              calendarSpreadLongShortTerm(
                quotedCall.strategy.options,
                sticky_model
              ).shortTerm
            }
          </Typography.Text>
          <Typography.Text>
            Short: BUY{" "}
            {
              calendarSpreadLongShortTerm(
                quotedCall.strategy.options,
                sticky_model
              ).shortTerm
            }{" "}
            & SELL{" "}
            {
              calendarSpreadLongShortTerm(
                quotedCall.strategy.options,
                sticky_model
              ).longTerm
            }
          </Typography.Text>
        </>
      )}
      {quotedCall.strategy.strategy_name === StrategyType.BUTTERFLY && (
        <>
          {" "}
          <Typography.Text>
            {" "} Long: BUY{" "}
            {`${PUT_CALL_STR[quotedCall.strategy.put_or_call]} ${
              sticky_model
                ? convertDelta[quotedCall.strategy.low_strike]
                : parseFloat(quotedCall.strategy.low_strike)
            }&${
              sticky_model
                ? convertDelta[quotedCall.strategy.high_strike]
                : parseFloat(quotedCall.strategy.high_strike)
            }`}{" "}
            & SELL{" "}
            {`2x ${PUT_CALL_STR[quotedCall.strategy.put_or_call]} ${
              sticky_model
                ? convertDelta[quotedCall.strategy.atm_strike]
                : parseFloat(quotedCall.strategy.atm_strike)
            }`}
          </Typography.Text>
          <Typography.Text>
            Short: SELL{" "}
            {`${PUT_CALL_STR[quotedCall.strategy.put_or_call]} ${
              sticky_model
                ? convertDelta[quotedCall.strategy.low_strike]
                : parseFloat(quotedCall.strategy.low_strike)
            }&${
              sticky_model
                ? convertDelta[quotedCall.strategy.high_strike]
                : parseFloat(quotedCall.strategy.high_strike)
            }`}{" "}
            & BUY{" "}
            {`2x ${PUT_CALL_STR[quotedCall.strategy.put_or_call]} ${
              sticky_model
                ? convertDelta[quotedCall.strategy.atm_strike]
                : parseFloat(quotedCall.strategy.atm_strike)
            }`}{" "}
            
          </Typography.Text>
        </>
      )}
      <StrategyQuote
        rfq={rfq}
        quotedCall={quotedCall}
        setQuotedCall={setQuotedCall}
        handleAnswerCall={handleAnswerCall}
        originalRfqStatus={originalRfqStatus}
      />
      {originalRfqStatus !== RFQ_WAITING && (
        <Typography.Text style={{ color: "#8c8c8c" }}>
          {RFQ_STATUS_STR[originalRfqStatus]}
        </Typography.Text>
      )}
    </Column>
  );
};
const Input = styled(NumberInput)`
  background-color: #000;
  padding-right: 5px;
`;

const BidInput = styled(Input)`
  color: #ff7875aa;
  border: 1px solid #ff7875aa;

  transition: all 0.2s;

  &:hover,
  &:active,
  &:focus {
    color: #ff7875 !important;
    border: 1px solid #ff7875 !important;
  }
`;

const AskInput = styled(Input)`
  color: #b7eb8f95;
  border: 1px solid #b7eb8f95;

  transition: all 0.2s;

  &:hover,
  &:active,
  &:focus {
    color: #b7eb8f !important;
    border: 1px solid #b7eb8f !important;
  }
`;

const Group = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
`;

const Label = styled.span`
  color: #ebebeb80;
`;

const OptionContainer = styled.div`
  border: 1px solid gray;
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export default NewQuoteRFQ;
