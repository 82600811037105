import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { clearRoom } from "store/room/actions";
import { ClientCall, RFQCall } from "types";

export type CallQuoteState = {
  selectedCalls: (RFQCall | ClientCall)[];
  selectedCall?: RFQCall | ClientCall;
};

const initialState: CallQuoteState = {
  selectedCalls: [],
};

const callQuoteSlice = createSlice({
  initialState,
  name: "callQuotes",
  reducers: {
    addCallQuote: (
      state,
      action: PayloadAction<{ call: RFQCall | ClientCall }>
    ) => {
      const { call } = action.payload;
      state.selectedCalls.push(call);
    },
    removeCallQuote: (state, action: PayloadAction<{ id: number }>) => {
      const { id } = action.payload;

      const wasSelected = state.selectedCall?.id === id;

      state.selectedCalls = state.selectedCalls.filter(
        (call) => call.id !== id
      );

      if (wasSelected) {
        if (state.selectedCalls.length > 0) {
          const [firstCall] = state.selectedCalls;
          state.selectedCall = firstCall;
        } else {
          state.selectedCall = undefined;
        }
      }
    },

    selectCallQuote: (
      state,
      action: PayloadAction<{ call: RFQCall | ClientCall }>
    ) => {
      const { call } = action.payload;
      state.selectedCall = call;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearRoom, () => {
      return initialState;
    });
  },
});

export default callQuoteSlice.reducer;
export const { addCallQuote, removeCallQuote, selectCallQuote } =
  callQuoteSlice.actions;
