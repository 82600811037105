import React from "react";
import styled from "styled-components";
import LoginForm from "../LoginForm";
import { Typography } from "antd";

const { Title, Paragraph } = Typography;

const LogInView = () => {
  return (
    <>
      <Background />
      <Container>
        <Logo src="/logo.png" alt="Traderion" />

        <Title level={2}>Virtual Learning Bank Powered by AI</Title>

        <Paragraph style={{ textAlign: "center", marginBottom: 50 }}>
          Welcome to Traderion, a gamified Sales & Trading platform with
          financial markets simulator: Equities, FX, Bonds, Commodities and
          Options
        </Paragraph>

        <LoginForm />
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 700px;
  padding: 50px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #eeeeee !important;

  animation: pulse 4s infinite;
  background: ${(props) => props.theme.color0};

  @keyframes pulse {
    0% {
      box-shadow: 0 0 800px 75px rgba(255, 211, 105, 0.05);
    }
    30% {
      box-shadow: 0 0 800px 75px rgba(255, 211, 105, 0.1),
        0 0 100px 0 rgba(255, 211, 105, 0.2);
    }
    50% {
      box-shadow: 0 0 800px 75px rgba(255, 211, 105, 0.1),
        0 0 100px 0 rgba(255, 211, 105, 0.2),
        0 0 30px -10px rgba(255, 211, 105, 0.7);
    }
    100% {
      box-shadow: 0 0 800px 75px rgba(255, 211, 105, 0.05);
    }
  }
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #000;
`;

const Logo = styled.img`
  width: 200px;
  height: auto;
  margin-bottom: 30px;
`;

export default LogInView;
