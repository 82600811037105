import { Column, Row } from "components/reusable/GenericStyledComponents";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { roomService } from "services";
import { BUY } from "stateConstants";
import {
  newSelectPricerData,
  selectedLinkedCall,
} from "store/newpricer/selectors";
import styled from "styled-components";
import { numberWithCommas, roundPremium } from "utils/numbers";
import { getState } from "store/store";
import { RFQCall } from "types";
import { timeAbsoluteTenorToUnix } from "utils/time";
import { RootState } from "store/rootReducer";

// const agregations = (type: string, pricers: { [key: string]: NewPricerColumn }) => {
//     switch ("type") {
//         case "sum":
//             [...Array]
//     }
// }

const PricerSummary: React.FC = () => {
  const { selectedCall, call_type } = useSelector(selectedLinkedCall);
  const pricers = useSelector(newSelectPricerData);
  const [bidPremium, setBidPremium] = useState<number>(0);
  const [askPremium, setAskPremium] = useState<number>(0);

  const getPremiums = async () => {
    if (selectedCall) {
      const _selectedCall = selectedCall as RFQCall;
      const { strategy_bid, strategy_ask } = await roomService.getPremium(
        _selectedCall.id
      );
      setBidPremium(strategy_bid);
      setAskPremium(strategy_ask);
    }
  };

  // useEffect(() => {
  //   if (call_type === "rfq") {
  //     getPremiums();
  //   }
  // }, [selectedCall, call_type]);

  if (!selectedCall) return null;
  return (
    <Column>
      <Row style={{ justifyContent: "center" }}>Summary:</Row>
      <Row style={{ justifyContent: "space-between" }}>
        <Text>Premium: </Text>
        <Text>
          {call_type === "rfq" ? (
            <Row style={{ gap: "1rem" }}>
              <div>Bid: {bidPremium}</div>
              <div>Ask: {askPremium}</div>
            </Row>
          ) : (
            numberWithCommas(
              Object.values(pricers)
                .map((p) =>
                  p.direction === BUY
                    ? -1 * roundPremium(p.premium)
                    : roundPremium(p.premium)
                )
                .reduce((a, b) => a + b)
                .toFixed(2),
              3
            )
          )}
        </Text>
      </Row>
    </Column>
  );
};

const Text = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: #f8f7f7;
  justify-items: center;
  align-items: center;
`;

export default PricerSummary;
