import { useAppDispatch } from "../../../store/store";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../store/rootReducer";
import {
  CALL,
  LONG,
  OPTION,
  PUT,
  SHORT,
  STRADDLE,
} from "../../../stateConstants";
import { spotVolEbHit } from "../../../store/spot/actions";
import { numberWithCommas, percent2Digits } from "../../../utils/numbers";
import { Tenor } from "../../../types";
import { timeUnixToDatePretty } from "../../../utils/time";

const useSellBuyButtons = (type: "Sell" | "Buy") => {
  const dispatch = useAppDispatch();
  const isSell = type === "Sell";

  const { TRADABLE_VOLS_PUT_CALL_OR_STRADDLE: strategy } = useSelector(
    (state: RootState) => state.settings.parameters
  );

  const swift = useSelector((state: RootState) => state.swifts.selectedSwift);

  const {
    selectedSellTenor,
    selectedBuyTenor,
    tradableVols,
    selectedDelta,
    sticky_model,
    volQuantity: quantity,
  } = useSelector(
    (state: RootState) => ({
      selectedSellTenor: state.spot.selectedSellTenor,
      selectedBuyTenor: state.spot.selectedBuyTenor,
      tradableVols: state.spot.tradableVols,
      selectedDelta: state.spot.selectedDelta,
      sticky_model: state.spot.sticky_model,
      volQuantity: state.spot.volQuantity,
    }),
    shallowEqual
  );

  const selectedTenor = isSell ? selectedSellTenor : selectedBuyTenor;

  const handleButtonPress = (put_or_call: typeof CALL | typeof PUT) => {
    // Tradable Vols
    const volEb = tradableVols[selectedTenor!][selectedDelta];
    const volatility = isSell ? volEb.sell : volEb.buy;

    if (!swift) return;
    switch (strategy) {
      case STRADDLE: {
        dispatch(
          spotVolEbHit({
            strategy: STRADDLE,
            volatility,
            tenor: selectedTenor!,
            amount: quantity,
            delta_strike: selectedDelta,
            short_or_long: isSell ? SHORT : LONG,
            swift_id: swift.swift_id,
          })
        );

        break;
      }

      case OPTION: {
        if (sticky_model) {
          if (selectedDelta !== 0.5) {
            dispatch(
              spotVolEbHit({
                strategy: OPTION,
                put_or_call,
                volatility,
                tenor: selectedTenor!,
                amount: quantity,
                delta_strike: selectedDelta,
                short_or_long: isSell ? SHORT : LONG,
                swift_id: swift.swift_id,
              })
            );
          } else {
            dispatch(
              spotVolEbHit({
                strategy: STRADDLE,
                put_or_call,
                volatility,
                tenor: selectedTenor!,
                amount: quantity,
                delta_strike: selectedDelta,
                short_or_long: isSell ? SHORT : LONG,
                swift_id: swift.swift_id,
              })
            );
          }
        } else {
          dispatch(
            spotVolEbHit({
              strategy: OPTION,
              put_or_call,
              volatility,
              tenor: selectedTenor!,
              amount: quantity,
              delta_strike: selectedDelta,
              short_or_long: isSell ? SHORT : LONG,
              swift_id: swift.swift_id,
            })
          );
        }

        break;
      }

      default: {
        // ??
      }
    }
  };

  const straddleButtonMessage = () => {
    if (
      !tradableVols ||
      !tradableVols[selectedTenor!] ||
      !tradableVols[selectedTenor!][selectedDelta]
    )
      return "";
    // Tradable vols
    const volEb = tradableVols[selectedTenor!][selectedDelta];
    const price = isSell ? volEb.sell : volEb.buy;

    return `${type} ${numberWithCommas(quantity)} X ${
      sticky_model
        ? Tenor[selectedTenor!]
        : timeUnixToDatePretty(parseInt(selectedTenor as any))
    } Straddle ${percent2Digits(price)}`;
  };

  return { handleButtonPress, straddleButtonMessage };
};

export default useSellBuyButtons;
