export const ROOM_RETRIEVE_INITIAL_DATA = "room/InitialData";
export const ROOM_UPDATE = "room/UpdateRoom";
export const ROOM_REQUEST_INITIAL_DATA = "room/requestInitialData";

export const ROOM_SEND_TIME = "room/sendTime";

export const ROOM_CLEAR = "room/clear";

export const ROOM_DISCONNECT = "room/disconnect";
export const ROOM_RESUME = "room/resume";
export const ROOM_PAUSE = "room/pause";
export const ROOM_FINISH = "room/finish";
export const ROOM_CONNECT = "room/connect";
export const ROOM_NOTIFY = "notify";
