import { createAction } from "@reduxjs/toolkit";

export const updateTenorsVolEb = createAction<{
  pattern: number[];
  swift_id: number;
}>("tenors/updateTenorsVolEb");
export const updateTenorsRest = createAction<{
  pattern: number[];
  swift_id: number;
}>("tenors/updateTenorsRest");
export const visibleRestConfirmation = createAction<{
  pattern: number[];
  swift_id: number;
}>("tenors/visibleRestConfirmation");
export const visibleVolEbConfirmation = createAction<{
  pattern: number[];
  swift_id: number;
}>("tenors/visibleVolEbConfirmation");
