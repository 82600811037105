import React, { useState, useEffect } from "react";
import { useAppDispatch } from "store/store";
import styled from "styled-components";
import {
  ClientCall,
  IndividualOptionOptions,
  RFQCall,
  StateStranggleOptions,
  StraddleOptions,
  StranggleOptions,
  StrategyType,
} from "types";
import { linkCallToPricer, setPricer } from "store/newpricer/actions";
import { BlackButton, Row } from "./GenericStyledComponents";
import { selectLinkedClientCalls } from "store/newpricer/selectors";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { CALL, LONG, PUT, SHORT } from "stateConstants";

type Props = {
  call: RFQCall | ClientCall;
  callId: string;
  isWaiting: boolean;
  sticky_model: number;
};

const ToPricerButton: React.FC<Props> = ({
  call,
  callId,
  isWaiting,
  sticky_model,
}) => {
  const dispatch = useAppDispatch();
  const { pricerToCallsLinks, callsToPricerLinks } = useSelector(
    (state: RootState) => state.newpricer
  );
  const [clicked, setClicked] = useState<boolean>(false);
  const sendToPricer = (correlation_id: number) => {
    if (Object.keys(call).includes("strategy")) {
      const rfqCall = call as RFQCall;
      Object.values(rfqCall.strategy.options).forEach(
        (genericCurrentCall, idx) => {
          const currentCall = genericCurrentCall;
          dispatch(
            setPricer({
              correlation_id: correlation_id + idx,
              common: {
                direction: currentCall.short_or_long,
                type: currentCall.put_or_call || PUT,
                notional: currentCall.amount,
                maturity: currentCall.tenor,
                strike: Number(currentCall.strike),
              },
            })
          );
          dispatch(
            linkCallToPricer({
              pricerId: (correlation_id + idx).toString(),
              callId,
            })
          );
        }
      );
    } else {
      const clientCall = call as ClientCall;
      dispatch(
        setPricer({
          correlation_id,
          common: {
            direction: clientCall.short_or_long,
            type: clientCall.put_or_call,
            notional: clientCall.amount,
            maturity: clientCall.tenor,
            strike: clientCall.strike,
          },
        })
      );
      dispatch(
        linkCallToPricer({ pricerId: correlation_id.toString(), callId })
      );
    }
  };

  if (!clicked)
    return (
      <BlackButton
        disabled={!isWaiting}
        onClick={() => {
          setClicked(true);
        }}
      >
        To Pricer
      </BlackButton>
    );
  return (
    <Row>
      <PricerButton
        disabled={
          !!pricerToCallsLinks[0].color ||
          Object.keys(callsToPricerLinks).includes(callId)
        }
        color={pricerToCallsLinks[0].color}
        onClick={() => {
          sendToPricer(0);
        }}
      >
        1
      </PricerButton>
      <PricerButton
        disabled={
          !!pricerToCallsLinks[1].color ||
          Object.keys(callsToPricerLinks).includes(callId)
        }
        color={pricerToCallsLinks[1].color}
        onClick={() => {
          sendToPricer(1);
        }}
      >
        2
      </PricerButton>
      <PricerButton
        disabled={
          !!pricerToCallsLinks[2].color ||
          Object.keys(callsToPricerLinks).includes(callId)
        }
        color={pricerToCallsLinks[2].color}
        onClick={() => {
          sendToPricer(2);
        }}
      >
        3
      </PricerButton>
      <PricerButton
        disabled={
          !!pricerToCallsLinks[3].color ||
          Object.keys(callsToPricerLinks).includes(callId)
        }
        color={pricerToCallsLinks[3].color}
        onClick={() => {
          sendToPricer(3);
        }}
      >
        4
      </PricerButton>
    </Row>
  );
};

const PricerButton = styled.button<{ color: string }>`
  width: fit-content;
  margin: 0;
  padding: 4px;
  border-radius: initial;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ color }) => color};
  background: rgba(0, 0, 0, 0.2);
  color: #8c8c8c;
`;

export default ToPricerButton;
