import { Tooltip, Button } from "antd";
import React, { useState } from "react";
import { Row } from "./GenericStyledComponents";
import { SaveOutlined } from "@ant-design/icons";

type Props = {
  tooltipText: string;
  onClick: () => void;
  Icon: any;
};

const HoverButton: React.FC<Props> = ({ tooltipText, onClick, Icon }) => {
  const [saveHovered, setSaveHovered] = useState(false);

  return (
    <Row
      style={{
        position: "absolute",
        bottom: "1rem",
        left: "50vw",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Tooltip placement="top" title={tooltipText}>
        <Button
          style={{
            display: "flex",
            alignItems: "center",
          }}
          onClick={onClick}
          onMouseEnter={() => {
            setSaveHovered(true);
          }}
          onMouseLeave={() => {
            setSaveHovered(false);
          }}
        >
          <SaveOutlined /> Save
        </Button>
      </Tooltip>
    </Row>
  );
};

export default HoverButton;
