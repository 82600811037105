import { UndoOutlined } from "@ant-design/icons";
import { Button, Table, TableProps, Typography } from "antd";
import { Column, Row } from "components/reusable/GenericStyledComponents";
import { useRoomTimeContext } from "contexts/RoomTimeProvider";
import React, { useCallback, useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import Toggle from "react-toggle";
import { roomService } from "services";
import {
  CALL,
  OPT_EXERCISED,
  OPT_EXPIRED,
  OPT_PENDING,
  TOGL_EXECUTE,
  TOGL_IGNORE,
} from "stateConstants";
import { optionToggle, setOptionsBlotter } from "store/blotter/actions";
import { RootState } from "store/rootReducer";
import { useAppDispatch } from "store/store";
import styled from "styled-components";
import { MATURITY, Option } from "types";
import { numberWithCommas, percent2Digits, roundPremium } from "utils/numbers";
import { secondsToTimestamp, timeUnixToDatePretty } from "utils/time";

type Props = {
  options: Option[];
};

const OptionsBlotterTable: React.FC<Props> = ({ options }) => {
  const dispatch = useAppDispatch();
  const sticky_model = useSelector(
    (state: RootState) => state.spot.sticky_model,
    shallowEqual
  );
  const mtm = useSelector((state: RootState) => state.blotter.mtm);
  const traderId = useSelector((state: RootState) => state.user.traderId);
  const AUTO_EXERCISE = useSelector(
    (state: RootState) => state.settings.parameters.AUTO_EXERCISE
  );
  const SINGLE_OR_MULTIPLE_DAYS_TRADING = useSelector(
    (state: RootState) =>
      state.settings.parameters.SINGLE_OR_MULTIPLE_DAYS_TRADING
  );
  const swift = useSelector((state: RootState) => state.swifts.selectedSwift);
    
  const getMtm = async () => {
    try {
      const _blotter = await roomService.getMtM(traderId);
      dispatch(setOptionsBlotter(_blotter));
    } catch (e) {
      console.log(e);
    }
  };

  const handleToggleChange = useCallback(
    (event, recordId) => {
      dispatch(
        optionToggle({
          optionId: recordId,
          toggle: event.target.checked ? TOGL_EXECUTE : TOGL_IGNORE,
        })
      );
    },
    [dispatch]
  );

  const dynamicColumns = useMemo(
    () => [
      {
        dataIndex: "market_taker_id",
        title: "Buy/Sell",
        shouldCellUpdate: (record: any, prevRecord: any) =>
          !shallowEqual(record, prevRecord),
        render: (_: any, record: any) => (
          <Text
            style={{
              color: record.is_bought ? "#00B287" : "#F84960",
            }}
          >
            {record.is_bought ? "Buy" : "Sell"}
          </Text>
        ),
      },
      ...(!SINGLE_OR_MULTIPLE_DAYS_TRADING
        ? []
        : [
            {
              dataIndex: "market_taker_id",
              title: "Exercise",
              shouldCellUpdate: (record: any, prevRecord: any) =>
                !shallowEqual(record, prevRecord),
              render: (_: any, record: any) => (
                <div>
                  <OptionText isBought={record.is_bought}>
                    {record.status === OPT_EXERCISED &&
                      (record.is_bought ? "Exercised" : "Assigned")}
                  </OptionText>
                  {record.status === OPT_EXPIRED && <Text>Expired</Text>}

                  {record.status === OPT_PENDING && record.is_bought && (
                    <Row className="align-items-center" style={{ gap: 5 }}>
                      <Toggle
                        checked={!!record.toggle}
                        disabled={AUTO_EXERCISE === 1}
                        icons={false}
                        onChange={(event) =>
                          handleToggleChange(event, record.id)
                        }
                      />
                      <Text>{record.toggle ? "Yes" : "No"}</Text>
                    </Row>
                  )}
                </div>
              ),
            },
          ]),
      {
        title: "Call/Put",
        dataIndex: "put_or_call",
        shouldCellUpdate: (record: any, prevRecord: any) =>
          !shallowEqual(record, prevRecord),
        render: (_: any, record: any) => (
          <Text>{record.put_or_call === CALL ? "Call" : "Put"}</Text>
        ),
      },

      {
        title: "Tenor",
        dataIndex: "tenor",
        shouldCellUpdate: (record: any, prevRecord: any) =>
          !shallowEqual(record, prevRecord),
        render: (_: any, record: any) => (
          <Text>
            {sticky_model
              ? MATURITY[record.tenor]
              : timeUnixToDatePretty(record.tenor)}
          </Text>
        ),
      },
      {
        title: "Strike",
        dataIndex: "strike",
        shouldCellUpdate: (record: any, prevRecord: any) =>
          !shallowEqual(record, prevRecord),
        render: (_: any, record: any) => <Text>{record.strike}</Text>,
      },
      {
        title: "Vol",
        dataIndex: "volatility",
        shouldCellUpdate: (record: any, prevRecord: any) =>
          !shallowEqual(record, prevRecord),
        render: (_: any, record: any) => (
          <Text>{percent2Digits(record.volatility)}</Text>
        ),
      },
      {
        title: "Notional",
        dataIndex: "amount",
        shouldCellUpdate: (record: any, prevRecord: any) =>
          !shallowEqual(record, prevRecord),
        render: (_: any, record: any) => (
          <Text>{`${numberWithCommas(record.amount)}/${numberWithCommas(
            record.amount * (swift?.vol_ticket_unit || 1)
          )}`}</Text>
        ),
      },
      {
        title: "Premium",
        dataIndex: "premium",
        shouldCellUpdate: (record: any, prevRecord: any) =>
          !shallowEqual(record, prevRecord),
        render: (_: any, record: any) => (
          <Text>
            {numberWithCommas(
              (record.is_bought ? -1 : 1) *
                record.initial_unit_premium *
                record.amount *
                (swift?.vol_ticket_unit || 0),
              0
            )}
          </Text>
        ),
      },
      {
        title: "Market value",
        dataIndex: "current_premium",
        shouldCellUpdate: (record: any, prevRecord: any) =>
          !shallowEqual(record, prevRecord),
        render: (_: any, record: any) => (
          <Text>
            {parseFloat(record.current_premium) === 0 ? 0 : numberWithCommas(roundPremium(record.is_bought ? record.current_premium : -1 * record.current_premium))}
          </Text>
        ),
      },
      {
        title: "Time",
        dataIndex: "created_at_room_time",
        shouldCellUpdate: (record: any, prevRecord: any) =>
          !shallowEqual(record, prevRecord),
        render: (_: any, record: any) => (
          <Text>
            {/* {Math.floor(option.room_time_tenor / REAL_SECONDS_TO_GAME_DAYS_RATIO) } */}
            {secondsToTimestamp(record.created_at_room_time)}
          </Text>
        ),
      },
    ],
    [traderId, swift, AUTO_EXERCISE, sticky_model]
  );

  return (
    <Column>
      <Button
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={() => getMtm()}
      >
        Refresh market value <UndoOutlined />
      </Button>
      <Column
        style={{
          justifyContent: "center",
          alignItems: "center",
          margin: "1rem",
        }}
      >
        {mtm !== null ? (
          <Typography.Text>Total MtM: {numberWithCommas(mtm)}</Typography.Text>
        ) : null}
      </Column>
      <BlotterTable
        rowKey="id"
        dataSource={options}
        pagination={false}
        style={{ height: "100%" }}
        columns={[...dynamicColumns]}
      />
    </Column>
  );
};

const OptionText: React.FC<{ isBought: boolean; children: React.ReactNode }> =
  React.memo(({ isBought, children }) => (
    <Text style={{ color: isBought ? "#00B287" : "#F84960" }}>{children}</Text>
  ));

const Text = styled(Typography.Text)`
  display: block;
  margin: 0;
  font-size: 12px;
  font-weight: 600;
`;

const BlotterTable = styled((props: TableProps<any>) => <Table {...props} />)`
  .ant-table {
    font-size: 10px !important;

    .ant-table-container th:first-child {
      border-start-start-radius: 3px !important;
    }
    .ant-table-container th:last-child {
      border-start-end-radius: 3px !important;
    }

    .ant-table-cell {
      padding: 5px 1px !important;
    }
  }
`;

export default OptionsBlotterTable;
