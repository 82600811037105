import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  MacroEventTypes,
  InitialDataPayload,
  MacroEvent,
  MacroEventPayload,
  EventState,
  EventPublishedPayload,
  VolImpact,
  NewsVotedConfimationPayload,
} from "types";
import { clearRoom, retrieveInitialData } from "../room/actions";
import {
  closedForVoting,
  DirectionConfidenceSpotPayload,
  directionConfidenceSpotRefreshed,
  DirectionConfidenceVolPayload,
  directionConfidenceVolRefreshed,
  eventPublished,
  newsVotedAction,
} from "./actions";

const initialState: () => EventState = () => ({
  news: [],
  forecasts: [],
  market_impact: {
    spot: 0,
    vol: {
      0: {
        0: 0,
      },
    },
  },
  impact_votes: {},
  market_impact_loaded: false,
  latestNews: undefined,
});

export const eventSlice = createSlice({
  initialState,
  name: "events",
  reducers: {},
  extraReducers: (builder) => {
    const parseEvent: (payload: MacroEventPayload) => MacroEvent = (
      payload
    ) => {
      const { event_data } = payload;
      const macroEvent: MacroEvent &
        Partial<Pick<MacroEventPayload, "event_data">> = {
        ...payload,
        ...event_data,
      };
      delete macroEvent.event_data;

      return macroEvent;
    };

    const sorter = (a: MacroEvent, b: MacroEvent) =>
      b.published_at - a.published_at;

    builder
      .addCase(
        retrieveInitialData,
        (state, action: PayloadAction<InitialDataPayload>) => {
          const { events, swifts, tenors, impact_votes } = action.payload;
          const [{ sticky_model, swift_id }] = swifts;
          const vol: VolImpact = {};
          state.impact_votes = impact_votes;

          if (sticky_model) {
            const { vol_eb_strikes } = action.payload;
            const strikes = vol_eb_strikes[swift_id];
            for (const d of strikes) {
              vol[d] = {};
              for (const t of tenors[swift_id]) {
                vol[d][t] = 0;
              }
            }
          } else {
            const { vol_eb_strikes } = action.payload;
            const strikes = vol_eb_strikes[swift_id];

            for (const s of strikes) {
              vol[s] = {};
              for (const t of tenors[swift_id]) {
                vol[s][t] = 0;
              }
            }
          }

          const news = events
            .filter((event) => event.event_type === MacroEventTypes.NEWS)
            .map(parseEvent)
            .sort(sorter);

          const forecasts = events
            .filter((event) => event.event_type === MacroEventTypes.FORECAST)
            .map(parseEvent)
            .sort(sorter);

          state.news = news;
          state.forecasts = forecasts;
          state.market_impact = {
            spot: 0,
            vol,
          };
          state.market_impact_loaded = true;
          state.latestNews = news[0] || undefined;
        }
      )
      .addCase(
        eventPublished,
        (state, action: PayloadAction<EventPublishedPayload>) => {
          const { event } = action.payload;

          const type =
            event.event_type === MacroEventTypes.NEWS ? "news" : "forecasts";
          state[type].push(parseEvent(event));
          state[type].sort(sorter);
          state.latestNews = parseEvent(event);
        }
      )
      .addCase(
        directionConfidenceSpotRefreshed,
        (state, action: PayloadAction<DirectionConfidenceSpotPayload>) => {
          const { payload } = action.payload;
          state.market_impact.spot = parseFloat(
            Object.values(payload.direction_confidence_values)[0]
          );
          state.market_impact_loaded = true;
        }
      )
      .addCase(
        directionConfidenceVolRefreshed,
        (state, action: PayloadAction<DirectionConfidenceVolPayload>) => {
          const { payload } = action.payload;
          const swift_id = Object.keys(payload.direction_confidence_values)[0];
          // eslint-disable-next-line
          for (const delta_strike of Object.keys(
            payload.direction_confidence_values[swift_id]
          )) {
            // eslint-disable-next-line
            for (const tenor of Object.keys(
              payload.direction_confidence_values[swift_id][delta_strike]
            )) {
              try {
                state.market_impact.vol[delta_strike][tenor] = parseFloat(
                  payload.direction_confidence_values[swift_id][delta_strike][
                    tenor
                  ]
                );
              } catch {
                // console.log(`tenor: ${tenor} strike: ${delta_strike} not in market imapct state`);
              }
            }
          }
          // state.market_impact.vol =
          state.market_impact_loaded = true;
        }
      )
      .addCase(
        newsVotedAction,
        (
          state,
          action: PayloadAction<
            NewsVotedConfimationPayload & { spot_or_vol: "spot" | "vol" }
          >
        ) => {
          const { spot_or_vol, trader_vote } = action.payload;
          const { event_id, swift_id, vote } = trader_vote;

          // Ensure `state.impact_votes[event_id]` is initialized
          if (!state.impact_votes[event_id]) {
            state.impact_votes[event_id] = {};
          }

          // Ensure `state.impact_votes[event_id][swift_id]` is initialized
          if (!state.impact_votes[event_id][swift_id]) {
            state.impact_votes[event_id][swift_id] = { spot: 0, vol: 0 };
          }
          // if (!Object.keys(state.impact_votes[event_id]).length) state.impact_votes[event_id] = {};
          // if (!Object.keys(state.impact_votes[event_id][swift_id]).length) state.impact_votes[event_id][swift_id] = { spot: 0, vol: 0 };
          if (spot_or_vol === "spot") {
            state.impact_votes[event_id][swift_id].spot = vote;
            if (!state.latestNews!.votes)
              state.latestNews!.votes = {
                [swift_id.toString()]: { voted_spot: 0, voted_vol: 0 },
              };
            state.latestNews!.votes[swift_id.toString()].voted_spot = vote;
          }
          if (spot_or_vol === "vol") {
            state.impact_votes[event_id][swift_id].vol = vote;
            if (!state.latestNews!.votes)
              state.latestNews!.votes = {
                [swift_id.toString()]: { voted_spot: 0, voted_vol: 0 },
              };
            state.latestNews!.votes[swift_id.toString()].voted_vol = vote;
          }
        }
      )
      .addCase(
        closedForVoting,
        (state, action: PayloadAction<{ event: { id: number } }>) => {
          state.news[0].vote_expired = true;
          if (state.latestNews) state.latestNews.vote_expired = true;
          // state.news[id].voted = true;
        }
      )
      .addCase(clearRoom, initialState);
  },
});

export default eventSlice.reducer;
