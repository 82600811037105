import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  VolatilityChartsUpdatedPayload,
  VolatilityCurves,
  VolatilityState,
  VolChartPointPayload,
} from "types";
import {
  setVolatilityCurves,
  // volatilitySurfaceUpdated,
  volChartsUpdated,
  // volChartUpdated,
} from "./actions";
import { clearRoom } from "../room/actions";

const initialState: () => VolatilityState = () => ({
  priceStrikes: [],
  percentStrike: [],
  volatility_curves: [],
});

const volatilitySlice = createSlice({
  name: "volatility",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // .addCase(retrieveInitialData, (state, action: PayloadAction<InitialDataPayload>) => {
      //   const { volatility_curves } = action.payload;

      //   // const { price_strikes, percent_strikes } = Object.values(
      //   //   action.payload.volatility_surfaces
      //   // )[0];
      //   const parsedVolCurves: VolatilityCurves = {};
      //   Object.keys(volatility_curves).forEach((tenor) => {
      //     const numericTenor = parseInt(tenor)
      //     if (!parsedVolCurves.hasOwnProperty(numericTenor)) parsedVolCurves[numericTenor] = {}
      //     Object.keys(volatility_curves[tenor]).forEach((delta_strike) => {
      //       const numericDeltaStrike = parseFloat(delta_strike)

      //       parsedVolCurves[numericTenor][numericDeltaStrike] = volatility_curves[tenor][delta_strike].map(p => ({ volatility: parseFloat(p.volatility) * 100, room_time: p.room_time }));
      //     })
      //   })
      //   state.volatility_curves = parsedVolCurves;

      //   // state.priceStrikes = price_strikes;
      //   // state.percentStrike = percent_strikes;
      // })
      // // .addCase(volatilitySurfaceUpdated, (state, action) => {
      // //   const { volatility_curves, price_strikes, percent_strikes } =
      // //     action.payload.surface;

      // //   state.volatilityCurves = volatility_curves;
      // //   state.priceStrikes = price_strikes;
      // //   state.percentStrike = percent_strikes;
      // // })
      // // .addCase(volChartUpdated, (state, action) => {
      // //   const { point } = action.payload;
      // //   const { delta_strike, tenor } = point;
      // //   state.volatility_curves[`${tenor} ${delta_strike}`].push(point);
      // // })
      .addCase(
        volChartsUpdated,
        (state, action: PayloadAction<VolatilityChartsUpdatedPayload>) => {
          const { charts } = action.payload;
          console.log("VOL CURVES UPDATED");
          if (Object.keys(state.volatility_curves).length) {
            charts.forEach((point) => {
              const { delta_strike, tenor, volatility, room_time, room_date } =
                point;

              const parsedDeltaStrike = parseFloat(delta_strike);
              if (!state.volatility_curves[tenor]) {
                state.volatility_curves[tenor] = {};
              }
              if (!state.volatility_curves[tenor][parsedDeltaStrike]) {
                state.volatility_curves[tenor][parsedDeltaStrike] = [];
              }

              try {
                state.volatility_curves[tenor][parsedDeltaStrike].push({
                  volatility: parseFloat(volatility) * 100,
                  room_time,
                  room_date,
                });
              } catch (e) {
                console.warn(
                  `[VolChart] Received tenor ${new Date(
                    tenor * 1000
                  ).toUTCString()} (${tenor}) delta_strike ${Number(
                    delta_strike
                  )} that is not in the system`
                );
              }
            });
          }
        }
      )
      .addCase(
        setVolatilityCurves,
        (
          state,
          action: PayloadAction<{
            [key: string]: { [key: string]: VolChartPointPayload[] };
          }>
        ) => {
          const volatility_curves = action.payload;
          const parsedVolCurves: VolatilityCurves = {};
          Object.keys(volatility_curves).forEach((tenor: any) => {
            const numericTenor = parseInt(tenor);
            if (!parsedVolCurves.hasOwnProperty(numericTenor))
              parsedVolCurves[numericTenor] = {};
            Object.keys(volatility_curves[tenor]).forEach(
              (delta_strike: any) => {
                const numericDeltaStrike = parseFloat(delta_strike);

                parsedVolCurves[numericTenor][numericDeltaStrike] =
                  volatility_curves[tenor][delta_strike].map((p) => ({
                    volatility: parseFloat(p.volatility) * 100,
                    room_time: p.room_time,
                    room_date: p.room_date,
                  }));
              }
            );
          });
          state.volatility_curves = parsedVolCurves;
        }
      )
      .addCase(clearRoom, initialState);
  },
});

export default volatilitySlice.reducer;
