import { Skeleton } from "antd";
import React from "react";
import { Column } from "./GenericStyledComponents";

const SkeletonRows: React.FC<{ rows: number }> = ({ rows }) => {
  return (
    <Column style={{ width: "50%" }}>
      {[...Array(rows)].map(() => (
        <Skeleton active />
      ))}
    </Column>
  );
};

export default SkeletonRows;
