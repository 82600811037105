export const RFQ_CALL_CREATED = "rfq/CallCreated";
export const RFQ_CALL_ANSWERED = "rfq/CallAnswered";
export const RFQ_CALL_HIT = "rfq/CallHit";
export const RFQ_CALL_REJECTED = "rfq/CallRejected";
export const RFQ_CALL_EXPIRED = "rfq/CallExpired";
export const RFQ_ERROR = "rfq/error";

export const RFQ_MAKE_CALL = "rfq/cmdMakeCall";
export const RFQ_ANSWER_CALL = "rfq/cmdAnswerCall";
export const RFQ_HIT_CALL = "rfq/cmdHitCall";
export const RFQ_NTG_CALL = "rfq/cmdNtgCall";

export const CMD_RFQ_MAKE_CALL = "rfq.cmd.call.make";
export const CMD_RFQ_ANSWER_CALL = "rfq.cmd.call.answer";
export const CMD_RFQ_HIT_CALL = "rfq.cmd.call.hit";
export const CMD_RFQ_NTG_CALL = "rfq.cmd.call.ntg";
