import { createAction } from "@reduxjs/toolkit";
import {
  CC_ACCEPT_CALL,
  CC_ACCEPTED,
  CC_ANSWER_CALL,
  CC_ANSWERED,
  CC_CANCEL_CALL,
  CC_CREATED,
  CC_DECLINE_CALL,
  CC_DECLINED,
  CC_EXPIRED,
  CC_CANCELED,
} from "./constants";
import {
  CCAnswerPayload,
  CCIdPayload,
  CCGenericPayload,
  PricerUpdateDataPayload,
  GetVolatilityPayload,
} from "types";

export const clientCallCreated = createAction<CCGenericPayload>(CC_CREATED);
export const clientCallAnswered = createAction<CCGenericPayload>(CC_ANSWERED);
export const clientCallAccepted = createAction<CCGenericPayload>(CC_ACCEPTED);
export const clientCallDeclined = createAction<CCGenericPayload>(CC_DECLINED);
export const clientCallExpired = createAction<CCGenericPayload>(CC_EXPIRED);
export const clientCallCanceled = createAction<CCGenericPayload>(CC_CANCELED);

export const clientCallAnswer = createAction<CCAnswerPayload>(CC_ANSWER_CALL);
export const clientCallAccept = createAction<CCIdPayload>(CC_ACCEPT_CALL);
export const clientCallDecline = createAction<CCIdPayload>(CC_DECLINE_CALL);
export const clientCallCancel = createAction<CCIdPayload>(CC_CANCEL_CALL);
export const ccPricerData =
  createAction<PricerUpdateDataPayload>("cc/getPricerData");

export const ccGetVolatility =
  createAction<GetVolatilityPayload>("cc/GetVolatility");
