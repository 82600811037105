import { PayloadAction } from "@reduxjs/toolkit";
import {
  call,
  take,
  delay,
  put,
  select,
  takeEvery,
  cancel,
  race,
} from "redux-saga/effects";

import {
  RFQAnswerCallPayload,
  RFQAnswerStrategyCallPayload,
  RFQCall,
  RFQCallGenericPayload,
  RFQHitCallPayload,
  RFQMakeCallPayload,
  RFQNtgCallPayload,
  StrategyType,
} from "types";
import {
  CMD_RFQ_ANSWER_CALL,
  CMD_RFQ_HIT_CALL,
  CMD_RFQ_MAKE_CALL,
  CMD_RFQ_NTG_CALL,
  RFQ_ANSWER_CALL,
  RFQ_CALL_ANSWERED,
  RFQ_CALL_EXPIRED,
  RFQ_CALL_HIT,
  RFQ_CALL_REJECTED,
  RFQ_HIT_CALL,
  RFQ_MAKE_CALL,
  RFQ_NTG_CALL,
} from "./constants";
import { socketSendMessage } from "../socket/actions";
import { removeRfq } from "./reducers";
import { RootState } from "../rootReducer";
import { unlinkPricerCall } from "store/newpricer/actions";
import { removeCallQuote } from "store/callQuote/reducers";

function* rfqMakeCallSaga({ payload }: PayloadAction<RFQMakeCallPayload>) {
  const { strategy, swiftId } = payload;

  switch (strategy) {
    case StrategyType.INDIVIDUAL_OPTION: {
      const { strike, amount, put_or_call, tenor } = payload.options;
      const request = {
        headers: {
          command: CMD_RFQ_MAKE_CALL,
        },
        swift_id: swiftId,
        strategy: {
          strategy,
          options: {
            strike,
            tenor,
            amount,
            put_or_call,
          },
        },
      };
      yield put(socketSendMessage(JSON.stringify(request)));
      break;
    }
    case StrategyType.STRADDLE: {
      const { strike, amount, tenor } = payload.options;
      const request = {
        headers: {
          command: CMD_RFQ_MAKE_CALL,
        },
        swift_id: swiftId,
        strategy: {
          strategy,
          options: {
            strike,
            tenor,
            amount,
          },
        },
      };
      yield put(socketSendMessage(JSON.stringify(request)));
      break;
    }
    case StrategyType.STRANGLE: {
      const { amount, tenor, strike1, strike2 } = payload.options;
      const request = {
        headers: {
          command: CMD_RFQ_MAKE_CALL,
        },
        swift_id: swiftId,
        strategy: {
          strategy,
          options: {
            strike1,
            strike2,
            tenor,
            amount,
          },
        },
      };
      yield put(socketSendMessage(JSON.stringify(request)));
      break;
    }
    case StrategyType.PUT_CALENDAR_SPREAD: {
      const { strike, amount, tenor1, tenor2 } = payload.options;
      const request = {
        headers: {
          command: CMD_RFQ_MAKE_CALL,
        },
        swift_id: swiftId,
        strategy: {
          strategy: 4,
          options: {
            strike,
            tenor1,
            tenor2,
            amount,
          },
        },
      };
      yield put(socketSendMessage(JSON.stringify(request)));
      break;
    }
    case StrategyType.CALL_CALENDAR_SPREAD: {
      const { strike, amount, tenor1, tenor2 } = payload.options;
      const request = {
        headers: {
          command: CMD_RFQ_MAKE_CALL,
        },
        swift_id: swiftId,
        strategy: {
          strategy: 3,
          options: {
            amount,
            strike,
            tenor1,
            tenor2,
          },
        },
      };
      yield put(socketSendMessage(JSON.stringify(request)));
      break;
    }
    case StrategyType.BEAR_SPREAD: {
      const { put_or_call, strike1, strike2, amount, tenor } = payload.options;
      const request = {
        headers: {
          command: CMD_RFQ_MAKE_CALL,
        },
        swift_id: swiftId,
        strategy: {
          strategy: 6,
          options: {
            amount,
            strike1,
            strike2,
            tenor,
            put_or_call,
          },
        },
      };
      yield put(socketSendMessage(JSON.stringify(request)));
      break;
    }
    case StrategyType.BULL_SPREAD: {
      const { put_or_call, strike1, strike2, amount, tenor } = payload.options;
      const request = {
        headers: {
          command: CMD_RFQ_MAKE_CALL,
        },
        swift_id: swiftId,
        strategy: {
          strategy: 5,
          options: {
            amount,
            strike1,
            strike2,
            tenor,
            put_or_call,
          },
        },
      };
      yield put(socketSendMessage(JSON.stringify(request)));
      break;
    }
    case StrategyType.BUTTERFLY: {
      const { put_or_call, strike1, strike2, strike3, amount, tenor } = payload.options;
      console.log(strike1, strike2, strike3);

      const request = {
        headers: {
          command: CMD_RFQ_MAKE_CALL,
        },
        swift_id: swiftId,
        strategy: {
          strategy: 7,
          options: {
            put_or_call,
            amount,
            strike1,
            strike2,
            strike3,
            tenor,
          },
        },
      };
      yield put(socketSendMessage(JSON.stringify(request)));
      break;
    }
  }
}

function* rfqAnswerCallSaga({ payload }: PayloadAction<RFQAnswerCallPayload>) {
  const { bid, ask, callId } = payload;

  const request = {
    headers: {
      command: CMD_RFQ_ANSWER_CALL,
    },
    call_id: callId,
    bid,
    ask,
  };

  yield put(socketSendMessage(JSON.stringify(request)));
}
function* rfqAnswerStrategyCallSaga({
  payload,
}: PayloadAction<RFQAnswerStrategyCallPayload>) {
  const { quotes, callId } = payload;

  const request = {
    headers: {
      command: CMD_RFQ_ANSWER_CALL,
    },
    call_id: callId,
    quotes,
  };

  yield put(socketSendMessage(JSON.stringify(request)));
}

function* rfqHitCallSaga({ payload }: PayloadAction<RFQHitCallPayload>) {
  const { callId, direction } = payload;

  const request = {
    headers: {
      command: CMD_RFQ_HIT_CALL,
    },
    call_id: callId,
    direction,
  };

  yield put(socketSendMessage(JSON.stringify(request)));
  yield put(unlinkPricerCall({ callId: `${callId}_rfq` }));
  yield put(removeCallQuote({ id: callId }));
  yield put(removeRfq(callId));
}

function* rfqNtgCallSaga(action: PayloadAction<RFQNtgCallPayload>) {
  const { callId } = action.payload;

  const request = {
    headers: {
      command: CMD_RFQ_NTG_CALL,
    },
    call_id: callId,
  };

  yield put(socketSendMessage(JSON.stringify(request)));

  // Remove rfq until Rejected message is fixed
  const traderId: string = yield select(
    (state: RootState) => state.user.traderId
  );
  const rfq: RFQCall = yield select((state: RootState) => state.rfq[callId]);
  yield put(unlinkPricerCall({ callId: `${rfq.id}_rfq` }));
  yield put(removeCallQuote({ id: rfq.id }));
  if (rfq.market_maker_id === traderId) {
    yield put(removeRfq(callId));
  }
}

function* rfqRemoveDelaySaga(action: PayloadAction<RFQCallGenericPayload>) {
  const { call: rfq } = action.payload;
  yield delay(10000);
  yield put(unlinkPricerCall({ callId: `${rfq.id}_rfq` }));
  yield put(removeCallQuote({ id: rfq.id }));
  yield put(removeRfq(rfq.id));
}

function* removeRfqIfIncoming(action: PayloadAction<RFQCallGenericPayload>) {
  const traderId: string = yield select(
    (state: RootState) => state.user.traderId
  );
  const { call: rfq } = action.payload;

  if (rfq.market_maker_id === traderId) {
    const { timeout } = yield race({
      hit: call(function* () {
        while (true) {
          const hitAction: PayloadAction<RFQCallGenericPayload> = yield take(
            RFQ_CALL_HIT
          );
          if (hitAction.payload.call.id === rfq.id) {
            yield cancel();
          }
        }
      }),
      timeout: delay(10000),
    });

    if (timeout) {
      yield put(removeRfq(rfq.id));
    }
  }
}

export function* watchRFQAsync() {
  yield takeEvery(RFQ_MAKE_CALL, rfqMakeCallSaga);
  yield takeEvery(RFQ_ANSWER_CALL, rfqAnswerCallSaga);
  yield takeEvery("rfq/cmdAnswerStrategyCall", rfqAnswerStrategyCallSaga);
  yield takeEvery(RFQ_HIT_CALL, rfqHitCallSaga);
  yield takeEvery(RFQ_NTG_CALL, rfqNtgCallSaga);

  // yield takeEvery(RFQ_CALL_ANSWERED, removeRfqIfIncoming);
  yield takeEvery(RFQ_CALL_HIT, rfqRemoveDelaySaga);
  yield takeEvery(RFQ_CALL_REJECTED, rfqRemoveDelaySaga);
  yield takeEvery(RFQ_CALL_EXPIRED, rfqRemoveDelaySaga);
}
