import { BUY, LONG, SELL, SHORT, CALL, PUT } from "stateConstants";
import {
  EbOrder,
  EbOrderPayload,
  SimpleEbOrder,
  SimpleEbOrderPayload,
} from "./ebOrders";
import { SpotDealPayload } from "./blotter";
import { Swift } from "./swifts";

// export type SpotState = {
//   swiftId: number;
//   bidOffers: SimpleEbOrder[];
//   askOffers: SimpleEbOrder[];
//   ebOrders: EbOrder[];
//   volEbOrders: VolEbOrder[];
//   spotChartValues: SpotChartPoint[];
//   tradableVols: any;
//   selectedDelta: any;
//   quantity: number;
//   validQuantity: boolean;
//   sticky_model: number;
//   delta_strike_values: any[];
//   selectedSellTenor?: Tenor;
//   selectedBuyTenor?: Tenor;
//   initial_state_loaded: boolean;
// };

export type OHLCPoint = {
  x: number;
  o: number;
  h: number;
  l: number;
  c: number;
};

export type LightweightOHLCPoint = {
  time: Date;
  open: number;
  high: number;
  low: number;
  close: number;
};

export type ApexChartCandlestick = { x: number; y: number[] };

export type SpotState = StrikeSpotState | DeltaSpotState;

export type VolEbPylon = {
  [key: number]: {
    // tenor
    [key: number]: {
      // delta_strike
      sellVolume: number;
      buyVolume: number;
      sell: number;
      buy: number;
      buyTraders: string[];
      sellTraders: string[];
    };
  };
};

export type StrikeSpotState = {
  swifts: Swift[];
  swiftId: number;
  bestBid: SimpleEbOrder;
  bestAsk: SimpleEbOrder;
  ebOrders: EbOrder[];
  volEbOrders: VolEbOrder[];
  spotChartValues: SpotChartPoint[];
  spotChartOHLC: { [key: string]: OHLCPoint[] };
  tradableVols: VolEbPylon;
  selectedDelta: number;
  spotQuantity: number;
  volQuantity: number;
  validVolQuantity: boolean;
  validSpotQuantity: boolean;
  sticky_model: 1;
  delta_strike_values: number[];
  selectedDeltaKey: number;
  selectedSellTenor?: number;
  selectedBuyTenor?: number;
  initial_state_loaded: boolean;
  tenors: { [key: number]: number[] };
  visible_vol_eb_tenors: { [key: number]: number[] };
  visible_tenors: { [key: number]: number[] };
  visible_vol_eb_delta_strike_values: { [key: number]: number[] };
  visible_delta_strike_values: { [key: number]: number[] };
};

export type DeltaSpotState = {
  swifts: Swift[];
  swiftId: number;
  bestBid: SimpleEbOrder;
  bestAsk: SimpleEbOrder;
  ebOrders: EbOrder[];
  volEbOrders: VolEbOrder[];
  spotChartValues: SpotChartPoint[];
  spotChartOHLC: { [key: string]: OHLCPoint[] };
  tradableVols: VolEbPylon;
  selectedDelta: number;
  spotQuantity: number;
  volQuantity: number;
  validVolQuantity: boolean;
  validSpotQuantity: boolean;
  sticky_model: 0;
  delta_strike_values: number[];
  selectedDeltaKey: number;
  selectedSellTenor?: number;
  selectedBuyTenor?: number;
  initial_state_loaded: boolean;
  tenors: { [key: number]: number[] };
  visible_vol_eb_tenors: { [key: number]: number[] };
  visible_tenors: { [key: number]: number[] };
  visible_vol_eb_delta_strike_values: { [key: number]: number[] };
  visible_delta_strike_values: { [key: number]: number[] };
};

export type TradableVol = {
  [k in Tenor]: {
    buy: number;
    buyVolume: number;
    sell: number;
    sellVolume: number;
  };
};

export type deltaObject = {
  [k in DeltaHedge]: TradableVol;
};

// export type strikeObject = {

// }

export enum DeltaHedge {
  "10dP" = 0.1,
  "25dP" = 0.25,
  "ATM" = 0.5,
  "25dC" = 0.75,
  "10dC" = 0.9,
}

export enum Tenor {
  "1W" = 5,
  "2W" = 10,
  "1M" = 21,
  "2M" = 42,
  "3M" = 63,
  "6M" = 126,
  "9M" = 189,
  "1Y" = 252,
  "2Y" = 504,
  "3Y" = 756,
  "4Y" = 1008,
  "5Y" = 1260,
  "6Y" = 1512,
  "7Y" = 1764,
  "10Y" = 2520,
}

export interface SpotChartPoint {
  price: number;
  room_time: number;
  room_date: number;
}

export type SpotChartPriceUpdatedPayload = {
  room_id: string;
  room_time: number;
  ohlc_data: {
    [key: string]: {
      data: OHLCPoint[];
      index: number | null;
    };
  };
  point: {
    price: string;
    room_time: number;
    room_date: number;
  };
};

export interface ElectronicBrokerRequest {
  trader: number;
  order: number;
  amount: number;
}

export type SpotEBUpdatedPayload = {
  room_id: string;
  direction: 0 | 1;
  swift_id: number;
  room_time: number;
  order: SimpleEbOrderPayload[];
};

export type SpotPositionUpdatedPayload = {
  swift_id: number;
  position: {
    amount: string;
    spot_realized_pnl: string;
    spot_unrealized_pnl: string;
  };
};

export type SportEbOrderAddedPayload = {
  type: "spot/OrderAdded";
  room_id: string;
  room_time: number;
  order: EbOrderPayload;
};

export type SportEbOrderCanceledPayload = {
  type: "spot/OrderCanceled";
  room_id: string;
  room_time: number;
  order: EbOrderPayload;
};

export type SpotDealDonePayload = {
  type: "spot/SpotDealDone";
  room_id: string;
  room_time: number;
  deal: SpotDealPayload;
};

export type SpotPlaceOrderPayload = {
  swift_id: number;
  direction: number;
  amount: number;
  price: number;
};

export type SpotPlaceOrderVolEbPayload = {
  swift_id: number;
  direction: number;
  amount: number;
  volatility: number;
  delta_strike: number;
  tenor: number;
  put_or_call: 0 | 1;
};

export type SpotOrderExercisedPayload = {
  type: "spot/OrderExercised";
  room_id: string;
  room_time: number;
  market_taker_id: string;
  market_taker_name: string;
  hit_amount: string;
  price: string;
  deal_source: string;
  order: EbOrderPayload;
};

export type SpotHitOrderPayload = {
  amount: number;
  price: number;
  direction: 0 | 1;
};

export type SpotCancelEbOrderPayload = {
  order_id: number;
};

export type SpotVolEbHitPayload = {
  strategy: 0 | 1;
  volatility: number;
  tenor: number;
  delta_strike: number;
  amount: number;
  short_or_long: typeof SHORT | typeof LONG;
  put_or_call?: typeof CALL | typeof PUT;
  swift_id: number;
};

export type VolEbDepth = {
  volatility: string;
  count: number;
  amount: number;
  traders: string[];
};

export interface VolEbDepthUpdatedPayload {
  type: "volatility/SurfaceUpdated";
  room_id: string;
  direction: number;
  delta_strike: number;
  tenor: Tenor;
  vol_eb_depth: VolEbDepth[];
  swift_id: string;
  room_time: string;
}

export interface VolEbDepthUnit {
  tenor: number;
  delta_strike: number;
  direction: number;
  depth: VolEbDepth;
}

export interface VolEbDepthsUpdatedPayload {
  type: "volatility/SurfaceUpdated";
  room_id: string;
  swift_id: number;
  vol_eb_depths: {
    [key: number]: VolEbDepthUnit[];
  };
}

export interface RemoveTenorPayload {
  type: "tenor/Removed";
  room_id: string;
  swift: string;
  tenor: number;
}

export interface CreateTenorPayload {
  type: "tenor/Created";
  room_id: string;
  swift: string;
  tenor: number;
}

export interface StrikeUpdatedPayload {
  type: "VolEb/strikesUpdated";
  room_id: string;
  room_time: string;
  strikes: string[];
}

export type VolEbOrder = {
  id: number;
  room_id: string;
  trader_id: string;
  trader_name: string;
  delta_strike: number;
  tenor: number;
  swift_id: number;
  order_type: number;
  direction: typeof BUY | typeof SELL;
  amount: number;
  visible_amount: number;
  volatility: number;
  status: number;
  chunk?: number;
  room_time: number;
};

export type VolEbOrderPayload = Omit<
  VolEbOrder,
  "delta_strike" | "tenor" | "volatility"
> & {
  delta_strike: number;
  tenor: number;
  volatility: string;
};

export type VolEbOrderAddedPayload = {
  type: "VolEb/OrderExercised";
  room_id: string;
  room_time: number;
  market_taker_id: string;
  market_taker_name: string;
  hit_amount: number;
  volatility: string;
  delta_strike: number;
  tenor: number;

  order: VolEbOrderPayload;
};

export type VolEbOrderExecutedPayload = {
  type: "VolEb/OrderAdded";
  room_id: string;
  room_time: number;
  order: VolEbOrder;
};

export interface VolEbOrderCanceledPayload {
  type: "VolEb/OrderCanceled";
  room_id: string;
  room_time: number;
  order: VolEbOrderPayload;
}

export type VolEbCancelOrderPayload = {
  order_id: number;
};
