import React, { useState } from "react";
import { Column, Row } from "components/reusable/GenericStyledComponents";
import styled from "styled-components";
import StyledText from "components/reusable/StyledText";
import { ScatterDataPoint } from "chart.js";
import Chart from "components/reusable/Chart";
import { InputNumber } from "antd";
import { CaretUpOutlined } from "@ant-design/icons";
import { YieldCurvesBE } from "types";

const YieldCurvesComponent: React.FC<{
  yield_curves: YieldCurvesBE;
  setYieldCurves: (val: YieldCurvesBE) => void;
}> = ({ yield_curves, setYieldCurves }) => {
  const { base_yc, variable_yc } = yield_curves;
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <Column style={{ gap: "2rem", width: "100%", alignItems: "center" }}>
        <Line />
        <ListTitle
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <h1>Yield Curves</h1>
          <div>
            <CaretUpOutlined
              style={{
                transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                transition: "all ease 300ms",
              }}
            />
          </div>
        </ListTitle>
        {expanded && (
          <Row style={{ gap: "1rem" }}>
            <Column>
              <Chart
                title="Base yield curves"
                graphColor="#22B793"
                chartValues={[
                  ...base_yc
                    .map(
                      (point, idx): ScatterDataPoint => ({
                        x: idx,
                        y: parseFloat(point.toFixed(2)),
                      })
                    )
                    .sort((a, b) => a.x - b.x),
                ]}
              />
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
                  alignItems: "center",
                }}
              >
                {React.Children.toArray(
                  base_yc.map((p, idx) => {
                    return (
                      <InputNumber
                        style={{ margin: ".125rem" }}
                        step={1}
                        value={parseFloat(p.toFixed(2))}
                        onChange={(value) => {
                          const newYieldCurves = { ...yield_curves };
                          const newBaseYc = [...newYieldCurves.base_yc];
                          newBaseYc[idx] = (value as number) || 0;
                          setYieldCurves({
                            ...newYieldCurves,
                            base_yc: newBaseYc,
                          });
                        }}
                      />
                    );
                  })
                )}
              </div>
            </Column>
            <Column>
              <Chart
                title="Variable yield curves"
                graphColor="#22B793"
                chartValues={[
                  ...variable_yc
                    .map(
                      (point, idx): ScatterDataPoint => ({
                        x: idx,
                        y: parseFloat(point.toFixed(2)),
                      })
                    )
                    .sort((a, b) => a.x - b.x),
                ]}
              />
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
                  alignItems: "center",
                }}
              >
                {React.Children.toArray(
                  variable_yc.map((p, idx) => {
                    return (
                      <InputNumber
                        style={{ margin: ".125rem" }}
                        step={1}
                        value={parseFloat(p.toFixed(2))}
                        onChange={(value) => {
                          const newYieldCurves = { ...yield_curves };
                          const newVariableYc = [...newYieldCurves.variable_yc];
                          newVariableYc[idx] = (value as number) || 0;
                          setYieldCurves({
                            ...newYieldCurves,
                            variable_yc: newVariableYc,
                          });
                        }}
                      />
                    );
                  })
                )}
              </div>
            </Column>
          </Row>
        )}
      </Column>
    </>
  );
};

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #2d363b;
  margin-left: 14px;
  margin-right: 14px;
`;

const ListTitle = styled(Row)`
  z-index: 100;
  &:hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
  }
  position: sticky;
  top: 0;
  align-items: center;
`;

export default YieldCurvesComponent;
