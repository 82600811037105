import React from "react";
import { useSelector } from "react-redux";

import styled from "styled-components";

import { RootState } from "store/rootReducer";
import StyledText from "components/reusable/StyledText";
import SpotRow from "./SpotRow";

const SpotBlotter = () => {
  const { spot_blotter } = useSelector((state: RootState) => state.blotter);

  return (
    <Container>
      <Table>
        <thead>
          <tr>
            <th>
              <TableHead fontSize={13} fontWeight={600}>
                Trade
              </TableHead>
            </th>
            <th>
              <TableHead fontSize={13} fontWeight={600}>
                Amount
              </TableHead>
            </th>
            <th>
              <TableHead fontSize={13} fontWeight={600}>
                Price
              </TableHead>
            </th>
          </tr>
        </thead>

        <tbody>
          {spot_blotter.map((deal) => (
            <SpotRow key={deal.id} deal={deal} />
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const Table = styled.table`
  background: transparent;
  width: 100%;
  padding: 11px 18px;

  & tbody tr:nth-child(odd) {
    // background: #192025; // layout
  }

  & tr th:first-child {
    padding-left: 18px;
  }

  & tbody tr td:first-child {
    padding-left: 18px;
  }

  & tr th {
    padding-bottom: 10px;
  }
`;

const TableHead = styled(StyledText)`
  color: #f8f7f770;
`;

export default SpotBlotter;
