import { Skeleton, Typography } from "antd";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { setDeltaHedge } from "store/spot/reducers";
import { useAppDispatch } from "store/store";
import styled, { css } from "styled-components";

const DeltaStrikeButtons: React.FC = () => {
  const {
    delta_strike_values,
    visible_vol_eb_delta_strike_values,
    selectedDelta,
    initial_state_loaded,
    swiftId,
  } = useSelector(
    (state: RootState) => ({
      delta_strike_values: state.spot.delta_strike_values,
      visible_vol_eb_delta_strike_values:
        state.spot.visible_vol_eb_delta_strike_values,
      selectedDelta: state.spot.selectedDelta,
      initial_state_loaded: state.spot.initial_state_loaded,
      swiftId: state.spot.swiftId,
    }),
    shallowEqual
  );

  const dispatch = useAppDispatch();

  return (
    <DeltaContainer>
      {visible_vol_eb_delta_strike_values[swiftId]
        .slice()
        .sort((a, b) => a - b)
        .map((index: number) => {
          const deltaValue = delta_strike_values[index];
          if (deltaValue && initial_state_loaded) {
            return (
              <DeltaButton
                key={deltaValue}
                selected={deltaValue === selectedDelta}
                onClick={() => {
                  dispatch(setDeltaHedge({ selectedDelta: deltaValue }));
                }}
              >
                {deltaValue}
              </DeltaButton>
            );
          }

          return <Skeleton />;
        })}
    </DeltaContainer>
  );
};

const StraddleStrikeButtons: React.FC = () => {
  return (
    <DeltaContainer>
      <Title>STRIKES</Title>

      <DeltaRow>
        <DeltaStrikeButtons />
      </DeltaRow>

      <Title style={{ textAlign: "right" }}>STRIKES</Title>
    </DeltaContainer>
  );
};

const DeltaContainer = styled.div<{ type?: "Buy" | "Sell" }>`
  display: flex;
  align-items: center;
  padding: 5px 0;
  justify-content: space-between;
  width: 100%;

  ${(props) => {
    if (props.type === "Buy") {
      return css`
        direction: rtl;
      `;
    }
  }}
`;

const Title = styled(Typography.Text)`
  flex: 1;
  font-size: 13px;
  font-weight: 600;
  display: block;
  cursor: default;
`;

const DeltaRow = styled.div`
  display: flex;
  gap: 10px;
  direction: ltr;
`;

const DeltaButton = styled.div<{ selected: boolean }>`
  padding: 0 5px;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 3px;

  ${(props) => {
    if (props.selected) {
      return css`
        border-color: ${(p) => p.theme.color3};
      `;
    }
  }}

  &:hover {
    background: #141414;
  }
`;

export default StraddleStrikeButtons;
