import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";

import { Permission, User, LoginResponse, InitialDataPayload } from "types";
import { clearRoom, retrieveInitialData } from "../room/actions";

const initialState: () => User = () => ({
  token: "",
  email: "",
  isAuthenticated: false,
  traderId: "",
  roomId: "",
  username: "",
  name: "",
  first_name: "",
  last_name: "",
  persistentId: 0,
  permission: Permission.None,
});

const userSlice = createSlice({
  initialState,
  name: "user",
  reducers: {
    userSuccessAuth: (
      state,
      action: PayloadAction<LoginResponse & { name: string }>
    ) => {
      try {
        const decodedJwt = jwtDecode<{
          exp: number;
          user_id: number;
          permission_group: Permission;
          name: string;
          first_name: string;
          last_name: string;
          email: string;
          username: string;
        }>(action.payload.access);

        state.email = decodedJwt.email;
        state.token = action.payload.access;
        state.username = decodedJwt.username;
        state.name = decodedJwt.name;
        state.first_name = decodedJwt.first_name;
        state.last_name = decodedJwt.last_name;
        state.persistentId = decodedJwt.user_id;
        state.isAuthenticated = true;
        state.permission = decodedJwt.permission_group;
      } catch (error) {
        console.error("Failed to decode token", error);

        // You might want to dispatch an action here to update your
        // application state to indicate that authentication failed
        // or directly update the state if it's managed within this slice
        state.isAuthenticated = false;
        state.token = "";
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        retrieveInitialData,
        (state, action: PayloadAction<InitialDataPayload>) => {
          state.traderId = action.payload.trader_id;
        }
      )
      .addCase(clearRoom, (state) => {
        state.traderId = "";
      });
  },
});

export const { userSuccessAuth } = userSlice.actions;
export default userSlice.reducer;
