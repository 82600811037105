export const CC_CREATED = "cc/created";
export const CC_ANSWERED = "cc/answered";
export const CC_ACCEPTED = "cc/accepted";
export const CC_DECLINED = "cc/declined";
export const CC_EXPIRED = "cc/expired";
export const CC_CANCELED = "cc/canceled";

export const CC_ANSWER_CALL = "cc/cmdAnswerCall";
export const CC_ACCEPT_CALL = "cc/cmdAcceptCall";
export const CC_DECLINE_CALL = "cc/cmdDeclineCall";
export const CC_CANCEL_CALL = "cc/cmdCancelCall";

export const CMD_CC_ANSWER_CALL = "clients.cmd.call.answer";
export const CMD_CC_ACCEPT_CALL = "clients.cmd.call.accept";
export const CMD_CC_DECLINE_CALL = "clients.cmd.call.decline";
export const CMD_CC_CANCEL_CALL = "clients.cmd.call.cancel";
