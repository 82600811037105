import { ApexOptions } from "apexcharts";

export const volChartOptions: (expanded: boolean) => ApexOptions = (
  expanded
) => ({
  chart: {
    type: "line",
    background: "transparent", // Dark background
    foreColor: "#ffffff90", // Light text color
    toolbar: {
      show: expanded,
    },
  },
  stroke: {
    curve: "straight",
  },
  markers: {
    size: 2,
  },
  theme: {
    mode: "dark",
    monochrome: {
      enabled: true,
      color: "rgb(0, 255, 0)",
      shadeTo: "light",
      shadeIntensity: 0.65,
    },
  },
  title: {
    text: "VOL",
    align: "left",
  },
  xaxis: {
    type: "datetime",
    axisBorder: {
      color: "gray",
    },
    axisTicks: {
      color: "gray",
    },
    labels: {
      style: {
        colors: ["#ffffff90"], // Set your desired color for y-axis labels
      },
    },
  },
  // Other global options...
  yaxis: {
    // This will change the y-axis label colors
    labels: {
      style: {
        colors: ["#ffffff90"], // Set your desired color for y-axis labels
      },
    },
    // This will change the y-axis tick colors (the small lines on the y-axis)
    axisTicks: {
      show: true,
      color: "#ffffff90", // Set your desired color for y-axis ticks
      width: 2,
      offsetX: 0,
      offsetY: 0,
    },
    // Other y-axis options...
  },
  grid: {
    show: true,
    borderColor: "#90A4AE", // Change the border color to your preference
    strokeDashArray: 0,
    position: "back",
    xaxis: {
      lines: {
        show: false,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
    },
    row: {
      colors: ["transparent", "#ffffff30"],
      opacity: 0.2,
    },
    column: {
      colors: undefined, // makes the colors of grid columns, alternates between two colors
      opacity: 0.2,
    },
    // padding: {
    //     top: 10,
    //     right: 10,
    //     bottom: 10,
    //     left: 10
    // },
    // Other grid options...
  },
  plotOptions: {
    candlestick: {
      colors: {
        upward: "#b7eb8f95", // Color for upward candles
        downward: "#ff787595", // Color for downward candles
      },
    },
  },
});
export const apexChartOptions: (expanded: boolean, title?: string) => ApexOptions = (
  expanded,
  title
) => ({
  chart: {
    type: "candlestick",
    background: "transparent", // Dark background
    foreColor: "#ffffff90", // Light text color
    toolbar: {
      show: expanded,
    },
  },
  theme: {
    mode: "dark",
    monochrome: {
      enabled: true,
      color: "rgb(0, 255, 0)",
      shadeTo: "light",
      shadeIntensity: 0.65,
    },
  },
  title: {
    text: title ? `Spot ${title}` : "Spot",
    align: "left",
  },
  xaxis: {
    type: "datetime",
    axisBorder: {
      color: "gray",
    },
    axisTicks: {
      color: "gray",
    },
    labels: {
      style: {
        colors: ["#ffffff90"], // Set your desired color for y-axis labels
      },
    },
  },
  // Other global options...
  yaxis: {
    // This will change the y-axis label colors
    labels: {
      style: {
        colors: ["#ffffff90"], // Set your desired color for y-axis labels
      },
    },
    // This will change the y-axis tick colors (the small lines on the y-axis)
    axisTicks: {
      show: true,
      color: "#ffffff90", // Set your desired color for y-axis ticks
      width: 2,
      offsetX: 0,
      offsetY: 0,
    },
    // Other y-axis options...
  },
  grid: {
    show: true,
    borderColor: "#90A4AE", // Change the border color to your preference
    strokeDashArray: 0,
    position: "back",
    xaxis: {
      lines: {
        show: false,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
    },
    row: {
      colors: ["transparent", "#ffffff30"],
      opacity: 0.2,
    },
    column: {
      colors: undefined, // makes the colors of grid columns, alternates between two colors
      opacity: 0.2,
    },
    // padding: {
    //     top: 10,
    //     right: 10,
    //     bottom: 10,
    //     left: 10
    // },
    // Other grid options...
  },
  plotOptions: {
    candlestick: {
      colors: {
        upward: "#b7eb8f95", // Color for upward candles
        downward: "#ff787595", // Color for downward candles
      },
    },
  },
});

export const intervals = {
  "1m": 60,
  "2m": 120,
  "5m": 300,
  "1D": 86400, // 1 Day
  "1W": 604800, // 1 Week
  "1M": 2592000, // 1 Month (approx.)
  "3M": 7776000, // 3 Months (approx.)
  "6M": 15552000, // 6 Months (approx.)
  "1Y": 31536000, // 1 Year (approx.)
};
