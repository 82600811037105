export interface User {
  isAuthenticated: boolean;
  token: string;
  traderId: string;
  username: string;
  name: string;
  last_name: string;
  first_name: string;
  email: string;
  roomId: string;
  persistentId: number;
  permission: Permission;
}

export enum Permission {
  "None" = "None",
  "Trainer" = "Trainer",
  "Admin" = "Admin",
  "User" = "User",
}

export type ModifiedUserType = {
  userId: number;
  first_name: string;
  last_name: string;
  email: string;
  username: string;
  password: string;
  confirmPassword: string;
  oldPassword: string;
};
