import React from "react";
import { useSelector } from "react-redux";
import {
  Form,
  Input,
  Button,
  Card,
  Row,
  Col,
  message,
  InputNumber,
} from "antd";
import { RootState } from "store/rootReducer";
import { trainerService } from "services";
import { requestCBState } from "store/centralbank/actions";
import { useAppDispatch } from "store/store";

type InitialValuesType = {
  [key: string]: number | string; // Allow for both string and number to accommodate InputNumber handling
};

const CBMetrics = () => {
  const dispatch = useAppDispatch();
  const { metrics } = useSelector((state: RootState) => state.centralbank); // Assuming roomId is part of the centralbank state
  const [form] = Form.useForm();
  const room_id = useSelector((state: RootState) => state.room.room_id);

  const onFinish = async (values: any) => {
    console.log("Received values from form: ", values);

    // Format the data as needed by your backend
    const formattedData = {
      metrics: metrics.map((metric) => ({
        main_metric_name: metric.main_metric_name,
        weight: values[`weight_${metric.main_metric_name}`] / 100,
        sub_metrics: metric.sub_metrics.map((subMetric) => ({
          sub_metric_name: subMetric.sub_metric_name,
          weight:
            values[
              `sub_metric_weight_${metric.main_metric_name}_${subMetric.sub_metric_name}`
            ] / 100,
        })),
      })),
    };

    try {
      await trainerService.updateMetrics(room_id, formattedData);
      message.success("Metrics updated successfully");
      dispatch(requestCBState());
      // Optional: refresh metrics data from the backend
    } catch (error) {
      console.error("Error updating metrics", error);
      message.error("Failed to update metrics");
    }
  };
  console.log({ metrics });

  // Prepopulate the form with values converted to percentages
  const initialValues: InitialValuesType = metrics.reduce<{
    [key: string]: number;
  }>((acc, metric) => {
    const metricWeightKey = `weight_${metric.main_metric_name}`;
    const metricWeight = parseFloat(metric.weight) * 100; // Ensure it's treated as a number

    acc[metricWeightKey] = metricWeight; // Convert to percentage

    metric.sub_metrics.forEach((subMetric) => {
      const subMetricWeightKey = `sub_metric_weight_${metric.main_metric_name}_${subMetric.sub_metric_name}`;
      const subMetricWeight = parseFloat(subMetric.weight) * 100; // Ensure it's treated as a number

      acc[subMetricWeightKey] = subMetricWeight; // Convert to percentage
    });

    return acc;
  }, {});
  if (!metrics.length) return <div>No metrics available.</div>;

  return (
    <Form
      style={{ marginTop: "1rem" }}
      form={form}
      onFinish={onFinish}
      layout="vertical"
      initialValues={initialValues}
    >
      <Row gutter={[16, 16]} justify="start">
        {metrics.map((metric, index) => (
          <Col xs={24} sm={12} md={8} key={metric.main_metric_name}>
            <Card
              title={metric.main_metric_display_name}
              size="small"
              style={{ height: "100%" }}
            >
              <Form.Item
                name={`weight_${metric.main_metric_name}`}
                label="Main Metric Weight (%)"
              >
                <InputNumber
                  size="small"
                  formatter={(value) => `${value}%`}
                  parser={(value) => value!.replace("%", "")}
                  style={{ width: "100%" }}
                />
              </Form.Item>
              {metric.sub_metrics.map((subMetric) => (
                <Form.Item
                  key={subMetric.sub_metric_name}
                  name={`sub_metric_weight_${metric.main_metric_name}_${subMetric.sub_metric_name}`}
                  label={`${subMetric.sub_metric_display_name} (%)`}
                >
                  <InputNumber
                    size="small"
                    formatter={(value) => `${value}%`}
                    parser={(value) => value!.replace("%", "")}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              ))}
            </Card>
          </Col>
        ))}
      </Row>
      <Col span={24} style={{ textAlign: "center" }}>
        <Button type="primary" htmlType="submit">
          Save Changes
        </Button>
      </Col>
    </Form>
  );
};

export default CBMetrics;
