import React, { useEffect, useState } from "react";
import TrainerNav from "../components/Trainer/TrainerNav";
import { getFromLS, setToLS } from "utils";
import styled from "styled-components";
import { Row } from "components/reusable/GenericStyledComponents";
import AdminScenario from "components/Admin/Scenario/AdminScenario";
import AdminCustomerNav from "components/AdminCustomers/AdminCustomerNav";
import { RootState } from "store/rootReducer";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/store";
import { adminService, trainerService } from "services";
import { getTrainerScenarios } from "store/trainer/reducers";
import AdminUsersPanel from "components/AdminCustomers/Users/AdminUsersPanel";
import AdminClassesPanel from "components/AdminCustomers/Classes/AdminClassesPanel";
import AdminTrainersPanel from "components/AdminCustomers/Trainers/AdminTrainersPanel";
import { Customer } from "types";

const initNav = () => {
  const nav = getFromLS("admincustomernav");
  return nav !== null ? nav : 0;
};

const AdminCustomer: React.FC = () => {
  const dispatch = useAppDispatch();
  const [customer, setCustomer] = useState<Customer>({ id: -1, name: "" });
  const windowUrl = window.location.pathname.split("/");
  const customer_id = parseInt(windowUrl[windowUrl.length - 1]);

  const fetchCustomer = async () => {
    const _customer = await adminService.getCustomerById(customer_id);
    setCustomer(_customer);
  };

  useEffect(() => {
    fetchCustomer();
  }, []);

  const [selectedNav, setSelectedNav] = useState(initNav());
  const changeNav = (idx: number) => {
    setToLS("admincustomernav", idx);
    setSelectedNav(idx);
  };

  return (
    <Row style={{ overflow: "scroll" }}>
      <AdminCustomerNav
        activeNav={selectedNav}
        changeNav={changeNav}
        name={customer.name}
      />
      {customer.id !== -1 && (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            padding: "36px 21px",
            overflow: "auto",
          }}
        >
          {selectedNav === 0 && (
            <div>
              <AdminClassesPanel customer={customer} />
            </div>
          )}
          {selectedNav === 1 && (
            <div>
              <AdminTrainersPanel customer={customer} />
            </div>
          )}
          {selectedNav === 2 && (
            <div>
              <AdminUsersPanel customer={customer} />
            </div>
          )}
        </div>
      )}
    </Row>
  );
};

export default AdminCustomer;
