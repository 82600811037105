import { MATURITY } from "types";

export enum MONTHS {
  Jan,
  Feb,
  Mar,
  Apr,
  May,
  Jun,
  Jul,
  Aug,
  Sep,
  Oct,
  Nov,
  Dec,
}

export enum DAYS {
  SUN,
  MON,
  TUE,
  WED,
  THU,
  FRI,
  SAT,
}

const prefixTimeWith0 = (time: number) => (time < 10 ? `0${time}` : `${time}`);

export const formatTime = (date: any): string => {
  if (!(typeof date.getMonth === "function")) {
    date = new Date(date);
  }
  const day: string = prefixTimeWith0(date.getDate());
  const month: string = MONTHS[date.getMonth()];
  const year = date.getFullYear();
  const time = `${prefixTimeWith0(date.getHours())}:${prefixTimeWith0(
    date.getMinutes()
  )}`;

  return `${day} / ${month} / ${year} ${time}`;
};

export const formatBlotterTime = (timestamp: number): string => {
  const date = new Date(timestamp * 1000);

  return `${MONTHS[date.getMonth()]} - ${date.getDate()}`;
};

export const timeUnixToDate = (timestamp: number): string => {
  if (timestamp === 0) return "-";
  const date = new Date(timestamp * 1000);
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1).toString()).slice(-2);
  const day = ("0" + date.getDate().toString()).slice(-2);

  return `${year}-${month}-${day}`;
};

export const timeUnixToDatePretty = (timestamp: number): string => {
  if (timestamp === 0) return "-";
  const date = new Date(timestamp * 1000);
  const year = date.getFullYear();
  const month = date.getMonth();
  return `${MONTHS[month]}-${year}`;
};

export const timeBEtoPretty = (timestamp: string): string => {
  // [year, month, day]
  const [year, month] = timestamp.split("-");
  return `${MONTHS[parseInt(month) - 1]}-${year}`;
};

export const timeDateToUnix = (unformattedDate: string): number => {
  const [day, month, year] = unformattedDate.split("-");
  const date = new Date(`${year}-${month}-${day}`);
  return date.getTime() / 1000;
};

export const timeDateToUnixYMD = (unformattedDate: string): number => {
  const [year, month, day] = unformattedDate.split("-");
  const date = new Date(`${year}-${month}-${day}`);
  return date.getTime() / 1000;
};

export const timeAbsoluteTenorToUnix = (unformattedDate: string): number => {
  const date = new Date(unformattedDate);
  return date.getTime() / 1000;
};

export const timeRFQ = (timestamp: number) => {
  const date = new Date(timestamp * 1000);

  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1).toString()).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
};

export const secondsToTimestamp = (seconds = 0) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor(seconds / 60) - hours * 60;
  const remSeconds = seconds - hours * 3600 - minutes * 60;

  return `${prefixTimeWith0(hours)}:${prefixTimeWith0(
    minutes
  )}:${prefixTimeWith0(remSeconds)}`;
};

export const secondsToHHMM = (seconds = 0) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor(seconds / 60) - hours * 60;

  return `${prefixTimeWith0(hours)}:${prefixTimeWith0(minutes)}`;
};

export const secondsToTimestampPretty = (seconds = 0) => {
  if (seconds === 0) return "0s";

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secondsLeft = seconds % 60;

  let result = "";

  if (hours > 0) {
    result += `${hours}h `;
  }
  if (minutes > 0) {
    result += `${minutes}m `;
  }
  if (secondsLeft > 0) {
    result += `${secondsLeft}s`;
  }

  return result.trim();
};

export const stickyModelAwareTenor = (tenor: number, sticky_model: number) => {
  return sticky_model ? MATURITY[tenor] : timeUnixToDatePretty(tenor);
};
