import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Card,
  Row,
  Col,
  message,
} from "antd";
import { adminService } from "services";
import { MetricsArray } from "types/reports";

type InitialValuesType = {
  [key: string]: number | string;
};

const AdminMetrics: React.FC = () => {
  const [metrics, setMetrics] = useState<MetricsArray>([]);
  const [form] = Form.useForm();

  const fetchMetrics = async () => {
    try {
      const _metrics = await adminService.getMetrics();
      setMetrics(_metrics);
    } catch (error) {
      console.error("Error fetching metrics:", error);
      message.error("Failed to fetch metrics");
    }
  };

  useEffect(() => {
    fetchMetrics();
  }, []);

  const onFinish = async (values: any) => {
    const formattedData = metrics.map((metric) => ({
      id: metric.id,
      main_metric_name: values[`name_${metric.id}`],
      main_metric_display_name: values[`displayName_${metric.id}`],
      weight: values[`weight_${metric.id}`] / 100,
      sub_metrics: metric.sub_metrics.map((subMetric) => ({
        id: subMetric.id,
        sub_metric_name: values[`subMetricName_${subMetric.id}`],
        sub_metric_display_name: values[`subMetricDisplayName_${subMetric.id}`],
        weight: values[`subMetricWeight_${subMetric.id}`] / 100,
      })),
    }));

    try {
      await adminService.updateMetrics(formattedData);
      message.success("Metrics updated successfully");
      fetchMetrics();
    } catch (error) {
      console.error("Error updating metrics:", error);
      message.error("Failed to update metrics");
    }
  };

  useEffect(() => {
    const newInitialValues = metrics.reduce<{
      [key: string]: number | string;
    }>((acc, metric) => {
      acc[`name_${metric.id}`] = metric.main_metric_name;
      acc[`displayName_${metric.id}`] = metric.main_metric_display_name;
      acc[`weight_${metric.id}`] = parseFloat(metric.weight) * 100;
      metric.sub_metrics.forEach((subMetric) => {
        acc[`subMetricName_${subMetric.id}`] = subMetric.sub_metric_name;
        acc[`subMetricDisplayName_${subMetric.id}`] =
          subMetric.sub_metric_display_name;
        acc[`subMetricWeight_${subMetric.id}`] =
          parseFloat(subMetric.weight) * 100;
      });
      return acc;
    }, {});

    // Update the form's values
    form.setFieldsValue(newInitialValues);
  }, [metrics, form]);

  //   const initialValues: InitialValuesType = metrics.reduce<{
  //     [key: string]: number | string;
  //   }>((acc, metric) => {
  //     acc[`name_${metric.id}`] = metric.main_metric_name;
  //     acc[`displayName_${metric.id}`] = metric.main_metric_display_name;
  //     acc[`weight_${metric.id}`] = parseFloat(metric.weight) * 100;
  //     metric.sub_metrics.forEach((subMetric) => {
  //       acc[`subMetricName_${subMetric.id}`] = subMetric.sub_metric_name;
  //       acc[`subMetricDisplayName_${subMetric.id}`] =
  //         subMetric.sub_metric_display_name;
  //       acc[`subMetricWeight_${subMetric.id}`] =
  //         parseFloat(subMetric.weight) * 100;
  //     });
  //     return acc;
  //   }, {});

  if (!metrics.length) return <div>No metrics available.</div>;

  return (
    <Form
      style={{ marginTop: "1rem", width: "100%" }}
      form={form}
      onFinish={onFinish}
      layout="vertical"
      //   initialValues={initialValues}
    >
      <Row gutter={[16, 16]} justify="start">
        {metrics.map((metric) => (
          <Col xs={24} sm={12} md={8} key={metric.id}>
            <Card
              title={`Metric: ${metric.main_metric_display_name}`}
              size="small"
              style={{ height: "100%" }}
            >
              <Form.Item name={`name_${metric.id}`} label="Metric Name">
                <Input />
              </Form.Item>
              <Form.Item name={`displayName_${metric.id}`} label="Display Name">
                <Input />
              </Form.Item>
              <Form.Item name={`weight_${metric.id}`} label="Weight (%)">
                <InputNumber
                  size="small"
                  formatter={(value) => `${value}%`}
                  parser={(value) => value!.replace("%", "")}
                  style={{ width: "100%" }}
                />
              </Form.Item>
              {metric.sub_metrics.map((subMetric) => (
                <React.Fragment key={subMetric.id}>
                  <div
                    style={{
                      border: "1px solid gray",
                      marginTop: ".5rem",
                      padding: ".5rem",
                      borderRadius: ".25rem",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Form.Item
                      name={`subMetricName_${subMetric.id}`}
                      label="Submetric Name"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name={`subMetricDisplayName_${subMetric.id}`}
                      label="Submetric Display Name"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name={`subMetricWeight_${subMetric.id}`}
                      label="Submetric Weight (%)"
                    >
                      <InputNumber
                        size="small"
                        formatter={(value) => `${value}%`}
                        parser={(value) => value!.replace("%", "")}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                    <Button
                      onClick={async () => {
                        await adminService.deleteSubmetric(subMetric.id);
                        await fetchMetrics();
                      }}
                    >
                      Delete submetric
                    </Button>
                  </div>
                </React.Fragment>
              ))}
              <Button
                style={{ margin: "0 auto" }}
                onClick={async () => {
                  await adminService.addSubmetric(metric.id, {
                    sub_metric_name: "New Submetric",
                    sub_metric_display_name: "New Submetric Display Name",
                    weight: "0.5",
                  });
                  await fetchMetrics();
                }}
              >
                Add submetric
              </Button>
              <Button
                style={{ margin: "0 auto" }}
                onClick={async () => {
                  await adminService.deleteMetric(metric.id);
                  await fetchMetrics();
                }}
              >
                Delete metric
              </Button>
            </Card>
          </Col>
        ))}
        <Col span={24} style={{ textAlign: "center" }}>
          <Button
            style={{ margin: "0 auto" }}
            onClick={async () => {
              await adminService.addMetric({
                main_metric_name: "New metric",
                main_metric_display_name: "New metric display name",
                weight: "1",
              });
              await fetchMetrics();
            }}
          >
            Add main metric
          </Button>
          <Button type="primary" htmlType="submit">
            Save Changes
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default AdminMetrics;
