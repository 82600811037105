import React, { useState } from "react";
import styled from "styled-components";

import { getState } from "store/store";
import { BID, ASK } from "stateConstants";
import { Column } from "../../../reusable/GenericStyledComponents";
import PlaceAskBidModal from "../../../Modal/PlaceAskBidModal";
import EBHeader from "../EBHeader";
import TradableVols from "../Vols/TradableVols";
import SellBuyButton from "../SellBuyButton";
import OrderButtons from "../OrderButtons";
import UnifiedSpotData from "../Spot/UnifiedSpotData";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";

const StickyDeltaBroker: React.FC = () => {
  const bestBid = useSelector((state: RootState) => state.spot.bestBid);
  const bestAsk = useSelector((state: RootState) => state.spot.bestAsk);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [mode, setMode] = useState<typeof BID | typeof ASK>(0);

  return (
    <div style={{ position: "relative" }}>
      <PlaceAskBidModal
        isShown={showModal}
        onClose={() => setShowModal(false)}
        mode={mode}
      />

      {/* Swift Header and Quantity Input */}
      <EBHeader />

      {/* Spot Quantity and Price */}
      <Row style={{ marginTop: "5px" }}>
        <Container>
          <TradableVols type="Sell" />
          {/* <SpotData order={bestBid} type="Sell" /> */}
        </Container>

        <Container>
          <TradableVols type="Buy" />
          {/* <SpotData order={bestAsk} type="Buy" /> */}
        </Container>
      </Row>

      <Row>
        <Container>
          <UnifiedSpotData order={bestBid} type="Sell" />
        </Container>
        <Container>
          <UnifiedSpotData order={bestAsk} type="Buy" />
        </Container>
      </Row>

      <Row style={{ marginTop: "5px" }}>
        <div style={{ width: "50%" }}>
          <SellBuyButton order={bestBid} type="Sell" />
        </div>
        <div style={{ width: "50%" }}>
          <SellBuyButton order={bestAsk} type="Buy" />
        </div>
      </Row>

      <OrderButtons setMode={setMode} setShowModal={setShowModal} />
    </div>
  );
};

const Container = styled(Column)`
  width: 100%;
  position: relative;
`;

const Row = styled.div`
  display: flex;
  gap: 5px;
`;

export default StickyDeltaBroker;
