import { createAction } from "@reduxjs/toolkit";

export const updateDeltaStrikeVolEb = createAction<{
  pattern: number[];
  swift_id: number;
}>("delta_strike_values/updateDeltaStrikeVolEb");
export const updateDeltaStrikeRest = createAction<{
  pattern: number[];
  swift_id: number;
}>("delta_strike_values/updateDeltaStrikeRest");
export const visibleDeltaStrikeRestConfirmation = createAction<{
  pattern: number[];
  swift_id: number;
}>("delta_strike_values/visibleRestConfirmation");
export const visibleDeltaStrikeVolEbConfirmation = createAction<{
  pattern: number[];
  swift_id: number;
}>("delta_strike_values/visibleVolEbConfirmation");
