import React from "react";
import styled, { css, keyframes } from "styled-components";
import { Link } from "react-router-dom";
import { Input, InputNumber, InputNumberProps, InputProps, Button } from "antd";

export const FloatInput = styled((props: InputProps) => <Input {...props} />)`
  padding-top: 0.75rem;
`;
export const FloatInputNumber = styled((props: InputNumberProps) => (
  <InputNumber {...props} />
))`
  padding-top: 0.75rem;
`;
export const FloatTextArea = styled((props: InputProps) => (
  <Input {...props} />
))`
  padding-top: 0.75rem;
`;

export const BaseGameContainer = styled.div`
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  // background: #171717;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${(p) => p.theme.color1};
`;

export const getScrollBarStyle = (isVertical: boolean) => css`
  &::-webkit-scrollbar {
    background: #141a1e;
    border-radius: 5px;
    display: block;
    ${isVertical ? "width: 8px;" : "height: 8px;"}
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #4f5a61;
    border-radius: 5px;
    border-right: none;
    border-left: none;
  }
`;

export const ViewClassButton = styled(Button)`
  border: 1px solid #ffffff40;
  border-radius: 6px;
  height: 34px;
  width: 148px;
  margin: 0;
  transition: 0.2s ease-in-out;

  &:hover {
    border-color: white;

    & p {
      color: white;
    }
  }
`;

export const RedCircle = styled.div`
  width: 7px;
  height: 7px;
  background-color: red;
  border-radius: 50%;
  box-shadow: 0 0 5px 2px red;
  margin: auto;
  padding: 0;
`;

export const GreenCircle = styled.div`
  width: 7px;
  height: 7px;
  background-color: green;
  border-radius: 50%;
  box-shadow: 0 0 5px 2px green;
  margin: auto;
  padding: 0;
`;

const breathe = keyframes`
  0% {
    box-shadow: 0 0 5px 2px #FFFF0050;
  }
  50% {
    box-shadow: 0 0 5px 2px #FFFF00;
  } 
  100% {
    box-shadow: 0 0 5px 2px #FFFF0050;
  }
`;

export const YellowCircle = styled.div<{ animate?: boolean }>`
  width: 7px;
  height: 7px;
  background-color: yellow;
  border-radius: 50%;
  // box-shadow: 0px 0px 5px 2px yellow;
  margin: auto;
  padding: 0;

  ${({ animate }) =>
    animate
      ? css`
          animation: ${breathe} 1.5s ease-in-out infinite;
        `
      : null}
`;

export const DataContainer = styled(Row)`
  gap: 15px;
  overflow-x: hidden;
  overflow-y: hidden;
  width: calc(100vw - 276px);
  padding-bottom: 10px;

  ${getScrollBarStyle(false)};
`;

export const AnimatedLink = styled(Link)`
  width: 100%;
  align-items: center;
  flex-direction: column;
  display: flex;
  &:hover {
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.3);
    transition: 0.2s ease-in-out;
  }
  &:active {
    box-shadow: 0 7px 7px rgba(0, 0, 0, 0.5);
    transform: scale(0.95);
    transition: 0.1s ease-in-out;
  }
`;

export const CallActionButton = styled.button`
  width: 100%;
  margin: 0;
  padding: 4px;
  border-radius: initial;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  background: rgba(0, 0, 0, 0.2);

  &:disabled {
    color: #ebebeb80;
    border-color: #ebebeb80;
  }
`;

export const RedButton = styled(CallActionButton)`
  color: #ff7875aa;
  border: 1px solid #ff7875aa;

  transition: all 0.2s;

  &:not(:disabled) {
    &:hover,
    &:active,
    &:focus {
      color: #ff7875 !important;
      border: 1px solid #ff7875 !important;
    }
  }
`;

export const GreenButton = styled(CallActionButton)`
  color: #b7eb8f95;
  border: 1px solid #b7eb8f95;

  transition: all 0.2s;

  &:not(:disabled) {
    &:hover,
    &:active,
    &:focus {
      color: #b7eb8f !important;
      border: 1px solid #b7eb8f !important;
    }
  }
`;

export const BlackButton = styled(CallActionButton)`
  width: fit-content;
  border-color: #8c8c8c;
  color: #8c8c8c;

  &:disabled {
    color: #ebebeb80;
    border-color: rgba(25, 32, 37, 0.8);
  }
`;

export const BrokerAreaContainer = styled(Column)`
  width: 100%;
  height: auto;
  // background: #1d313d;
  border: 1px solid red; // layout

  position: relative;
  justify-content: space-between;
`;

export const GenericSellBuyButton = styled(Button)`
  border-radius: 3px;
  width: 100%;

  color: #8c8c8c;
  background: #141414;
  border: 1px solid ${(p) => p.theme.color2} !important;
  font-size: 13px;
  height: 3rem;
  padding: 2px;
  white-space: pre-wrap;
  word-break: break-all;

  &:hover,
  &:active {
    border: 1px solid ${(p) => p.theme.color1} !important;
  }

  &:disabled {
    pointer-events: none;
  }
`;
