import { useEffect } from "react";
import { accessTokenKey, refreshTokenKey } from "utils/constants";
import { useAppDispatch } from "store/store";
import { userSuccessAuth } from "store/user/reducers";

export const useAuth = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const accessToken = localStorage.getItem(accessTokenKey);
    const refreshToken = localStorage.getItem(refreshTokenKey);

    if (accessToken && refreshToken) {
      dispatch(
        userSuccessAuth({
          access: accessToken,
          name: "user",
          refresh: refreshToken,
        })
      );
    }
  }, []);
};
