import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { SimpleEbOrder } from "types";
import StickyStrikeSellBuy from "./StickyStrikeSellBuy";
import StickyDeltaSellBuy from "./StickyDeltaSellBuy";
import SpotButtons from "./SpotButtons";

type Props = {
  order: SimpleEbOrder | null;
  type: "Sell" | "Buy";
};

const SellBuyButton: React.FC<Props> = ({ order, type }) => {
  const { selectedSellTenor, selectedBuyTenor, tradableVols, sticky_model } =
    useSelector(
      (state: RootState) => ({
        selectedSellTenor: state.spot.selectedSellTenor,
        selectedBuyTenor: state.spot.selectedBuyTenor,
        tradableVols: state.spot.tradableVols,
        sticky_model: state.spot.sticky_model,
      }),
      shallowEqual
    );

  const isSell = type === "Sell";
  const selectedTenor = isSell ? selectedSellTenor : selectedBuyTenor;
  const isSpot = selectedTenor === undefined;

  if (tradableVols) {
    if (isSpot) {
      return <SpotButtons order={order} type={type} />;
    }

    return sticky_model ? (
      <StickyDeltaSellBuy order={order} type={type} />
    ) : (
      <StickyStrikeSellBuy order={order} type={type} />
    );
  }
  return <></>;
};

export default SellBuyButton;
