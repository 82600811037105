import {
  Button,
  InputNumber,
  InputNumberProps,
  message,
  Select,
  Switch,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { BUY, CALL, PUT, SELL } from "stateConstants";
import { RootState } from "store/rootReducer";
import styled, { css, keyframes } from "styled-components";
import { numberWithCommas, percent2Digits, roundPremium } from "utils/numbers";
import { timeUnixToDatePretty } from "utils/time";
import { useAppDispatch } from "store/store";
import {
  removePricer,
  unlinkPricerCall,
  updatePricerField,
} from "store/newpricer/actions";
import { MATURITY, NewPricerColumn, StrategyType } from "types";
import { rfqMakeCall } from "store/rfq/actions";
import { CloseOutlined } from "@ant-design/icons";

const popup = keyframes`
  from {
    transform: scale(0, 100%);
  }

  to {
    transform: scale(100%, 100%);
  }
`;

const InfoText = styled(Typography.Text)`
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
`;

const PricerText = styled(Typography.Text)`
  font-size: 13px;
  font-weight: 500;
  color: #f8f7f7;
  justify-items: center;
  align-items: center;
  height: 100%;
  display: flex;
  margin: 5px auto;

  // ${() => css`
    //   animation: ${popup} 1s ease-in-out;
    //
  `}
`;

const Container = styled.div<{ color?: string; selected?: string }>`
  display: flex;
  justify-items: center;
  align-items: center;
  background-color: #141414;
  align-self: stretch;
  padding: 3px 1px;

  ${({ color, selected }) => {
    if (color) {
      return `
        border-left: 1px solid ${color};
        border-right: 1px solid ${color};
        ${selected ? `box-shadow: 1px 0px 0px ${color}` : ""};
      `;
    }

    return `
      border-left: 1px solid transparent;
      border-right: 1px solid transparent;
      `;
  }}
`;

const GreySpan = styled.span`
  color: #f8f7f780;
  white-space: nowrap;
`;

const RFQButton = styled(Button)`
  letter-spacing: 0.7px;
  border: transparent;
  border-radius: 2px;
  height: 25px;
  width: 100%;
  padding: 0;
`;

export const StateToInfo: React.FC<{ stateKey: string }> = ({ stateKey }) => {
  switch (stateKey) {
    case "direction":
      return <InfoText style={{ gridColumn: "1" }}>Buy / Sell</InfoText>;
    case "type":
      return <InfoText style={{ gridColumn: "1" }}>Call / Put</InfoText>;

    case "maturity":
      return <InfoText style={{ gridColumn: "1" }}>Maturity</InfoText>;
    case "notional":
      return <InfoText style={{ gridColumn: "1" }}>Notional</InfoText>;
    case "strike":
      return <InfoText style={{ gridColumn: "1" }}>Strike</InfoText>;
    case "spot":
      return <InfoText style={{ gridColumn: "1" }}>Spot</InfoText>;
    case "carry":
      return <InfoText style={{ gridColumn: "1" }}>Carry</InfoText>;

    case "divYield":
      return <InfoText style={{ gridColumn: "1" }}>DivYield</InfoText>;
    case "interestRates":
      return <InfoText style={{ gridColumn: "1" }}>Interest Rate</InfoText>;
    case "forward":
      return <InfoText style={{ gridColumn: "1" }}>Forward</InfoText>;
    case "volatility":
      return <InfoText style={{ gridColumn: "1" }}>Volatility</InfoText>;
    case "price":
      return (
        <InfoText style={{ gridColumn: "1" }}>
          Price <GreySpan>($)</GreySpan>
        </InfoText>
      );
    case "pricePercentOfSpot":
      return (
        <InfoText style={{ gridColumn: "1" }}>
          Price <GreySpan>(% Spot)</GreySpan>
        </InfoText>
      );
    case "premium":
      return (
        <InfoText style={{ gridColumn: "1" }}>
          Premium <GreySpan>($)</GreySpan>
        </InfoText>
      );
    case "lockVol":
      // bad
      return <div> </div>;
    case "rfq":
      // bad
      return <div> </div>;
    case "actions":
      return <InfoText style={{ gridColumn: "1" }}>Actions</InfoText>;
    case "noOfDelta":
      return (
        <InfoText style={{ gridColumn: "1" }}>
          Delta <GreySpan>(#S)</GreySpan>
        </InfoText>
      );

    case "vegaDollars":
      return (
        <InfoText style={{ gridColumn: "1" }}>
          Vega <GreySpan>($)</GreySpan>
        </InfoText>
      );

    case "noOfGamma":
      return (
        <InfoText style={{ gridColumn: "1" }}>
          Gamma <GreySpan>(#S)</GreySpan>
        </InfoText>
      );
    case "thetaDollars":
      return (
        <InfoText style={{ gridColumn: "1" }}>
          Theta <GreySpan>($)</GreySpan>
        </InfoText>
      );

    default:
      return null;
  }
};

type StateToColProps = {
  stateKey: string;
  col: number;
  debouncer: (pricer: NewPricerColumn) => void;
  pricer: NewPricerColumn;
  linked: {
    call_id: string;
    color: string;
  };
};

export const StateToColumn: React.FC<StateToColProps> = ({
  stateKey,
  col,
  debouncer,
  pricer,
  linked,
}) => {
  const dispatch = useAppDispatch();
  const [messageApi, contextHolder] = message.useMessage();

  const {
    delta_strike_values,
    sticky_model,
    visible_tenors,
    visible_delta_strike_values,
    tenors,
    swiftId,
  } = useSelector(
    (state: RootState) => ({
      delta_strike_values: state.spot.delta_strike_values,
      sticky_model: state.spot.sticky_model,
      visible_tenors: state.spot.visible_tenors,
      visible_delta_strike_values: state.spot.visible_delta_strike_values,
      tenors: state.spot.tenors,
      swiftId: state.spot.swiftId,
    }),
    shallowEqual
  );

  const bestBid = useSelector((state: RootState) => state.spot.bestBid);
  const bestAsk = useSelector((state: RootState) => state.spot.bestAsk);
  const { yield_curves } = useSelector((state: RootState) => state.room);

  const {
    PLAYERS_VOL_MIN_TICKET,
    PLAYERS_VOL_MAX_TICKET,
    SPOT_DECIMAL_PLACES,
  } = useSelector((state: RootState) => state.settings.parameters);

  const spotValue = pricer.type === CALL ? bestAsk?.price : bestBid?.price;
  const swift = useSelector((state: RootState) => state.swifts.selectedSwift);

  const [maturityOptions, setMaturityOptions] = useState<
    { label: string; value: number }[]
  >(
    visible_tenors[swiftId].map((k) => {
      return {
        value: tenors[swiftId][k],
        label: sticky_model
          ? MATURITY[tenors[swiftId][k]]
          : timeUnixToDatePretty(tenors[swiftId][k]),
      };
    })
  );

  const [strikeOptions, setStrikeOptions] = useState<
    { label: number; value: number }[]
  >(
    visible_delta_strike_values[swiftId].map((v) => {
      return { value: delta_strike_values[v], label: delta_strike_values[v] };
    })
  );

  useEffect(() => {
    if (!sticky_model)
      dispatch(
        updatePricerField({
          key: "strike",
          correlation_id: col,
          value: delta_strike_values[visible_delta_strike_values[swiftId][0]],
        })
      );
    dispatch(
      updatePricerField({
        key: "maturity",
        correlation_id: col,
        value: tenors[swiftId][visible_tenors[swiftId][0]],
      })
    );
  }, [
    visible_delta_strike_values,
    visible_tenors,
    tenors,
    delta_strike_values,
  ]);

  useEffect(() => {
    setMaturityOptions(
      visible_tenors[swiftId].map((k) => {
        return {
          value: tenors[swiftId][k],
          label: sticky_model
            ? MATURITY[tenors[swiftId][k]]
            : timeUnixToDatePretty(tenors[swiftId][k]),
        };
      })
    );
  }, [tenors, visible_tenors]);

  useEffect(() => {
    setStrikeOptions(
      visible_delta_strike_values[swiftId].map((v) => {
        return { value: delta_strike_values[v], label: delta_strike_values[v] };
      })
    );
  }, [delta_strike_values, visible_delta_strike_values]);

  useEffect(() => {
    debouncer(pricer);
  }, [pricer]);

  if (!pricer) return null;

  switch (stateKey) {
    case "direction":
      return (
        <Container
          style={{ gridColumn: `${col + 2}` }}
          color={pricer.linked_to ? linked.color : ""}
        >
          <Select
            size="small"
            style={{ width: "100%" }}
            options={[
              { label: "SELL", value: SELL },
              { label: "BUY", value: BUY },
            ]}
            onChange={(value) => {
              dispatch(
                updatePricerField({ key: stateKey, correlation_id: col, value })
              );
            }}
            value={pricer.direction}
            defaultValue={BUY}
          />
        </Container>
      );
    case "type":
      return (
        <Container
          style={{ gridColumn: `${col + 2}` }}
          color={pricer.linked_to ? linked.color : ""}
        >
          <Select
            size="small"
            style={{ width: "100%" }}
            options={[
              { label: "PUT", value: PUT },
              { label: "CALL", value: CALL },
            ]}
            onChange={(value) => {
              dispatch(
                updatePricerField({ key: stateKey, correlation_id: col, value })
              );
            }}
            value={pricer.type}
            defaultValue={CALL}
          />
        </Container>
      );
    case "maturity":
      return (
        <Container
          style={{ gridColumn: `${col + 2}` }}
          color={pricer.linked_to ? linked.color : ""}
        >
          <Select
            size="small"
            style={{ width: "100%" }}
            value={pricer.maturity}
            options={maturityOptions}
            onChange={(value) => {
              dispatch(
                updatePricerField({ key: stateKey, correlation_id: col, value })
              );
            }}
            defaultValue={tenors[swiftId][visible_tenors[swiftId][0]]}
          />
        </Container>
      );
    case "notional":
      return (
        <Container
          style={{ gridColumn: `${col + 2}` }}
          color={pricer.linked_to ? linked.color : ""}
        >
          {contextHolder}
          <InputNumber
            size="small"
            type="number"
            stringMode
            value={pricer.notional}
            onChange={(val) => {
              const value = Number(val);

              if (
                value < PLAYERS_VOL_MIN_TICKET ||
                value > PLAYERS_VOL_MAX_TICKET
              ) {
                messageApi.open({
                  key: "notional_error",
                  type: "error",
                  content: `Notional should be between ${PLAYERS_VOL_MIN_TICKET}-${PLAYERS_VOL_MAX_TICKET} `,
                });
              }

              dispatch(
                updatePricerField({
                  key: stateKey,
                  correlation_id: col,
                  value,
                })
              );
            }}
          />
        </Container>
      );
    case "strike":
      if (sticky_model) {
        return (
          <Container
            style={{ gridColumn: `${col + 2}` }}
            color={pricer.linked_to ? linked.color : ""}
          >
            <InputNumber
              size="small"
              style={{ width: "100%" }}
              stringMode
              defaultValue={bestAsk?.price || 0}
              value={pricer.strike}
              step={0.0001}
              onChange={(e) => {
                // bug in antd, sees e as number, but it's string
                if (e) {
                  dispatch(
                    updatePricerField({
                      key: stateKey,
                      correlation_id: col,
                      value: parseFloat(
                        Number(e as unknown as string).toFixed(4)
                      ),
                    })
                  );
                }
              }}
            />
          </Container>
        );
      }
      return (
        <Container
          style={{ gridColumn: `${col + 2}` }}
          color={pricer.linked_to ? linked.color : ""}
        >
          <Select
            style={{
              gridColumn: `${col + 2}`,
              width: "100%",
            }}
            size="small"
            options={strikeOptions}
            defaultValue={
              delta_strike_values[visible_delta_strike_values[swiftId][0]]
            }
            value={pricer.strike}
            onChange={(value) => {
              dispatch(
                updatePricerField({ key: stateKey, correlation_id: col, value })
              );
            }}
          />
        </Container>
      );
    case "spot":
      return (
        <Container
          style={{ gridColumn: `${col + 2}` }}
          color={pricer.linked_to ? linked.color : ""}
        >
          <PricerText>
            {spotValue ? numberWithCommas(spotValue, 4) : 0}
          </PricerText>
        </Container>
      );
    case "carry":
      return (
        <Container
          style={{ gridColumn: `${col + 2}` }}
          color={pricer.linked_to ? linked.color : ""}
        >
          <PricerText style={{ gridColumn: `${col + 2}` }}>
            {percent2Digits(
              parseFloat(
                yield_curves[swift.swift_id].variable_yc[pricer.maturity] || "0"
              ) -
                parseFloat(
                  yield_curves[swift.swift_id].base_yc[pricer.maturity] || "0"
                )
            )}{" "}
          </PricerText>
        </Container>
      );
    case "divYield":
      return (
        <Container
          style={{ gridColumn: `${col + 2}` }}
          color={pricer.linked_to ? linked.color : ""}
        >
          <PricerText style={{ gridColumn: `${col + 2}` }}>
            {percent2Digits(
              yield_curves[swift.swift_id].variable_yc[pricer.maturity]
            )}{" "}
          </PricerText>
        </Container>
      );
    case "interestRates":
      return (
        <Container
          style={{ gridColumn: `${col + 2}` }}
          color={pricer.linked_to ? linked.color : ""}
        >
          <PricerText style={{ gridColumn: `${col + 2}` }}>
            {percent2Digits(
              yield_curves[swift.swift_id].base_yc[pricer.maturity]
            )}{" "}
          </PricerText>
        </Container>
      );
    case "forward":
      return (
        <Container
          style={{ gridColumn: `${col + 2}` }}
          color={pricer.linked_to ? linked.color : ""}
        >
          <PricerText style={{ gridColumn: `${col + 2}` }}>
            {numberWithCommas(pricer.forward, SPOT_DECIMAL_PLACES)}
          </PricerText>
        </Container>
      );
    case "volatility":
      return (
        <Container
          style={{ gridColumn: `${col + 2}` }}
          color={pricer.linked_to ? linked.color : ""}
        >
          <VolInputNumber
            size="small"
            style={{ gridColumn: `${col + 2}`, width: "100%" }}
            value={pricer.volatility}
            precision={2}
            step={0.1}
            onChange={(value) => {
              dispatch(
                updatePricerField({
                  key: stateKey,
                  correlation_id: col,
                  value: value.toFixed(2) || 0,
                })
              );
            }}
          />
        </Container>
      );
    case "price":
      return (
        <Container
          style={{ gridColumn: `${col + 2}` }}
          color={pricer.linked_to ? linked.color : ""}
        >
          <PricerText style={{ gridColumn: `${col + 2}` }}>
            {" "}
            {numberWithCommas(pricer.price, 2)}{" "}
          </PricerText>
        </Container>
      );
    case "pricePercentOfSpot":
      return (
        <Container
          style={{ gridColumn: `${col + 2}` }}
          color={pricer.linked_to ? linked.color : ""}
        >
          <PricerText style={{ gridColumn: `${col + 2}` }}>
            {percent2Digits(pricer.pricePercentOfSpot)}
          </PricerText>
        </Container>
      );
    case "premium":
      return (
        <Container
          style={{ gridColumn: `${col + 2}` }}
          color={pricer.linked_to ? linked.color : ""}
        >
          <PricerText style={{ gridColumn: `${col + 2}` }}>
            {numberWithCommas(
              Math.round(
                (pricer.direction === BUY ? -1 : 1) *
                  roundPremium(pricer.premium * swift.vol_ticket_unit)
              ),
              3
            )}
          </PricerText>
        </Container>
      );
    case "rfq":
      return (
        <Container
          style={{ gridColumn: `${col + 2}` }}
          color={pricer.linked_to ? linked.color : ""}
        >
          <RFQButton
            type="primary"
            style={{ gridColumn: `${col + 2}` }}
            onClick={() => {
              if (sticky_model) {
                dispatch(
                  rfqMakeCall({
                    strategy: StrategyType.INDIVIDUAL_OPTION,
                    swiftId: swift?.swift_id || 0,
                    options: {
                      short_or_long: pricer.direction ? 1 : 0,
                      amount: pricer.notional,
                      put_or_call: pricer.type ? 1 : 0,
                      strike: pricer.strike,
                      tenor: pricer.maturity,
                    },
                  })
                );
              } else {
                dispatch(
                  rfqMakeCall({
                    strategy: StrategyType.INDIVIDUAL_OPTION,
                    swiftId: swift?.swift_id || 0,
                    options: {
                      short_or_long: pricer.direction ? 1 : 0,
                      amount: pricer.notional,
                      put_or_call: pricer.type ? 1 : 0,
                      strike: pricer.strike,
                      tenor: pricer.maturity,
                    },
                  })
                );
              }
            }}
          >
            RFQ
          </RFQButton>
        </Container>
      );
    case "lockVol":
      return (
        <Container
          style={{ gridColumn: `${col + 2}` }}
          color={pricer.linked_to ? linked.color : ""}
        >
          <Switch
            style={{ width: "100%" }}
            checked={!!pricer.lockVol}
            unCheckedChildren="Lock vol"
            checkedChildren="Unlock"
            onChange={() => {
              dispatch(
                updatePricerField({
                  key: stateKey,
                  correlation_id: col,
                  value: pricer.lockVol === 0 ? 1 : 0,
                })
              );
            }}
          />
        </Container>
      );
    case "actions":
      return (
        <Container
          color={pricer.linked_to ? linked.color : ""}
          style={{
            gridColumn: `${col + 2}`,
            borderTop: `1px solid ${pricer.linked_to ? linked.color : "#000"}`,
          }}
        >
          <Button
            danger
            size="small"
            style={{
              gridColumn: `${col + 2}`,
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              dispatch(unlinkPricerCall({ callId: pricer.linked_to }));
              dispatch(removePricer({ col: col.toString() }));
            }}
          >
            <CloseOutlined style={{ marginTop: 0 }} />
          </Button>
        </Container>
      );
    case "noOfDelta":
      return (
        <Container
          color={pricer.linked_to ? linked.color : ""}
          style={{ gridColumn: `${col + 2}` }}
        >
          <PricerText style={{ gridColumn: `${col + 2}` }}>
            {numberWithCommas(pricer.noOfDelta.toFixed(0))}
          </PricerText>
        </Container>
      );

    case "vegaDollars":
      return (
        <Container
          color={pricer.linked_to ? linked.color : ""}
          style={{
            gridColumn: `${col + 2}`,

            // boxShadow: "3px 3px 3px #00000020"
          }}
        >
          <PricerText style={{ gridColumn: `${col + 2}` }}>
            {numberWithCommas(pricer.vegaDollars.toFixed(0))}
          </PricerText>
        </Container>
      );
    case "noOfGamma":
      return (
        <Container
          color={pricer.linked_to ? linked.color : ""}
          style={{ gridColumn: `${col + 2}` }}
        >
          <PricerText style={{ gridColumn: `${col + 2}` }}>
            {numberWithCommas(pricer.noOfGamma.toFixed(0))}
          </PricerText>
        </Container>
      );
    case "thetaDollars":
      return (
        <Container
          color={pricer.linked_to ? linked.color : ""}
          style={{
            gridColumn: `${col + 2}`,
            borderBottom: `1px solid ${
              pricer.linked_to ? linked.color : "transparent"
            }`,
          }}
        >
          <PricerText style={{ gridColumn: `${col + 2}` }}>
            {pricer.type === CALL
              ? pricer.call_theta.toFixed(5)
              : pricer.put_theta.toFixed(5)}
          </PricerText>
        </Container>
      );
    default:
      return null;
  }
};

const VolInputNumber = styled((props: InputNumberProps<any>) => (
  <InputNumber {...props} />
))`
  & input {
    color: ${(p) => p.theme.primary} !important;
  }
`;

export const visiblePricerKeys = [
  "actions",
  "direction",
  "type",
  "notional",
  "maturity",
  "strike",
  // "spot",
  "carry",
  // "interestRates",
  // "divYield",
  "forward",
  "volatility",
  "lockVol",
  "rfq",
  "price",
  "pricePercentOfSpot",
  "premium",
  "delta",
  "gamma",
  "vega",
  "theta",
  "noOfDelta",
  "noOfGamma",
  "vegaDollars",
  "thetaDollars",
];
