import { Button, Input, InputNumber } from "antd";
import { Row } from "components/reusable/GenericStyledComponents";
import StyledText from "components/reusable/StyledText";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { updateSettings } from "store/settings/actions";
import { useAppDispatch } from "store/store";
import { ParameterTypes } from "types";
import { numberWithCommas } from "utils/numbers";

const PnlDivider: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    settings: { parameters },
  } = useSelector((state: RootState) => state.centralbank);

  const PNL_DISPLAY_DIVIDER = useSelector(
    (state: RootState) => state.settings.parameters.PNL_DISPLAY_DIVIDER
  );

  const pnl_divider = parameters.find(
    (p) => p.code === ("PNL_DISPLAY_DIVIDER" as unknown as ParameterTypes)
  );

  const [dividerValue, setDividerValue] = useState<number>(PNL_DISPLAY_DIVIDER);

  return (
    <Row
      style={{
        justifyContent: "space-between",
        alignItems: "center",
        gap: "20%",
      }}
    >
      <StyledText fontSize={14} fontWeight={400}>
        PnL divider:
      </StyledText>
      <Row style={{ alignItems: "center" }}>
        <InputNumber
          min={1}
          value={dividerValue}
          formatter={(value) => numberWithCommas(value)}
          parser={(value) => +(value || "1").replace(/[^\d.-]/g, "")}
          onChange={(value) => {
            setDividerValue(value || 1);
          }}
          onStep={(value: number, info: any) => {
            if (info.type === "up") setDividerValue((dividerValue || 1) * 10);
            if (info.type === "down")
              setDividerValue(Math.max((dividerValue || 1) / 10, 1));
          }}
        />
        <Button
          onClick={() => {
            if (pnl_divider)
              dispatch(
                updateSettings({
                  param_id: pnl_divider.id,
                  value: dividerValue,
                })
              );
          }}
        >
          Apply
        </Button>
      </Row>
    </Row>
  );
};

export default PnlDivider;
