import React from "react";
import { Link } from "react-router-dom";
import {
  HistoryOutlined,
  PieChartOutlined,
  PlayCircleOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import styled from "styled-components";

import { Column } from "../reusable/GenericStyledComponents";
import TrainerNavItem from "../reusable/TrainerNavItem";

interface ITrainerNavProps {
  activeNav: number;
  changeNav: (idx: number) => void;
}

const TrainerNav: React.FC<ITrainerNavProps> = ({ changeNav, activeNav }) => (
  <Container>
    <Link to="/">
      <Logo src="/logo2.png" alt="logo" />
    </Link>

    <TrainerNavItem
      icon={<TeamOutlined />}
      title="Classes"
      active={activeNav === 0}
      changeNav={() => changeNav(0)}
    />
    <TrainerNavItem
      icon={<PieChartOutlined />}
      title="Scenarios"
      active={activeNav === 1}
      changeNav={() => changeNav(1)}
    />
    <TrainerNavItem
      icon={<PlayCircleOutlined />}
      title="Live"
      active={activeNav === 2}
      changeNav={() => changeNav(2)}
    />
    <TrainerNavItem
      icon={<HistoryOutlined />}
      title="History"
      active={activeNav === 3}
      changeNav={() => changeNav(3)}
    />
  </Container>
);

const Container = styled(Column)`
  height: 100vh;
  width: 236px;
  background-color: ${(props) => props.theme.color0};
  box-shadow: 5px 0 20px 5px rgba(0, 0, 0, 0.3);
`;

const Logo = styled.img`
  padding: 20px 30px;
`;

export default TrainerNav;
