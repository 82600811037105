import React, { useEffect, useState } from "react";
import GenericModal from "../../../Modal/GenericModal";
import styled from "styled-components";
import {
  Column,
  Row,
  FloatInput,
} from "components/reusable/GenericStyledComponents";
import StyledText from "../../../reusable/StyledText";
import CloseButton from "assets/NavIcons/clear-button.svg";
import { TrainerEditPayload } from "types";
import FloatLabel from "components/reusable/FloatLabel";
import { Button, message } from "antd";
import { adminService } from "services";

type Props = {
  isShown: boolean;
  oldTrainer: TrainerEditPayload;
  closeModal: () => void;
};

const EditTrainerModal: React.FC<Props> = ({
  isShown,
  oldTrainer,
  closeModal,
}) => {
  const [editedTrainer, setEditedTrainer] =
    useState<TrainerEditPayload>(oldTrainer);
  useEffect(() => {
    setEditedTrainer(oldTrainer);
  }, [oldTrainer]);

  return (
    <GenericModal
      isShown={isShown}
      onRequestClose={closeModal}
      customClassName="GameRulesModal__Content"
      customOverlayClassName="MiddleModal__Overlay"
      overlayBackground="#00000060"
    >
      <Container style={{ overflowY: "auto" }}>
        <Row className="justify-content-between align-items-center">
          <StyledText
            fontSize={14}
            fontWeight={500}
            fontFamily="IBMPlexSans"
            color="#FFFFFF50"
          >
            Trainer edit
          </StyledText>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
          <img
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
            role="button"
            src={CloseButton}
            alt="delete"
            onClick={() => {
              closeModal();
            }}
          />
        </Row>
        <Column
          style={{
            marginTop: "1rem",
            marginLeft: ".5rem",
            gap: "1rem",
          }}
        >
          <FloatLabel label="First name" value={editedTrainer.first_name}>
            <FloatInput
              value={editedTrainer.first_name}
              onChange={(e) => {
                setEditedTrainer({
                  ...editedTrainer,
                  first_name: e.target.value,
                });
              }}
            />
          </FloatLabel>
          <FloatLabel label="Last name" value={editedTrainer.last_name}>
            <FloatInput
              value={editedTrainer.last_name}
              onChange={(e) => {
                setEditedTrainer({
                  ...editedTrainer,
                  last_name: e.target.value,
                });
              }}
            />
          </FloatLabel>
          <FloatLabel label="Username" value={editedTrainer.username}>
            <FloatInput
              value={editedTrainer.username}
              onChange={(e) => {
                setEditedTrainer({
                  ...editedTrainer,
                  username: e.target.value,
                });
              }}
            />
          </FloatLabel>
          <FloatLabel label="Password" value={editedTrainer.password}>
            <FloatInput
              value={editedTrainer.password}
              onChange={(e) => {
                setEditedTrainer({
                  ...editedTrainer,
                  password: e.target.value,
                });
              }}
            />
          </FloatLabel>
          <Button
            onClick={() => {
              adminService.editTrainer(editedTrainer).then((res) => {
                closeModal();
                message.success("Trainer added, please refresh the page!");
              });
            }}
          >
            Save
          </Button>
        </Column>
      </Container>
    </GenericModal>
  );
};
const Container = styled(Column)`
  width: 100%;
`;
export default EditTrainerModal;
