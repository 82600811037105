import { useEffect, useState } from "react";
import _ from "lodash";
import { getFromLS, setToLS } from "../utils";

export const useTheme = () => {
  const themes = getFromLS("trad-all-themes");
  const [theme, setTheme] = useState(themes.dark);
  const [themeLoaded, setThemeLoaded] = useState(false);

  const setMode = (mode: string) => {
    setToLS("trad-theme", themes[mode]);
    setTheme(themes[mode]);
  };

  const getFonts = () => _.values(_.mapValues(themes.data, "font"));

  useEffect(() => {
    const localTheme = getFromLS("trad-theme");
    if (localTheme) {
      setTheme(localTheme);
    } else {
      setTheme(themes.dark);
    }
    setThemeLoaded(true);
  }, []);

  return { theme, themeLoaded, setMode, getFonts };
};
