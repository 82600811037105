import React from "react";
import styled from "styled-components";
import StyledText from "../reusable/StyledText";
import {
  showScenarioModal,
  setSelectedScenario,
  hideScenarioModal,
} from "store/trainer/reducers";
import { useAppDispatch } from "store/store";
import ScenarioModal from "../Modal/ScenarioModal";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { Table, Space, Button, Typography } from "antd";
import { Permission } from "types";

const TrainerScenarios: React.FC = () => {
  const dispatch = useAppDispatch();

  const { scenarios, scenarioModalShown, assetClasses, instruments } =
    useSelector((state: RootState) => state.trainer);
  const permission = useSelector((state: RootState) => state.user.permission);

  return (
    <Container>
      <Typography.Title level={3}>Scenarios</Typography.Title>

      <DataContainer>
        <Table
          loading={!scenarios}
          rowKey="created"
          style={{ width: "100%" }}
          columns={[
            {
              dataIndex: "name",
              title: "Scenario name",
            },
            ...(permission === Permission.Admin
              ? [
                  {
                    dataIndex: "visible",
                    title: "Visible (Admin)",
                    filters: [
                      { text: "Visible", value: true },
                      { text: "Hidden", value: false },
                    ],
                    onFilter: (
                      value: string | number | boolean,
                      record: any
                    ) => {
                      return record.visible === value;
                    },
                    render: (_: any, record: any) => (
                      <Space size="small">
                        {record.visible ? "Yes" : "No"}
                      </Space>
                    ),
                  },
                ]
              : []),
            {
              filters: assetClasses.map((ac) => {
                return { text: ac, value: ac };
              }),
              onFilter: (value: string | number | boolean, record) => {
                return record.swift[0].asset_class === value;
              },
              dataIndex: "asset_class",
              title: "Asset class",
              render: (_, record) => (
                <Space size="middle">{record.swift[0].asset_class}</Space>
              ),
            },
            {
              filters: instruments.map((ac) => {
                return { text: ac, value: ac };
              }),
              onFilter: (value: string | number | boolean, record) => {
                return record.swift[0].code === value;
              },
              dataIndex: "instrument",
              title: "Instrument",
              render: (_, record) => (
                <Space size="middle">{record.swift[0].code}</Space>
              ),
            },
            {
              dataIndex: "duration",
              title: "Duration",
              render: (_, record) => (
                <Space size="middle">{`${record.duration / 3600}h`}</Space>
              ),
            },
            {
              title: "Actions",
              render: (_, record) => (
                <Space size="middle">
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(setSelectedScenario(record));
                      dispatch(showScenarioModal());
                    }}
                  >
                    <StyledText
                      fontSize={14}
                      fontWeight={400}
                      color="#FFFFFF"
                      fontFamily="IBMPlexSans"
                    >
                      New session
                    </StyledText>
                  </Button>
                </Space>
              ),
            },
          ]}
          dataSource={scenarios}
          pagination={false}
          expandable={{
            expandedRowRender: (record) => (
              <Space size="middle" style={{ margin: 0 }}>
                {record.description}
              </Space>
            ),
          }}
        />
      </DataContainer>

      <ScenarioModal
        showModal={scenarioModalShown}
        onClose={() => {
          dispatch(hideScenarioModal());
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const DataContainer = styled.div`
  margin-top: 1rem;
`;

export default TrainerScenarios;
