import React from "react";
import { useSelector } from "react-redux";
import { List, Modal, Typography } from "antd";

import { Column, Row } from "../reusable/GenericStyledComponents";
import { RootState } from "store/rootReducer";
import { numberWithCommas } from "utils/numbers";

type Props = {
  open: boolean;
  hide: () => void;
};

const GameRulesModal: React.FC<Props> = ({ open, hide }) => {
  const { game_rules } = useSelector((state: RootState) => state.settings);
  const swift = useSelector((state: RootState) => state.swifts.selectedSwift);
  const { SINGLE_OR_MULTIPLE_DAYS_TRADING } = useSelector(
    (state: RootState) => state.settings.parameters
  );

  if (!swift) return null;
  return (
    <Modal
      title="Trading Limits"
      open={open}
      onCancel={hide}
      footer={null}
      width="400px"
    >
      <List bordered>
        {React.Children.toArray(
          game_rules
            .filter((rule) => {
              if (!SINGLE_OR_MULTIPLE_DAYS_TRADING) {
                if (
                  rule.code === "REAL_SECONDS_TO_GAME_DAYS_RATIO" ||
                  rule.code === ""
                )
                  return false;
                return true;
              }
              return true;
            })
            .map(({ code, value }) => {
              return (
                <List.Item
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography.Text>{formatName(code)}</Typography.Text>
                  <Typography.Text>{numberWithCommas(value)}</Typography.Text>
                </List.Item>
              );
            })
        )}
      </List>

      {/* <Item name="Spot ticket unit" value={swift.spot_ticket_unit} />
        <Item name="Vol ticket unit" value={swift.vol_ticket_unit} /> */}

      {/* {Object.entries(settings).map((s) => { */}
      {/*  const [name, value] = s; */}
      {/*  if (value) */}
      {/*    return ( */}
      {/*      <div key={name}> */}
      {/*        <Row className="justify-content-between"> */}
      {/*          <StyledText fontSize={15} fontWeight={500}> */}
      {/*            {name} */}
      {/*          </StyledText> */}
      {/*          <StyledText fontSize={15} fontWeight={500}> */}
      {/*            {value} */}
      {/*          </StyledText> */}
      {/*        </Row> */}

      {/*        <div style={{ height: '1px', backgroundColor: '#FFFFFF60' }} /> */}
      {/*      </div> */}
      {/*    ); */}
      {/*  return null; */}
      {/* })} */}
      {/* <Item
          name="Player Spot Max Ticket"
          value={settings.parameters.PLAYERS_SPOT_MAX_TICKET}
        />
        <Item
          name="Player Spot Min Ticket"
          value={settings.parameters.PLAYERS_SPOT_MIN_TICKET}
        />
        <Item
          name="Player Volatility Max Ticket"
          value={settings.parameters.PLAYERS_VOL_MAX_TICKET}
        />
        <Item
          name="Player Volatility Min Ticket"
          value={settings.parameters.PLAYERS_VOL_MIN_TICKET}
        />
        <Item
          name="Player Position Limit"
          value={settings.parameters.PLAYERS_TOTALORDERS_LIMIT}
        /> */}
      {/* <Item name="Player Delta Limit" value={settings.parameters.PLAYERS_DELTA_LIMIT} /> */}
      {/* <Item name="Player Gamma Limit" value={settings.parameters.PLAYERS_GAMMA_LIMIT} /> */}
      {/* <Item name="Player Vega Limit" value={settings.parameters.PLAYERS_VEGA_LIMIT} /> */}
      {/* <Item name="Player Theta Limit" value={settings.parameters.PLAYERS_THETA_LIMIT} /> */}
    </Modal>
  );
};

const formatName = (name: string) => {
  if (name === "PLAYERS_TOTALORDERS_LIMIT") return "Players total orders limit";
  const parts = name.toLowerCase().split("_");
  return (
    parts
      .map((part, i) =>
        i === 0 ? part.charAt(0).toUpperCase() + part.slice(1) : part
      )
      .join(" ") + ":"
  );
};

export default GameRulesModal;
