import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Button, Card } from "antd";

import { Row, Column } from "components/reusable/GenericStyledComponents";
import { dateFromSeconds } from "utils/numbers";
import { RootState } from "store/rootReducer";
import { useAppDispatch } from "store/store";
import { finishRoom, resumeRoom, roomPause } from "store/room/actions";
import { useRoomTimeContext } from "contexts/RoomTimeProvider";
import { requestCBState } from "store/centralbank/actions";

const statusText = {
  0: "Starting..",
  1: "Running",
  2: "Paused",
  3: "Finished",
};

const CBRoomCommandLine: React.FC<{ mb?: string }> = ({ mb }) => {
  const dispatch = useAppDispatch();
  const { getRoomDate, gameTime } = useRoomTimeContext();
  const { paused, status } = useSelector(
    (state: RootState) => state.room,
    shallowEqual
  );
  const { REAL_SECONDS_TO_GAME_DAYS_RATIO } = useSelector(
    (state: RootState) => state.settings.parameters
  );
  const roomDate = getRoomDate();

  return (
    <Card style={{ marginBottom: "1.5rem" }}>
      <Row style={{ gap: "0.5rem" }}>
        <Row style={{ gap: "0.5rem", alignItems: "center" }}>
          {roomDate?.toDateString() ? `${roomDate?.toDateString()} | ` : ""}
          {`Game time: `}
          <span style={{ fontWeight: 500 }}>{dateFromSeconds(gameTime)}</span>|
          <span style={{ fontWeight: 500 }}>
            {Math.floor(gameTime / REAL_SECONDS_TO_GAME_DAYS_RATIO)}
          </span>
          {` (days)`}
        </Row>

        <Row style={{ gap: "1rem", marginLeft: "auto" }}>
          {status === 1 || status === 2 ? (
            <Button
              style={{
                borderColor: paused ? "#b7eb8f" : "#FFD369",
                color: paused ? "#b7eb8f" : "#FFD369",
              }}
              onClick={() => {
                if (paused) dispatch(resumeRoom());
                else dispatch(roomPause());
              }}
            >
              {!paused ? "Pause Session" : "Resume Session"}
            </Button>
          ) : (
            <></>
          )}

          {status !== 3 ? (
            <Button
              danger
              onClick={() => {
                dispatch(finishRoom());
                setTimeout(() => {
                  dispatch(requestCBState());
                }, 2000);
              }}
            >
              Finish Session
            </Button>
          ) : (
            <></>
          )}
        </Row>
      </Row>
    </Card>
  );
};

export default CBRoomCommandLine;
