import { Column, Row } from "components/reusable/GenericStyledComponents";
import React from "react";
import styled, { css, keyframes } from "styled-components";
import { Swift, TrainerScenarioBE, TrainerSwift } from "types";

type Props = {
  swift: Swift;
  selectedSwift: Swift | null;
  selectedScenario: TrainerScenarioBE;
  setSelectedSwift: React.Dispatch<React.SetStateAction<Swift | null>>;
  setSelectedScenario: React.Dispatch<React.SetStateAction<TrainerScenarioBE>>;
};

const SwiftCard: React.FC<Props> = ({
  swift,
  selectedScenario,
  selectedSwift,
  setSelectedSwift,
  setSelectedScenario,
}) => {
  return (
    <CardContainer
      selected={swift.id === selectedSwift?.id}
      onClick={() => {
        setSelectedSwift(swift);
        setSelectedScenario({
          ...selectedScenario,
          swift: [swift],
          price_curves: { [swift.code]: [] },
        });
      }}
      style={{
        gap: ".5rem",
        border: "1px solid gray",
        padding: "1rem",
        borderRadius: ".25rem",
      }}
    >
      <Row>Code: {swift.code}</Row>
      <Row>Name: {swift.name}</Row>
      <Row>Reporting currency: {swift.reporting_currency}</Row>
      <Row>
        Sticky model: {swift.sticky_model ? "Sticky delta" : "Sticky strike"}
      </Row>
      <Row>
        Tenor type: {swift.tenor_type ? "Relative tenor" : "Absolute tenor"}
      </Row>
    </CardContainer>
  );
};

const popup = keyframes`
  from {
    transform: scale(105%);
  }

  to {
    transform: scale(100%);
  }
`;

const CardContainer = styled.div<{
  selected: boolean;
}>`
  &:hover {
    background: #262f3550;
  }
  background: #262f35;
  margin-bottom: 2rem;
  ${(props) =>
    props.selected &&
    css`
      animation: ${popup} 0.5s ease-in-out;
      box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.25);
      background: #262f3550;
    `}
`;

export default SwiftCard;
